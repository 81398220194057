export enum Role {
  Admin = 'Admin',
  User = 'User',
}

export enum Department {
  Admin = 'Admin',
  Customer_Service = 'Customer Service',
  Managers = 'Managers',
  Purchase = 'Purchase',
  Sales = 'Sales',
  Warehouse = 'Warehouse',
}
