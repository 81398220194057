import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { CTA_FORM_FIELDS } from 'enums/customer-trade-application-form.enums';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: CTA_FORM_FIELDS.SHOP_NUMBER_DELIVERY_ADDRESS,
    label: 'Shop number',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.STREET_NUMBER_DELIVERY_ADDRES,
    label: 'Street number',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.STREET_NAME_DELIVERY_ADDRESS,
    label: 'Street name',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.SUBURB_DELIVERY_ADDRESS,
    label: 'Suburb',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.STATE_DELIVERY_ADDRESS,
    label: 'State',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.POSTCODE_DELIVERY_ADDRESS,
    label: 'Postcode',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'DELIVERY_ACCESS',
    label: 'Access to the delivery address (ex. from near door)',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
  },
  {
    name: 'BUSINESS_MATCH_DELIVERY_ADDRESS',
    label: 'Is the business address the same as the delivery one?',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Yes', 'No'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'POSTAL_MATCH_DELIVERY_ADDRESS',
    label: 'Is the postal address the same as the delivery one?',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Yes', 'No'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
];

export const BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'STREET_NUMBER_BUSINESS_ADDRESS',
    label: 'Street number',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'SUBURB_BUSINESS_ADDRESS',
    label: 'Suburb',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'STATE_BUSINESS_ADDRESS',
    label: 'State',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'POSTCODE_BUSINESS_ADDRESS',
    label: 'Postcode',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
];

export const BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'STREET_NUMBER_POSTAL_ADDRESS',
    label: 'Street number',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'SUBURB_POSTAL_ADDRESS',
    label: 'Suburb',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'STATE_POSTAL_ADDRESS',
    label: 'State',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
  {
    name: 'POSTCODE_POSTAL_ADDRESS',
    label: 'Postcode',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 3,
  },
];

export const BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1: Array<FormControlItemLayout> = [
  {
    name: CTA_FORM_FIELDS.REFERENCE_1,
    label: '1. Name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
  },
  {
    name: 'REFERENCE1_CONTACT_PERSON',
    label: 'Contact person',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.REFERENCE1_CONTACT_PHONE,
    label: 'Contact phone',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
];

export const BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2: Array<FormControlItemLayout> = [
  {
    name: CTA_FORM_FIELDS.REFERENCE_2,
    label: '2. Name',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
  },
  {
    name: 'REFERENCE2_CONTACT_PERSON',
    label: 'Contact person',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'REFERENCE2_CONTACT_PHONE',
    label: 'Contact phone',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
];

export const BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL = (type?: string): Array<FormControlItemLayout> => {
  return [
    {
      name: CTA_FORM_FIELDS.TYPE,
      label: 'Type of business',
      required: true,
      fullWidth: true,
      type: FORM_CONTROL_TYPE.NEW_SELECT,
      newSelectList: [
        'Restaurant',
        'Takeaway shop',
        'Grocery store',
        'Wholesale',
        'Distributor',
        'Online shop',
        'Other',
      ],
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.DEL_TIME,
      label: 'Business hours',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.BUSINESS_PHONE_DELIVERY_ADDRE,
      label: 'Business phone',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: 'BUSINESS_OTHER_TYPE',
      label: 'If "other" type of business, please specify below',
      required: !!type && type === 'Other' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.LIQUOR_LICENSE,
      label: 'Liquor license number',
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: 'EMAIL_ADDRESS',
      label: 'Email address',
      required: true,
      fullWidth: true,
      type: FORM_CONTROL_TYPE.EMAIL,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.STORE_CONTACT_NAME,
      label: "Store contact's name",
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.STORE_CONTACT_MOBILE_NUMBER,
      label: 'Store contact mobile number',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: CTA_FORM_FIELDS.SHOP_CONTACT_POSITION,
      label: 'Shop contact position',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 4,
    },
  ];
};

export const BUSINESS_DETAILS_MISC_LAYOUT2_CONDITIONAL = (
  isExistentCustomer?: string,
  knowOwnerOrStaff?: string
): Array<FormControlItemLayout> => {
  return [
    {
      name: CTA_FORM_FIELDS.IS_EXISTENT_CUSTOMER,
      label: 'Are you an existing customer of JFC?',
      required: true,
      fullWidth: true,
      type: FORM_CONTROL_TYPE.NEW_SELECT,
      newSelectList: ['Yes', 'No'],
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
    },
    {
      name: CTA_FORM_FIELDS.CUSTOMER_NUMBER,
      label: 'If yes, please provide your customer number',
      required: !!isExistentCustomer && isExistentCustomer === 'Yes' ? true : false,
      fullWidth: true,
      disabled: !!isExistentCustomer && isExistentCustomer === 'No' ? true : false,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
    },
    {
      name: 'KNOW_OWNER_OR_STAFF',
      label: 'Do you know any restaurant owners or staff who have business with JFC?',
      fullWidth: true,
      type: FORM_CONTROL_TYPE.NEW_SELECT,
      newSelectList: ['Yes', 'No'],
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 6,
    },
    {
      name: 'PROVIDE_CUSTOMER_NUMBER',
      label: 'If yes, please provide more details',
      required: !!knowOwnerOrStaff && knowOwnerOrStaff === 'Yes' ? true : false,
      fullWidth: true,
      disabled: !!knowOwnerOrStaff && knowOwnerOrStaff === 'No' ? true : false,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 6,
    },
    {
      name: CTA_FORM_FIELDS.SALES_REPRESENTATIVE1,
      label: 'If you know the sales representative in JFC, please write the name',
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
    },
    {
      name: 'RESTAURANT_NAME',
      label: 'Name of the restaurant where you used to work',
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
    },
  ];
};

export const BUSINESS_DETAILS_ABN_CERTIFICATE: FormControlItemLayout = {
  name: 'BUSINESS_DETAILS_ABN_CERTIFICATE',
  label: 'Please attach a valid ABN certificate',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.FILE_UPLOAD,
};
