import { useEffect, useState } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import { grey } from "@mui/material/colors";

import { formatCurrencyNumber, getPercentage } from "utils/common.utils";
import DashboardCard from "components/Card/DashboardCard";
import { useUserStore } from "state/user.store";
import { MultiColorBarChart } from "components/Charts/MultiColorBarChart";
import LoaderContainer from "components/common/LoaderContainer";
import API from "api";
import HomepageCard from "components/Card/HomepageCard";

const HomePage = () => {
  const [documentData, setDocumentData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(
    moment().startOf("month").toDate()
  );
  const [toDate, setToDate] = useState<Date | null>(moment().toDate());
  const { isAuth } = useUserStore();

  const documentApiCall = async () => {
    try {
      const { data } = await API.get("/documents/homepage-data", {
        params: {
          from: moment(fromDate).format("YYYY-MM-DD"),
          to: moment(toDate).format("YYYY-MM-DD"),
        },
      });
      console.log("Data ::", data);
      setDocumentData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    documentApiCall();
  }, [fromDate, toDate]);

  // disable past date
  function disablePrevDates(startDate: any) {
    const startSeconds = Date.parse(startDate);
    return (date: any) => {
      return Date.parse(date) < startSeconds;
    };
  }

  const processedInvoices = {
    name: "Invoices Processed",
    number: documentData?.cardData?.invoicesProcessed.count,
    icon: (
      <PublishedWithChangesIcon fontSize="large" sx={{ color: "#828b9b" }} />
    ),
    value: documentData?.cardData?.invoicesProcessed.total,
    percentage: getPercentage(
      documentData?.cardData?.invoicesProcessed.count,
      documentData?.cardData?.totalInvoices,
      1
    ),
    color: "#f3722c",
  };

  const turnAroundTime = {
    name: "Turnaround Time",
    number:
      Number(documentData?.cardData?.turnAroundTime ?? 0).toFixed(0) + " Days",
    icon: <AccessTimeFilledIcon fontSize="large" sx={{ color: "#828b9b" }} />,
    value: "",
    percentage: "",
    color: "#f3722c",
  };

  const waitingToBeProcessed = {
    name: "Waiting To Be Processed",
    number: documentData?.cardData?.waitingToBeProcessed.count,
    icon: <HourglassTopIcon fontSize="large" sx={{ color: "#828b9b" }} />,
    value: documentData?.cardData?.waitingToBeProcessed.total,
    percentage: getPercentage(
      documentData?.cardData?.waitingToBeProcessed.count,
      documentData?.cardData?.totalInvoices,
      1
    ),
    color: "#f3722c",
  };

  const invoiceCardData = [
    processedInvoices,
    turnAroundTime,
    waitingToBeProcessed,
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 500, color: grey[800] }}>
            Good day {isAuth?.firstName}!
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: grey[700] }}
          >
            Here's your overview for this month
          </Typography>
        </Box>

        <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
          <Typography sx={{ mx: 1 }}>From</Typography>
          <DatePicker
            label="Start Date"
            value={fromDate || null}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setFromDate(newValue);
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />

          <Typography sx={{ mx: 1 }}>To</Typography>
          <DatePicker
            label="End Date"
            value={toDate || null}
            inputFormat="dd/MM/yyyy"
            shouldDisableDate={disablePrevDates(fromDate)}
            onChange={(newValue: any) => {
              setToDate(newValue);
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </Box>
      </Box>

      <LoaderContainer open={loading}>
        <Box
          mt={3}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {invoiceCardData.map((data, index) => {
              return (
                <Grid key={index} item xs={12} md={4} lg={4} xl={4}>
                  <HomepageCard
                    amount={data.number}
                    title={data.name}
                    cardIcon={data.icon}
                    caption={data.value ? formatCurrencyNumber(data.value) : ""}
                    // subCaption={data.percentage ? data.percentage + "%" : ""}
                    // data={data}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              pt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sx={{ height: 400 }}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: 400 }}>
                  <MultiColorBarChart
                    title="Invoices $ by user status"
                    data={documentData?.chartData}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </LoaderContainer>
    </Box>
  );
};

export default HomePage;
