import { FC, useState } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { GeneralExpenseItem } from 'interfaces/myforms.interface'
import { GENERAL_EXPENSE_LAYOUT } from '../../form-layouts/general-expense.layout'
import FormControlItem, { FormControlItemLayout } from "../../form-components/FormControlItem";
import { generalClaimItemValidator, tableCellValue } from "utils/form.utils";
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";

interface GeneralExpenseFormMobileProps {
  items: GeneralExpenseItem[]
  onChange: (v: any) => void
  submitValidating: boolean,
  itemsValidating: boolean,
  setItemsValidating: (value: boolean) => void;
  formData?: GeneralExpenseItem,
  editData?: GeneralExpenseItem,
  setEditData: (value: GeneralExpenseItem | undefined) => void;
  handleChangeFormData: (value: any) => void,
  handleAddItem: () => void,
  formLayouts?: FormControlItemLayout[]
  editLayouts?: FormControlItemLayout[]
}

const GeneralExpenseFormMobile: FC<GeneralExpenseFormMobileProps> = ({
  items,
  onChange,
  submitValidating,
  itemsValidating,
  setItemsValidating,
  formData,
  editData,
  setEditData,
  handleChangeFormData,
  handleAddItem,
  formLayouts,
  editLayouts
}) => {

  const layouts = GENERAL_EXPENSE_LAYOUT

  const [collapsedItems, setCollapseItems] = useState<Array<string | number>>([])
  const [editValidating, setEditValidating] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleCollapseItems = (id: string | number) => {
    if (collapsedItems.includes(id)) {
      setCollapseItems([...collapsedItems.filter(item => item !== id)])
    } else {
      setCollapseItems([...collapsedItems, id])
    }
  }

  const handleDeleteItem = (id: string | number) => {
    if (id === editData?.id) {
      setEditData(undefined)
      setEditValidating(false)
      setItemsValidating(false)
    }
    const newData = items.filter(item => item.id !== id)
    onChange([...newData])
  }

  const handleEditItem = (item: GeneralExpenseItem) => {
    if (editData) {
      const contentOption = snackBarStyle({
        text: 'Please save the item you are editing.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return
    }
    setEditData(item)
  }

  const handleSaveEditedItem = () => {
    if (!generalClaimItemValidator(editData as GeneralExpenseItem)) {
      setEditValidating(true)
      // setItemsValidating(true)
      const contentOption = snackBarStyle({
        text: 'Please fillout all required fields.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return false
    }
    const newData: GeneralExpenseItem[] = []
    items.forEach((item) => {
      if (editData && editData.id === item.id) {
        newData.push({ ...editData })
      } else {
        newData.push({ ...item })
      }
    })
    onChange([...newData])
    setEditData(undefined)
    setEditValidating(false)
  }

  const handleSaveNewItem = () => {
    setEditValidating(false)
    handleAddItem()
  }

  return (
    <>
      {items.map((item, index) => {
        return (
          <Accordion
            key={item.id}
            sx={{ display: { xs: 'block', lg: 'none' } }}
            expanded={collapsedItems.includes(item.id)}
            onChange={() => handleCollapseItems(item.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{`${editData?.id === item.id ? 'Editing ' : ''}Claim ${index + 1}`}</Typography>
            </AccordionSummary>
            {editData?.id === item.id ?
              <Grid container spacing={2} sx={{ p: 2 }}>
                {editLayouts?.map((layout, index) => {
                  return (
                    <Grid key={index} item xs={layout.gridSizeMobile} sm={layout.gridSizeTablet}>
                      <FormControlItem
                        data={editData}
                        onChange={setEditData}
                        layout={layout}
                        submitValidating={submitValidating}
                        itemsValidating={editValidating}
                      />
                    </Grid>
                  )
                })}
              </Grid> :
              <AccordionDetails>
                <Grid container spacing={2}>
                  {layouts?.map((layout, index) => {
                    return (
                      <Grid key={index} item xs={layout.gridSizeMobile} sm={layout.gridSizeTablet}>
                        <TextField
                          fullWidth
                          required
                          variant="standard"
                          label={layout.label}
                          defaultValue={tableCellValue(layout, item)}
                          InputProps={{
                            readOnly: true,
                            disabled: true
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </AccordionDetails>}
            <AccordionActions sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              {editData?.id === item.id ?
                <Button size="small" variant="contained" onClick={handleSaveEditedItem}>Save</Button> :
                <Button size="small" variant="contained" onClick={() => handleEditItem(item)}>Edit</Button>}
              <Button size="small" variant="contained" onClick={() => handleDeleteItem(item.id)}>Delete</Button>
            </AccordionActions>
          </Accordion>
        )
      })}
      <Paper sx={{ p: 2, mt: 2, display: { xs: 'block', lg: 'none' } }} elevation={5}>
        <Typography>New Claim</Typography>
        <Grid container spacing={2}>
          {formLayouts?.map((layout, index) => {
            return (
              <Grid key={index} item xs={layout.gridSizeMobile} sm={layout.gridSizeTablet}>
                <FormControlItem
                  data={formData}
                  onChange={handleChangeFormData}
                  layout={layout}
                  submitValidating={submitValidating}
                  itemsValidating={itemsValidating && !editValidating}
                />
              </Grid>
            )
          })}
        </Grid>
        <Box>
          <Button size="small" variant="contained" onClick={handleSaveNewItem}>Save</Button>
        </Box>
      </Paper>
    </>
  )
}

export default GeneralExpenseFormMobile;
