import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import { ZoomTransition } from 'components/common/Transitions';
import { DialogProps } from 'interfaces/common.interfaces';
import { grey } from "@mui/material/colors";
import API from 'api'

interface SignUpSuccessDialogProps extends DialogProps {
  email: string;
}

const SuccessRegistration: React.FC<SignUpSuccessDialogProps> = ({ open, handleClose, email }) => {

  const [loading, setLoading] = useState<boolean>(false)

  const resendLink = async () => {
    setLoading(true)
    try {
      const { data } = await API.post('/auth/resend-link', { username: email, clientUrl: window.location.origin })
    } catch (error) {
      console.log("ERROR", error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 3000)

    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={ZoomTransition}
      maxWidth={false}
    >
      <DialogTitle
        sx={{ fontWeight: 700, display: 'flex', justifyContent: 'center' }}
      >
        Registration
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
          <Typography variant="h5">Verify your account</Typography>
          <Typography sx={{ color: grey[700] }}>Account activation link has been sent to the email address you provided.</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 3, justifyContent: 'center' }}>
          <Typography variant="caption">Didn't get the mail?</Typography>
          {loading ?
            <CircularProgress size={20} /> :
            <Link variant="caption" sx={{ cursor: 'pointer' }} onClick={resendLink}>Send it again</Link>}

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessRegistration;
