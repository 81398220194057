import { createTheme } from "@mui/material";
import { useSettingStore } from "state/setting.store";

const useTheme = () => {
  const { mode } = useSettingStore();

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#536DFE",
      },
      success: {
        main: "#4CAF50",
      },
      error: {
        main: "#F44336",
      },
    },
  });

  return theme;
};

export { useTheme };
