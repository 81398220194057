export const ENTERTAINMENT_EXPENSE_SUBCATEGORY: {
  label: string;
  value: string;
}[] = [{ label: 'Domestic', value: 'Domestic Entertainment' }];

export const TRAVEL_EXPENSE_SUBCATEGORY: { label: string; value: string }[] = [
  { label: 'Domestic', value: 'Domestic Travel' },
  { label: 'Overseas', value: 'Overseas Travel' },
];

export const ENTERTAINMENT_PARTY_AMOUNT_PER_PERSON: {
  label: string;
  value: number;
}[] = [
    { label: '$150', value: 150 },
    { label: '$200', value: 200 }
  ];