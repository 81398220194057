import { Checkbox, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { User } from 'interfaces/user.interface';
import { Column } from 'pages/common/users';
import { FC } from 'react'
import { Order, getComparator } from 'utils/table.utils';
import UserProfileCell from './UserProfileCell';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

interface UsersTableBodyProps {
    rows: User[],
    handleClickSelect: (userId: string) => void
    page: number;
    rowsPerPage: number;
    selected: readonly string[];
    columns: readonly Column[];
    emptyRows: number;
    order: Order;
    orderBy: string;
}

const UsersTableBody: FC<UsersTableBodyProps> = ({ rows, handleClickSelect, page, rowsPerPage, selected, columns, emptyRows, order, orderBy }) => {

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    return (
        <TableBody>
            {rows
                // @ts-ignore
                ?.sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: User, index: number) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                            hover
                            onClick={() => handleClickSelect(row.id)}
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.username}
                            selected={isItemSelected}
                        >
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    color='primary'
                                    checked={isItemSelected}
                                    size='small'
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                            {columns.map((column) => {
                                const value = row[column.key];
                                if (column.key === 'firstName') {
                                    return (
                                        <TableCell
                                            key={column.key}
                                            align='center'
                                        >
                                            <UserProfileCell
                                                firstName={row.firstName}
                                                lastName={row.lastName}
                                                username={row.username}
                                            />
                                        </TableCell>
                                    );
                                }

                                if (column.key === 'isActivated') {
                                    return (
                                        <TableCell
                                            key={column.key}
                                            align='center'
                                        >
                                            {row.isActivated ?
                                                <Tooltip title="Account Activated" placement="left-start">
                                                    <CheckCircleIcon fontSize="small" color="success" />
                                                </Tooltip> :
                                                <Tooltip title="Pending Account Confirmation" placement="left-start">
                                                    <PendingIcon fontSize="small" color="warning" />
                                                </Tooltip>
                                            }
                                        </TableCell>
                                    );
                                }

                                return (
                                    <TableCell
                                        key={column.key}
                                        align='center'
                                    >
                                        {value}
                                    </TableCell>
                                );

                            })}
                        </TableRow>
                    );
                })}
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: 33 * emptyRows,
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>
    )
}

export default UsersTableBody