import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { InvoiceSummary } from 'interfaces/invoice.interface';
import { useInvoiceStore } from 'state/invoice.store';

export interface ApproveProps {
  invoices: InvoiceSummary[];
}

const Approve: React.FC<ApproveProps> = ({ invoices }) => {
  const { singleSelected } = useInvoiceStore();

  return (
    <Table
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none',
        },
      }}
    >
      <TableHead
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: '2px solid grey',
          },
        }}
      >
        <TableRow>
          <TableCell sx={{ fontWeight: 600, width: 5 }}></TableCell>
          <TableCell sx={{ fontWeight: 600 }} align='left'>
            Invoice #
          </TableCell>
          <TableCell sx={{ fontWeight: 600 }} align='right'>
            Total Amount
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {invoices
          .filter(
            (invoice: InvoiceSummary) => singleSelected === invoice.docuwareId
          )
          .map((row: InvoiceSummary, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>1</TableCell>
              <TableCell
                align='left'
                sx={{
                  color: 'primary.main',
                  width: 300,
                  fontWeight: 500,
                }}
              >
                {row.invoiceNumber}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 500,
                }}
                align='right'
              >
                {row.totalAmount}{' '}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default Approve;
