import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import API from "api";
import LoaderContainer from "components/common/LoaderContainer";
import InvoicesTable from "components/invoices/InvoicesTable";
import InvoiceStatusTabs from "components/invoices/InvoiceStatusTabs";
import { ACCOUNTING, DEPT_MANAGERS, EXECUTIVES, MANAGERS } from "constants/departments";
import { DecisionTypeEnum, DOC_STATUS, WORKFLOW_STATUS } from "enums/invoice.enums";
import { useInvoiceStore } from "state/invoice.store";
import { useUserStore } from "state/user.store";
import InvoiceCard from "components/Card/InvoiceCard";
import toast from "react-hot-toast";

const Invoices = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>(null);
  const [searchString, setSearchString] = useState<string>("");
  const { logout, isAuth } = useUserStore();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const department = searchParams.get("department");
  const status = searchParams.get("status") ?? searchParams.get("workflow_status");
  const page = searchParams.get("page");
  const limit = searchParams.get("limit");

  const hasSearchParams = !!department && !!status && !!page && !!limit;

  const { bulkSelected, updateInvoice, processing } = useInvoiceStore();

  const getInvoicesRequest = async () => {
    setLoading(true);
    try {
      const { data } = await API.get("/documents", {
        params: {
          ...(searchParams.get("status") ? { status } : { workflow_status: status }),
          department,
          skip: Number(page) * Number(limit),
          limit: Number(limit),
          ...(searchString ? { search: searchString } : {}),
        },
      });
      if (data) setDocuments(data);
    } catch (err) {
      toast.error("Failed to retrieve invoices!");
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    getInvoicesRequest();
  };

  const handleChangeSearch = (event: any) => {
    setSearchString(event.target.value);
  };

  const handleDeleteSearch = () => {
    setSearchString("");
  };

  // refetching invoices after approve/reject/requestinfo
  useEffect(() => {
    if (processing === false && hasSearchParams) {
      getInvoicesRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing, searchParams, searchString]);

  useEffect(() => {
    const initialDepartment = isAuth?.department === DEPT_MANAGERS.FINANCIAL_CONTROLLER ? "All" : isAuth?.department;

    if (!status || !department || !page || !limit) {
      setSearchParams({
        status: DOC_STATUS.WAITING_APPROVAL,
        department: initialDepartment,
        page: "0",
        limit: "15",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            My Invoices
          </Typography>
          {loading ? (
            <CircularProgress sx={{ ml: 1.5 }} size={20} />
          ) : (
            <Tooltip title="Refresh" placement="right">
              <IconButton onClick={handleRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <TextField
          name="search"
          value={searchString}
          onChange={handleChangeSearch}
          label="Search by PO No., Invoice No. or Supplier Name"
          fullWidth={false}
          size="small"
          sx={{ width: "40%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleDeleteSearch}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {!!documents && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: 5,
            mt: 2,
            px: { lg: 10 },
          }}
        >
          <InvoiceCard
            amount={documents.find((doc: any) => doc.status === DOC_STATUS.WAITING_APPROVAL).count}
            title="Pending"
            cardIcon={<PendingActionsIcon fontSize="large" sx={{ color: "#828b9b" }} />}
          />
          <InvoiceCard
            amount={documents.find((doc: any) => doc.status === WORKFLOW_STATUS.APPROVED_DECISION).count}
            title="Approved"
            cardIcon={<CheckCircleIcon fontSize="large" sx={{ color: "#828b9b" }} />}
          />
          <InvoiceCard
            amount={documents.find((doc: any) => doc.status === WORKFLOW_STATUS.REJECTED_DECISION).count}
            title="Rejected"
            cardIcon={<ThumbDownIcon fontSize="large" sx={{ color: "#828b9b" }} />}
          />
        </Box>
      )}

      <Box
        sx={{
          py: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {hasSearchParams && <InvoiceStatusTabs status={status} currentSearchParams={currentSearchParams} />}
        {bulkSelected.length > 0 && status === DOC_STATUS.WAITING_APPROVAL ? (
          <Button
            variant="contained"
            color="success"
            size="medium"
            sx={{
              borderRadius: 6,
              boxShadow: 6,
              color: "white",
              textTransform: "capitalize",
            }}
            onClick={() =>
              updateInvoice({
                decisionType: DecisionTypeEnum.APPROVED,
              })
            }
          >
            Bulk Approve
          </Button>
        ) : null}
      </Box>

      <Paper elevation={5} sx={{ height: "100%", p: 2 }}>
        <Box sx={{ pb: 2 }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{ fontWeight: 600 }} id="simple-select-label">
              Department Manager
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={searchParams.get("department") ?? isAuth.department}
              onChange={(e: SelectChangeEvent) => {
                setSearchParams({
                  department: e.target.value,
                  page: "0",
                  limit: String(limit),
                  ...(status === WORKFLOW_STATUS.APPROVED_DECISION || status === WORKFLOW_STATUS.REJECTED_DECISION
                    ? { workflow_status: status }
                    : { status: status ?? DOC_STATUS.WAITING_APPROVAL }),
                });
              }}
              label="Department Manager"
            >
              {([...EXECUTIVES, ...ACCOUNTING].includes(isAuth.department)
                ? ["All", ...EXECUTIVES, ...ACCOUNTING, ...MANAGERS]
                : [isAuth.department]
              ).map((department: string) => {
                return (
                  <MenuItem key={department} value={department}>
                    {department}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <LoaderContainer open={loading}>
          {documents && hasSearchParams && (
            <InvoicesTable
              invoices={documents.find((invoice: any) => invoice.status === status).items}
              count={documents.find((invoice: any) => invoice.status === status).count}
              status={status}
              page={page}
              limit={limit}
              currentSearchParams={currentSearchParams}
            />
          )}
        </LoaderContainer>
      </Paper>
    </Box>
  );
};

export default Invoices;
