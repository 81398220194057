import { useEffect, useMemo, useState } from 'react';

import {
  CallMissedOutgoing,
  CheckCircle,
  Home,
  HourglassTop,
  LocalParking,
  Pause,
} from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BarChart } from 'components/Charts/BarChart';
import { PieChart, PieChartTooltip } from 'components/Charts/PieChart';
import moment from 'moment';
import DashboardCard from '../../components/Card/DashboardCard';

import Refresh from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import API from 'api';
import { VerticalChart } from 'components/Charts/VerticalChart';
import LoaderContainer from 'components/common/LoaderContainer';
import ParkedInvoicesDetailTable from 'components/tables/ParkedInvoicesDetailTable';
import PendingInvoicesDetailTable from 'components/tables/PendingInvoicesDetailTable';
import RejectedInvoicesDetailTable from 'components/tables/RejectedInvoicesDetailTable';
import WaitingInvoicesDetailTable from 'components/tables/WaitingInvoicesDetailTable';
import { DOCUMENT_TYPE_QUERY_ENUM } from 'interfaces/enums';
import {
  formatArray,
  formatCurrencyNumber,
  getMomentIntervalArray,
  getPercentage,
  getRandomColor,
} from 'utils/common.utils';

const Dashboard = () => {
  const [fromDate, setFromDate] = useState<Date | null>(
    moment().startOf('month').toDate()
  );
  const [toDate, setToDate] = useState<Date | null>(moment().toDate());
  const [documentData, setDocumentData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  // const [value, onChange] = useState([new Date(), new Date()]);

  const invoiceByMonth = useMemo(() => {
    const months = getMomentIntervalArray();

    return months.map((m) => {
      const label = m.format('MMM YYYY');

      const matchMonth = formatArray(documentData?.invoice_by_month).find(
        (item: any) => item.label === label
      );

      if (matchMonth) {
        return matchMonth;
      }

      return { label: label, value1: 0, value2: 0 };
    });
  }, [documentData.invoice_by_month]);

  // api call
  async function documentApiCall() {
    try {
      // console.log("from date AFFect::", moment(fromDate).format("YYYY-MM-DD"));
      // console.log("to date AFFect::", moment(toDate).format("YYYY-MM-DD"));

      const { data } = await API.get('/documents/total', {
        params: {
          to: moment(toDate).format('YYYY-MM-DD'),
          from: moment(fromDate).format('YYYY-MM-DD'),
          DOCUMENT_TYPE: DOCUMENT_TYPE_QUERY_ENUM.INVOICE,
        },
      });
      setDocumentData(data);
      console.log("Data ::", data);
    } catch (err) {
      console.error(err);
    }
  }

  const handleRefresh = () => {
    setLoading(true);
    documentApiCall().then(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    documentApiCall().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  const totalCardData = {
    name: 'Total Invoices',
    number: documentData?.total_invoice,
    icon: <Home fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.total_invoice_amount,
    percentage: 0,
    color: '#f94144',
  };

  const processedCardData = {
    name: 'Processed',
    number: documentData?.approved_invoice,
    icon: <CheckCircle fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.approved_invoice_amount,
    percentage: getPercentage(
      documentData?.approved_invoice,
      documentData?.total_invoice,
      1
    ),
    color: '#f3722c',
  };

  const waitingApprovalCardData = {
    name: 'Waiting Approval',
    number: documentData?.waiting_invoice,
    icon: <FavoriteIcon fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.waiting_invoice_amount,
    percentage: getPercentage(
      documentData?.waiting_invoice,
      documentData?.total_invoice,
      1
    ),
    color: '#f8961e',
    detailTableId: 'waiting-invoices-detail-table',
  };

  const pendingCardData = {
    name: 'Pending Tasks',
    number: documentData?.pending_invoice,
    icon: <Pause fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.pending_invoice_amount,
    percentage: getPercentage(
      documentData?.pending_invoice,
      documentData?.total_invoice,
      1
    ),
    color: '#f9c74f',
    detailTableId: 'pending-invoices-detail-table',
  };

  const rejectedCardData = {
    name: 'Rejected',
    number: documentData?.rejected_invoice,
    icon: <CallMissedOutgoing fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.rejected_invoice_amount,
    percentage: getPercentage(
      documentData?.rejected_invoice,
      documentData?.total_invoice,
      1
    ),
    color: '#90be6d',
    detailTableId: 'rejected-invoices-detail-table',
  };

  const parkedCardData = {
    name: 'Parked',
    number: documentData?.park_invoice,
    icon: <LocalParking fontSize='large' sx={{ color: '#828b9b' }} />,
    value: documentData?.park_invoice_amount,
    percentage: getPercentage(
      documentData?.park_invoice,
      documentData?.total_invoice,
      1
    ),
    color: '#43aa8b',
    detailTableId: 'parked-invoices-detail-table',
  };

  const processingCardData = {
    name: 'Processing',
    number: documentData?.park_invoice,
    icon: <HourglassTop fontSize='large' sx={{ color: '#828b9b' }} />,
    value:
      totalCardData?.value -
      processedCardData?.value -
      waitingApprovalCardData?.value -
      pendingCardData?.value -
      rejectedCardData?.value,
    percentage:
      100 -
      Number(processedCardData?.percentage) -
      Number(waitingApprovalCardData?.percentage) -
      Number(pendingCardData?.percentage) -
      Number(rejectedCardData?.percentage),
    color: '#577590',
  };

  const invoiceCardData = [
    totalCardData,
    processedCardData,
    waitingApprovalCardData,
    pendingCardData,
    rejectedCardData,
    parkedCardData,
  ];

  // disable past date
  function disablePrevDates(startDate: any) {
    const startSeconds = Date.parse(startDate);
    return (date: any) => {
      return Date.parse(date) < startSeconds;
    };
  }

  const noDataSection = (
    <Box
      sx={{
        height: 300,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>No Data Found.</Typography>
    </Box>
  );

  return (
    <Box>
      {/* Date-range picker */}
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ m: 1 }} variant='h4'>
              AP Dashboard
            </Typography>
            {loading ? (
              <CircularProgress sx={{ ml: 1.5 }} size={20} />
            ) : (
              <Tooltip title='Refresh'>
                <IconButton onClick={handleRefresh}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <LoaderContainer open={loading}>
            <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mx: 1 }}>From</Typography>

              <DatePicker
                label='Start Date'
                value={fromDate || null}
                inputFormat='dd/MM/yyyy'
                onChange={(newValue: any) => {
                  setFromDate(newValue);
                }}
                renderInput={(params) => <TextField size='small' {...params} />}
              />

              <Typography sx={{ mx: 1 }}>To</Typography>

              <DatePicker
                label='End Date'
                value={toDate || null}
                inputFormat='dd/MM/yyyy'
                // minDate={new Date(fromDate)}
                // disablePast
                shouldDisableDate={disablePrevDates(fromDate)}
                onChange={(newValue: any) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => <TextField size='small' {...params} />}
              />
            </Box>
          </LoaderContainer>
        </Box>
      </Box>

      {/* invoice item */}
      <Box
        mt={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {invoiceCardData.map((data, index) => {
            return (
              <Grid key={index} item sm={6} xs={12} md={4} lg={3} xl={2}>
                <DashboardCard
                  amount={data.number}
                  title={data.name}
                  cardIcon={data.icon}
                  caption={formatCurrencyNumber(data.value)}
                  subCaption={data.percentage ? data.percentage + '%' : ''}
                  data={data}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12} xl={6} xs={12}>
            <Card>
              <CardContent>
                {documentData?.invoice_by_month?.length > 0 ? (
                  <Box sx={{ height: 300, position: 'relative' }}>
                    <BarChart title='Invoices By Month' data={invoiceByMonth} />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                {documentData?.percentage_by_status?.length > 0 ? (
                  <Box sx={{ height: 300, position: 'relative' }}>
                    <PieChart
                      title={'Percentage By Status'}
                      data={[
                        processedCardData,
                        waitingApprovalCardData,
                        pendingCardData,
                        rejectedCardData,
                        processingCardData,
                      ].map((item) => ({
                        label: item?.name,
                        value: item?.percentage,
                        color: item?.color ?? getRandomColor(),
                      }))}
                      tooltip={PieChartTooltip}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12} xl={6} xs={12}>
            <Card>
              <CardContent>
                {documentData?.invoices_by_type?.length > 0 ? (
                  <Box sx={{ height: 300, position: 'relative' }}>
                    <BarChart
                      title='Invoices By Type'
                      data={documentData?.invoices_by_type}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                {documentData?.invoices_by_type?.length > 0 ? (
                  <Box sx={{ height: 300, position: 'relative' }}>
                    <PieChart
                      title={'Percentage By Type'}
                      data={documentData?.invoices_by_type?.map(
                        (item: any) => ({
                          label: item?.label,
                          value: item?.value1,
                          color: item?.color ?? getRandomColor(),
                        })
                      )}
                      tooltip={PieChartTooltip}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12} xl={6} xs={12}>
            <Card>
              <CardContent>
                {documentData?.top_ten_supplier?.length > 0 ? (
                  <Box sx={{ height: 300, position: 'relative' }}>
                    <VerticalChart
                      data={documentData?.top_ten_supplier}
                      title='Top 10 Suppliers - Year to Date'
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card sx={{ height: '100%' }} id={pendingCardData.detailTableId}>
              <CardContent>
                {documentData?.pending_invoices?.length > 0 ? (
                  <Box
                    sx={{ height: 300, position: 'relative', overflow: 'auto' }}
                  >
                    <PendingInvoicesDetailTable
                      title='Pending Approval Documents by Department'
                      data={documentData?.pending_invoices}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card
              sx={{ height: '100%' }}
              id={waitingApprovalCardData.detailTableId}
            >
              <CardContent>
                {documentData?.waiting_invoices?.length > 0 ? (
                  <Box
                    sx={{ height: 300, position: 'relative', overflow: 'auto' }}
                  >
                    <WaitingInvoicesDetailTable
                      title='Waiting Approval'
                      data={documentData?.waiting_invoices}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card sx={{ height: '100%' }} id={rejectedCardData.detailTableId}>
              <CardContent>
                {documentData?.rejected_invoices?.length > 0 ? (
                  <Box
                    sx={{ height: 300, position: 'relative', overflow: 'auto' }}
                  >
                    <RejectedInvoicesDetailTable
                      title='Rejected Invoices'
                      data={documentData?.rejected_invoices}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card sx={{ height: '100%' }} id={parkedCardData.detailTableId}>
              <CardContent>
                {documentData?.park_invoices?.length > 0 ? (
                  <Box
                    sx={{ height: 300, position: 'relative', overflow: 'auto' }}
                  >
                    <ParkedInvoicesDetailTable
                      title='Parked Invoices'
                      data={documentData?.park_invoices}
                    />
                  </Box>
                ) : (
                  noDataSection
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
