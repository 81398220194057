import { FC, useState } from 'react'

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { EntertainmentClaim } from "interfaces/myforms.interface";
import { ENTERTAINMENT_EXPENSE_LAYOUT } from "../../form-layouts/entertainment-expense.layout"
import FormControlItem, { FormControlItemLayout } from "../../form-components/FormControlItem"
import { YES_OR_NO } from "interfaces/enums"
import { snackBarStyle } from 'utils/common.utils';
import { entertainmentClaimItemValidator } from 'utils/form.utils';
import { Box } from '@mui/material';

interface EntertainmentFormDesktopProps {
    items: EntertainmentClaim[]
    onChange: (v: any) => void
    submitValidating: boolean,
    itemsValidating: boolean,
    setItemsValidating: (value: boolean) => void;
    formData?: EntertainmentClaim,
    editData?:  EntertainmentClaim,
    setEditData: (value: EntertainmentClaim | undefined) => void;
    handleChangeFormData: (value: any) => void,
    handleAddItem: () => void,
    formNumberLayout: FormControlItemLayout,
    formOverdue: FormControlItemLayout,
    lastSaleOverLayout: FormControlItemLayout,
    lastSalPaymentLayout: FormControlItemLayout,
    formLayouts: FormControlItemLayout[]
}

const EntertainmentFormDesktop: FC<EntertainmentFormDesktopProps> = ({
    items,
    onChange,
    submitValidating,
    itemsValidating,
    setItemsValidating,
    formData,
    editData,
    setEditData,
    handleChangeFormData,
    handleAddItem,
    formNumberLayout,
    formOverdue,
    lastSaleOverLayout,
    lastSalPaymentLayout,
    formLayouts,
}) => {

    const layouts = ENTERTAINMENT_EXPENSE_LAYOUT

    const handleDelete = (rowIndex: number) => {
        const newData = [...items.slice(0, rowIndex), ...items.slice(rowIndex + 1)]
        onChange(newData)
    }
    const [editValidating, setEditValidating] = useState<boolean>(false)
    
    const handleDeleteItem = (id: string | number) => {
        if (id === editData?.id) {
            setEditData(undefined)
            setEditValidating(false)
            setItemsValidating(false)
        }
        const newData = items.filter(item => item.id !== id)
        onChange([...newData])
    }

    const handleEditItem = (item: EntertainmentClaim) => {
        if (editData) {
            const contentOption = snackBarStyle({
                text: 'Please save the item you are editing.',
                variant: 'warning',
            })
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        setEditData(item)
    }

    const handleSaveEditedItem = () => {
        if (!entertainmentClaimItemValidator(editData as EntertainmentClaim)) {
            setEditValidating(true)
            const contentOption = snackBarStyle({
                text: 'Please fillout all required fields.',
                variant: 'warning',
            })
            enqueueSnackbar(contentOption.message, contentOption.options)
            return false
        }
        const newData: EntertainmentClaim[] = []
        items.forEach((item) => {
            if (editData && editData.id === item.id) {
                newData.push({ ...editData })
            } else {
                newData.push({ ...item })
            }
        })
        onChange([...newData])
        setEditData(undefined)
        setEditValidating(false)
    }

    return (
      <Paper elevation={5} sx={{ p: 2, display: { xs: 'none', lg: 'block' } }}>
        {items?.map((row, rowIndex) => (
          <div key={row.id}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                paddingBottom: 4,
                textAlign: 'center',
              }}
            >
              {`Claim ${rowIndex + 1}`}
            </Typography>

            {editData?.id === row.id ? (
           <>
              <Grid container spacing={2}>
                {formLayouts?.map((item, itemIndex) => (
                  <Grid
                    key={`${item.name}${itemIndex}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                  >
                    <FormControlItem
                      data={editData}
                      onChange={setEditData}
                      layout={item}
                      submitValidating={submitValidating}
                      itemsValidating={editValidating}
                    />
                  </Grid>
                ))}
              </Grid>
               <br />
               <Grid container spacing={4}>
                 <Grid
                   key={lastSaleOverLayout.name}
                   item
                   lg={3}
                   md={4}
                   sm={6}
                   xs={6}
                 >
                                              <FormControlItem
                              data={editData}
                              onChange={setEditData}
                              layout={lastSaleOverLayout}
                              submitValidating={submitValidating}
                              itemsValidating={editValidating}
                            />
                 </Grid>
                 <Grid
                   key={formNumberLayout.name}
                   item
                   lg={9}
                   md={4}
                   sm={6}
                   xs={6}
                 >
{editData?.lastSaleOver30d?.value ===
                              YES_OR_NO.YES && (
                              <FormControlItem
                                data={editData}
                                onChange={setEditData}
                                layout={formNumberLayout}
                                submitValidating={submitValidating}
                                itemsValidating={editValidating}
                              />
                            )}
                 </Grid>
               </Grid>
               <br />
               <Grid container spacing={4}>
                 <Grid
                   key={lastSalPaymentLayout.name}
                   item
                   lg={3}
                   md={4}
                   sm={6}
                   xs={6}
                 >
                               <FormControlItem
                              data={editData}
                              onChange={setEditData}
                              layout={lastSalPaymentLayout}
                              submitValidating={submitValidating}
                              itemsValidating={editValidating}
                            />
                 </Grid>
                 <Grid key={formOverdue.name} item lg={9} md={4} sm={6} xs={6}>
                 {editData?.isLastPaymentOverdue?.value ===
                              YES_OR_NO.YES && (
                              <FormControlItem
                                data={editData}
                                onChange={setEditData}
                                layout={formOverdue}
                                submitValidating={submitValidating}
                                itemsValidating={editValidating}
                              />
                            )}
                 </Grid>
               </Grid>
              </>
            ) : (     
                <>
            <Grid container spacing={2}>
              {formLayouts?.map((item, itemIndex) => (
                <Grid
                  key={`${item.name}${itemIndex}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <FormControlItem
                    data={row}
                    onChange={handleChangeFormData}
                    layout={{ ...item, disabled: true }}
                  />
                </Grid>
              ))}
            </Grid>
            <br />
            <Grid container spacing={4}>
              <Grid
                key={lastSaleOverLayout.name}
                item
                lg={3}
                md={4}
                sm={6}
                xs={6}
              >
                <FormControlItem
                  data={row}
                  onChange={handleChangeFormData}
                  layout={{ ...lastSaleOverLayout, disabled: true }}
                />
              </Grid>
              <Grid
                key={formNumberLayout.name}
                item
                lg={9}
                md={4}
                sm={6}
                xs={6}
              >
                {row?.lastSaleOver30d?.value === YES_OR_NO.YES && (
                  <FormControlItem
                    data={row}
                    onChange={handleChangeFormData}
                    layout={{ ...formNumberLayout, disabled: true }}
                  />
                )}
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
              <Grid
                key={lastSalPaymentLayout.name}
                item
                lg={3}
                md={4}
                sm={6}
                xs={6}
              >
                <FormControlItem
                  data={row}
                  onChange={handleChangeFormData}
                  layout={{ ...lastSalPaymentLayout, disabled: true }}
                />
              </Grid>
              <Grid key={formOverdue.name} item lg={9} md={4} sm={6} xs={6}>
                {row?.isLastPaymentOverdue?.value === YES_OR_NO.YES && (
                  <FormControlItem
                    data={row}
                    onChange={handleChangeFormData}
                    layout={{ ...formOverdue, disabled: true }}
                  />
                )}
              </Grid>
            </Grid>
            </>
            )}

            {editData?.id === row.id ? (
              <Button
                variant="contained"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={() => handleSaveEditedItem()}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={() => handleEditItem(row)}
              >
                Edit
              </Button>
            )}
            <Button
              variant="contained"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={() => handleDelete(rowIndex)}
            >
              Delete
            </Button>
          </div>
        ))}
        <div>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              paddingBottom: 4,
              textAlign: 'center',
            }}
          >
            New Claim
          </Typography>
          <Grid container spacing={4}>
            {formLayouts.map((item, itemIndex) => (
              <Grid
                key={`${item.name}${itemIndex}`}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <FormControlItem
                  data={formData}
                  onChange={handleChangeFormData}
                  layout={item}
                  submitValidating={submitValidating}
                  itemsValidating={itemsValidating}
                />
              </Grid>
            ))}
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid
              key={lastSaleOverLayout.name}
              item
              lg={3}
              md={4}
              sm={12}
              xs={12}
            >
              <FormControlItem
                data={formData}
                onChange={handleChangeFormData}
                layout={lastSaleOverLayout}
                submitValidating={submitValidating}
                itemsValidating={itemsValidating}
              />
            </Grid>
            <Grid
              key={formNumberLayout.name}
              item
              lg={9}
              md={8}
              sm={12}
              xs={12}
            >
              {formData?.lastSaleOver30d?.value === YES_OR_NO.YES && (
                <FormControlItem
                  data={formData}
                  onChange={handleChangeFormData}
                  layout={formNumberLayout}
                  submitValidating={submitValidating}
                  itemsValidating={itemsValidating}
                />
              )}
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid
              key={lastSalPaymentLayout.name}
              item
              lg={3}
              md={4}
              sm={12}
              xs={12}
            >
              <FormControlItem
                data={formData}
                onChange={handleChangeFormData}
                layout={lastSalPaymentLayout}
                submitValidating={submitValidating}
                itemsValidating={itemsValidating}
              />
            </Grid>
            <Grid key={formOverdue.name} item lg={9} md={8} sm={12} xs={12}>
              {formData?.isLastPaymentOverdue?.value === YES_OR_NO.YES && (
                <FormControlItem
                  data={formData}
                  onChange={handleChangeFormData}
                  layout={formOverdue}
                  submitValidating={submitValidating}
                  itemsValidating={itemsValidating}
                />
              )}
            </Grid>
          </Grid>
        </div>

        <br />
        <Button
          variant="contained"
          sx={{ textTransform: 'none' }}
          onClick={handleAddItem}
        >
          Save
        </Button>
      </Paper>
    );
}

export default EntertainmentFormDesktop

function enqueueSnackbar(message: string, options: { anchorOrigin: { vertical: "top" | "bottom"; horizontal: "right" | "left" | "center"; }; variant: "default" | "error" | "info" | "success" | "warning"; autoHideDuration: number; }) {
    throw new Error('Function not implemented.');
}
