import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, Typography } from "@mui/material"

import FormControlItem, { FormControlItemLayout } from "components/forms/form-components/FormControlItem";
import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { useSnackbar } from "notistack";
import { snackBarStyle } from "utils/common.utils";
import jwt_decode from 'jwt-decode';
import { JFC_ACCOUNT_SETUP_FORM_LAYOUT } from "components/users/layouts/jfc-account-setup-form.layout";
import { IS_ESPH, IS_JFC } from "constants/global";
import API from 'api'

export const PASSWORD_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'password',
        label: 'Password',
        type: FORM_CONTROL_TYPE.PASSWORD,
        required: true,
        fullWidth: true,
        autoComplete: 'off',
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'confirmPassword',
        label: 'Confirm Password',
        type: FORM_CONTROL_TYPE.PASSWORD,
        required: true,
        fullWidth: true,
        autoComplete: 'off',
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
];

const AccountSetup = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar()
    const [formData, setFormData] = useState<any>(null)
    const [validating, setValidating] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<string[] | null>(null)

    const JFC_CUSTOM_LAYOUT = useMemo(() => {
        return JFC_ACCOUNT_SETUP_FORM_LAYOUT.map(layout => {
            if (layout.name === 'branch') {
                return { ...layout, newSelectList: formData?.organisationCountry === 'NZ' ? ['ACK', 'CHC', 'WEL'] : ['HQT', 'SYD', 'BNE', 'GCS', 'SSC', 'MEL', 'PER', 'ADL', 'CAN'] }
            }
            return { ...layout }
        })
    }, [formData?.organisationCountry])

    const onChange = (value: any) => {
        setFormData(value)
    }

    const jfcValidator = () => {
        if (
            Boolean(formData) &&
            Boolean(formData.department) &&
            Boolean(formData.username) &&
            Boolean(formData.firstName) &&
            Boolean(formData.lastName) &&
            Boolean(formData.branch) &&
            Boolean(formData.employeeNumber) &&
            Boolean(formData.password) &&
            Boolean(formData.confirmPassword)
        ) return true
        return false
    }

    const esphValidator = () => {
        if (
            Boolean(formData) &&
            Boolean(formData.department) &&
            Boolean(formData.username) &&
            Boolean(formData.firstName) &&
            Boolean(formData.lastName) &&
            Boolean(formData.password) &&
            Boolean(formData.confirmPassword)
        ) return true
        return false
    }

    const handleSubmit = async () => {
        setValidating(true)
        if (IS_JFC) {
            if (!jfcValidator()) return
        }
        if (IS_ESPH) {
            if (!esphValidator()) return
        }
        if (formData?.password !== formData?.confirmPassword) {
            const contentOption = snackBarStyle({
                text: 'Passwords not match!',
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        try {
            setLoading(true)
            const { data } = await API.patch('/users/account-setup', { ...formData })
            if (data) {
                const contentOption = snackBarStyle({
                    text: 'Successfully activated account',
                    variant: "success",
                    autoHideDuration: 2000
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                setTimeout(() => {
                    navigate('auth/signin')
                }, 2000)
            }
        } catch (error) {
            console.log(error)
            const errors = (error as any).response.data.message
            if (Array.isArray(errors)) {
                setErrors(errors)
            } else {
                setErrors([errors])
            }
        } finally {
            setLoading(false)
            setValidating(false)
            sessionStorage.removeItem('public-path')
        }
    }


    const checkUser = async (id: string) => {
        const { data } = await API.get(`/users/${id}`)
        if (data.isActivated) {
            const contentOption = snackBarStyle({
                text: 'Account already active!',
                variant: "error",
                autoHideDuration: 2000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            navigate('auth/signin')
            sessionStorage.removeItem('public-path')
            return
        }
    }

    useEffect(() => {
        const data: any = jwt_decode(searchParams.get("token") as string)
        setFormData(data)
        checkUser(data.id)
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                {IS_JFC ? (
                    <img
                        src={`/static/img/JFC-LOGO-${formData?.organisationCountry}.png`}
                        alt='JFC'
                        style={{ width: '30%' }}
                    />
                ) : (
                    <img
                        src='/static/img/esph.png'
                        alt='ESPH'
                        style={{ width: '30%' }}
                    />
                )}

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, px: { xs: 2, sm: 15, md: 25, lg: 45, xl: 55 } }}>
                <Alert sx={{ mb: 2 }} severity="info">Please review your account details and make the changes if necessary.</Alert>
                {errors && (
                    <Box sx={{ pb: 2 }}>
                        {errors.map((error, index) => {
                            return (
                                <Box key={index}>
                                    <Typography color='error' variant='caption'>
                                        ⚠ {error}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                )}
                <Typography variant="body1" sx={{ fontWeight: 500, pb: 0.5 }}>Account Details</Typography>
                <Grid container spacing={1}>
                    {
                        JFC_CUSTOM_LAYOUT.map((item: FormControlItemLayout) => {
                            return (
                                <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                    <FormControlItem
                                        data={formData}
                                        onChange={onChange}
                                        layout={item}
                                        submitValidating={validating}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>


                <Typography variant="body1" sx={{ fontWeight: 500, pb: 0.5 }}>Setup Password</Typography>
                <Grid container spacing={1}>
                    {PASSWORD_LAYOUT.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                <LoadingButton
                    size="large"
                    onClick={handleSubmit}
                    loading={loading}
                    variant="contained"
                >
                    Confirm
                </LoadingButton>
            </Box>
        </>
    )
}

export default AccountSetup