import { Box, Typography } from '@mui/material';
import { Documents } from 'interfaces/document.interfaces';
import { FC, PropsWithChildren, useMemo } from 'react';
import {
  compareStrings,
  formatArray,
  formatCurrencyNumber,
  toNumber,
} from 'utils/common.utils';
import TreeTable, { DocumentsTree } from './treeTable/TreeTable';

const WaitingInvoicesDetailTable: FC<
  PropsWithChildren<{ title: string; data: Documents[] }>
> = ({ title = '', data = [] }) => {
  const formattedData = useMemo(
    () =>
      data.reduce((ret: DocumentsTree[], cur: Documents) => {
        const formattedCur = {
          ...cur,
          name: cur.INVOICE_NUMBER,
          count: 1,
          total: toNumber(cur.TOTAL),
        };
        const matchDepartmentIndex = ret.findIndex((item) =>
          compareStrings(item.name, formattedCur.DEPARTMENT)
        );

        if (matchDepartmentIndex < 0) {
          ret.push({
            name: formattedCur.DEPARTMENT,
            count: 1,
            total: toNumber(formattedCur.TOTAL),
            data: [
              {
                name: formattedCur.SUPPLIER_NAME,
                data: [formattedCur],
                count: 1,
                total: toNumber(formattedCur.TOTAL),
              },
            ],
          });
          return ret;
        }

        const matchSupplierIndex = ret[matchDepartmentIndex].data.findIndex(
          (item) => compareStrings(item.name, formattedCur.SUPPLIER_NAME)
        );

        const formattedSupplier = {
          name: formattedCur.SUPPLIER_NAME,
          count:
            toNumber(
              ret[matchDepartmentIndex].data?.[matchSupplierIndex]?.count
            ) + 1,
          total:
            toNumber(
              ret[matchDepartmentIndex].data?.[matchSupplierIndex]?.total
            ) + toNumber(formattedCur.TOTAL),
          data: [
            ...formatArray<Documents>(
              ret[matchDepartmentIndex].data?.[matchSupplierIndex]?.data
            ),
            formattedCur,
          ],
        };

        return [
          ...ret.slice(0, matchDepartmentIndex),
          {
            name: formattedCur.DEPARTMENT,
            count: toNumber(ret[matchDepartmentIndex].count) + 1,
            total:
              toNumber(ret[matchDepartmentIndex].total) +
              toNumber(formattedCur.TOTAL),
            data:
              matchSupplierIndex < 0
                ? [...ret[matchDepartmentIndex].data, formattedSupplier]
                : [
                    ...ret[matchDepartmentIndex].data.slice(
                      0,
                      matchSupplierIndex
                    ),
                    formattedSupplier,
                    ...ret[matchDepartmentIndex].data.slice(
                      matchSupplierIndex + 1
                    ),
                  ],
          },
          ...ret.slice(matchDepartmentIndex + 1),
        ];
      }, []),
    [data]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {title ? (
        <Typography
          sx={{ m: 1, fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}
          variant='h4'
        >
          {title}
        </Typography>
      ) : null}
      <Box sx={{ flexGrow: 1 }}>
        <TreeTable
          data={formattedData}
          childKey='data'
          columns={[
            {
              label: 'Supplier Name / Invoice#',
              key: 'name',
              cell: (row) => row?.name || '(blank)',
            },
            { label: 'Total Invoices', key: 'count' },
            {
              label: 'Total Amount',
              key: 'total',
              cell: (row) => formatCurrencyNumber(row?.total),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default WaitingInvoicesDetailTable;
