import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { CTA_FORM_FIELDS } from 'enums/customer-trade-application-form.enums';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const TERMS_AND_CONDITIONS_CONFIRMATION: FormControlItemLayout = {
  name: 'TERMS_AND_CONDITIONS_CONFIRMATION',
  label: 'I confirm I have read JFC Australia Terms and Conditions of Sale',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.NEW_SELECT,
  newSelectList: ['Yes', 'No'],
};

export const GUARANTOR_LAYOUT_1: Array<FormControlItemLayout> = [
  {
    name: CTA_FORM_FIELDS.GUARANTOR_FULL_NAME,
    label: "Guarantor's full name",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_POSITION',
    label: "Guarantor's position",
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.GUARANTOR_MOBILE,
    label: "Guarantor's mobile",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_RESIDENCY',
    label: "Guarantor's residency status",
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: [
      'Australian/New Zealand Citizen',
      'Permanent Resident',
      'Student Visa Resident',
      'Working Holiday Visa Resident',
      'Tourist Visa Resident',
      'Other',
    ],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: CTA_FORM_FIELDS.GUARANTOR_EMAIL,
    label: "Guarantor's email",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.EMAIL,
  },
  {
    name: 'GUARANTOR_DOB',
    label: "Guarantor's DOB",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.DATE,
  },
  {
    name: 'GUARANTOR_ADDRESS',
    label: "Guarantor's address",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'GUARANTOR_SIGNATURE',
    label: '1. Signature (Same signature as on your attached Photo ID) *',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const WITNESS_LAYOUT_1: Array<FormControlItemLayout> = [
  {
    name: 'WITNESS_FULL_NAME_1',
    label: "Witness' full name",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'WITNESS_EMAIL_1',
    label: "Witness' email",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.EMAIL,
  },
  {
    name: 'WITNESS_ADDRESS_1',
    label: "Witness' address",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'WITNESS_SIGNATURE_1',
    label: '1. Signature (Same signature as on your attached Photo ID) *',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const GUARANTOR_LAYOUT_2: Array<FormControlItemLayout> = [
  {
    name: 'GUARANTOR_FULL_NAME_2',
    label: "Guarantor's full name",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_POSITION_2',
    label: "Guarantor's position",
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_MOBILE_2',
    label: "Guarantor's mobile",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_RESIDENCY_2',
    label: "Guarantor's residency status",
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: [
      'Australian/New Zealand Citizen',
      'Permanent Resident',
      'Student Visa Resident',
      'Working Holiday Visa Resident',
      'Tourist Visa Resident',
      'Other',
    ],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'GUARANTOR_EMAIL_2',
    label: "Guarantor's email",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.EMAIL,
  },
  {
    name: 'GUARANTOR_DOB_2',
    label: "Guarantor's DOB",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.DATE,
  },
  {
    name: 'GUARANTOR_ADDRESS_2',
    label: "Guarantor's address",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'GUARANTOR_SIGNATURE_2',
    label: '2. Signature (Same signature as on your attached Photo ID)',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const WITNESS_LAYOUT_2: Array<FormControlItemLayout> = [
  {
    name: 'WITNESS_FULL_NAME_2',
    label: "Witness' full name",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'WITNESS_EMAIL_2',
    label: "Witness' email",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.EMAIL,
  },
  {
    name: 'WITNESS_ADDRESS_2',
    label: "Witness' address",
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'WITNESS_SIGNATURE_2',
    label: '2. Signature (Same signature as on your attached Photo ID)',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const DRIVERS_LICENSE: FormControlItemLayout = {
  name: 'DRIVERS_LICENSE',
  label: "Please attach a valid driver's license (front and back)",
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.FILE_UPLOAD,
};
