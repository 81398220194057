import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import { useSettingStore } from 'state/setting.store';
import SidebarRoutes from './SidebarRoutes';
import SidebarFooter from './SidebarFooter';

const drawerWidth = 210;

const Sidebar = () => {
  const theme = useTheme();

  const { extend } = useSettingStore();

  return (
    <Paper
      elevation={5}
      sx={{
        borderRadius: 0,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'auto',
        height: '100%',
        zIndex: 11,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        width: extend ? drawerWidth : `calc(${theme.spacing(8)} + 1px)`,

      }}
    >
      <SidebarRoutes />

      <SidebarFooter />
    </Paper>
  );
};

export default Sidebar;
