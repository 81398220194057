import { FC } from "react";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DialogProps } from "interfaces/common.interfaces";
import GeneralExpensePreview from "./GeneralExpense/GeneralExpensePreview";
import PurchaseRequestPreview from "./Purchase/PurchaseRequestPreview";
import TravelExpensePreview from "./TravelExpense/TravelExpensePreview";
import EntertainmentExpensePreview from "./Entertainment/EntertainmentExpensePreview";
import TaxiParkingExpensePreview from "./TaxiParking/TaxiParkingExpensePreview";


interface PreviewModalProps extends DialogProps {
    formId?: number;
    formType?: string;
}

const PreviewModal: FC<PreviewModalProps> = ({
    open,
    handleClose,
    formId,
    formType
}) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const renderForm = () => {
        switch (formType) {
            case 'Purchase Request':
                return <PurchaseRequestPreview formId={formId} paperView />;
            case 'General Expense':
                return <GeneralExpensePreview formId={formId} paperView={true} />;
            case 'Taxi/Parking Expense':
                return <TaxiParkingExpensePreview formId={formId} paperView={true} />;
            case 'Entertainment Expense':
                return <EntertainmentExpensePreview formId={formId} paperView={true} />;
            case 'Travel Expense':
                return <TravelExpensePreview formId={formId} paperView={true} />;
            default:
                return null
        }
    }

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
            sx={{
                "& div.MuiPaper-root": {
                    minHeight: "90vh",
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="end">
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            color: "black",
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {renderForm()}
            </DialogContent>
        </Dialog>
    );
};

export default PreviewModal;
