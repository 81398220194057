import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { PurchaseOrderSummary } from "interfaces/order.interface";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInvoiceStore } from "state/invoice.store";
import { createPurchaseOrderSummary } from "utils/poorder.utlils";
import PurchaseOrderActionIcon from "./table-cell/purchaseOrderActionIcon";

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

interface Column {
  key: keyof PurchaseOrderSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
  minWidth?: number;
  width?: number;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  {
    key: "po_number",
    numeric: false,
    disablePadding: false,
    label: "PO#",
    align: "center",
  },
  {
    key: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
    align: "center",
  },
  {
    key: "name",
    numeric: false,
    disablePadding: true,
    label: "Vendor Name",
    align: "center",
  },
  {
    key: "items",
    numeric: false,
    disablePadding: true,
    label: "Items",
    align: "center",
  },

  {
    key: "total_amount",
    numeric: true,
    disablePadding: true,
    label: "Total Amount",
    align: "center",
  },
  {
    key: "status",
    numeric: true,
    disablePadding: true,
    label: "Status",
    align: "center",
  },
  {
    key: "action",
    numeric: true,
    disablePadding: true,
    label: "Action",
    align: "center",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PurchaseOrderSummary
  ) => void;
  order: Order;
  orderBy: string;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
  type: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelectAllClick,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    numSelected,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rowCount,
    type,
  } = props;
  const createSortHandler =
    (property: keyof PurchaseOrderSummary) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align="center"
            padding={"normal"}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
            >
              {/* Below code is done due to request to wrap in two lines all two-word head titles on all screen sizes */}
              {headCell.label.split(" ").length === 2 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                    {headCell.label.split(" ")[0]}
                  </Typography>
                  <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                    {headCell.label.split(" ")[1]}
                  </Typography>
                </Box>
              ) : type === "pending" ? (
                headCell.label
              ) : (
                headCell.label.split("Action")
              )}

              {orderBy === headCell.key ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface PurchaseOrdersTableProps {
  orders: any;
  type?: any;
}

// props { orders }
const PurchaseOrdersTable: React.FC<PurchaseOrdersTableProps> = ({
  orders,
  type,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof PurchaseOrderSummary>("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState<any>([]);
  const [poNumber, setPoNumber] = useState<string | null>(null);

  const { bulkSelected, updateInvoice } = useInvoiceStore();

  const navigate = useNavigate();

  console.log("bulkSelectedbulkSelected", bulkSelected);
  useEffect(() => {
    const data = orders.map((order: PurchaseOrderSummary) => {
      return { ...createPurchaseOrderSummary(order) };
    });
    console.log("data", data);
    setRows(data);
  }, [orders]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PurchaseOrderSummary
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map(
        (row: PurchaseOrderSummary) => row.po_number
      );
      updateInvoice({ bulkSelected: newSelecteds });
      return;
    }
    updateInvoice({ bulkSelected: [] });
  };

  const isSelected = (name: string) => bulkSelected.indexOf(name) !== -1;

  const handleSelectRow = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = bulkSelected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(bulkSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(bulkSelected.slice(1));
    } else if (selectedIndex === bulkSelected.length - 1) {
      newSelected = newSelected.concat(bulkSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        bulkSelected.slice(0, selectedIndex),
        bulkSelected.slice(selectedIndex + 1)
      );
    }
    updateInvoice({ bulkSelected: newSelected });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log("poNumberpoNumber", poNumber);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <EnhancedTableHead
            type={type}
            numSelected={bulkSelected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort(getComparator(order, orderBy))
              .map((row: PurchaseOrderSummary, index: number) => {
                // console.log("Docuware id:::>>?::", row.docuwareId);
                const isItemSelected = isSelected(row.po_number);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.po_number}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleSelectRow(event, row.po_number)
                        }
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.key];
                      if (column.key === "po_number") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            <Link
                              sx={{ cursor: "pointer", textDecoration: "none" }}
                              onClick={() => {
                                navigate("/view-purchase-order", {
                                  state: { poId: row.po_number },
                                });
                                setPoNumber(row.po_number);
                              }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      }

                      if (column.key === "created_at") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            <>{moment(value).format("DD/MM/YYYY")}</>
                          </TableCell>
                        );
                      }

                      if (column.key === "name") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "items") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "total_amount") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "status") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                  value === "pending"
                                    ? "#FFB400"
                                    : value === "approved"
                                    ? "#4CAF50"
                                    : "#ff0000",
                                textTransform: "capitalize",
                              }}
                            >
                              {value === "pending" ? (
                                <AccessTimeIcon fontSize="small" />
                              ) : value === "approved" ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                              ) : (
                                <HighlightOffIcon fontSize="small" />
                              )}

                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 500 }}
                              >
                                {value}
                              </Typography>
                            </Box>
                          </TableCell>
                        );
                      }
                      if (row.status === "pending") {
                        if (column.key === "action") {
                          return (
                            <TableCell key={column.key} align={column.align}>
                              <PurchaseOrderActionIcon row={row} />
                            </TableCell>
                          );
                        }
                      }

                      return (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          sx={{ pr: 4, width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PurchaseOrdersTable;
