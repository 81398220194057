import { FC, useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import API from 'api'
import { User } from 'interfaces/user.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import { Department, Role } from 'enums/user.enum';
import { IS_JFC } from "constants/global";
import { JFC_USER_FORM_LAYOUT } from "./layouts/jfc-user-form.layout";
import { OrganisationCountry, OrganisationName } from "enums/organisation.enums";
import FormControlItem, { FormControlItemLayout } from "components/forms/form-components/FormControlItem";
import { ESPH_USER_FORM_LAYOUT } from "./layouts/esph-user-form.layout";
import { useUserStore } from "state/user.store";
import { createUserValidator } from "utils/user.utils";
import { JFC_AU_BRANCHES, JFC_NZ_BRANCHES } from "constants/jfc.constants";
import { useSnackbar } from "notistack";
import { snackBarStyle } from "utils/common.utils";

interface EditUserDialogProps {
    open: boolean;
    handleClose: () => void;
    user?: User;
    updateUsers: (updatedUser: User) => void;
}

interface UserFormData {
    role: Role;
    department: Department;
    username: string;
    firstName: string;
    lastName: string;
    branch: string;
    employeeNumber: string;
    vendorId: string;
    password: string;
}

const EditUserDialog: FC<EditUserDialogProps> = ({ open, handleClose, user, updateUsers }) => {

    const [formData, setFormData] = useState<UserFormData | null>(null)
    const [validating, setValidating] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()

    const isAuth = useUserStore(state => state.isAuth)

    const JFC_CUSTOM_LAYOUT = useMemo(() => {
        return JFC_USER_FORM_LAYOUT.map(layout => {
            if (layout.name === 'branch') {
                return { ...layout, newSelectList: isAuth?.country === OrganisationCountry.New_Zealand ? JFC_NZ_BRANCHES : JFC_AU_BRANCHES }
            }
            if (layout.name === 'username') {
                return { ...layout, disabled: true }
            }
            return { ...layout }
        })
    }, [isAuth])


    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleSubmit = async () => {
        setValidating(true)
        if (!createUserValidator(formData, IS_JFC ? OrganisationName.JFC : OrganisationName.ESPH)) return
        setIsProcessing(true)
        try {
            const { data } = await API.patch(`/users/${user?.id}`, { ...formData })
            if (data) {
                const contentOption = snackBarStyle({
                    text: "Successfully edited user",
                    variant: "success",
                    autoHideDuration: 3000
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                updateUsers(data)
                handleClose()
            }
        } catch (err) {
            console.log(err)
            const contentOption = snackBarStyle({
                text: "Error editing user",
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
        } finally {
            setIsProcessing(false)
        }
    }


    const onChange = (value: any) => {
        setFormData(value)
    }

    useEffect(() => {
        setFormData({ ...user } as UserFormData)
    }, [])

    return (
        <Dialog open={open} onClose={handleClose} sx={{ '& div.MuiPaper-root': { width: '100%', mx: 2, p: 1, pt: 0.5 } }}>
            <IconButton
                sx={{ position: 'absolute', right: 0 }}
                onClick={handleClose}
            >
                <CloseIcon fontSize='medium' />
            </IconButton>
            <DialogTitle sx={{ fontWeight: 700, display: 'flex', justifyContent: 'center' }}>Edit User</DialogTitle>

            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                <Grid container spacing={1} sx={{ pt: 2 }}>
                    {IS_JFC ? JFC_CUSTOM_LAYOUT.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    }) :
                        ESPH_USER_FORM_LAYOUT.map((item: FormControlItemLayout) => {
                            return (
                                <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                    <FormControlItem
                                        data={formData}
                                        onChange={onChange}
                                        layout={item}
                                        submitValidating={validating}
                                    />
                                </Grid>
                            )
                        })
                    }

                </Grid>


            </DialogContent>
            <DialogActions sx={{ px: 2 }}>
                <Button fullWidth disabled={isProcessing} variant='outlined' onClick={handleClose}>Cancel</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    loading={isProcessing}
                    variant="contained"
                    type='submit'
                    fullWidth
                >
                    Confirm
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}

export default EditUserDialog