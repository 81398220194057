import { FC, useState } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton'

import { DialogProps } from 'interfaces/common.interfaces';
import API from 'api'
import { useCustomerStore } from "state/customer.store";
import { useSnackbar } from "notistack";
import { snackBarStyle } from "utils/common.utils";
import { useFormStore } from "state/form.store";

interface DeleteCreditCardDialogProps extends DialogProps { }

const DeleteCreditCardDialog: FC<DeleteCreditCardDialogProps> = ({ open, handleClose }) => {

  const { enqueueSnackbar } = useSnackbar()
  const [processing, setProcessing] = useState(false)
  const { activeForm } = useCustomerStore()
  const { updateForm } = useFormStore()

  const handleDeleteCreditCard = () => {
    setProcessing(true)
    try {
      const deleteCreditCard = async () => {
        const { data } = await API.patch(`/customer-trade-application-form/delete-credit-card/${activeForm?.id}`)
        if (data) {
          const contentOption = snackBarStyle({
            text: `Credit card deleted.`,
            variant: "success",
          });
          enqueueSnackbar(contentOption.message, contentOption.options)
          updateForm(data)
          setProcessing(false)
          handleClose()
        }
      }
      deleteCreditCard()
    } catch (error) {
      console.error(error)
      const contentOption = snackBarStyle({
        text: `Delete credit card failed.`,
        variant: "error",
      });
      enqueueSnackbar(contentOption.message, contentOption.options)
      setProcessing(false)
      handleClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
        Delete Credit Card
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 2, md: 5 } }}>
        <Typography color='error'>Are you sure you want delete your credit card details? (For security reasons, it will be deleted)</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <Button disabled={processing} fullWidth sx={{ textTransform: 'none' }} variant='outlined' onClick={handleClose}>Cancel</Button>
        <LoadingButton
          fullWidth
          variant='outlined'
          onClick={handleDeleteCreditCard}
          loading={processing}
          sx={{ textTransform: 'none' }}
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCreditCardDialog