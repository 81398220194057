import { FC, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ImageListItem from "@mui/material/ImageListItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import ReactSignatureCanvas from "react-signature-canvas";
import MobileSignaturePad from "./MobileSignaturePad";
import API from "api";
import { useCustomerStore } from "state/customer.store";
import toast from "react-hot-toast";

const emptyCanvas =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

interface SignaturePadProps {
  data: any;
  value: string;
  name: string;
  onChange: (v: any) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
}

const SignaturePad: FC<SignaturePadProps> = ({
  data,
  value,
  onChange,
  name,
  error,
  helperText,
  label,
}) => {
  const sigCanvas = useRef<any>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { activeForm } = useCustomerStore();

  const clearSignature = () => {
    if (value) {
      onChange({ ...(data ?? {}), [name]: null });
    } else {
      sigCanvas.current?.clear();
    }
  };

  const saveSignature = async () => {
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/jpg");
    if (signatureData === emptyCanvas) {
      toast.error("Please submit a valid signature!");
      return;
    }
    setLoading(true);
    setOpen(false);
    try {
      const res = await API.post(
        "/customer-trade-application-form/upload-signature",
        {
          formId: activeForm?.id,
          formFieldName: name,
          base64Data: signatureData,
        }
      );
      onChange({ ...(data ?? {}), [name]: res?.data.url });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          display: { xs: "none", sm: "block" },
          height: value ? "100%" : 200,
          width: "100%",
          position: "relative",
          border: error ? "1px solid red" : "",
        }}
      >
        <Typography sx={{ position: "absolute", p: 1 }} variant="subtitle2">
          {label ?? ""}
        </Typography>
        <Box sx={{ position: "absolute", bottom: 0, zIndex: 2 }}>
          <Button size="small" onClick={clearSignature}>
            Clear
          </Button>
          <Button disabled={!!value} size="small" onClick={saveSignature}>
            Submit
          </Button>
        </Box>

        {loading ? (
          <Box
            sx={{
              minHeight: 200,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : value ? (
          <Box
            sx={{
              minHeight: 200,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <ImageListItem>
              <img
                src={`${value}`}
                srcSet={`${value}`}
                alt={name}
                loading="lazy"
              />
            </ImageListItem>
          </Box>
        ) : (
          <ReactSignatureCanvas
            ref={sigCanvas}
            minWidth={1}
            maxWidth={2}
            canvasProps={{ className: "signatureCanvas" }}
          />
        )}
        <Typography
          color="error"
          sx={{ position: "absolute", right: 0, top: 0, p: 1 }}
          variant="caption"
        >
          {helperText}
        </Typography>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          minHeight: 150,
          position: "relative",
          display: { xs: "flex", sm: "none" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ position: "absolute", top: 0, left: 0, p: 1 }}
          variant="subtitle2"
        >
          {label ?? ""}
        </Typography>
        <Box sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 2 }}>
          <Button size="small" onClick={clearSignature}>
            Clear
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              minHeight: 200,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : value ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageListItem>
              <img
                src={`${value}`}
                srcSet={`${value}`}
                alt={name}
                loading="lazy"
              />
            </ImageListItem>
          </Box>
        ) : (
          <Button sx={{ textTransform: "none" }} onClick={() => setOpen(true)}>
            <BorderColorOutlinedIcon />
            <Typography>Click here to signature</Typography>
          </Button>
        )}
      </Paper>
      <MobileSignaturePad
        open={open}
        handleClose={() => setOpen(false)}
        sigCanvas={sigCanvas}
        saveSignature={saveSignature}
        clearSignature={clearSignature}
      />
    </>
  );
};

export default SignaturePad;
