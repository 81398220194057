import AWS from 'aws-sdk/index'
import 'filepond/dist/filepond.min.css'
import { ActualFileObject, FilePondFile } from 'filepond/types'
import { FC, useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { nanoid } from "nanoid"

registerPlugin(
  FilePondPluginFileValidateType,
);

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'ap-southeast-2'
})

const UploadFormControl: FC<{
  onChange?: (p: string[]) => void
  deleteFile?: (value: string) => void
}> = ({ onChange = () => { }, deleteFile = () => { } }) => {
  const [files, setFiles] = useState<ActualFileObject[]>([])

  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])

  const createKey = (fileName: string) => {
    const cleanedFileName = String(fileName ?? '').replace(/[^.\w]/g, '_')
    return `${nanoid(10)}-${cleanedFileName}`
  }

  const updateFiles = (fileItems: FilePondFile[]) => {
    setFiles(fileItems.map((fileItem) => fileItem.file))
  }

  useEffect(() => {
    if (uploadedFiles.length) {
      onChange(uploadedFiles)
    }
  }, [uploadedFiles])


  return (
    <div className='multi-file-upload'>
      <FilePond
        files={files}
        name='file'
        labelIdle='Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'
        allowMultiple={true}
        maxFiles={6}
        onupdatefiles={(fileItems) => updateFiles(fileItems)}
        server={{
          process: function (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) {
            s3.upload(
              {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
                Key: createKey(file.name),
                Body: file,
                ContentType: file.type
              },
              function (err, data) {
                if (err) {
                  error('Something went wrong')
                  return
                }
                load(data.Key)
                setUploadedFiles(prev => [...prev, data.Key])
              }
            )
          },

          revert: (fileId, callback, error) => {
            deleteFile(fileId)
            setUploadedFiles(uploadedFiles.filter(file => file !== fileId))
          },
        }}
        labelFileProcessingComplete='Click to Download'
        acceptedFileTypes={[
          'image/*',
          'application/pdf',
        ]}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve, reject) => {
            resolve(type);
          })
        }
      />
    </div>
  )
}
export default UploadFormControl
