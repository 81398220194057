import { Vendors, vendorSummary } from '../interfaces/vendor.interface'
// create summary for Orders table
export const createVendorSummary = (vendor: Vendors): vendorSummary => {
    const {
        dateCreated,
        vendorName,
        vendorId,
        currency,
        taxClass,
        phone,
        email,
        address,
        city,
        postCode,
        country,
        status,
    } = vendor;

    return {
        dateCreated: dateCreated,
        vendorName: vendorName,
        vendorId: vendorId,
        currency: currency,
        taxClass: taxClass,
        phone: phone,
        email: email,
        address: address,
        city: city,
        postCode: postCode,
        country: country,
        status: status,
    };
};