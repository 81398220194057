import { Avatar, Box, Typography } from '@mui/material'
import { FC } from 'react'

interface UserProfileCellProps {
    firstName: string;
    lastName: string;
    username: string;
}

const UserProfileCell: FC<UserProfileCellProps> = ({ firstName, lastName, username }) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
            }}
        >
            <Avatar sx={{ bgcolor: "#F6C470" }}>
                {firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()}
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='body1'>{firstName + ' ' + lastName}</Typography>
                <Typography variant='caption'>{username}</Typography>
            </Box>

        </Box>
    )
}

export default UserProfileCell