import { FC } from 'react'

import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { PurchaseItem } from "interfaces/myforms.interface"
import { PURCHASE_REQUEST_LAYOUT } from "../../form-layouts/purchase-request.layout"
import FormControlItem from "../../form-components/FormControlItem"
import { tableCellValue } from "utils/form.utils"

interface PurchaseRequestFormDesktopProps {
    items: PurchaseItem[]
    onChange: (v: any) => void
    submitValidating: boolean,
    itemsValidating: boolean,
    formData?: PurchaseItem,
    handleChangeFormData: (value: any) => void,
    handleAddItem: () => void,
}

const PurchaseRequestFormDesktop: FC<PurchaseRequestFormDesktopProps> = ({
    items,
    onChange,
    submitValidating,
    itemsValidating,
    formData,
    handleChangeFormData,
    handleAddItem
}) => {

    const layouts = PURCHASE_REQUEST_LAYOUT

    const handleDelete = (rowIndex: number) => {
        const newData = [...items.slice(0, rowIndex), ...items.slice(rowIndex + 1)];
        onChange(newData);
    };

    return (
        <Paper elevation={5} sx={{ p: 2, display: { xs: 'none', lg: 'block' } }}>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={25} />
                            {layouts.map((layout, layoutIndex) => (
                                <TableCell key={`tableHead${layoutIndex}`} width={500}>
                                    {`${layout.label} *` ?? ''}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell sx={{ width: "25px" }}>
                                    <Button
                                        sx={{ width: "25px", minWidth: "25px" }}
                                        onClick={() => handleDelete(rowIndex)}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </Button>
                                </TableCell>
                                {layouts.map((layout, layoutIndex) => (
                                    <TableCell key={`tableBody${layoutIndex}`} width={500}>
                                        {tableCellValue(layout, row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell width={25} />
                            {layouts.map((layout, layoutIndex) => (
                                <TableCell
                                    key={`addItem${layoutIndex}`}
                                    colSpan={layoutIndex === layouts.length - 1 ? 3 : 0}
                                >
                                    <FormControlItem
                                        data={formData}
                                        onChange={handleChangeFormData}
                                        layout={layout}
                                        submitValidating={submitValidating}
                                        itemsValidating={itemsValidating}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={handleAddItem}
            >
                Save
            </Button>
        </Paper>
    )
}

export default PurchaseRequestFormDesktop