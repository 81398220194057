import { AppRegistration, ArrowDropDown, ArrowRight } from '@mui/icons-material'
import ContactsIcon from '@mui/icons-material/Contacts'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import ReceiptIcon from '@mui/icons-material/Receipt'
import StorefrontIcon from '@mui/icons-material/Storefront'
import GroupIcon from '@mui/icons-material/Group';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import HomeIcon from '@mui/icons-material/Home';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import { Box, Typography } from '@mui/material'
import { common, grey, indigo } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { IS_JFC } from 'constants/global'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSettingStore } from 'state/setting.store'
import { useUserStore } from 'state/user.store'
import { Role } from "enums/user.enum"
import { OrganisationCountry } from "enums/organisation.enums"

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string
    '--tree-view-bg-color'?: string
    '--tree-color'?: string
    '--tree-bg-color'?: string
    '--tree-hover-color'?: string
    '--tree-hover-bg-color'?: string
  }
}

interface IRoute {
  label: string
  icon: any
  route?: string
  admin?: boolean
  children?: Array<IRoute>
}

const JFC_AU_EMPLOYEE_ROUTES: IRoute[] = [
  {
    label: 'My Forms',
    icon: <AppRegistration />,
    route: '/my-form',
    children: [
      {
        label: 'Purchase request',
        icon: null,
        route: '/purchase-request',
      },
      {
        label: 'Employee claim',
        icon: null,
        route: '/general-expense',
        children: [
          {
            label: 'General expense',
            icon: null,
            route: '/general-expense',
          },
          {
            label: 'Taxi/Parking',
            icon: null,
            route: '/taxi-parking',
          },
          {
            label: 'Entertainment',
            icon: null,
            route: '/entertainment',
          },
          // {
          //   label: 'Entertainment-Party',
          //   icon: null,
          //   route: '/entertainment',
          // },
          // {
          //   label: 'Travel',
          //   icon: null,
          //   route: '/travel',
          // },
        ],
      },
    ],
  },
  {
    label: 'Users',
    icon: <GroupIcon />,
    route: '/users',
    admin: true
  },
]

const JFC_NZ_EMPLOYEE_ROUTES: IRoute[] = [
  {
    label: 'My Forms',
    icon: <AppRegistration />,
    route: '/my-form',
    children: [
      {
        label: 'Purchase request',
        icon: null,
        route: '/purchase-request',
      },
      {
        label: 'Employee claim',
        icon: null,
        route: '/general-expense',
        children: [
          {
            label: 'General expense',
            icon: null,
            route: '/general-expense',
          },
          {
            label: 'Taxi/Parking',
            icon: null,
            route: '/taxi-parking',
          },
          {
            label: 'Entertainment',
            icon: null,
            route: '/entertainment',
          },
        ],
      },
    ],
  },
  {
    label: 'Users',
    icon: <GroupIcon />,
    route: '/users',
    admin: true
  },
]


const ESPH_EMPLOYEE_ROUTES: IRoute[] = [
  {
    label: 'Home Page',
    icon: <HomeIcon />,
    route: '/homepage',
    admin: true,
  },
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    route: '/dashboard',
  },
  {
    label: 'My Invoices',
    icon: <ReceiptIcon />,
    route: '/invoices',
  },
  {
    label: 'Purchase Orders',
    icon: <DescriptionIcon />,
    route: '/purchase-order',
    children: [
      {
        label: 'Vendors',
        icon: <ContactsIcon />,
        route: '/vendors',
      },
      {
        label: 'Products',
        icon: <StorefrontIcon />,
        route: '/products',
      },
      // {
      //   label: 'create-purchase-order',
      //   icon: <DescriptionIcon />,
      //   route: '/create-purchase-order',
      // },
      // {
      //   label: 'view-purchase-order',
      //   icon: <DescriptionIcon />,
      //   route: '/view-purchase-order',
      // },
    ],
  },
  {
    label: 'Reports',
    icon: <AssessmentIcon />,
    children: [
      {
        label: 'Doctors Invoices',
        icon: <FormatListNumberedIcon />,
        route: '/doctors-invoices',
      },
    ],
  },
  {
    label: 'Users',
    icon: <GroupIcon />,
    route: '/users',
    admin: true
  },
]


const getRoutes = (company: string, role: Role, country?: OrganisationCountry) => {
  switch (company) {
    case 'JFC':
      switch (country) {
        case OrganisationCountry.Australia:
          return role !== Role.Admin ? JFC_AU_EMPLOYEE_ROUTES.filter(route => !route.admin) : JFC_AU_EMPLOYEE_ROUTES
        case OrganisationCountry.New_Zealand:
          return role !== Role.Admin ? JFC_NZ_EMPLOYEE_ROUTES.filter(route => !route.admin) : JFC_NZ_EMPLOYEE_ROUTES
        default:
          return []
      }
    case 'ESPH':
      return role !== Role.Admin ? ESPH_EMPLOYEE_ROUTES.filter(route => !route.admin) : ESPH_EMPLOYEE_ROUTES
    default:
      return []
  }
}

export default function SidebarRoutes() {
  const navigate = useNavigate()
  const { extend } = useSettingStore()
  const { isAuth } = useUserStore()
  let temp: string[] = []
  let treeView = [] as { treeItemList: string[]; treeItemName: string }[]
  const selectedTree = (data: IRoute) => {
    temp.push(data.label)
    data?.children && data?.children?.map((item) => selectedTree(item))
  }

  const data = useMemo(() => {
    const company = IS_JFC ? 'JFC' : 'ESPH'
    return getRoutes(company, isAuth?.role, isAuth?.country ?? null)
  }, [isAuth])

  data.map((item) => {
    selectedTree(item)
    treeView.push({ treeItemList: temp, treeItemName: item.label })
    temp = []
    return true
  })

  const [activeTree, setActiveTree] = useState<string>('')
  const renderTree = (nodes: IRoute) => (
    <StyledTreeItem
      key={nodes.label}
      nodeId={nodes.label}
      label={
        <TreeItemLabel
          label={nodes.label}
          labelIcon={nodes.icon}
          extend={extend}
        />
      }
      style={{
        '--tree-color': activeTree === nodes.label ? common.white : grey[600],
        '--tree-bg-color':
          activeTree === nodes.label ? indigo.A200 : common.white,
        '--tree-hover-color':
          activeTree === nodes.label ? common.white : grey[600],
        '--tree-hover-bg-color':
          activeTree === nodes.label ? indigo.A100 : grey[300],
        '--tree-view-color':
          activeTree === nodes.label ? common.white : indigo.A200,
        '--tree-view-bg-color':
          activeTree === nodes.label ? indigo.A200 : common.white,
      }}
      onClick={() => handleClick(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  )

  const handleClick = (iRoute: IRoute) => {
    if (iRoute.route && iRoute.route) {
      navigate(iRoute.route)
    }
    treeView.map(
      (item) =>
        item.treeItemList.includes(iRoute.label) &&
        setActiveTree(item.treeItemName)
    )
    return true
  }

  return (
    <TreeView
      aria-label='rich object'
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ArrowRight />}
      sx={{
        height: 110,
        flexGrow: 1,
        minWidth: 200,
        width: '100%',
      }}
    >
      {data.map((data) => renderTree(data))}
    </TreeView>
  )
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: `var(--tree-bg-color)`,
    color: 'var(--tree-color)',
    flexDirection: 'row-reverse',
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
    },
    '&:hover': {
      backgroundColor: `var(--tree-hover-bg-color)`,
      color: 'var(--tree-hover-color)',
    },
  },
}))

const TreeItemLabel = (props: {
  labelIcon: any
  label: string
  extend: boolean
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
    <Box color='inherit' sx={{ mr: 2 }}>
      {props.labelIcon && props.labelIcon}
    </Box>
    {props.extend && (
      <Typography variant='body2' sx={{ fontWeight: 'inherit', flex: 1 }}>
        {props.label}
      </Typography>
    )}
  </Box>
)
