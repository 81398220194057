import create from "zustand";

export const FinalJson = create((set: any) => ({
  purchaseOrderoneLine: {
    lineItem: [
      {
        item_code: "",
        item_description: "",
        price: "",
        quantity: "",
        amount: "",
        unit_measure: "",
        tax_class: "",
        tax: "",
        discount_amount: "",
        total: "",
        _id: 0,
      },
    ],
  },
  setFinalJson: (updatedJsonData: any) =>
    set((state: any) => ({
      purchaseOrderoneLine: Object.assign(
        state.purchaseOrderoneLine,
        updatedJsonData
      ),
    })),
}));

export const serchProductId = create((set: any) => ({
  serchProductId: "",
  setSerchProductId: () => set((state: any) => ({ serchProductId: state })),
}));

// export const selectdProductJson = create((set: any) => ({
//     purchaseOrderoneLine: {
//         lineItem: [
//             {
//                 item_code: '',
//                 item_description: '',
//                 price: '',
//                 quantity: '',
//                 amount: '',
//                 unit_measure: '',
//                 tax_class: '',
//                 tax: '',
//                 discount_amount: '',
//                 total: '',
//                 _id: 0
//             }]
//     },
//     setFinalJson: (updatedJsonData: any) => set((state: any) => ({ purchaseOrderoneLine: Object.assign(state.purchaseOrderoneLine, updatedJsonData) })),
// }))
