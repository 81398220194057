import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const ACCEPTANCE_OF_CONDITIONS_LAYOUT_1 = (residency?: string): Array<FormControlItemLayout> => {
  return [
    {
      name: 'FULL_NAME_AOC_1',
      label: '1. Full name',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: 'RESIDENCE_STATUS_AOC_1',
      label: 'Residence status',
      required: true,
      fullWidth: true,
      type: FORM_CONTROL_TYPE.NEW_SELECT,
      newSelectList: [
        'Australian/New Zealand Citizen',
        'Permanent Resident',
        'Student Visa Resident',
        'Working Holiday Visa Resident',
        'Tourist Visa Resident',
        'Other',
      ],
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
    },
    {
      name: 'DATE_AOC_1',
      label: 'Date',
      required: true,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 4,
      type: FORM_CONTROL_TYPE.DATE,
    },
    {
      name: 'OTHER_RESIDENCY_STATUS_AOC_1',
      label: 'If "other" residency status is selected, please specify',
      required: !!residency && residency === 'Other' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 12,
    },
    {
      name: 'SIGNATURE_AOC_1',
      label: '1. Signature (Same signature as on your attached Photo ID) *',
      required: true,
      fullWidth: true,
      type: FORM_CONTROL_TYPE.SIGNATURE,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 12,
    },
  ];
};

export const ACCEPTANCE_OF_CONDITIONS_LAYOUT_2: Array<FormControlItemLayout> = [
  {
    name: 'FULL_NAME_AOC_2',
    label: '2. Full name',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'RESIDENCE_STATUS_AOC_2',
    label: 'Residence status',
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: [
      'Australian/New Zealand Citizen',
      'Permanent Resident',
      'Student Visa Resident',
      'Working Holiday Visa Resident',
      'Tourist Visa Resident',
      'Other',
    ],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'DATE_AOC_2',
    label: 'Date',
    fullWidth: true,
    type: FORM_CONTROL_TYPE.DATE,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'OTHER_RESIDENCY_STATUS_AOC_2',
    label: 'If "other" residency status is selected, please specify',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 12,
  },
  {
    name: 'SIGNATURE_AOC_2',
    label: '1. Signature (Same signature as on your attached Photo ID)',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];
