import { FC } from 'react'

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import FormControlItem, { FormControlItemLayout } from '../form-components/FormControlItem';
import { useUserStore } from 'state/user.store';
import { FORM_CONTROL_TYPE } from "enums/form.enums";

const dateLayout: FormControlItemLayout = {
    name: 'claimDate',
    label: 'Date',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.DATE,
}

interface EmployeeInformationProps {
    data: any;
    onChange: (v: any) => void;
    submitValidating: boolean
}

const EmployeeInformation: FC<EmployeeInformationProps> = ({ data = {}, onChange = () => { }, submitValidating }) => {

    const { isAuth } = useUserStore()

    return (
        <Paper elevation={5} sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField label="First name" fullWidth disabled defaultValue={isAuth?.firstName ?? ""} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="Last name" fullWidth disabled defaultValue={isAuth?.lastName ?? ""} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="Employee number" fullWidth disabled defaultValue={isAuth?.employeeNumber ?? ""} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlItem
                        data={data}
                        onChange={onChange}
                        layout={dateLayout}
                        submitValidating={submitValidating} />
                </Grid>
            </Grid>

        </Paper>
    );

}

export default EmployeeInformation