import { FC } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { DialogProps } from "interfaces/common.interfaces";

interface MobileSignaturePadProps extends DialogProps {
  sigCanvas: any;
  saveSignature: () => void;
  clearSignature: () => void;
}

const MobileSignaturePad: FC<MobileSignaturePadProps> = ({
  open,
  handleClose,
  sigCanvas,
  saveSignature,
  clearSignature,
}) => {
  const handleSubmit = async () => {
    saveSignature();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogActions>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogActions>
      <DialogTitle sx={{}} variant="body2">
        Please sign within the square
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: "1px solid black", height: "80%" }}>
          <ReactSignatureCanvas
            ref={sigCanvas}
            minWidth={1}
            maxWidth={2}
            canvasProps={{ className: "signatureCanvas" }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={clearSignature}
        >
          Clear
        </Button>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileSignaturePad;
