import CustomTextArea from 'components/common/CustomTextArea';
import { useInvoiceStore } from 'state/invoice.store';

const Reject = () => {
  const { updateInvoice } = useInvoiceStore();

  return (
    <CustomTextArea
      onChange={(e) => updateInvoice({ reason: e.target.value })}
      required
    />
  );
};

export default Reject;
