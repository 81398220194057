import { FC, useState } from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { EntertainmentClaim } from "interfaces/myforms.interface"
import FormControlItem, { FormControlItemLayout } from "../../form-components/FormControlItem"
import { entertainmentClaimItemValidator, tableCellValue } from "utils/form.utils";
import { YES_OR_NO } from "interfaces/enums";
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";

interface EntertainmentFormMobileProps {
    items: EntertainmentClaim[]
    onChange: (v: any) => void
    submitValidating: boolean,
    itemsValidating: boolean,
    setItemsValidating: (value: boolean) => void;
    formData?: EntertainmentClaim,
    editData?: EntertainmentClaim,
    setEditData: (value: EntertainmentClaim | undefined) => void;
    handleChangeFormData: (value: any) => void,
    handleAddItem: () => void,
    formNumberLayout: FormControlItemLayout,
    formOverdue: FormControlItemLayout,
    lastSaleOverLayout: FormControlItemLayout,
    lastSalPaymentLayout: FormControlItemLayout,
    formLayouts: FormControlItemLayout[]
}

const EntertainmentFormMobile: FC<EntertainmentFormMobileProps> = ({
    items,
    onChange,
    submitValidating,
    itemsValidating,
    setItemsValidating,
    formData,
    editData,
    setEditData,
    handleChangeFormData,
    handleAddItem,
    formNumberLayout,
    formOverdue,
    lastSaleOverLayout,
    lastSalPaymentLayout,
    formLayouts
}) => {

    const [collapsedItems, setCollapseItems] = useState<Array<string | number>>([])
    const [editValidating, setEditValidating] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleCollapseItems = (id: string | number) => {
        if (collapsedItems.includes(id)) {
            setCollapseItems([...collapsedItems.filter(item => item !== id)])
        } else {
            setCollapseItems([...collapsedItems, id])
        }
    }

    const handleDeleteItem = (id: string | number) => {
        if (id === editData?.id) {
            setEditData(undefined)
            setEditValidating(false)
            setItemsValidating(false)
        }
        const newData = items.filter(item => item.id !== id)
        onChange([...newData])
    }

    const handleEditItem = (item: EntertainmentClaim) => {
        if (editData) {
            const contentOption = snackBarStyle({
                text: 'Please save the item you are editing.',
                variant: 'warning',
            })
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        setEditData(item)
    }

    const handleSaveEditedItem = () => {
        if (!entertainmentClaimItemValidator(editData as EntertainmentClaim)) {
            setEditValidating(true)
            const contentOption = snackBarStyle({
                text: 'Please fillout all required fields.',
                variant: 'warning',
            })
            enqueueSnackbar(contentOption.message, contentOption.options)
            return false
        }
        const newData: EntertainmentClaim[] = []
        items.forEach((item) => {
            if (editData && editData.id === item.id) {
                newData.push({ ...editData })
            } else {
                newData.push({ ...item })
            }
        })
        onChange([...newData])
        setEditData(undefined)
        setEditValidating(false)
    }

    return (
        <>
            {items.map((item, index) => {
                return (
                    <Accordion
                        key={item.id}
                        sx={{ display: { xs: 'block', lg: 'none' } }}
                        expanded={collapsedItems.includes(item.id)}
                        onChange={() => handleCollapseItems(item.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{`${editData?.id === item.id ? 'Editing ' : ''}Claim ${index + 1}`}</Typography>
                        </AccordionSummary>
                        {editData?.id === item.id ?
                            <>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    {formLayouts?.map((layout, index) => {
                                        return (
                                            <Grid key={index} item xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}>
                                                <FormControlItem
                                                    data={editData}
                                                    onChange={setEditData}
                                                    layout={layout}
                                                    submitValidating={submitValidating}
                                                    itemsValidating={editValidating}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    <Grid key={lastSaleOverLayout.name} item lg={3} md={4} sm={12} xs={12}>
                                        <FormControlItem
                                            data={editData}
                                            onChange={setEditData}
                                            layout={lastSaleOverLayout}
                                            submitValidating={submitValidating}
                                            itemsValidating={editValidating}
                                        />
                                    </Grid>
                                    <Grid key={formNumberLayout.name} item lg={9} md={8} sm={12} xs={12}>
                                        {editData?.lastSaleOver30d?.value === YES_OR_NO.YES && (
                                            <FormControlItem
                                                data={editData}
                                                onChange={setEditData}
                                                layout={formNumberLayout}
                                                submitValidating={submitValidating}
                                                itemsValidating={editValidating}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    <Grid key={lastSalPaymentLayout.name} item lg={3} md={4} sm={12} xs={12}>
                                        <FormControlItem
                                            data={editData}
                                            onChange={setEditData}
                                            layout={lastSalPaymentLayout}
                                            submitValidating={submitValidating}
                                            itemsValidating={editValidating}
                                        />
                                    </Grid>
                                    <Grid key={formOverdue.name} item lg={9} md={8} sm={12} xs={12}>
                                        {editData?.isLastPaymentOverdue?.value === YES_OR_NO.YES && (
                                            <FormControlItem
                                                data={editData}
                                                onChange={setEditData}
                                                layout={formOverdue}
                                                submitValidating={submitValidating}
                                                itemsValidating={editValidating}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    {formLayouts?.map((layout, index) => {
                                        return (
                                            <Grid key={index} item xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    label={layout.label}
                                                    defaultValue={tableCellValue(layout, item)}
                                                    InputProps={{
                                                        readOnly: true,
                                                        disabled: true
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid key={lastSaleOverLayout.name} item lg={3} md={4} sm={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            variant="standard"
                                            label={lastSaleOverLayout.label}
                                            defaultValue={tableCellValue(lastSaleOverLayout, item)}
                                            InputProps={{
                                                readOnly: true,
                                                disabled: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid key={formNumberLayout.name} item lg={9} md={8} sm={12} xs={12}>
                                        {item?.lastSaleOver30d?.value === YES_OR_NO.YES && (
                                            <TextField
                                                fullWidth
                                                required
                                                variant="standard"
                                                label={formNumberLayout.label}
                                                defaultValue={tableCellValue(formNumberLayout, item)}
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: true
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid key={lastSalPaymentLayout.name} item lg={3} md={4} sm={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            variant="standard"
                                            label={lastSalPaymentLayout.label}
                                            defaultValue={tableCellValue(lastSalPaymentLayout, item)}
                                            InputProps={{
                                                readOnly: true,
                                                disabled: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid key={formOverdue.name} item lg={9} md={8} sm={12} xs={12}>
                                        {item?.isLastPaymentOverdue?.value === YES_OR_NO.YES && (
                                            <TextField
                                                fullWidth
                                                required
                                                variant="standard"
                                                label={formOverdue.label}
                                                defaultValue={tableCellValue(formOverdue, item)}
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: true
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>}
                        <AccordionActions sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            {editData?.id === item.id ?
                                <Button size="small" variant="contained" onClick={handleSaveEditedItem}>Save</Button> :
                                <Button size="small" variant="contained" onClick={() => handleEditItem(item)}>Edit</Button>}
                            <Button size="small" variant="contained" onClick={() => handleDeleteItem(item.id)}>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                )
            })}
            <Paper sx={{ p: 2, mt: 2, display: { xs: 'block', lg: 'none' } }} elevation={5}>
                <Typography>New Claim</Typography>
                <>
                    <Grid container spacing={2}>
                        {formLayouts?.map((layout, index) => {
                            return (
                                <Grid key={index} item xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}>
                                    <FormControlItem
                                        data={formData}
                                        onChange={handleChangeFormData}
                                        layout={layout}
                                        submitValidating={submitValidating}
                                        itemsValidating={itemsValidating}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid key={lastSaleOverLayout.name} item lg={3} md={4} sm={12} xs={12}>
                            <FormControlItem
                                data={formData}
                                onChange={handleChangeFormData}
                                layout={lastSaleOverLayout}
                                submitValidating={submitValidating}
                                itemsValidating={itemsValidating}
                            />
                        </Grid>
                        <Grid key={formNumberLayout.name} item lg={9} md={8} sm={12} xs={12}>
                            {formData?.lastSaleOver30d?.value === YES_OR_NO.YES && (
                                <FormControlItem
                                    data={formData}
                                    onChange={handleChangeFormData}
                                    layout={formNumberLayout}
                                    submitValidating={submitValidating}
                                    itemsValidating={itemsValidating}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid key={lastSalPaymentLayout.name} item lg={3} md={4} sm={12} xs={12}>
                            <FormControlItem
                                data={formData}
                                onChange={handleChangeFormData}
                                layout={lastSalPaymentLayout}
                                submitValidating={submitValidating}
                                itemsValidating={itemsValidating}
                            />
                        </Grid>
                        <Grid key={formOverdue.name} item lg={9} md={8} sm={12} xs={12}>
                            {formData?.isLastPaymentOverdue?.value === YES_OR_NO.YES && (
                                <FormControlItem
                                    data={formData}
                                    onChange={handleChangeFormData}
                                    layout={formOverdue}
                                    submitValidating={submitValidating}
                                    itemsValidating={itemsValidating}
                                />
                            )}
                        </Grid>
                    </Grid>
                </>
                <Box>
                    <Button size="small" variant="contained" onClick={handleAddItem}>Save</Button>
                </Box>
            </Paper>
        </>
    )
}

export default EntertainmentFormMobile