import { OrganisationName } from 'enums/organisation.enums';

export const filterUsersTable: any = (rows: any, filter: any) => {
  const filteredData = rows.filter(
    (item: any) =>
      (filter.firstName === undefined || filter.firstName.includes(`${item.firstName} ${item.lastName}`)) &&
      (filter.employeeNumber === undefined || filter.employeeNumber.includes(item.employeeNumber)) &&
      (filter.department === undefined || filter.department.includes(item.department)) &&
      (filter.branch === undefined || filter.branch.includes(item.branch)) &&
      (filter.vendorId === undefined || filter.vendorId.includes(item.vendorId)) &&
      (filter.role === undefined || filter.role.includes(item.role))
  );
  return filteredData;
};

export const createUserValidator = (formData: any, organisation: OrganisationName) => {
  if (organisation === OrganisationName.JFC) {
    if (
      formData &&
      formData.role &&
      formData.department &&
      formData.username &&
      formData.firstName &&
      formData.lastName &&
      formData.branch &&
      formData.employeeNumber
    )
      return true;
    return false;
  }
  if (organisation === OrganisationName.ESPH) {
    if (
      formData &&
      formData.role &&
      formData.department &&
      formData.username &&
      formData.firstName &&
      formData.lastName
    )
      return true;
    return false;
  }
};
