import { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import HelpMenuDialog from './HelpMenuDialog';
import { EMAIL_CATEGORY } from 'interfaces/enums';

const HelpMenu = ({ anchorEl, handleMenuClose }: any) => {
  const [category, setCategory] = useState('');

  const isMenuOpen = Boolean(anchorEl);
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="caption" color="text.secondary" sx={{ pl: 1 }}>
        HELP
      </Typography>
      <MenuItem
        onClick={() => {
          setCategory(EMAIL_CATEGORY.REQUEST_FEATURE);
          handleMenuClose();
        }}
      >
        Request a feature
      </MenuItem>
      <MenuItem
        onClick={() => {
          setCategory(EMAIL_CATEGORY.REPORT_ISSUE);
          handleMenuClose();
        }}
      >
        Report an issue
      </MenuItem>
      <HelpMenuDialog category={category} handleClose={() => setCategory('')} />
    </Menu>
  );
};

export default HelpMenu;
