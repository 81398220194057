import React from 'react'
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import DashboardIcon from '@mui/icons-material/Dashboard'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { useSettingStore } from "state/setting.store";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IS_JFC } from "constants/global";
import { useUserStore } from "state/user.store";
import { OrganisationCountry } from "enums/organisation.enums";

const JFC_AU_ROUTES = [
    { label: 'General expense', icon: <ReceiptIcon />, route: '/general-expense' },
    { label: 'Taxi/Parking', icon: <LocalTaxiIcon />, route: '/taxi-parking' },
    { label: 'Entertainment', icon: <MovieFilterIcon />, route: '/entertainment' },
    // { label: 'Travel', icon: <FlightTakeoffIcon />, route: '/travel' },
]

const JFC_NZ_ROUTES = [
    { label: 'General expense', icon: <ReceiptIcon />, route: '/general-expense' },
    { label: 'Taxi/Parking', icon: <LocalTaxiIcon />, route: '/taxi-parking' },
    { label: 'Entertainment', icon: <MovieFilterIcon />, route: '/entertainment' },
]

const ESPH_ROUTES = [
    { label: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
    { label: 'My Invoices', icon: <ReceiptIcon />, route: '/invoices' },
    { label: 'Purchase Orders', icon: <DescriptionIcon />, route: '/purchase-order' },
    { label: 'Doctors Invoices', icon: <FormatListNumberedIcon />, route: '/doctors-invoices' },
]

const MobileDrawer = () => {

    const { extend, toggleExtend } = useSettingStore();
    const { isAuth } = useUserStore()
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Drawer
                anchor='left'
                open={extend}
                onClose={toggleExtend}
                sx={{ display: { xs: 'block', lg: 'none' } }}
            >
                {IS_JFC ?
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={toggleExtend}
                        onKeyDown={toggleExtend}
                    >
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate('/my-form')}>
                                    <ListItemIcon>
                                        <AppRegistrationIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='My Forms' />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate('/purchase-request')}>
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Purchase request' />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="caption" sx={{ fontWeight: 800, color: grey[500], pl: 1, pt: 1 }}>Employee Claims</Typography>
                        <List>
                            {(isAuth?.country === OrganisationCountry.Australia ? [...JFC_AU_ROUTES] : [...JFC_NZ_ROUTES]).map((route, index) => (
                                <ListItem key={route.label} disablePadding>
                                    <ListItemButton onClick={() => navigate(route.route)}>
                                        <ListItemIcon>
                                            {route.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={route.label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box> :
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={toggleExtend}
                        onKeyDown={toggleExtend}
                    >
                        <List>
                            {ESPH_ROUTES.map((route, index) => (
                                <ListItem key={route.label} disablePadding>
                                    <ListItemButton onClick={() => navigate(route.route)}>
                                        <ListItemIcon>
                                            {route.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={route.label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>}
            </Drawer>
        </React.Fragment>
    );
}

export default MobileDrawer