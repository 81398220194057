import { IS_JFC } from 'constants/global'
import { Navigate } from 'react-router-dom'
import { useUserStore } from 'state/user.store'

const CatchAllRoutes = () => {
  const { isAuth } = useUserStore()
  return isAuth ? (
    IS_JFC ? (
      <Navigate to='/my-form' />
    ) : (
      <Navigate to='/invoices' />
    )
  ) : (
    <Navigate to='/auth/signin' />
    // <Navigate to='/website-maintenance' />
  )
}

export default CatchAllRoutes;
