import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import API from "api";
import SuccessResetPassword from "components/authentication/SuccessResetPassword";
import { useSearchParams } from "react-router-dom";
import { useUserStore } from "state/user.store";
import { checkRegex } from "utils/common.utils";

const ResetPassword = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { logout, isAuth } = useUserStore();

  // logout user while resetpassword page mount.
  React.useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [credentials, setCredentials] = useState({
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState<string[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [showresetPassSuccess, setShowresetPassSuccess] = useState(false);

  const handleOnChangeCredentials = (event: any) => {
    setErrors(null);
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  // get query params
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleResetPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (credentials.password !== credentials.confirmpassword)
      return setErrors(["password and confirm password both must be same!"]);
    if (
      !checkRegex(credentials.password) ||
      !checkRegex(credentials.confirmpassword)
    )
      return setErrors([
        "password and confirm password must contain one uppercase lowercase and special character or password should be greater than 8!",
      ]);
    const resetPassword = await API.post(`/forgot-password/${token}`, {
      password: credentials.password,
    });
    setLoading(true);
    if (resetPassword.data.status) {
      setShowresetPassSuccess(true);
      setLoading(false);
    } else {
      setErrors([resetPassword.data.msg]);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      {errors && (
        <Box sx={{ pb: 2 }}>
          {errors.map((error, index) => {
            return (
              <Box key={index}>
                <Typography color="error" variant="caption">
                  ⚠ {error}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}

      <form onSubmit={handleResetPassword}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ py: 1 }}>
            <Typography variant="body2">Password</Typography>
            <TextField
              fullWidth
              name="password"
              type="password"
              placeholder="Please type your password"
              value={credentials.password}
              onChange={handleOnChangeCredentials}
              variant="outlined"
              required
              sx={{ pt: 0.5, pb: 1 }}
              inputProps={{
                sx: {
                  fontSize: 14,
                  "&::placeholder": { opacity: 0.7 },
                },
              }}
            />
            <Typography variant="body2">Confirm password</Typography>
            <TextField
              fullWidth
              name="confirmpassword"
              type="password"
              placeholder="Please type your confirm password"
              value={credentials.confirmpassword}
              onChange={handleOnChangeCredentials}
              variant="outlined"
              required
              sx={{ pt: 0.5 }}
              inputProps={{
                sx: {
                  fontSize: 14,
                  "&::placeholder": { opacity: 0.7 },
                },
              }}
            />
          </Box>

          <Link
            onClick={(event: any) => {
              event.preventDefault();
              navigate("/auth/signin");
            }}
            variant="caption"
            sx={{ cursor: "pointer", textDecoration: "none" }}
          >
            Don't want to reset password ?
          </Link>
          <LoadingButton
            fullWidth
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ mt: 2, textTransform: "none" }}
            size="medium"
          >
            Reset Password
          </LoadingButton>
        </Box>
      </form>

      <SuccessResetPassword
        open={showresetPassSuccess}
        handleClose={() => setShowresetPassSuccess(false)}
      />
    </Box>
  );
};

export default ResetPassword;
