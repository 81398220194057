import { FC } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'
import grey from "@mui/material/colors/grey";

import { FormStatus, useCustomerStore } from "state/customer.store";
import { useSettingStore } from "state/setting.store";
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";
import { CUSTOMER_DOCUMENT_TYPES } from "enums/customer-trade-application-form.enums";

interface CustomerMobileDrawerProps {
    setDeleteForm: (form: FormStatus) => void
}


const CustomerMobileDrawer: FC<CustomerMobileDrawerProps> = ({ setDeleteForm }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const { extend, toggleExtend } = useSettingStore();
    const { customerApplicationForms, paymentInformationForms, activeForm, setCustomerApplicationForms, setPaymentInformationForms, setActiveForm } = useCustomerStore()

    const isCustomerTradeApplication = location.pathname.includes('customer-trade-application-form')
    const isPaymentInformation = location.pathname.includes('payment-information-form')

    const handleCreateNewForm = (formType: CUSTOMER_DOCUMENT_TYPES) => {
        if ((formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ? [...customerApplicationForms] : [...paymentInformationForms]).includes(null)) {
            const contentOption = snackBarStyle({
                text: 'Please use the blank form before creating a new one.',
                variant: "warning",
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        setActiveForm(null)
        formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
            setCustomerApplicationForms([...customerApplicationForms, null]) :
            setPaymentInformationForms([...paymentInformationForms, null])
    }

    const handleDeleteForm = (form: FormStatus | null, formType: CUSTOMER_DOCUMENT_TYPES) => {
        if (form) {
            setDeleteForm(form)
        } else {
            if (activeForm === null) {
                if (formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM) {
                    setActiveForm(customerApplicationForms[0])
                } else {
                    setActiveForm(paymentInformationForms[0])
                }

            }

            formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                setCustomerApplicationForms([...customerApplicationForms.filter(form => form !== null)]) :
                setPaymentInformationForms([...paymentInformationForms.filter(form => form !== null)])
        }
    }

    return (
        <>
            <Drawer
                anchor='left'
                open={extend}
                onClose={toggleExtend}
                sx={{ display: { xs: 'block', lg: 'none' } }}
            >

                {isCustomerTradeApplication ?

                    <Box
                        sx={{ width: 220, p: 1, pt: 2 }}
                        role="presentation"
                        onClick={toggleExtend}
                        onKeyDown={toggleExtend}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 800, color: grey[500] }}>Customer Trade Applications</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                            {customerApplicationForms.map((form, index) => {
                                return (
                                    <Box key={form?.id ?? null} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Link
                                            component="button"
                                            variant="body1"
                                            sx={{
                                                textDecoration: (isCustomerTradeApplication && activeForm?.id === form?.id) ? null : 'none',
                                                fontWeight: (isCustomerTradeApplication && activeForm?.id === form?.id) ? 600 : 400
                                            }}
                                            onClick={() => {
                                                setActiveForm(form)
                                                navigate('/customer/customer-trade-application-form/applicant_details')
                                            }}
                                        >
                                            {`Form ${index + 1} ${!form ? '(blank)' : ''}`}
                                        </Link>
                                        {(form?.status === 'partial' || (customerApplicationForms.length > 1 && form === null)) ?
                                            <IconButton onClick={() => handleDeleteForm(form ?? null, CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM)}>
                                                <DeleteIcon fontSize="small" color="primary" />
                                            </IconButton> : null}
                                    </Box>
                                )
                            })}
                            <Button onClick={() => handleCreateNewForm(CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM)} sx={{ textTransform: 'none', p: 0 }}>+ New Form</Button>
                        </Box>
                    </Box> : null}

                {isPaymentInformation ?
                    <Box
                        sx={{ width: 220, p: 1, pt: 2 }}
                        role="presentation"
                        onClick={toggleExtend}
                        onKeyDown={toggleExtend}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 800, color: grey[500] }}>Customer Trade Applications</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                            {paymentInformationForms.map((form, index) => {
                                return (
                                    <Box key={form?.id ?? null} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Link
                                            component="button"
                                            variant="body1"
                                            sx={{
                                                textDecoration: (isPaymentInformation && activeForm?.id === form?.id) ? null : 'none',
                                                fontWeight: (isPaymentInformation && activeForm?.id === form?.id) ? 600 : 400
                                            }}
                                            onClick={() => {
                                                setActiveForm(form)
                                                navigate('/customer/payment-information-form/applicant_details')
                                            }}
                                        >
                                            {`Form ${index + 1} ${!form ? '(blank)' : ''}`}
                                        </Link>
                                        {(form?.status === 'partial' || (paymentInformationForms.length > 1 && form === null)) ?
                                            <IconButton onClick={() => handleDeleteForm(form ?? null, CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM)}>
                                                <DeleteIcon fontSize="small" color="primary" />
                                            </IconButton> : null}
                                    </Box>
                                )
                            })}
                            <Button onClick={() => handleCreateNewForm(CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM)} sx={{ textTransform: 'none', p: 0 }}>+ New Form</Button>
                        </Box>
                    </Box> : null}
            </Drawer>
        </>
    )
}

export default CustomerMobileDrawer