export enum FORM_CONTROL_TYPE {
  DATE = 'DATE',
  TIME = 'TIME',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  MULTI_LINE_TEXT = 'MULTI_LINE_TEXT',
  SELECT = 'SELECT',
  EMAIL = 'email',
  PASSWORD = 'password',
  NEW_SELECT = 'NEW_SELECT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  NEW_NUMBER = 'NEW_NUMBER',
  SIGNATURE = 'SIGNATURE',
  ADDRESS_AUTOFILL = 'ADDRESS_AUTOFILL',
  CHECKBOX = 'CHECKBOX',
  NUMBER_AS_STRING = 'NUMBER_AS_STRING',
}
