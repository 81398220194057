import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

//Not being used as form layout
export const PAYMENT_METHOD_LAYOUT: FormControlItemLayout = {
  name: 'PAYMENT_METHOD',
  label: 'Payment method',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.NEW_SELECT,
  newSelectList: [
    'Direct Debit: It is an automatic transaction that transfers money from your account to another.',
    'EFT - Advance payment: It is an electronic funds transfer.',
    'EFT - On the delivery date: It is an electronic funds transfer. date',
    'Credit Card: Merchant fees may be applied.',
  ],
};

//*****START DIRECT DEBIT *****//

export const DIRECT_DEBIT_CHOSEN_LAYOUT: FormControlItemLayout = {
  name: 'DIRECT_DEBIT_CHOSEN',
  label: 'Would you be able to provide the Direct Debit details for your business?',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.NEW_SELECT,
  newSelectList: ['Yes', 'No'],
};

export const DIRECT_DEBIT_1: Array<FormControlItemLayout> = [
  {
    name: 'BANK_NAME',
    label: 'Financial institution name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'BRANCH_NAME_DD',
    label: 'Branch name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'ADDRESS_DD',
    label: 'Address',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
];

export const DIRECT_DEBIT_2: Array<FormControlItemLayout> = [
  {
    name: 'BANK_ACCOUNT_NAME_DD',
    label: 'Name/s on account',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'BSB_NUMBER_DD',
    label: 'BSB number (Must be 6 Digits)',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
    charLength: 6,
    mask: '######',
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'ACCOUNT_NUMBER_DD',
    label: 'Account number',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
  },
];

export const ACKNOWLEDGEMENT_AND_SIGNATURES_1: Array<FormControlItemLayout> = [
  {
    name: 'ACKNOWLEDGEMENT_FULL_NAME_DD_1',
    label: '1. Full Name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'ACKNOWLEDGEMENT_POSITION_DD_1',
    label: 'Position',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'ACKNOWLEDGEMENT_ADDRESS_DD_1',
    label: 'Address',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'ACKNOWLEDGEMENT_DATE_DD_1',
    label: 'Date',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.DATE,
  },
  {
    name: 'ACKNOWLEDGEMENT_SIGNATURE_DD_1',
    label: '1. Signature (Same signature as on your attached Photo ID) *',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const ACKNOWLEDGEMENT_AND_SIGNATURES_2: Array<FormControlItemLayout> = [
  {
    name: 'ACKNOWLEDGEMENT_FULL_NAME_DD_2',
    label: '2. Full Name',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'ACKNOWLEDGEMENT_POSITION_DD_2',
    label: 'Position',
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
  },
  {
    name: 'ACKNOWLEDGEMENT_ADDRESS_DD_2',
    label: 'Address',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'ACKNOWLEDGEMENT_DATE_DD_2',
    label: 'Date',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 6,
    type: FORM_CONTROL_TYPE.DATE,
  },
  {
    name: 'ACKNOWLEDGEMENT_SIGNATURE_DD_2',
    label: '2. Signature (Same signature as on your attached Photo ID)',
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];

export const DIRECT_DEBIT_SERVICE_AGREEMENT: FormControlItemLayout = {
  name: 'DIRECT_DEBIT_SERVICE_AGREEMENT',
  label: 'I confirm I have read the Direct Debit Request Service Agreement',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.CHECKBOX,
};
//*****END DIRECT DEBIT *****//

//*****START EFT ADVANCE PAYMENT*****//
export const EFT_ADVANCE_PAYMENT_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'BANK_ACCOUNT_NAME_EFTAP',
    label: 'Name/s on account',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
  },
  {
    name: 'BSB_NUMBER_EFTAP',
    label: 'BSB number (Must be 6 Digits)',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
    charLength: 6,
    mask: '######',
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'ACCOUNT_NUMBER_EFTAP',
    label: 'Account number',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'FULL_NAME_EFTAP',
    label: 'Full name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'POSITION_EFTAP',
    label: 'Position',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'DATE_EFTAP',
    label: 'Date',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
    type: FORM_CONTROL_TYPE.DATE,
  },
  {
    name: 'ADDRESS_EFTAP',
    label: 'Address',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
  },
  {
    name: 'SIGNATURE_EFTAP',
    label: '1. Signature (Same signature as on your attached Photo ID) *',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];
//*****END EFT ADVANCE PAYMENT*****//

//*****START EFT ON THE DELIVERY DATE*****//
export const EFT_ON_DELIVERY_DATE_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'BANK_ACCOUNT_NAME_EFTODD',
    label: 'Name/s on account',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 4,
  },
  {
    name: 'BSB_NUMBER_EFTODD',
    label: 'BSB number (Must be 6 Digits)',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
    charLength: 6,
    mask: '######',
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'ACCOUNT_NUMBER_EFTODD',
    label: 'Account number',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'FULL_NAME_EFTODD',
    label: 'Full name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'POSITION_EFTODD',
    label: 'Position',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Owner', 'Director', 'Others'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'DATE_EFTODD',
    label: 'Date',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.DATE,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 4,
  },
  {
    name: 'ADDRESS_EFTODD',
    label: 'Address',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.ADDRESS_AUTOFILL,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
    gridSizeDesktop: 12,
  },
  {
    name: 'SIGNATURE_EFTODD',
    label: '1. Signature (Same signature as on your attached Photo ID) *',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
    gridSizeDesktop: 12,
    type: FORM_CONTROL_TYPE.SIGNATURE,
  },
];
//*****END EFT ON THE DELIVERY DATE*****//

//*****START CREDIT CARD*****//
export const CREDIT_CARD_LAYOUT = (directDebitChosen?: string): Array<FormControlItemLayout> => {
  return [
    {
      name: 'CREDIT_CARD_SIGNATURE',
      label: `Signature (Same signature as on your attached Photo ID) ${
        !directDebitChosen ? '*' : directDebitChosen === 'No' ? '*' : ''
      }`,
      required: !directDebitChosen ? true : directDebitChosen === 'No' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 12,
      type: FORM_CONTROL_TYPE.SIGNATURE,
    },
  ];
};

export const CREDIT_CARD_DETAILS_LAYOUT = (directDebitChosen?: string): Array<FormControlItemLayout> => {
  return [
    {
      name: 'CARD_NUMBER',
      label: 'Card number',
      required: !directDebitChosen ? true : directDebitChosen === 'No' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 12,
      type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
      mask: '#### #### #### #### #####',
      size: 'small',
    },
    {
      name: 'EXPIRY_DATE_',
      label: 'Expiry Date',
      required: !directDebitChosen ? true : directDebitChosen === 'No' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
      type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
      mask: '##/##',
      size: 'small',
    },
    {
      name: 'CSV__SECURITY_CODE_',
      label: 'CVC',
      required: !directDebitChosen ? true : directDebitChosen === 'No' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 6,
      gridSizeDesktop: 6,
      type: FORM_CONTROL_TYPE.NUMBER_AS_STRING,
      mask: '####',
      size: 'small',
    },
    {
      name: 'CARD_HOLDER_NAME_',
      label: 'Card holder name',
      required: !directDebitChosen ? true : directDebitChosen === 'No' ? true : false,
      fullWidth: true,
      gridSizeMobile: 12,
      gridSizeTablet: 12,
      gridSizeDesktop: 12,
      size: 'small',
    },
  ];
};
//*****END CREDIT CARD*****//
