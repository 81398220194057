import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

import Box from '@mui/material/Box'
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import {
  ParkingExpenseItem,
  ExpenseForm,
  TaxiExpenseItem,
} from 'interfaces/myforms.interface'
import { useSnackbar, withSnackbar } from 'notistack'
import UploadFormControl from '../../form-components/UploadFormControl'
import { formattedDate, snackBarStyle } from 'utils/common.utils'
import Loader from 'components/common/Loader'
import FormHeader from '../FormHeader'
import EmployeeInformation from '../EmployeeInformation'
import FormButtons from '../FormButtons'
import { formDataValidator, parkingClaimItemValidator, taxiClaimItemValidator } from 'utils/form.utils'
import CommentsForm from '../CommentsForm'
import API from 'api'
import { PARKING_EXPENSE_LAYOUT, TAXI_EXPENSE_LAYOUT } from "../../form-layouts/taxiparking-expense.layout";
import { OrganisationCountry } from "enums/organisation.enums";
import { JFC_AU_BRANCHES, JFC_NZ_BRANCHES } from "../general-expense-form/SelectList";
import { useUserStore } from "state/user.store";
import TaxiItemsFormDesktop from "./TaxiItemsFormDesktop";
import ParkingItemsFormDesktop from "./ParkingItemsFormDesktop";
import TaxiItemsFormMobile from "./TaxiItemsFormMobile";
import ParkingItemsFormMobile from "./ParkingItemsFormMobile";
import { Grid } from "@mui/material";



const TaxiParking = () => {
  const [data, setData] = useState<ExpenseForm>({
    taxiExpenseItems: [] as TaxiExpenseItem[],
    parkingExpenseItems: [] as ParkingExpenseItem[],
    claimDate: new Date(),
    attachments: [],
    comment: '',
  })
  const [taxiFormData, setTaxiFormData] = useState<TaxiExpenseItem | undefined>(undefined)
  const [taxiEditData, setTaxiEditData] = useState<TaxiExpenseItem | undefined>(undefined)
  const [parkingFormData, setParkingFormData] = useState<ParkingExpenseItem | undefined>(undefined)
  const [parkingEditData, setParkingEditData] = useState<ParkingExpenseItem | undefined>(undefined)
  const [processing, setProcessing] = useState<boolean>(false)
  const [submitValidating, setSubmitValidating] = useState<boolean>(false)
  const [taxiItemsValidating, setTaxiItemsValidating] = useState<boolean>(false)
  const [parkingItemsValidating, setParkingItemsValidating] = useState<boolean>(false)
  const navigate = useNavigate()

  const isAuth = useUserStore(state => state.isAuth)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const taxiLayouts = TAXI_EXPENSE_LAYOUT.map((layout) => {
    if (layout.name === "branch") {
      return { ...layout, selectList: isAuth.country === OrganisationCountry.Australia ? JFC_AU_BRANCHES : JFC_NZ_BRANCHES }
    }
    return { ...layout }
  })

  const parkingLayouts = PARKING_EXPENSE_LAYOUT.map((layout) => {
    if (layout.name === "branch") {
      return { ...layout, selectList: isAuth.country === OrganisationCountry.Australia ? JFC_AU_BRANCHES : JFC_NZ_BRANCHES }
    }
    return { ...layout }
  })

  const handleChangeTaxiFormData = (value: any = {}) => {
    setTaxiFormData(value)
  }

  const handleChangeParkingFormData = (value: any = {}) => {
    setParkingFormData(value)
  }

  const handleAddTaxiItem = () => {
    if (!taxiClaimItemValidator(taxiFormData as TaxiExpenseItem)) {
      setTaxiItemsValidating(true)
      const contentOption = snackBarStyle({
        text: 'Please fillout all required fields.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return false
    }
    setTaxiItemsValidating(false)
    setData({ ...data, taxiExpenseItems: [...data.taxiExpenseItems as Array<any>, { ...taxiFormData, id: nanoid() }] })
    handleChangeTaxiFormData(undefined)
  }

  const handleAddParkingItem = () => {
    if (!parkingClaimItemValidator(parkingFormData as ParkingExpenseItem)) {
      setParkingItemsValidating(true)
      const contentOption = snackBarStyle({
        text: 'Please fillout all required fields.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return false
    }
    setParkingItemsValidating(false)

    setData({
      ...data,
      parkingExpenseItems: [
        ...data.parkingExpenseItems as Array<any>,
        { ...parkingFormData, id: nanoid() }
      ]
    })
    handleChangeParkingFormData(undefined)
  }

  const handleChange = (value: any, keyName: string) => {
    setData((s) => ({ ...(s ?? {}), [keyName]: value }))
  }

  const handleDeleteFile = (value: string) => {
    setData((s) => ({ ...(s ?? {}), attachments: s.attachments?.filter(att => att !== value) }))
  }

  const handleSave = async () => {
    await API.post('/taxiparking-expense-form/save', data)
    return true
  }

  const handleSubmit = async () => {
    setSubmitValidating(true)
    const validatorResult = formDataValidator(
      'Claim',
      (data.taxiExpenseItems?.length ?? 0) + (data.parkingExpenseItems?.length ?? 0),
      data)
    if (validatorResult === 'Valid') {
      setProcessing(true)
      const contentOption = snackBarStyle({
        text: 'Processing Taxi/Parking Expense Form',
        variant: "info",
        autoHideDuration: 30000,
      });
      const key = enqueueSnackbar(contentOption.message, contentOption.options);
      const payload = {
        ...data,
        taxiExpenseItems: data.taxiExpenseItems?.map((item: any) => {
          if (item.id) delete item.id
          return { ...item, branch: item.branch.value }
        }),
        parkingExpenseItems: data.parkingExpenseItems?.map((item: any) => {
          if (item.id) delete item.id
          return { ...item, branch: item.branch.value }
        }),
        claimDate: formattedDate(data.claimDate as Date),
      }
      try {
        const res = await API.post('/taxiparking-expense-form/', payload)
        if (res.data.status) {
          setProcessing(false)
          closeSnackbar(key)
          const contentOption = snackBarStyle({
            text: `${res.data.msg}, Redirecting to submitted forms page`,
            variant: "success",
            autoHideDuration: 3000
          });
          enqueueSnackbar(contentOption.message, contentOption.options);
          setTimeout(() => navigate("my-form"), 5000)
        }
      } catch (err) {
        setProcessing(false)
        closeSnackbar(key)
        const contentOption = snackBarStyle({
          text: "Submit Failed",
          variant: "error",
        });
        enqueueSnackbar(contentOption.message, contentOption.options);
      }
    } else {
      enqueueSnackbar(validatorResult.message, validatorResult.options)
    }
  }

  return (
    <div>
      <Loader open={processing} />
      <FormHeader />

      <Typography sx={{ fontWeight: 500, fontSize: { xs: 16, sm: 18, md: 21 } }}>
        Taxi/Parking Claim
      </Typography>

      <Paper elevation={5} sx={{ p: { xs: 1, sm: 2 }, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <EmployeeInformation
          data={data}
          onChange={(v) => setData(v)}
          submitValidating={submitValidating}
        />

        <Box>
          <Box sx={{ pb: 2 }}>
            <Typography variant='body1' sx={{ fontWeight: 600 }}>
              Taxi Expense
            </Typography>

            <TaxiItemsFormDesktop
              items={data?.taxiExpenseItems ?? []}
              onChange={(v) => handleChange(v, 'taxiExpenseItems')}
              submitValidating={submitValidating}
              itemsValidating={taxiItemsValidating}
              formData={taxiFormData}
              handleChangeFormData={handleChangeTaxiFormData}
              handleAddItem={handleAddTaxiItem}
              formLayouts={taxiLayouts}
            />

            <TaxiItemsFormMobile
              items={data?.taxiExpenseItems ?? []}
              onChange={(v) => handleChange(v, 'taxiExpenseItems')}
              submitValidating={submitValidating}
              itemsValidating={taxiItemsValidating}
              setItemsValidating={setTaxiItemsValidating}
              formData={taxiFormData}
              editData={taxiEditData}
              setEditData={setTaxiEditData}
              handleChangeFormData={handleChangeTaxiFormData}
              handleAddItem={handleAddTaxiItem}
              formLayouts={taxiLayouts}
              editLayouts={taxiLayouts}
            />
          </Box>

          <Box>
            <Typography variant='body1' sx={{ fontWeight: 600 }}>
              Parking Expense
            </Typography>
            <ParkingItemsFormDesktop
              items={data?.parkingExpenseItems ?? []}
              onChange={(v) => handleChange(v, 'parkingExpenseItems')}
              submitValidating={submitValidating}
              itemsValidating={parkingItemsValidating}
              formData={parkingFormData}
              handleChangeFormData={handleChangeParkingFormData}
              handleAddItem={handleAddParkingItem}
              formLayouts={parkingLayouts}
            />

            <ParkingItemsFormMobile
              items={data?.parkingExpenseItems ?? []}
              onChange={(v) => handleChange(v, 'parkingExpenseItems')}
              submitValidating={submitValidating}
              itemsValidating={parkingItemsValidating}
              setItemsValidating={setParkingItemsValidating}
              formData={parkingFormData}
              editData={parkingEditData}
              setEditData={setParkingEditData}
              handleChangeFormData={handleChangeParkingFormData}
              handleAddItem={handleAddParkingItem}
              formLayouts={parkingLayouts}
              editLayouts={parkingLayouts}
            />
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant='body1' sx={{ fontWeight: 600, pb: 2, visibility: 'hidden' }}>Comment</Typography>
            <CommentsForm
              data={data}
              onChange={(v) => setData(v)}
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <Typography variant='body1' sx={{ fontWeight: 600, pb: 2 }}>Please attach a file*</Typography>
            <UploadFormControl onChange={(v) => handleChange(v, 'attachments')} deleteFile={handleDeleteFile} />
          </Grid>
        </Grid>

        <FormButtons handleSave={handleSave} handleSubmit={handleSubmit} processing={processing} />
      </Paper>
    </div>
  )
}

export default withSnackbar(TaxiParking)
