import { useEffect, useState, useCallback } from "react";
import moment from "moment";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from "@mui/icons-material/Refresh";
import ImageListItem from "@mui/material/ImageListItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';

import MyFormProgressTable from "components/tables/MyFormProgressTable";
import MyFormSubmitTable from "components/tables/MyFormSubmitTable";
import { useUserStore } from "state/user.store";
import { setPendingFormApidata, setSubmitFormApidata } from "state/form.store";
import MyFormsCardList from "components/forms/my-forms/MyFormsCardList";
import API from 'api'


const MyForms = () => {
    const { pendingFormApiData, setPendingFormApiData }: any = setPendingFormApidata();
    const { submitFormApiData, setSubmitFormApiData }: any = setSubmitFormApidata();
    const [loading, setLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);
    const [search, setSearch] = useState('')
    const isAuth = useUserStore(state => state.isAuth)

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getFormRequest = useCallback(async () => {
        setLoading(true);
        try {
            const pendingResponse = await API.get("/my-form/pending");
            setPendingFormApiData(pendingResponse?.data);
            const submitResponse = await API.get("/my-form/submit");

            //sort claims in desc order
            submitResponse.data.sort((a: any, b: any) => {
                const dateA = moment(a.submittedDate);
                const dateB = moment(b.submittedDate);
                return dateB.diff(dateA);
            });

            setSubmitFormApiData(submitResponse.data);
        } catch (err) {
            console.log("err", err);
        } finally {
            setLoading(false);
        }
    }, [setPendingFormApiData]);


    useEffect(() => {
        getFormRequest();
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <ImageListItem sx={{ width: { xs: "60%", sm: "45%", md: "30%" } }}>
                    <img
                        src={`/static/img/JFC-LOGO-${isAuth?.country}.png`}
                        alt="JFC Logo"
                        loading="lazy"
                    />
                </ImageListItem>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: { xs: 500, md: 600 }, fontSize: { xs: 16, md: 20 } }}>
                        Employee Claims
                    </Typography>

                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Tooltip title="Refresh" placement="right">
                            <IconButton onClick={() => getFormRequest()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <TextField
                    sx={{ width: { xs: 145, md: 200 } }}
                    size="small"
                    placeholder="Form No."
                    type="search"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                    }}
                />

            </Box>

            {/* Tab Portion */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                }}
            >
                <Box>
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        aria-label="icon label tabs example"
                    >
                        {/* <Tab
                  label={`In Progress (${pendingFormApiData?.length})`}
                  sx={{
                    textTransform: "none",
                  }}
                /> */}
                        <Tab
                            label={`Submitted (${submitFormApiData?.length})`}
                            sx={{
                                textTransform: "none",
                            }}
                        />
                    </Tabs>
                </Box>
            </Box>

            {/* MY FORMS TABLE */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <MyFormsCardList submitforms={submitFormApiData} search={search} />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Paper elevation={5} sx={{ height: "100%", p: 2 }}>
                    {tabValue === 1 ? (
                        <MyFormProgressTable progress={pendingFormApiData} />
                    ) : (
                        <MyFormSubmitTable submitforms={submitFormApiData} search={search} />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default MyForms;
