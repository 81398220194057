import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import API from "api";
import OrderStatusTabs from "components/orders/orderStatusTabs";
import { DeleteProductModal } from "components/products/modal/deleteProductModal";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInvoiceStore } from "state/invoice.store";

const EditProduct = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bulkSelected, updateInvoice } = useInvoiceStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getProductRequest = useCallback(async () => {
    setLoading(true);
    try {
      const res = await API.get(`/products/${state.editproductId}`);
      setProduct(res.data.data[0]);
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  }, [state]);

  useEffect(() => {
    getProductRequest();
  }, [getProductRequest]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DeleteProduct = (deleteProductID: any) => {
    setDeleteProduct(deleteProductID[0]);
    handleOpen();
  };

  function handleUpdateProductChange(evt: any) {
    const value = evt.target.value;
    const _product = { ...product };
    _product[evt.target.name] = value;
    setProduct(_product);
  }

  const updateProductdata = async () => {
    const updatedata = await API.patch(`/products/${state.editproductId}`, {
      ...product,
    });
    if (updatedata.data.status) {
      updateInvoice({ bulkSelected: [] });
      navigate("/products");
    }
  };
  console.log("productproduct", product);
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Edit Products
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            Padding: 2,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <OrderStatusTabs statusName="ITEMS" />
            <Box sx={{ mt: "10px" }}>
              <TextField
                onChange={handleUpdateProductChange}
                name="item_code"
                defaultValue={product?.item_code}
                label="Supplier Item Code"
                fullWidth
                multiline
                type="text"
                variant="outlined"
                margin="dense"
              />

              <TextField
                onChange={handleUpdateProductChange}
                name="item_description"
                defaultValue={product?.item_description}
                multiline
                label="Item Description"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
              />

              <TextField
                onChange={handleUpdateProductChange}
                name="unit_price"
                defaultValue={product?.unit_price}
                multiline
                label="Unit Price"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
              />

              <TextField
                onChange={handleUpdateProductChange}
                name="tax_rate"
                defaultValue={product?.tax_rate}
                multiline
                label="Tax Rate"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
                gap: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                startIcon={<DeleteIcon />}
              // onClick={() => DeleteProduct(state.editproductId)}
              >
                Delete
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={updateProductdata}
              >
                save
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      {open && (
        <DeleteProductModal
          open={open}
          handleClose={handleClose}
          deleteProduct={deleteProduct}
        />
      )}
    </>
  );
};

export default EditProduct;
