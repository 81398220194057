import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

import { SubmitSummary } from "interfaces/submitform.interface";
import { setSubmitFormApidata, setSubmittedForm } from "../../state/form.store";
import PreviewModal from "components/pdfPreview/PreviewModal";
import Loader from "components/common/Loader";
import API from "api";

interface Column {
  key: keyof SubmitSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
  minWidth?: number;
  width?: number;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  {
    key: "type",
    numeric: false,
    disablePadding: false,
    label: "Form Type",
    align: "left",
  },
  {
    key: "paymentStatus",
    numeric: false,
    disablePadding: true,
    label: "Status",
    align: "center",
  },
  {
    key: "formNumber",
    numeric: false,
    disablePadding: true,
    label: "Form Number",
    align: "center",
  },
  {
    key: "submittedDate",
    numeric: false,
    disablePadding: true,
    label: "Date submitted",
    align: "center",
  },
  {
    key: "submittedBy",
    numeric: false,
    disablePadding: true,
    label: "Submitted by",
    align: "center",
  },
  {
    key: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Total Amount",
    align: "center",
  },
];

interface EnhancedTableProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align={headCell.align}
            padding={"normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell
          sx={{
            fontWeight: 600,
          }}
          align="center"
          padding={"normal"}
        >
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export interface MyFormSubmitTableProps {
  submitforms?: SubmitSummary[];
  search?: string;
}

const MyFormSubmitTable: React.FC<MyFormSubmitTableProps> = ({ submitforms = [], search }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [previewForm, setPreviewForm] = useState<{
    type: string;
    id: number;
  } | null>(null);
  const [rows, setRows] = useState<SubmitSummary[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const { setSubmitFormApiData }: any = setSubmitFormApidata();
  const { submittedForms, setSubmittedForms }: any = setSubmittedForm();

  useEffect(() => {
    if (search) {
      setRows(submitforms.filter((form) => String(form.formNumber).includes(search)));
    } else {
      setRows(submitforms);
    }
  }, [submitforms, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((row: SubmitSummary) => {
        return {
          formNumber: row.formNumber,
          type: row.type,
        };
      });
      setSubmittedForms(newSelecteds);
      return;
    }
    setSubmittedForms([]);
  };

  const isSelected = (name: { formNumber: number; type: string }) => {
    let value = false;
    for (const el of submittedForms) {
      // @ts-ignore
      if (el.formNumber === name.formNumber && el.type === name.type) {
        value = true;
      }
    }
    return value;
  };

  const handleSelectRow: any = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: { formNumber: number; type: string }
  ) => {
    const selectedIndex = submittedForms.filter(
      (el: any) => el.formNumber === name.formNumber && el.type === name.type
    );

    let newSelected: readonly any[] = [];
    if (!selectedIndex.length) {
      newSelected = newSelected.concat(submittedForms, name);
    } else {
      let indexRemove = submittedForms.findIndex(
        (el: any) => el.formNumber === name.formNumber && el.type === name.type
      );
      submittedForms.splice(indexRemove, 1);
      newSelected = submittedForms;
    }
    setSubmittedForms(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleDeletePaidClaim = async (id: number, type: string) => {
    setProcessing(true);
    const deleteToast = toast.loading("Deleting claim...");
    try {
      const { data } = await API.patch("/my-form", {
        id,
        type,
      });
      if (data.success) {
        toast.success("Claim deleted successfully");
        const newRows = rows.filter((row) => row.id !== id);
        setSubmitFormApiData(newRows);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Failed to delete claim. Please try again");
      console.log(error);
    } finally {
      toast.dismiss(deleteToast);
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Loader open={processing} showProgress={false} />
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={submittedForms.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows?.length}
          />
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: SubmitSummary, index: number) => {
                const isItemSelected = isSelected({
                  formNumber: row.formNumber,
                  type: row.type,
                });
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.formNumber} selected={isItemSelected}>
                    {/* TODO-ROMMEL: DO NOT DELETE, feature may be used in the future */}
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleSelectRow(event, {
                            formNumber: row.formNumber,
                            type: row.type,
                          })
                        }
                      />
                    </TableCell> */}
                    {columns.map((column) => {
                      const value = row[column.key];

                      if (column.key === "type") {
                        return (
                          <TableCell key={column.key} align={column.align} sx={{ width: column.width }}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "submittedDate") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {(value
                              ? moment(String(value)).format("DD/MM/YYYY")
                              : moment(String(row.createdAt)).format("DD/MM/YYYY")) ?? "-"}
                          </TableCell>
                        );
                      }

                      if (column.key === "totalAmount") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {parseFloat(value).toFixed(2)}
                          </TableCell>
                        );
                      }

                      if (column.key === "paymentStatus") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value === "Paid" ? value : "-"}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.key} align={column.align}>
                          {!!value ? value : "-"}
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      <Tooltip title="View Form">
                        <IconButton onClick={() => setPreviewForm({ id: row.id, type: row.type })}>
                          <PictureAsPdfIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <span>
                          <IconButton
                            // disabled={row.paymentStatus !== "Paid" && row.type !== "Purchase Request"}
                            onClick={() => handleDeletePaidClaim(row.id, row.type)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PreviewModal
        open={!!previewForm}
        handleClose={() => setPreviewForm(null)}
        formId={previewForm?.id}
        formType={previewForm?.type}
      />
    </Box>
  );
};

export default MyFormSubmitTable;
