import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const REVIEW_BEFORE_SUBMISSION_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'INFORMATION_TRUE_AND_CORRECT',
    label: 'This information is true and correct - Submit now',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.CHECKBOX,
  },
  {
    name: 'ACKNOWLEDGE_ALL_LEGAL',
    label: 'I/We acknowledge all legal have been entered into this application',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.CHECKBOX,
  },
];
