import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { FormControlItemLayout } from "../form-components/FormControlItem";

export const TAXI_EXPENSE_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'itemDate',
        label: 'Date',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'branch',
        label: 'Branch',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'from',
        label: 'From',
        required: true,
        fullWidth: true,
        variant: 'standard',
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'to',
        label: 'To',
        required: true,
        variant: 'standard',
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'reasons',
        label: 'Reasons',
        fullWidth: true,
        variant: 'standard',
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'amountInclGst',
        label: '$ Amt Incl GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
]


export const PARKING_EXPENSE_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'itemDate',
        label: 'Date',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'branch',
        label: 'Branch',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'entryTime',
        label: 'Entry Time',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.TIME,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'exitTime',
        label: 'Exit Time',
        required: true,
        variant: 'standard',
        fullWidth: true,
        type: FORM_CONTROL_TYPE.TIME,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'reasons',
        label: 'Reasons',
        fullWidth: true,
        variant: 'standard',
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 12,
    },
    {
        name: 'customer',
        label: 'Customers',
        fullWidth: true,
        variant: 'standard',
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'amountInclGst',
        label: '$ Amt Incl GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
]