import {
  BarControllerChartOptions,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  Legend,
  LinearScale,
  PluginChartOptions,
  registerables,
  ScaleChartOptions,
  Tick,
  Title,
  Tooltip,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { Bar } from 'react-chartjs-2';
import { formatCurrencyNumber } from 'utils/common.utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export function VerticalChart({ data: propsData, title = '' }: any) {
  const options:
    | _DeepPartialObject<
        CoreChartOptions<'bar'> &
          ElementChartOptions<'bar'> &
          PluginChartOptions<'bar'> &
          DatasetChartOptions<'bar'> &
          ScaleChartOptions<'bar'> &
          BarControllerChartOptions
      >
    | undefined = {
    indexAxis: 'x' as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        color: '#000',
        text: title,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return formatCurrencyNumber(context.parsed.y);
          },
        },
      },
    },
    locale: 'en-EN',
    scales: {
      y: {
        grid: {
          drawBorder: false,
          color: '#E5E6EB',
        },
        ticks: {
          color: '#B3BAC1',
          format: {
            style: 'currency',
            currency: 'USD',
            notation: 'compact',
          },
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: '#B3BAC1',
          callback: (
            tickValue: string | number,
            index: number,
            ticks: Tick[]
          ) => {
            const label = propsData?.[index]?.label ?? '';
            return label?.length > 20 ? `${label?.slice(0, 20)}...` : label;
          },
        },
      },
    },
  };

  // const labels = [
  //   { label: "DEC 20", value: 30000 },
  //   { label: "JAN 21", value: 28000 },
  //   { label: "FEB 21", value: 35000 },
  //   { label: "MAR 21", value: 40000 },
  //   { label: "APR 21", value: 32000 },
  // ];

  const data: any = {
    labels: propsData.map((d: any) => {
      return d.label;
    }),
    datasets: [
      {
        label: 'Dataset',
        data: propsData.map((d: any) => {
          return d.value;
        }),
        borderSkipped: false,
        categoryPercentage: 0.6,
        barPercentage: 0.7,
        borderRadius: '10',
        backgroundColor: '#B353FE',
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
