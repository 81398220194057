import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React from "react";
import { setPurchaseorderdata } from "state/purchaseorder.store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 2,
};

interface deletePoModalProps {
  open: boolean;
  handleClose: any;
  type: string;
  selectedPurchaseorder?: string[];
}

export const DeletePoModal: React.FC<deletePoModalProps> = ({
  open,
  handleClose,
  type,
  selectedPurchaseorder,
}) => {
  const { purchaseOrderApiData }: any = setPurchaseorderdata();

  return (
    <div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "#536DFE",
            opacity: 0.4,
          },
        }}
      >
        <Box sx={style}>
          {type === "delete" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Delete Purchase Orders
                  </Typography>
                  <Typography
                    variant="caption"
                    component="h5"
                    sx={{ color: "#999" }}
                  >
                    Do you want to do delete this purchase order?
                  </Typography>
                </Box>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Approve Purchase Orders
                  </Typography>
                  <Typography
                    variant="caption"
                    component="h5"
                    sx={{
                      color: "#999",
                      textAlign: "left",
                      width: "315px",
                    }}
                  >
                    Please Confirm That you would like to bulk approve The
                    Following purchase orders:
                  </Typography>
                </Box>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Box>
            </>
          )}

          <Box mt={2}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& td": { padding: "5px" },
                    "& th": { padding: "5px" },
                  }}
                >
                  <TableCell>PO#</TableCell>
                  <TableCell align="center">Date Created</TableCell>
                  <TableCell align="center">Total Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrderApiData
                  .filter((purchaseOrders: any) =>
                    selectedPurchaseorder?.includes(purchaseOrders.po_number)
                  )
                  .map((row: any) => (
                    <TableRow
                      key={row.po_number}
                      sx={{
                        "& td": { border: 0, padding: "5px" },
                        "& th": { border: 0, padding: "5px" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.po_number}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.total_amount}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Box mt={3} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "250px", boxShadow: "none", borderRadius: "12px" }}
            >
              CONFIRM
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
