import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import { ZoomTransition } from 'components/common/Transitions';
import { EMAIL_CATEGORY } from 'interfaces/enums';

const RequestUpgradeDialog = ({ open, handleClose, setCategory }: any) => {

  const handleExitDialog = () => {
    handleClose();
  };

  const handleUpgradeClick = async () => {
    setCategory(EMAIL_CATEGORY.REQUEST_UPGRADE)
    handleClose()
  };

  return (
    <Dialog
      open={open}
      onClose={handleExitDialog}
      TransitionComponent={ZoomTransition}
      fullWidth
      BackdropProps={{
        style: {
          backgroundColor: '#536DFE',
          opacity: 0.4,
        },
      }}
      sx={{ '& div.MuiPaper-root': { borderRadius: 2 } }}
      PaperProps={{ elevation: 6 }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pb: 0,
        }}
      >
        <IconButton
          onClick={handleExitDialog}
          sx={{
            position: 'absolute',
            p: 0,
            top: 15,
            right: 20,
            color: 'black',
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>

      </DialogTitle>

      <DialogContent sx={{ mt: 3 }}>

        <Typography variant='h5'>
          Please upgrade your plan to access this feature
        </Typography>
      </DialogContent>


      <DialogActions sx={{ pb: 3, px: 3, justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={handleUpgradeClick}
          sx={{
            borderRadius: 10,
            width: '75%',
            textTransform: 'capitalize',
            py: 1.2,
          }}
        >
          Upgrade
        </Button>
      </DialogActions>


    </Dialog>
  );
};

export default RequestUpgradeDialog;
