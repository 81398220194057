import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { FormControlItemLayout } from "../form-components/FormControlItem";

export const PURCHASE_REQUEST_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: "quantity",
        label: "Quantity",
        type: FORM_CONTROL_TYPE.NUMBER,
        variant: "standard",
        required: true,
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: "item",
        label: "Item",
        required: true,
        fullWidth: true,
        variant: "standard",
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: "description",
        label: "Description",
        variant: "standard",
        required: true,
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: "inclGST",
        label: "Amount Incl GST",
        variant: "standard",
        type: FORM_CONTROL_TYPE.CURRENCY,
        required: true,
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
];