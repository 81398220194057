import React from "react";

import Box from "@mui/material/Box";

export interface LogoProps {
  color: string;
  height: string;
  width: string;
}

const Logo: React.FC<LogoProps> = ({ color, height, width }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 232 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          d="M72.4737 10.332L64.3383 18.5081C63.9315 17.3691 63.3756 16.3522 62.6706 15.4573C61.9655 14.5353 61.1655 13.7624 60.2706 13.1387C59.4028 12.515 58.4537 12.0404 57.4232 11.715C56.3927 11.3896 55.3351 11.2269 54.2504 11.2269C52.7318 11.2269 51.2945 11.5387 49.9386 12.1625C48.6098 12.7862 47.4438 13.6675 46.4404 14.8065C45.4641 15.9183 44.6913 17.2471 44.1218 18.7928C43.5523 20.3386 43.2676 22.047 43.2676 23.9182C43.2676 25.4368 43.5523 26.8605 44.1218 28.1893C44.6913 29.518 45.4641 30.6841 46.4404 31.6875C47.4438 32.6909 48.6098 33.4773 49.9386 34.0468C51.2945 34.6162 52.7318 34.901 54.2504 34.901C55.3351 34.901 56.3792 34.7518 57.3825 34.4535C58.3859 34.1552 59.3215 33.7349 60.1893 33.1925C61.0841 32.6231 61.8706 31.9451 62.5485 31.1587C63.2536 30.3451 63.8231 29.4502 64.257 28.474L72.3924 36.6501C71.3619 38.1145 70.1687 39.4297 68.8128 40.5958C67.484 41.7619 66.0332 42.7517 64.4604 43.5652C62.9146 44.3788 61.274 44.9889 59.5384 45.3957C57.83 45.8296 56.0673 46.0465 54.2504 46.0465C51.186 46.0465 48.298 45.477 45.5862 44.3381C42.9015 43.172 40.5422 41.5856 38.5083 39.5788C36.5016 37.5721 34.9152 35.2264 33.7491 32.5417C32.583 29.857 32 26.9825 32 23.9182C32 20.5826 32.583 17.464 33.7491 14.5624C34.9152 11.6608 36.5016 9.1388 38.5083 6.99647C40.5422 4.82702 42.9015 3.11858 45.5862 1.87115C48.298 0.623716 51.186 0 54.2504 0C56.0673 0 57.8435 0.230504 59.5791 0.691511C61.3418 1.15252 63.0095 1.83047 64.5824 2.72537C66.1823 3.59315 67.6467 4.66431 68.9755 5.93886C70.3314 7.21341 71.4975 8.67779 72.4737 10.332Z"
        />
        <path
          fill={color}
          d="M119.819 23.0639C119.819 26.3181 119.236 29.3553 118.07 32.1756C116.904 34.9688 115.317 37.3958 113.31 39.4568C111.304 41.4907 108.944 43.1042 106.233 44.2974C103.548 45.4635 100.66 46.0465 97.5684 46.0465C94.504 46.0465 91.616 45.4635 88.9042 44.2974C86.2195 43.1042 83.8602 41.4907 81.8263 39.4568C79.8196 37.3958 78.2332 34.9688 77.0671 32.1756C75.901 29.3553 75.318 26.3181 75.318 23.0639C75.318 19.7555 75.901 16.6912 77.0671 13.8709C78.2332 11.0506 79.8196 8.62355 81.8263 6.5897C83.8602 4.52872 86.2195 2.91519 88.9042 1.74912C91.616 0.583039 94.504 0 97.5684 0C100.66 0 103.548 0.555921 106.233 1.66776C108.944 2.75249 111.304 4.31178 113.31 6.34563C115.317 8.35237 116.904 10.7794 118.07 13.6268C119.236 16.4471 119.819 19.5928 119.819 23.0639ZM108.633 23.0639C108.633 21.2741 108.334 19.6606 107.738 18.2234C107.168 16.759 106.382 15.5115 105.378 14.4811C104.375 13.4235 103.195 12.6235 101.839 12.0811C100.511 11.5116 99.087 11.2269 97.5684 11.2269C96.0498 11.2269 94.6125 11.5116 93.2566 12.0811C91.9278 12.6235 90.7618 13.4235 89.7584 14.4811C88.7821 15.5115 88.0093 16.759 87.4398 18.2234C86.8703 19.6606 86.5856 21.2741 86.5856 23.0639C86.5856 24.7453 86.8703 26.3045 87.4398 27.7418C88.0093 29.1791 88.7821 30.4265 89.7584 31.4841C90.7618 32.5417 91.9278 33.3824 93.2566 34.0061C94.6125 34.6027 96.0498 34.901 97.5684 34.901C99.087 34.901 100.511 34.6162 101.839 34.0468C103.195 33.4773 104.375 32.6773 105.378 31.6468C106.382 30.6163 107.168 29.3689 107.738 27.9045C108.334 26.4401 108.633 24.8266 108.633 23.0639Z"
        />
        <path
          fill={color}
          d="M181.401 29.4909C181.401 31.7688 180.967 33.9247 180.099 35.9586C179.231 37.9653 178.038 39.7144 176.52 41.2059C175.028 42.6974 173.265 43.8771 171.232 44.7448C169.225 45.6126 167.069 46.0465 164.764 46.0465C162.703 46.0465 160.723 45.694 158.825 44.9889C156.927 44.2567 155.205 43.1991 153.659 41.8161C152.14 43.1991 150.432 44.2567 148.534 44.9889C146.663 45.694 144.683 46.0465 142.595 46.0465C140.29 46.0465 138.134 45.6126 136.127 44.7448C134.12 43.8771 132.358 42.6974 130.839 41.2059C129.348 39.7144 128.168 37.9653 127.3 35.9586C126.432 33.9247 125.999 31.7688 125.999 29.4909V1.74912H137.103V29.4909C137.103 30.2502 137.239 30.9689 137.51 31.6468C137.808 32.2976 138.202 32.8807 138.69 33.3959C139.205 33.884 139.788 34.2773 140.439 34.5756C141.117 34.8467 141.836 34.9823 142.595 34.9823C143.354 34.9823 144.073 34.8467 144.751 34.5756C145.429 34.2773 146.025 33.884 146.541 33.3959C147.056 32.8807 147.449 32.2976 147.72 31.6468C148.018 30.9689 148.168 30.2502 148.168 29.4909V1.74912H159.232V29.4909C159.232 30.2502 159.381 30.9689 159.679 31.6468C159.978 32.2976 160.371 32.8807 160.859 33.3959C161.374 33.884 161.957 34.2773 162.608 34.5756C163.286 34.8467 164.005 34.9823 164.764 34.9823C165.523 34.9823 166.242 34.8467 166.92 34.5756C167.598 34.2773 168.181 33.884 168.669 33.3959C169.184 32.8807 169.591 32.2976 169.889 31.6468C170.187 30.9689 170.337 30.2502 170.337 29.4909V1.74912H181.401V29.4909Z"
        />
        <path
          fill={color}
          d="M232 23.0639C232 26.3181 231.417 29.3553 230.251 32.1756C229.085 34.9688 227.498 37.3958 225.492 39.4568C223.485 41.4907 221.126 43.1042 218.414 44.2974C215.729 45.4635 212.841 46.0465 209.75 46.0465C206.685 46.0465 203.797 45.4635 201.085 44.2974C198.401 43.1042 196.041 41.4907 194.008 39.4568C192.001 37.3958 190.414 34.9688 189.248 32.1756C188.082 29.3553 187.499 26.3181 187.499 23.0639C187.499 19.7555 188.082 16.6912 189.248 13.8709C190.414 11.0506 192.001 8.62355 194.008 6.5897C196.041 4.52872 198.401 2.91519 201.085 1.74912C203.797 0.583039 206.685 0 209.75 0C212.841 0 215.729 0.555921 218.414 1.66776C221.126 2.75249 223.485 4.31178 225.492 6.34563C227.498 8.35237 229.085 10.7794 230.251 13.6268C231.417 16.4471 232 19.5928 232 23.0639ZM220.814 23.0639C220.814 21.2741 220.515 19.6606 219.919 18.2234C219.349 16.759 218.563 15.5115 217.56 14.4811C216.556 13.4235 215.377 12.6235 214.021 12.0811C212.692 11.5116 211.268 11.2269 209.75 11.2269C208.231 11.2269 206.794 11.5116 205.438 12.0811C204.109 12.6235 202.943 13.4235 201.94 14.4811C200.963 15.5115 200.19 16.759 199.621 18.2234C199.052 19.6606 198.767 21.2741 198.767 23.0639C198.767 24.7453 199.052 26.3045 199.621 27.7418C200.19 29.1791 200.963 30.4265 201.94 31.4841C202.943 32.5417 204.109 33.3824 205.438 34.0061C206.794 34.6027 208.231 34.901 209.75 34.901C211.268 34.901 212.692 34.6162 214.021 34.0468C215.377 33.4773 216.556 32.6773 217.56 31.6468C218.563 30.6163 219.349 29.3689 219.919 27.9045C220.515 26.4401 220.814 24.8266 220.814 23.0639Z"
        />
        <path
          fill={color}
          d="M15.9128 41.3331L24.0466 41.3331C24.4015 41.3331 24.7396 41.1943 24.9912 40.9431C25.241 40.6937 25.3819 40.3543 25.3819 40L25.3819 25.3333L38.7386 25.3333C39.1894 27.9958 39.8598 30.2495 42.3369 31.3315C44.814 32.4115 40.0195 32.1715 42.2825 30.694C44.5437 29.2165 41.0002 26.7001 41.0002 24.0002C41.0002 21.3003 44.5437 18.784 42.2825 17.3065C40.0195 15.829 44.8144 15.589 42.3369 16.669C39.8598 17.7509 39.1894 20.0047 38.7386 22.6672L25.3819 22.6672L25.3819 8.00052C25.3819 7.26361 24.7846 6.66739 24.0466 6.66739L15.9128 6.66739C15.4621 4.00483 13.6986 1.74922 11.2215 0.669198C8.74442 -0.410823 5.88779 -0.172691 3.62478 1.30483C1.36364 2.78421 0.000159986 5.30052 0.000159868 8.00047C0.00015975 10.6987 1.36359 13.2167 3.62478 14.6942C5.88779 16.1717 8.74408 16.4117 11.2215 15.3298C13.6986 14.2498 15.4621 11.9942 15.9128 9.33356L22.7115 9.33356L22.7115 38.6664L15.9128 38.6664C15.4621 36.0058 13.6986 33.7502 11.2215 32.6702C8.74442 31.5883 5.88779 31.8283 3.62478 33.3058C1.36364 34.7833 0.000158587 37.3015 0.000158469 39.9995C0.000158351 42.6996 1.36359 45.2158 3.62478 46.6952C5.88779 48.1727 8.74408 48.4108 11.2215 47.3308C13.6986 46.2508 15.4621 43.9956 15.9128 41.3331Z"
        />
      </svg>
    </Box>
  );
};

export default Logo;
