import { FC } from 'react'

import Box from "@mui/material/Box";
import ImageListItem from "@mui/material/ImageListItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

interface SignatureImageProps {
    label?: string;
    imageUrl?: string
}

const SignatureImage: FC<SignatureImageProps> = ({ label, imageUrl }) => {
    return (
        <Paper elevation={3} sx={{ minHeight: 200, width: '100%', position: 'relative' }}>
            <Typography sx={{ position: 'absolute', p: 1 }} variant='subtitle2'>{label}</Typography>
            {imageUrl ?
                <Box sx={{ minHeight: 200, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ImageListItem>
                        <img
                            style={{ maxHeight: '10cm' }}
                            src={`${imageUrl}`}
                            srcSet={`${imageUrl}`}
                            alt={label}
                            loading="lazy"
                        />
                    </ImageListItem>
                </Box> : null}
        </Paper>
    )
}

export default SignatureImage