import { Backdrop, Box, CircularProgress, LinearProgress } from '@mui/material';
import React from 'react';

type Props = {
  open?: boolean | Boolean;
  variant?: 'linear' | 'circular';
  showProgress?: boolean;
  children?: JSX.Element | null;
};

const Loader: React.FC<Props> = ({
  children,
  open = false,
  showProgress = true,
  variant = 'circular',
}) => {
  return (
    <Box>
      {open ? (
        variant === 'circular' ? (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open as boolean}
          >
            <CircularProgress sx={{ display: !showProgress ? 'none' : '' }} color='inherit' />
          </Backdrop>
        ) : variant === 'linear' ? (
          <LinearProgress color='primary' />
        ) : null
      ) : null}

      {children}
    </Box>
  );
};

export default Loader;
