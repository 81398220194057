import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { ExpenseForm, GeneralExpenseItem } from 'interfaces/myforms.interface'
import { useSnackbar, withSnackbar } from 'notistack'
import UploadFormControl from '../../form-components/UploadFormControl'
import { formattedDate, snackBarStyle } from 'utils/common.utils'
import { formDataValidator, generalClaimItemValidator } from 'utils/form.utils'
import Loader from 'components/common/Loader'
import EmployeeInformation from '../EmployeeInformation'
import FormHeader from '../FormHeader'
import FormButtons from '../FormButtons'
import CommentsForm from '../CommentsForm'
import GeneralExpenseFormDesktop from "./GeneralExpenseFormDesktop"
import GeneralExpenseFormMobile from "./GeneralExpenseFormMobile"
import { GENERAL_EXPENSE_SUB_CATEGORY, JFC_AU_BRANCHES, JFC_NZ_BRANCHES } from "./SelectList"
import { GENERAL_EXPENSE_LAYOUT } from "../../form-layouts/general-expense.layout";
import { useUserStore } from "state/user.store";
import { OrganisationCountry } from "enums/organisation.enums";
import API from 'api'

const GeneralExpense = () => {
  const [data, setData] = useState<ExpenseForm>({
    generalExpenseItems: [] as GeneralExpenseItem[],
    claimDate: new Date(),
    attachments: [],
    comment: ''
  })

  const [formData, setFormData] = useState<GeneralExpenseItem | undefined>(undefined)
  const [editData, setEditData] = useState<GeneralExpenseItem | undefined>(undefined)
  const [processing, setProcessing] = useState<boolean>(false)
  const [submitValidating, setSubmitValidating] = useState<boolean>(false)
  const [itemsValidating, setItemsValidating] = useState<boolean>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isAuth = useUserStore(state => state.isAuth)

  // TODO-ROMMEL: DON'T DELETE, save as draft feature
  // useEffect(() => {
  //   ; (async () => {
  //     const savedData = (await API.get('/general-expense-claim/save')) ?? {}
  //     setFormData((s) => ({
  //       ...(s ?? {}),
  //       expenseItems: savedData?.data?.generalExpenseItems ?? ([] as GeneralExpenseItem[]),
  //       filePath: savedData?.data?.filePath ?? '',
  //     }))
  //   })()
  // }, [])

  const layouts = GENERAL_EXPENSE_LAYOUT

  const initialLayouts = layouts.map((layout) => {
    if (layout.name === "branch") {
      return { ...layout, selectList: isAuth.country === OrganisationCountry.Australia ? JFC_AU_BRANCHES : JFC_NZ_BRANCHES }
    }
    return { ...layout }
  })

  const currentFormLayouts = useMemo(() => {
    if (formData?.['category']) {
      return initialLayouts.map((layout) => {
        if (layout.name === "subCategory") {
          return { ...layout, selectList: GENERAL_EXPENSE_SUB_CATEGORY[formData?.category.value] }
        }
        return { ...layout }
      }
      )
    }
    return initialLayouts
  }, [formData?.category])

  const editFormLayouts = useMemo(() => {
    if (editData?.['category']) {
      return initialLayouts.map((layout) => {
        if (layout.name === "subCategory") {
          return { ...layout, selectList: GENERAL_EXPENSE_SUB_CATEGORY[editData?.category.value] }
        }
        return { ...layout }
      }
      )
    }
    return initialLayouts
  }, [editData?.category])



  const handleChangeFormData = (value: any = {}) => {
    setFormData(value)
  }

  const handleAddItem = () => {
    if (!generalClaimItemValidator(formData as GeneralExpenseItem)) {
      setItemsValidating(true)
      const contentOption = snackBarStyle({
        text: 'Please fillout all required fields.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return false
    }
    setItemsValidating(false)
    setData({ ...data, generalExpenseItems: [...data.generalExpenseItems as Array<any>, { ...formData, id: nanoid() }] })
    handleChangeFormData(undefined)
  }

  const handleSave = async () => {
    await API.post('/general-expense-claim/save', data)
    return true
  }

  const handleChange = (value: any, keyName: string) => {
    setData((s) => ({ ...(s ?? {}), [keyName]: value }))
  }

  const handleDeleteFile = (value: string) => {
    setData((s) => ({ ...(s ?? {}), attachments: s.attachments?.filter(att => att !== value) }))
  }

  const handleSubmit = async () => {
    setSubmitValidating(true)
    if (editData) {
      const contentOption = snackBarStyle({
        text: 'Please save the item you are editing.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return
    }
    const validatorResult = formDataValidator('Claim', data.generalExpenseItems?.length ?? 0, data)
    if (validatorResult === 'Valid') {
      setProcessing(true)
      const contentOption = snackBarStyle({
        text: 'Processing General Expense Form',
        variant: "info",
        autoHideDuration: 30000,
      });
      const key = enqueueSnackbar(contentOption.message, contentOption.options);
      const payload = {
        ...data,
        generalExpenseItems: data.generalExpenseItems?.map((item: any) => {
          if (item.id) delete item.id
          return {
            ...item,
            branch: item.branch.value,
            category: item.category.label,
            subCategory: item.subCategory.value
          }
        }),
        claimDate: formattedDate(data.claimDate as Date),
      }
      try {
        const res = await API.post('/general-expense-form/', payload)
        if (res.data.status) {
          setProcessing(false)
          closeSnackbar(key)
          const contentOption = snackBarStyle({
            text: `${res.data.msg}, Redirecting to submitted forms page`,
            variant: "success",
            autoHideDuration: 3000
          });
          enqueueSnackbar(contentOption.message, contentOption.options);
          setTimeout(() => navigate("my-form"), 5000)
        }
      } catch (err) {
        setProcessing(false)
        closeSnackbar(key)
        const contentOption = snackBarStyle({
          text: "Submit Failed",
          variant: "error",
        });
        enqueueSnackbar(contentOption.message, contentOption.options);
      }
    } else {
      enqueueSnackbar(validatorResult.message, validatorResult.options)
    }
  }

  return (
    <div>
      <Loader open={processing} />
      <FormHeader />
      <Typography sx={{ fontWeight: 500, fontSize: { xs: 16, sm: 18, md: 21 } }}>
        General Expense Claim
      </Typography>
      <Paper elevation={5} sx={{ p: { xs: 1, sm: 2 }, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <EmployeeInformation
          data={data}
          onChange={(v) => setData(v)}
          submitValidating={submitValidating}
        />

        <Box>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            Items
          </Typography>

          <GeneralExpenseFormDesktop
            items={data?.generalExpenseItems ?? []}
            onChange={(v) => handleChange(v, 'generalExpenseItems')}
            submitValidating={submitValidating}
            itemsValidating={itemsValidating}
            formData={formData}
            handleChangeFormData={handleChangeFormData}
            handleAddItem={handleAddItem}
            formLayouts={currentFormLayouts}
          />

          <GeneralExpenseFormMobile
            items={data?.generalExpenseItems ?? []}
            onChange={(v) => handleChange(v, 'generalExpenseItems')}
            submitValidating={submitValidating}
            itemsValidating={itemsValidating}
            setItemsValidating={setItemsValidating}
            formData={formData}
            editData={editData}
            setEditData={setEditData}
            handleChangeFormData={handleChangeFormData}
            handleAddItem={handleAddItem}
            formLayouts={currentFormLayouts}
            editLayouts={editFormLayouts}
          />


        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant='body1' sx={{ fontWeight: 600, pb: 2, visibility: 'hidden' }}>Comment</Typography>
            <CommentsForm
              data={data}
              onChange={(v) => setData(v)}
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <Typography variant='body1' sx={{ fontWeight: 600, pb: 2 }}>Please attach a file*</Typography>
            <UploadFormControl onChange={(v) => handleChange(v, 'attachments')} deleteFile={handleDeleteFile} />
          </Grid>
        </Grid>

        <FormButtons handleSave={handleSave} handleSubmit={handleSubmit} processing={processing} />
      </Paper>
    </div>
  )
}

export default withSnackbar(GeneralExpense)
