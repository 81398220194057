export const JFC_NZ_BRANCHES: { label: string; value: string }[] = [
  { label: 'Auckland', value: 'auckland' },
  { label: 'Wellington', value: 'wellington' },
  { label: 'Christchurch', value: 'christchurch' },
];

export const JFC_AU_BRANCHES: { label: string; value: string }[] = [
  { label: 'Head Office', value: 'Head Office' },
  { label: 'Sydney', value: 'Sydney' },
  { label: 'Adelaide', value: 'Adelaide' },
  { label: 'Brisbane', value: 'Brisbane' },
  { label: 'Canberra', value: 'Canberra' },
  { label: 'Gold Coast', value: 'Gold Coast' },
  { label: 'Melbourne', value: 'Melbourne' },
  { label: 'Perth', value: 'Perth' },
  { label: 'Sunshine Coast', value: 'Sunshine Coast' },
];

export const CURRENCY: { label: string; value: string }[] = [
  { label: 'NZD', value: 'NZD' },
  { label: 'AUD', value: 'AUD' },
  { label: 'USD', value: 'USD' },
];

export const GENERAL_EXPENSE_CATEGORY: SELECT_TYPE[] = [
  { label: 'Office', value: 'office' },
  { label: 'Others', value: 'others' },
  { label: 'Vehicle', value: 'vehicle' },
  { label: 'Warehouse', value: 'warehouse' },
  { label: 'Travel', value: 'travel' },
  { label: 'Cannot find', value: 'cannot_find' },
];

interface SELECT_TYPE {
  label: string;
  value: string;
}

export interface SUB_CATEGORY_TYPE {
  office: SELECT_TYPE[];
  others: SELECT_TYPE[];
  vehicle: SELECT_TYPE[];
  warehouse: SELECT_TYPE[];
  travel: SELECT_TYPE[];
  cannot_find: SELECT_TYPE[];
}

export const GENERAL_EXPENSE_SUB_CATEGORY: SUB_CATEGORY_TYPE = {
  office: [
    { label: 'Security Cost', value: 'Security Cost' },
    { label: 'Staff Amenities', value: 'Staff Amenities' },
    { label: 'Staff Cost -Others', value: 'Staff Cost -Others' },
    { label: 'Staff Welfare -Medical', value: 'Staff Welfare -Medical' },
    { label: 'Stationary -Consumables', value: 'Stationary -Consumables' },
    { label: 'Stationary -Printing', value: 'Stationary -Printing' },
    { label: 'Training', value: 'Training' },
  ],
  others: [
    { label: 'Call charges', value: 'Call charges' },
    { label: 'Debtors -JFC NZ', value: 'Debtors -JFC NZ' },
    { label: 'Internet', value: 'Internet' },
    {
      label: 'Licence & Trade Membership',
      value: 'Licence & Trade Membership',
    },
    { label: 'Licence Fee', value: 'Licence Fee' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Promotion', value: 'Promotion' },
    { label: 'Software Licence', value: 'Software Licence' },
    { label: 'Software Support', value: 'Software Support' },
    { label: 'Subscription', value: 'Subscription' },
    { label: 'Web Hosting', value: 'Web Hosting' },
    { label: 'Donations', value: 'donations' },
    { label: 'Meeting', value: 'meeting' },
    { label: 'Sample', value: 'sample' },
  ],
  vehicle: [
    { label: 'Car', value: 'Car' },
    { label: 'Car Lease', value: 'Car Lease' },
    { label: 'Others', value: 'Others' },
    { label: 'Petrol', value: 'Petrol' },
    { label: 'Repair', value: 'Repair' },
  ],
  warehouse: [
    { label: 'Cleaning', value: 'Cleaning' },
    { label: 'Courier', value: 'Courier' },
    { label: 'Freight Charge', value: 'Freight Charge' },
    { label: 'Other Warehouse Expenses', value: 'Other Warehouse Expenses' },
    { label: 'Postage', value: 'Postage' },
    { label: 'QA Cost', value: 'QA Cost' },
    { label: 'Relocation Cost', value: 'Relocation Cost' },
    { label: 'Repair & MTNC -Plant', value: 'Repair & MTNC -Plant' },
    { label: 'Storage', value: 'Storage' },
    { label: 'Warehouse Supplies', value: 'Warehouse Supplies' },
  ],
  travel: [{ label: 'Overseas Travel', value: 'Overseas Travel' }],
  cannot_find: [{ label: 'Others', value: 'others' }],
};
