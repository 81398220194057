import moment from "moment";
import { progressSummary } from "interfaces/inprogressform.interface";
import { createprogressSummary } from "utils/formprogress.util";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { setPendingForm } from "state/form.store";

interface Column {
  key: keyof progressSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
  minWidth?: number;
  width?: number;
  format?: (value: number) => string;
}

export const invoiceTableColumns: readonly Column[] = [
  {
    key: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    align: "center",
  },
  {
    key: "formNumber",
    numeric: false,
    disablePadding: true,
    label: "Form Number",
    align: "center",
  },
  {
    key: "claimDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    align: "center",
  },
  {
    key: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Total Amount",
    align: "center",
  },
  {
    key: "purchaseRequestForm",
    numeric: true,
    disablePadding: true,
    label: "Purchase Request Form",
    align: "center",
  },
];

interface EnhancedTableProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  const columns = invoiceTableColumns;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align="center"
            padding={"normal"}
          >
            {/* Below code is done due to request to wrap in two lines all two-word head titles on all screen sizes */}
            {headCell.label.split("").length === 2 ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                  {headCell.label.split(" ")[0]}
                </Typography>
                <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                  {" "}
                  {headCell.label.split(" ")[1]}
                </Typography>
              </Box>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface MyFormProgressTableProps {
  progress?: progressSummary[];
}

const MyFormProgressTable: React.FC<MyFormProgressTableProps> = ({
  progress,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState<any>([]);

  const { pendingForms, setPendingForms }: any = setPendingForm();

  const columns = invoiceTableColumns;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((row: progressSummary) => {
        return {
          formNumber: row.formNumber,
          type: row.type,
        };
      });
      setPendingForms(newSelecteds);
      return;
    }
    setPendingForms([]);
  };

  const isSelected = (name: { formNumber: number; type: string }) => {
    let value = false;
    for (const el of pendingForms) {
      // @ts-ignore
      if (el.formNumber === name.formNumber && el.type === name.type) {
        value = true;
      }
    }
    return value;
  };

  const handleSelectRow: any = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: { formNumber: number; type: string }
  ) => {
    const selectedIndex = pendingForms.filter(
      (el: any) => el.formNumber === name.formNumber && el.type === name.type
    );

    let newSelected: readonly any[] = [];
    if (!selectedIndex.length) {
      newSelected = newSelected.concat(pendingForms, name);
    } else {
      let indexRemove = pendingForms.findIndex(
        (el: any) => el.formNumber === name.formNumber && el.type === name.type
      );
      pendingForms.splice(indexRemove, 1);
      newSelected = pendingForms;
    }
    setPendingForms(newSelected);
  };

  useEffect(() => {
    const data = progress?.map((progress: progressSummary) => {
      return { ...createprogressSummary(progress) };
    });
    setRows(data);
  }, [progress]);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: Math.max(window.innerHeight - 380, 400) }}
      >
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={pendingForms.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: progressSummary, index: number) => {
                const isItemSelected = isSelected({
                  formNumber: row.formNumber,
                  type: row.type,
                });
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.formNumber}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleSelectRow(event, {
                            formNumber: row.formNumber,
                            type: row.type,
                          })
                        }
                      />
                    </TableCell>

                    {columns.map((column) => {
                      const value = row[column.key];

                      if (column.key === "type") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "formNumber") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            <>{value}</>
                          </TableCell>
                        );
                      }

                      if (column.key === "claimDate") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {moment(value).format("DD/MM/YYYY")}
                          </TableCell>
                        );
                      }

                      if (column.key === "totalAmount") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "purchaseRequestForm") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {!!value ? (
                              <>
                                <Link to={String(value)}>{row.type} claim</Link>
                              </>
                            ) : (
                              <>-</>
                            )}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          sx={{ pr: 4, width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default MyFormProgressTable;
