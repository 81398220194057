import { FC } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import FormHeader from './FormHeader'
import EmployeeInformation from './EmployeeInformation'

interface ClaimAttachmentsProps {
    attachments?: string[];
    formData: any;
    page: number;
    totalPages: number;
    formType: string;
    paperView?: boolean;
    country: string;
}

const extractFilenameFromS3Url = (url: string) => {
    const filename = url.substring(url.lastIndexOf("/") + 1, url.indexOf("?"));
    return filename.substring(11)
}

const ClaimAttachments: FC<ClaimAttachmentsProps> = ({ attachments, formData, page, totalPages, formType, paperView, country }) => {

    return (
        <Paper
            elevation={paperView ? 3 : 0}
            sx={{
                width: '21cm',
                height: '29.7cm',
                marginX: 'auto',
                display: 'flex',
                flexDirection: 'column',
                px: 4,
                py: 5,
                position: 'relative',
                breakInside: 'avoid',
                breakBefore: 'always',
                marginY: paperView ? 3 : null,
                boxShadow: paperView ? null : 'none'
            }}>
            <FormHeader
                documentDocuwareId={formData.documentDocuwareId}
                formType={formType}
                country={country}
            />
            <EmployeeInformation
                user={formData.user}
                date={formData.claimDate}
            />

            <Box sx={{ py: 3, flex: 1, }}>
                <Typography variant="h6" color='gray' sx={{ pb: 1 }}>Attachment</Typography>
                <Grid container sx={{ height: '95%', margin: 0, width: '100%', borderRadius: 1, border: '3px solid rgba(128, 128, 128, 0.6)' }}>
                    {attachments?.map((attachment, index) => {
                        return (
                            <Grid key={index} item xs={4} sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {attachment.includes(".pdf") ?
                                    // <iframe src={attachment} style={{ width: '100%', height: '50%', overflow: "hidden" }} />
                                    <Box sx={{ border: "2px solid grey", borderRadius: 1, width: '100%', height: '50%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src='/static/img/pdf-attachment.png' alt={extractFilenameFromS3Url(attachment)} />
                                    </Box>
                                    :
                                    <img style={{ width: '100%', height: '50%' }} src={attachment} alt={extractFilenameFromS3Url(attachment)} />
                                }
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='caption' sx={{ wordWrap: 'break-word', width: '100%', }}>{extractFilenameFromS3Url(attachment)}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Typography sx={{ position: "absolute", right: 30, bottom: 30 }}>{`Page ${page} - ${totalPages}`}</Typography>
        </Paper>
    )
}

export default ClaimAttachments