import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/system';
import API from 'api';
import RequestUpgradeDialog from 'components/common/RequestUpgradeDialog';
import { DeletePoModal } from 'components/orders/modal/deletePoModal';
import PurchaseOrdersTable from 'components/orders/purchaseOrderTable';
import HelpMenuDialog from 'components/sidebar/HelpMenuDialog';
import { ACCOUNTING, EXECUTIVES, MANAGERS } from 'constants/departments';
import { EMAIL_CATEGORY } from 'interfaces/enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInvoiceStore } from 'state/invoice.store';
import { setPurchaseorderdata } from 'state/purchaseorder.store';
import { useUserStore } from 'state/user.store';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PurchaseOrder = () => {
  const { bulkSelected, updateInvoice } = useInvoiceStore();
  const { purchaseOrderApiData, setPurchaseOrderApiData }: any =
    setPurchaseorderdata();

  const [poStatusTab, setPoStatusTab] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState<boolean>(false);
  //   const [searchParam, setSearchParam] = useState();

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState<Boolean>(false)
  const [category, setCategory] = useState<EMAIL_CATEGORY | ''>('');

  //////////////////////////////////////////
  ///****DON'T DELETE COMMENTED CODE****////
  /////////////////////////////////////////

  // const navigate = useNavigate();

  // const getPurchaseOrderRequest = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const res = await API.get('/purchase-orders');
  //     setPurchaseOrderApiData(res.data.data);
  //   } catch (err) {
  //     console.log('err', err);
  //   } finally {
  //     setLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   getPurchaseOrderRequest();
  // }, [getPurchaseOrderRequest]);

  // const createNewPo = () => {
  //   navigate('/create-purchase-order');
  // };

  // const EditPurchaseOrder = (purchaseOrderID: any) => {
  //   console.log('EditPurchaseOrder', purchaseOrderID);
  // };
  // const DeletePurchaseOrder = (purchaseOrderID: any) => {
  //   console.log('purchaseOrderIDpurchaseOrderID', purchaseOrderID);
  //   handleOpen();
  // };

  // const searchPurchaseOrder = async (e: any) => {
  //   const searchParam = e.target.value;
  //   if (searchParam.length) {
  //     const res = await API.get(`/purchase-orders/search/${searchParam}`);
  //     setPurchaseOrderApiData(res.data.data);
  //   } else {
  //     getPurchaseOrderRequest();
  //   }
  // };

  const { isAuth } = useUserStore();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPoStatusTab(newValue);
  };

  const filterArray = (arr: any[], type: string) => {
    let array;
    if (type === 'approved') {
      array = arr.filter((status: any) => status.status === 'approved');
    } else if (type === 'rejected') {
      array = arr.filter((status: any) => status.status === 'rejected');
    } else if (type === 'pending') {
      array = arr.filter((status: any) => status.status === 'pending');
    }
    return array;
  };

  // const handleRefresh = () => {
  //   getPurchaseOrderRequest();
  // };

  const handleUpgradeRequest = () => {
    setOpenUpgradeDialog(true)
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              Purchase Order
            </Typography>
            {loading ? (
              <CircularProgress sx={{ ml: 1.5 }} size={20} />
            ) : (
              <Tooltip title='Refresh' placement='right'>
                {/* <IconButton onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton> */}
                <IconButton onClick={handleUpgradeRequest}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            sx={{
              width: '45ch',
              '& .css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '15px',
              },
            }}
            type='search'
            variant='outlined'
            margin='dense'
            // onChange={searchPurchaseOrder}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  Search <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 2,
            gap: 2.2,
          }}
        >
          <Button
            type='submit'
            variant='contained'
            size='small'
            startIcon={<AddIcon />}
            // onClick={createNewPo}
            onClick={handleUpgradeRequest}
          >
            create new
          </Button>

          <Button
            type='submit'
            variant='contained'
            size='small'
            disabled={bulkSelected.length > 1 || !bulkSelected.length}
            startIcon={<EditIcon />}
            // onClick={() => EditPurchaseOrder(bulkSelected)}
            onClick={handleUpgradeRequest}
          >
            Edit
          </Button>

          <Button
            type='submit'
            variant='contained'
            size='small'
            startIcon={<DeleteIcon />}
            disabled={!bulkSelected.length}
            // onClick={() => DeletePurchaseOrder(bulkSelected)}
            onClick={handleUpgradeRequest}
          >
            Delete
          </Button>
        </Box>
        <Box
          sx={{
            py: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            value={poStatusTab}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label={`pending (${filterArray(purchaseOrderApiData, 'pending')?.length
                })`}
              {...a11yProps(0)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label={`approved (${filterArray(purchaseOrderApiData, 'approved')?.length
                })`}
              {...a11yProps(1)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label={`rejected (${filterArray(purchaseOrderApiData, 'rejected')?.length
                })`}
              {...a11yProps(2)}
              sx={{ textTransform: 'capitalize' }}
            />
          </Tabs>
          {/* <OrdertatusTabs statusName={'pending'} /> */}
        </Box>

        <Paper elevation={5} sx={{ height: '100%', p: 2 }}>
          <Box>
            <FormControl variant='standard' sx={{ minWidth: 120 }}>
              <InputLabel sx={{ fontWeight: 600 }} id='simple-select-label'>
                Department
              </InputLabel>
              <Select
                labelId='simple-select-label'
                id='simple-select'
                value={'All'}
                onChange={(e: SelectChangeEvent) =>
                  updateInvoice({
                    chosenDepartment: e.target.value,
                  })
                }
                label='Department'
              >
                {([...EXECUTIVES, ...ACCOUNTING].includes(isAuth.department)
                  ? ['All', ...EXECUTIVES, ...ACCOUNTING, ...MANAGERS]
                  : [isAuth.department]
                ).map((department: string) => {
                  return (
                    <MenuItem key={department} value={department}>
                      {department}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <TabPanel value={poStatusTab} index={0}>
            <PurchaseOrdersTable
              orders={filterArray(purchaseOrderApiData, 'pending')}
              type='pending'
            />
          </TabPanel>
          <TabPanel value={poStatusTab} index={1}>
            <PurchaseOrdersTable
              orders={filterArray(purchaseOrderApiData, 'approved')}
              type='approved'
            />
          </TabPanel>
          <TabPanel value={poStatusTab} index={2}>
            <PurchaseOrdersTable
              orders={filterArray(purchaseOrderApiData, 'rejected')}
              type='rejected'
            />
          </TabPanel>

          {/* purchase order table. */}
          {/* <PurchaseOrdersTable orders={purchaseOrder} /> */}
        </Paper>
      </Box>

      {/* {open && (
        <DeletePoModal
          open={open}
          handleClose={handleClose}
          type={'delete'}
          selectedPurchaseorder={bulkSelected}
        />
      )} */}
      <RequestUpgradeDialog open={openUpgradeDialog} handleClose={() => setOpenUpgradeDialog(false)} setCategory={setCategory} />
      <HelpMenuDialog category={category} handleClose={() => setCategory('')} />
    </>
  );
};

export default PurchaseOrder;
