import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { YES_OR_NO } from 'interfaces/enums';
import moment from 'moment';
import { mdy2dmy, snackBarStyle } from './common.utils';
import {
  EntertainmentClaim,
  ExpenseForm,
  GeneralExpenseItem,
  ParkingExpenseItem,
  PartyItems,
  PurchaseItem,
  PurchaseReqForm,
  TaxiExpenseItem,
} from 'interfaces/myforms.interface';
import {
  ACCEPTANCE_OF_CONDITIONS_LAYOUT_1,
  ACCEPTANCE_OF_CONDITIONS_LAYOUT_2,
} from 'components/forms/form-layouts/customer-trade-application-layouts/acceptance-of-conditions.layout';
import {
  APPLICANT_DETAILS_LAYOUT,
  APPLICANT_EMAIL_LAYOUT,
} from 'components/forms/form-layouts/customer-trade-application-layouts/applicant-details.layout';
import {
  BUSINESS_DETAILS_ABN_CERTIFICATE,
  BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT,
  BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT,
  BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL,
  BUSINESS_DETAILS_MISC_LAYOUT2_CONDITIONAL,
  BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT,
  BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1,
  BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2,
} from 'components/forms/form-layouts/customer-trade-application-layouts/business-details.layout';
import {
  ACKNOWLEDGEMENT_AND_SIGNATURES_1,
  ACKNOWLEDGEMENT_AND_SIGNATURES_2,
  CREDIT_CARD_DETAILS_LAYOUT,
  CREDIT_CARD_LAYOUT,
  DIRECT_DEBIT_1,
  DIRECT_DEBIT_2,
  DIRECT_DEBIT_CHOSEN_LAYOUT,
  DIRECT_DEBIT_SERVICE_AGREEMENT,
  EFT_ADVANCE_PAYMENT_LAYOUT,
  EFT_ON_DELIVERY_DATE_LAYOUT,
  PAYMENT_METHOD_LAYOUT,
} from 'components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout';
import { REVIEW_BEFORE_SUBMISSION_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/review-before-submission.layout';
import {
  DRIVERS_LICENSE,
  GUARANTOR_LAYOUT_1,
  GUARANTOR_LAYOUT_2,
  TERMS_AND_CONDITIONS_CONFIRMATION,
  WITNESS_LAYOUT_1,
  WITNESS_LAYOUT_2,
} from 'components/forms/form-layouts/customer-trade-application-layouts/terms-and-conditions.layout';
import { CUSTOMER_DOCUMENT_TYPES, PaymentMethod } from 'enums/customer-trade-application-form.enums';
import { PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT } from 'components/forms/form-layouts/payment-information-layouts/payment-information-applicant-details.layout';
import {
  PAYMENT_INFORMATION_DRIVERS_LICENSE,
  PAYMENT_INFORMATION_GUARANTOR_LAYOUT_1,
  PAYMENT_INFORMATION_TERMS_AND_CONDITIONS_CONFIRMATION,
  PAYMENT_INFORMATION_WITNESS_LAYOUT_1,
} from 'components/forms/form-layouts/payment-information-layouts/payment-information-guarantor-details.layout';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';
import { OrganisationCountry } from 'enums/organisation.enums';

const formatValue = (value: any, type?: FORM_CONTROL_TYPE) => {
  if (typeof value === 'boolean') {
    return value ? YES_OR_NO.YES : YES_OR_NO.NO;
  }
  switch (type) {
    case FORM_CONTROL_TYPE.CURRENCY:
      return value ? `$${value}` : 'N/A';
    case FORM_CONTROL_TYPE.DATE:
      return value ? moment(value).format('DD/MM/YYYY') : 'N/A';
    default:
      return value ?? 'N/A';
  }
};

export const createRows = (
  claimItemData: any,
  layouts: Array<FormControlItemLayout>,
  excludeFieldNames?: string[],
  additionalData: any = []
) => {
  const filteredLayouts = [...layouts.filter((layout) => !excludeFieldNames?.includes(layout.name))];
  const finalLayouts = [...filteredLayouts, ...additionalData];
  const data: any = [];
  for (let i = 0; i < finalLayouts.length; i++) {
    if (i % 2 === 0) {
      if (i + 1 >= finalLayouts.length) {
        data.push({
          [finalLayouts[i].label as string]:
            finalLayouts[i].type === 'Additional'
              ? finalLayouts[i].value
              : formatValue(claimItemData[finalLayouts[i].name], finalLayouts[i].type as FORM_CONTROL_TYPE),
          name: null,
        });
      } else {
        data.push({
          [finalLayouts[i].label as string]:
            finalLayouts[i].type === 'Additional'
              ? finalLayouts[i].value
              : formatValue(claimItemData[finalLayouts[i].name], finalLayouts[i].type as FORM_CONTROL_TYPE),
          [finalLayouts[i + 1].label as string]:
            finalLayouts[i + 1].type === 'Additional'
              ? finalLayouts[i + 1].value
              : formatValue(claimItemData[finalLayouts[i + 1].name], finalLayouts[i + 1].type as FORM_CONTROL_TYPE),
        });
      }
    }
  }
  return data;
};

export const formDataValidator: any = (
  type: 'Claim' | 'PurchaseRequest',
  itemsLength: number,
  data: ExpenseForm | PurchaseReqForm
  ) => {
  if (!(itemsLength > 0)) {
    return snackBarStyle({
      text: 'Please add an item/s.',
      variant: 'warning',
    });
  }
  if (!Boolean(data.attachments?.length)) {
    return snackBarStyle({
      text: type === 'PurchaseRequest' ? 'Please upload your invoice file.' : 'Please upload your quotation file.',
      variant: 'warning',
    });
  }
  if (type === 'PurchaseRequest') {
    if (!Boolean((data as PurchaseReqForm).description)) {
      return snackBarStyle({
        text: 'Please note what is your reason for this.',
        variant: 'warning',
      });
    }
  }

  return 'Valid';
};

export const tableCellValue = (layout: FormControlItemLayout, row: any) => {
  if (layout.type === FORM_CONTROL_TYPE.SELECT) {
    return `${row?.[layout.name]?.label ?? row?.[layout.name]}`;
  } else if (layout.type === FORM_CONTROL_TYPE.CURRENCY) {
    return `$${row?.[layout.name]}`;
  } else if (layout.type === FORM_CONTROL_TYPE.DATE) {
    return `${mdy2dmy(row?.[layout.name])}`;
    // } else if (layout.type === FORM_CONTROL_TYPE.TIME) {
    //   return `${getTime(row?.[layout.name])}`;
  } else {
    return row?.[layout.name] ?? '';
  }
};

export const generalClaimItemValidator = (checkingData: GeneralExpenseItem) => {
  if (!checkingData) return false;
  if (
    Boolean(checkingData.itemDate) &&
    Boolean(checkingData.amountInclGst) &&
    Boolean(checkingData.branch) &&
    Boolean(checkingData.gst) &&
    Boolean(checkingData.category) &&
    Boolean(checkingData.invoiceNumber) &&
    Boolean(checkingData.notes) &&
    Boolean(checkingData.subCategory)
  ) {
    return true;
  }
  return false;
};

export const purchaseItemValidator = (checkingData: PurchaseItem) => {
  if (!checkingData) return false;
  if (
    Boolean(checkingData.description) &&
    Boolean(checkingData.inclGST) &&
    Boolean(checkingData.item) &&
    Boolean(Number(checkingData.quantity) > 0)
  ) {
    return true;
  }
  return false;
};

export const taxiClaimItemValidator = (checkingData: TaxiExpenseItem) => {
  if (!checkingData) return false;
  const date = moment(checkingData.itemDate as string);
  if (
    date.isValid() &&
    Boolean(checkingData.itemDate) &&
    Boolean(checkingData.amountInclGst) &&
    Boolean(checkingData.from) &&
    Boolean(checkingData.to)
  ) {
    return true;
  }
  return false;
};

export const parkingClaimItemValidator = (checkingData: ParkingExpenseItem) => {
  const militaryTimeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!checkingData) return false;
  const date = moment(checkingData.itemDate as string);
  if (
    date.isValid() &&
    Boolean(checkingData.itemDate) &&
    Boolean(checkingData.amountInclGst) &&
    Boolean(checkingData.entryTime) &&
    Boolean(checkingData.exitTime) &&
    militaryTimeRegex.test((checkingData.entryTime as string).substring(0, 5)) &&
    militaryTimeRegex.test((checkingData.exitTime as string).substring(0, 5)) &&
    Boolean(checkingData.exitTime)
  ) {
    return true;
  }
  return false;
};

export const entertainmentClaimItemValidator = (checkingData: EntertainmentClaim) => {
  if (!checkingData) return false;
  if (
    Boolean(checkingData.itemDate) &&
    Boolean(checkingData.branch) &&
    Boolean(checkingData.customerName) &&
    Boolean(checkingData.place) &&
    Boolean(checkingData.amountInclGst) &&
    Boolean(checkingData.gst) &&
    Boolean(
      checkingData.lastSaleOver30d?.value === YES_OR_NO.YES ? Boolean(checkingData.lastSaleOver30dReason) : true
    ) &&
    Boolean(
      checkingData.isLastPaymentOverdue?.value === YES_OR_NO.YES ? Boolean(checkingData.accountOverdueReason) : true
    )
  ) {
    return true;
  }
  return false;
};

export const getTime = (value: string): string => {
  const time = moment(new Date(value)).format('hh:mm A');
  return `${time}`;
};

export interface FormStep {
  label: string;
  route: string;
  layout?: Array<FormControlItemLayout>;
}

export const DIRECT_DEBIT_LAYOUTS = [
  ...DIRECT_DEBIT_1,
  ...DIRECT_DEBIT_2,
  ...ACKNOWLEDGEMENT_AND_SIGNATURES_1,
  ...ACKNOWLEDGEMENT_AND_SIGNATURES_2,
  DIRECT_DEBIT_SERVICE_AGREEMENT,
];

const TERMS_AND_CONDITIONS_OF_SALE_LAYOUT = [
  TERMS_AND_CONDITIONS_CONFIRMATION,
  ...GUARANTOR_LAYOUT_1,
  ...WITNESS_LAYOUT_1,
  DRIVERS_LICENSE,
  ...GUARANTOR_LAYOUT_2,
  ...WITNESS_LAYOUT_2,
];

const GUARANTOR_DETAILS_LAYOUT = [
  PAYMENT_INFORMATION_TERMS_AND_CONDITIONS_CONFIRMATION,
  ...PAYMENT_INFORMATION_GUARANTOR_LAYOUT_1,
  ...PAYMENT_INFORMATION_WITNESS_LAYOUT_1,
  PAYMENT_INFORMATION_DRIVERS_LICENSE,
  ...GUARANTOR_LAYOUT_2,
  ...WITNESS_LAYOUT_2,
];

export const filterPaymentLayout = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethod.DirectDebit:
      return [...DIRECT_DEBIT_LAYOUTS];
    case PaymentMethod.AFTAdvancePayment:
      return [...EFT_ADVANCE_PAYMENT_LAYOUT];
    case PaymentMethod.EFTOnTheDeliveryDate:
      return [...EFT_ON_DELIVERY_DATE_LAYOUT];
    case PaymentMethod.CreditCard:
      return [...CREDIT_CARD_DETAILS_LAYOUT(), ...CREDIT_CARD_LAYOUT()];
    default:
      return [];
  }
};

export const getSteps = (
  documentType: CUSTOMER_DOCUMENT_TYPES,
  stepsValidation: boolean,
  rawData?: any
): FormStep[] => {
  switch (documentType) {
    case CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM:
      return [
        {
          label: 'Applicant Details',
          route: 'applicant_details',
          layout: [...APPLICANT_DETAILS_LAYOUT, APPLICANT_EMAIL_LAYOUT],
        },
        {
          label: 'Business Details',
          route: 'business_details',
          layout: [
            ...BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT,
            ...(rawData?.BUSINESS_MATCH_DELIVERY_ADDRESS === 'No' ? BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT : []),
            ...(rawData?.POSTAL_MATCH_DELIVERY_ADDRESS === 'No' ? BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT : []),
            ...BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL(rawData?.TYPE ?? ''),
            ...BUSINESS_DETAILS_MISC_LAYOUT2_CONDITIONAL(
              rawData?.IS_EXISTENT_CUSTOMER ?? '',
              rawData?.KNOW_OWNER_OR_STAFF ?? ''
            ),
            ...BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1,
            ...BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2,
            BUSINESS_DETAILS_ABN_CERTIFICATE,
          ],
        },
        {
          label: 'Payment Information',
          route: 'payment_information',
          layout: stepsValidation
            ? [...filterPaymentLayout(rawData?.PAYMENT_METHOD ?? '')]
            : [PAYMENT_METHOD_LAYOUT, ...filterPaymentLayout(rawData?.PAYMENT_METHOD ?? '')],
        },
        {
          label: 'Acceptance of Conditions',
          route: 'acceptance_of_conditions',
          layout: [
            ...ACCEPTANCE_OF_CONDITIONS_LAYOUT_1(rawData?.RESIDENCE_STATUS_AOC_1),
            ...ACCEPTANCE_OF_CONDITIONS_LAYOUT_2,
          ],
        },
        {
          label: 'Terms and Conditions of Sale',
          route: 'terms_and_conditions_of_sale',
          layout: [...TERMS_AND_CONDITIONS_OF_SALE_LAYOUT],
        },
        {
          label: 'Review Before Submission',
          route: 'review_before_submission',
          layout: [...REVIEW_BEFORE_SUBMISSION_LAYOUT],
        },
      ];
    case CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM:
      return [
        {
          label: 'Applicant Details',
          route: 'applicant_details',
          layout: [...PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT, APPLICANT_EMAIL_LAYOUT],
        },
        {
          label: 'Direct Debit',
          route: 'direct_debit',
          layout: [DIRECT_DEBIT_CHOSEN_LAYOUT, ...(rawData?.DIRECT_DEBIT_CHOSEN === 'Yes' ? DIRECT_DEBIT_LAYOUTS : [])],
        },
        {
          label: 'Credit Card',
          route: 'credit_card',
          layout: [
            ...CREDIT_CARD_DETAILS_LAYOUT(rawData?.DIRECT_DEBIT_CHOSEN),
            ...CREDIT_CARD_LAYOUT(rawData?.DIRECT_DEBIT_CHOSEN),
          ],
        },
        {
          label: 'Guarantor Details',
          route: 'guarantor_details',
          layout: [...GUARANTOR_DETAILS_LAYOUT],
        },
        {
          label: 'Review Before Submission',
          route: 'review_before_submission',
          layout: [...REVIEW_BEFORE_SUBMISSION_LAYOUT],
        },
      ];
  }
};

export const setFocusName = (itemName: string) => {
  console.log('FOCUS NAME', itemName);
  switch (itemName) {
    case 'CARD_NUMBER':
      return 'number';
    case 'EXPIRY_DATE_':
      return 'expiry';
    case 'CSV__SECURITY_CODE_':
      return 'cvc';
    case 'CARD_HOLDER_NAME_':
      return 'name';
    default:
      return itemName;
  }
};

export const validateCreditCardExpiry = (expiryDate: string) => {
  const regex = /^(0[1-9]|1[0-2])\/(2[3-9]|3[0-9]|4[0])$/;

  if (!regex.test(expiryDate)) {
    return false; // Invalid format
  }

  const [mm, yy] = expiryDate.split('/');

  const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year

  if (parseInt(yy) < currentYear || (parseInt(yy) === currentYear && parseInt(mm) < 1)) {
    return false; // Expiry date has already passed
  }

  return true;
};

export const getGstPercentByCountry = (country: OrganisationCountry) => {
  switch (country) {
    case OrganisationCountry.Australia:
      return 0.1;
    case OrganisationCountry.New_Zealand:
      return 0.15;
    default:
      return 0;
  }
};

//PDF Preview: Group attachments by 3 per page
export function groupByThree(arr: string[]): string[][] {
  const result: string[][] = [];

  for (let i = 0; i < arr.length; i += 3) {
    if (i + 2 < arr.length) {
      result.push([arr[i], arr[i + 1], arr[i + 2]]);
    } else if (i + 1 < arr.length) {
      result.push([arr[i], arr[i + 1]]);
    } else {
      result.push([arr[i]]);
    }
  }

  return result;
}

