import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { DecisionTypeEnum, DOC_STATUS } from 'enums/invoice.enums';
import { InvoiceSummary } from 'interfaces/invoice.interface';
import { useInvoiceStore } from 'state/invoice.store';
import { useSearchParams } from "react-router-dom";

interface ActionButtonsProps {
  row: InvoiceSummary;
}

const TableCellActions: React.FC<ActionButtonsProps> = ({ row }) => {
  const { updateInvoice, bulkSelected } = useInvoiceStore();

  const [searchParams] = useSearchParams();


  const docStatus = searchParams.get("status")

  const isWaiting = docStatus === DOC_STATUS.WAITING_APPROVAL;

  return isWaiting ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Button
        variant='contained'
        size='small'
        color='success'
        sx={{
          boxShadow: 6,
          borderRadius: 5,
          textTransform: 'capitalize',
          color: 'white',
          fontSize: "0.7rem"
        }}
        disabled={bulkSelected.length > 0}
        onClick={() =>
          updateInvoice({
            singleSelected: row.docuwareId,
            decisionType: DecisionTypeEnum.APPROVED,
          })
        }
      >
        Approve
      </Button>
      <Button
        variant='contained'
        size='small'
        color='error'
        sx={{ boxShadow: 6, borderRadius: 5, textTransform: 'capitalize', fontSize: "0.7rem" }}
        disabled={bulkSelected.length > 0}
        onClick={() =>
          updateInvoice({
            singleSelected: row.docuwareId,
            decisionType: DecisionTypeEnum.REJECTED,
          })
        }
      >
        Reject
      </Button>
      <Button
        variant='contained'
        size='small'
        color='info'
        sx={{ boxShadow: 6, borderRadius: 5, textTransform: 'capitalize', fontSize: "0.7rem" }}
        disabled={bulkSelected.length > 0}
        onClick={() =>
          updateInvoice({
            singleSelected: row.docuwareId,
            decisionType: DecisionTypeEnum.ON_HOLD,
          })
        }
      >
        On Hold
      </Button>

      <IconButton
        size='small'
        sx={{
          boxShadow: 6,
          backgroundColor: '#64B6F7',
          '&:hover': {
            backgroundColor: '#1885db',
          },
        }}
        disabled={
          bulkSelected.length > 0 || row.workflowStatus === 'INFO_REQUESTED'
        }
        onClick={() =>
          updateInvoice({
            singleSelected: row.docuwareId,
            decisionType: DecisionTypeEnum.REQUESTED_INFO,
          })
        }
      >
        <InfoIcon sx={{ color: 'white' }} fontSize='small' />
      </IconButton>
    </Box>
  ) : null;
};

export default TableCellActions;
