import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { FormControlItemLayout } from "../form-components/FormControlItem";
import { GENERAL_EXPENSE_CATEGORY } from "../employee-forms/general-expense-form/SelectList";


export const GENERAL_EXPENSE_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'itemDate',
        label: 'Date',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'branch',
        label: 'Branch',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        // selectList: GENERAL_EXPENSE_BRANCH,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'category',
        label: 'Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: GENERAL_EXPENSE_CATEGORY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'subCategory',
        label: 'Sub Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'invoiceNumber',
        label: 'Invoice Number',
        required: true,
        fullWidth: true,
        variant: 'standard',
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'notes',
        label: 'Descriptions',
        required: true,
        variant: 'standard',
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'amountInclGst',
        label: '$ Amt Incl GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
    {
        name: 'gst',
        label: '$GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
    },
];
