import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { vendorSummary } from "interfaces/vendor.interface";
import moment from "moment";
import { useInvoiceStore } from "state/invoice.store";
import { createVendorSummary } from "utils/vendor.utils";

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

interface Column {
  key: keyof vendorSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
  minWidth?: number;
  width?: number;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  {
    key: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
    align: "center",
  },
  {
    key: "vendorName",
    numeric: false,
    disablePadding: true,
    label: "Vendor Name",
    align: "center",
  },
  {
    key: "vendorId",
    numeric: false,
    disablePadding: true,
    label: "Vendor Id",
    align: "center",
  },
  {
    key: "currency",
    numeric: false,
    disablePadding: true,
    label: "Currency",
    align: "center",
  },

  {
    key: "taxClass",
    numeric: true,
    disablePadding: true,
    label: "Tax Class",
    align: "center",
  },
  {
    key: "phone",
    numeric: true,
    disablePadding: true,
    label: "Phone",
    align: "center",
  },
  {
    key: "email",
    numeric: true,
    disablePadding: true,
    label: "Email",
    align: "center",
  },
  {
    key: "address",
    numeric: true,
    disablePadding: true,
    label: "Address",
    align: "center",
  },
  {
    key: "city",
    numeric: true,
    disablePadding: true,
    label: "City",
    align: "center",
  },
  {
    key: "postCode",
    numeric: true,
    disablePadding: true,
    label: "Post Code",
    align: "center",
  },
  {
    key: "country",
    numeric: true,
    disablePadding: true,
    label: "Country",
    align: "center",
  },
  {
    key: "status",
    numeric: true,
    disablePadding: true,
    label: "Status",
    align: "center",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof vendorSummary
  ) => void;
  order: Order;
  orderBy: string;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    // onSelectAllClick,
    // numSelected,
    // rowCount,
  } = props;
  const createSortHandler =
    (property: keyof vendorSummary) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align="center"
            padding={"normal"}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
            >
              {/* Below code is done due to request to wrap in two lines all two-word head titles on all screen sizes */}
              {headCell.label.split(" ").length === 2 ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                    {headCell.label.split(" ")[0]}
                  </Typography>
                  <Typography variant="inherit" sx={{ fontWeight: 600 }}>
                    {" "}
                    {headCell.label.split(" ")[1]}
                  </Typography>
                </Box>
              ) : (
                headCell.label
              )}

              {orderBy === headCell.key ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface VendorTableProps {
  orders: vendorSummary[];
}

// props { orders }
const VendorTable: React.FC<VendorTableProps> = ({ orders }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof vendorSummary>("dateCreated");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState<any>([]);
  //   const [poNumber, setPoNumber] = useState<string | null>(null);
  // select Box
  //   const [select, setSelect] = useState("");

  //   const onSelectHandleChange = (event: SelectChangeEvent) => {
  //     setSelect(event.target.value as string);
  //   };
  // select Box

  const { bulkSelected, updateInvoice } = useInvoiceStore();

  //   const navigate = useNavigate();

  console.log("bulkSelectedbulkSelected", bulkSelected);
  useEffect(() => {
    const data = orders.map((order: vendorSummary) => {
      return { ...createVendorSummary(order) };
    });
    console.log("data", data);
    setRows(data);
  }, [orders]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof vendorSummary
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row: vendorSummary) => row.vendorId);
      updateInvoice({ bulkSelected: newSelecteds });
      return;
    }
    updateInvoice({ bulkSelected: [] });
  };

  const isSelected = (name: string) => bulkSelected.indexOf(name) !== -1;

  const handleSelectRow: any = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = bulkSelected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(bulkSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(bulkSelected.slice(1));
    } else if (selectedIndex === bulkSelected.length - 1) {
      newSelected = newSelected.concat(bulkSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        bulkSelected.slice(0, selectedIndex),
        bulkSelected.slice(selectedIndex + 1)
      );
    }
    updateInvoice({ bulkSelected: newSelected });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //   console.log("poNumberpoNumber", poNumber);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={bulkSelected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort(getComparator(order, orderBy))
              .map((row: vendorSummary, index: number) => {
                // console.log("Docuware id:::>>?::", row.docuwareId);
                const isItemSelected = isSelected(row.vendorId);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.vendorId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleSelectRow(event, row.vendorId)
                        }
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.key];

                      if (column.key === "dateCreated") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            {moment(value).format("DD/MM/YYYY")}
                          </TableCell>
                        );
                      }

                      if (column.key === "vendorName") {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            <>{value}</>
                          </TableCell>
                        );
                      }

                      if (column.key === "vendorId") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "currency") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "taxClass") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "phone") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      if (column.key === "email") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "100px",
                                textAlign: "center",
                                fontSize: "0.875rem",
                                fontWeight: 400,
                              }}
                            >
                              {value}
                            </Typography>
                          </TableCell>
                        );
                      }
                      if (column.key === "address") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "100px",
                                textAlign: "center",
                                fontSize: "0.875rem",
                                fontWeight: 400,
                              }}
                            >
                              {value}
                            </Typography>
                          </TableCell>
                        );
                      }
                      if (column.key === "city") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }
                      if (column.key === "postCode") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }
                      if (column.key === "country") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }
                      if (column.key === "status") {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          sx={{ pr: 4, width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default VendorTable;
