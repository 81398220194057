import { FC } from 'react'

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import { GeneralExpenseItem } from 'interfaces/myforms.interface'
import FormControlItem, { FormControlItemLayout } from '../../form-components/FormControlItem'
import { tableCellValue } from 'utils/form.utils'
import { GENERAL_EXPENSE_LAYOUT } from "../../form-layouts/general-expense.layout"

interface GeneralExpenseFormDesktopProps {
  items: GeneralExpenseItem[]
  onChange: (v: any) => void
  submitValidating: boolean,
  itemsValidating: boolean,
  formData?: GeneralExpenseItem,
  handleChangeFormData: (value: any) => void,
  handleAddItem: () => void,
  formLayouts?: FormControlItemLayout[]
}

const GeneralExpenseFormDesktop: FC<GeneralExpenseFormDesktopProps> = ({
  items,
  onChange,
  submitValidating,
  itemsValidating,
  formData,
  handleChangeFormData,
  handleAddItem,
  formLayouts
}) => {

  const layouts = GENERAL_EXPENSE_LAYOUT

  const handleDelete = (rowIndex: number) => {
    const newData = [...items.slice(0, rowIndex), ...items.slice(rowIndex + 1)]
    onChange(newData)
  }

  return (
    <Paper elevation={5} sx={{ p: 2, display: { xs: 'none', lg: 'block' } }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell width={25} />
              {layouts.map((layout, layoutIndex) => (
                <TableCell key={`tableHead${layoutIndex}`} width={500}>
                  {`${layout.label} *` ?? ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, rowIndex) => (
              <TableRow key={`${item.id}${rowIndex}`}>
                {layouts.map((layout, layoutIndex) => (
                  <TableCell key={`tableBody${layoutIndex}`} width={500}>
                    {tableCellValue(layout, item)}
                  </TableCell>
                ))}
                                <TableCell sx={{ width: '25px' }}>
                  <Button
                    sx={{ width: '25px', minWidth: '25px' }}
                    onClick={() => handleDelete(rowIndex)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {
              <TableRow>
                <TableCell width={25} />
                {formLayouts?.map((layout, layoutIndex) => (
                  <TableCell key={`addItem${layoutIndex}`} width={500}>
                    <FormControlItem
                      data={formData}
                      onChange={handleChangeFormData}
                      layout={layout}
                      submitValidating={submitValidating}
                      itemsValidating={itemsValidating}
                    />
                  </TableCell>
                ))}
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <Button
        variant='contained'
        sx={{ textTransform: 'none' }}
        onClick={handleAddItem}
      >
        Save
      </Button>
    </Paper>
  )
}

export default GeneralExpenseFormDesktop