import React from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import { ZoomTransition } from 'components/common/Transitions';
import { DialogProps } from 'interfaces/common.interfaces';

const SuccessResetPassword: React.FC<DialogProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleProceedLogin = () => {
    handleClose();
    navigate('/auth/signin');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={ZoomTransition}
      maxWidth={false}
    >
      <DialogTitle
        sx={{ fontWeight: 700, display: 'flex', justifyContent: 'center' }}
      >
        Reset Password.
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <HowToRegIcon color="success" fontSize="large" />
          <Typography sx={{ color: 'green' }}>
            password reset successFully.
          </Typography>
        </Box>
      </DialogContent>
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}
      >
        <Button
          variant="contained"
          onClick={handleProceedLogin}
          sx={{
            height: 50,
            borderRadius: 10,
            width: '85%',
          }}
        >
          Proceed to login
        </Button>
      </Box>
    </Dialog>
  );
};

export default SuccessResetPassword;
