import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { printPoSummary } from "interfaces/createpo.interface";
import React from "react";

function createData(
  itemCode: string,
  description: string,
  qty: string,
  uom: string,
  unitprice: string,
  subtotal: string,
  tax: string
) {
  return { itemCode, description, qty, uom, unitprice, subtotal, tax };
}

const rows = [
  createData(
    "A1-1030-40",
    "Apple inc, Microsoft",
    "10",
    "Ea",
    "$19.88",
    "$198.80",
    "0"
  ),
];

export interface PrintPoTableProps {
  printPo: printPoSummary[];
}

// props { orders }
const PrintPoTable: React.FC<PrintPoTableProps> = ({ printPo }) => {
  return (
    <Box sx={{ width: "100%", overflow: "hidden", px: "40px" }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">UOM</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Subtotal Amount (Excl GST)</TableCell>
              <TableCell align="right">Tax Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.itemCode}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.itemCode}
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">{row.qty}</TableCell>
                <TableCell align="right">{row.uom}</TableCell>
                <TableCell align="right">{row.unitprice}</TableCell>
                <TableCell align="right">{row.subtotal}</TableCell>
                <TableCell align="right">{row.tax}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ marginTop: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            justifyItems: "right",
          }}
        >
          <Grid container justifyContent="flex-end">
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                Sub Total
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                Discount
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                GST
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "right" }}>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                $198.80
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                00.0
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                19.80
              </Typography>
              <Typography sx={{ width: "full", fontWeight: "bold" }}>
                $218.89
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PrintPoTable;
