import { CUSTOMER_DOCUMENT_TYPES } from 'enums/customer-trade-application-form.enums';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface FormStatus {
  id: string;
  status: 'complete' | 'partial';
  type: CUSTOMER_DOCUMENT_TYPES;
}

interface Customer {
  customerApplicationForms: Array<FormStatus | null>;
  paymentInformationForms: Array<FormStatus | null>;
  activeForm: FormStatus | null;
  setCustomerApplicationForms: (value: Array<FormStatus | null>) => void;
  setPaymentInformationForms: (value: Array<FormStatus | null>) => void;
  setActiveForm: (value: FormStatus | null) => void;
}

export const useCustomerStore = create(
  persist<Customer>(
    (set, get) => ({
      customerApplicationForms: [null],
      paymentInformationForms: [null],
      activeForm: null,
      setCustomerApplicationForms: (payload) => set({ customerApplicationForms: payload }),
      setPaymentInformationForms: (payload) => set({ paymentInformationForms: payload }),
      setActiveForm: (payload) => set({ activeForm: payload }),
    }),
    {
      name: 'customer',
      getStorage: () => sessionStorage,
    }
  )
);
