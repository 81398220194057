import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

export interface FormTabsProps {
  selected?: number;
  onChange?: (v: string, vi: number) => void;
  options?: Array<string>;
}

const FormTabs: React.FC<FormTabsProps> = ({
  selected = 0,
  onChange = () => {},
  options = [],
}) => {
  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const matchIndex = options.findIndex((item) => item === value);

    onChange(value, matchIndex);
  };

  return (
    <Box sx={{ pb: 1 }}>
      <Tabs value={options[selected]} onChange={handleChange}>
        {options.map((option, optionIndex) => (
          <Tab
            key={optionIndex}
            sx={{ textTransform: 'none' }}
            label={option}
            value={option}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default FormTabs;
