import { FC } from 'react'

import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import { grey } from "@mui/material/colors";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockResetIcon from "@mui/icons-material/LockReset";

import { useUserStore } from "state/user.store";

interface MobileBottomProfileMenuProps {
    open: boolean;
    handleClose: () => void;
    setOpenChangePasswordForm: (value: boolean) => void;
}

const MobileBottomProfileMenu: FC<MobileBottomProfileMenuProps> = ({ open, handleClose, setOpenChangePasswordForm }) => {

    const { isAuth, logout } = useUserStore()


    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
        >
            <Box sx={{ display: "flex", gap: 1.5, p: 2, alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "#F6C470", width: 50, height: 50 }}>
                    {isAuth?.firstName.charAt(0).toUpperCase() +
                        isAuth?.lastName.charAt(0).toUpperCase()}
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 500 }}
                    >{`${isAuth?.firstName} ${isAuth?.lastName}`}</Typography>
                    <Typography variant="caption" sx={{ color: grey[800] }}>
                        {isAuth?.department}
                    </Typography>
                    <Typography variant="caption" sx={{ color: grey[600] }}>
                        {isAuth?.username}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ mb: 1 }} />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        setOpenChangePasswordForm(true)
                        handleClose()
                    }}>
                        <ListItemIcon>
                            <LockResetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Change Password" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        logout()
                        handleClose()
                    }}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log out" />
                    </ListItemButton>
                </ListItem>
            </List>
        </SwipeableDrawer>
    )
}

export default MobileBottomProfileMenu