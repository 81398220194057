import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import API from "api";
import update from "immutability-helper";
import * as React from "react";
import { FinalJson } from "state/purchaseorderLine.store";
import { useDebounce } from "use-debounce";
import { PurchaseOrderLineItemModal } from "./modal/purchaseOrderLineModal";

interface Data {
  action?: any;
  item_description: string;
  quantity: number;
  price: number;
  unit_measure: number;
  item_code: any;
  amount: number;
  tax_class: string;
  tax: number;
  discount_amount: number;
  total: number;
  _id: number;
}

function createItemData(lineItem: Data): Data {
  const {
    item_code,
    item_description,
    price,
    quantity,
    amount,
    unit_measure,
    tax_class,
    tax,
    discount_amount,
    total,
    _id,
  } = lineItem;
  return {
    item_code,
    item_description,
    price,
    quantity,
    amount,
    unit_measure,
    tax_class,
    tax,
    discount_amount,
    total,
    _id,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width?: string;
  align?: "left" | "right" | "center";
}

const headCells: readonly HeadCell[] = [
  {
    id: "action",
    align: "center",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
  {
    id: "item_code",
    align: "center",
    numeric: false,
    disablePadding: true,
    label: "Item Code",
  },
  {
    id: "item_description",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Item Description",
  },
  {
    id: "price",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Price $",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Quantity",
  },
  {
    id: "unit_measure",
    numeric: true,
    width: "15px",
    disablePadding: false,
    align: "center",
    label: "Unit Of Measure",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Amount",
  },
  {
    id: "tax_class",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Tax Class",
  },
  {
    id: "tax",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Tax $",
  },
  {
    id: "discount_amount",
    numeric: true,
    disablePadding: false,
    width: "15px",
    align: "center",
    label: "Discount Amount",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    align: "center",
    label: "Total",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
            {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface PurchaseOrderItemTableProps {
  rowsItem: any;
}

export default function PurchaseOrderItemTable({
  rowsItem,
}: PurchaseOrderItemTableProps) {
  // purchaseOrder Product Line Modal
  const [open, setOpen] = React.useState(false);
  const [itemCode, setItemCode] = React.useState<any>();
  const [itemIndex, setItemIndex] = React.useState<any>();
  const handleOpen = (itemIndex: any, itemcode: any) => {
    setItemIndex(itemIndex);
    // setItemCode(itemcode)
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<any>([]);
  const { purchaseOrderoneLine, setFinalJson } = FinalJson();
  const [onDebounceChange] = useDebounce(itemCode, 1000);

  function handleChange(evt: any, rowsItemId: any) {
    const _value = evt.target.value;
    const _jsonData = { ...purchaseOrderoneLine };
    const index = purchaseOrderoneLine.lineItem.findIndex(
      (e) => e._id === rowsItemId
    );
    const updateLineItemZustand: any = purchaseOrderoneLine.lineItem[index];
    updateLineItemZustand[evt.target.name] = _value;
    const updateObj = update(purchaseOrderoneLine.lineItem, {
      $splice: [[index, 1, updateLineItemZustand]],
    });
    _jsonData.lineItem = updateObj;
    setFinalJson(_jsonData);
  }
  const getData = () => {
    const data = rowsItem.map((invoice: any) => {
      return { ...createItemData(invoice) };
    });
    setRows(data);
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsItem]);

  React.useEffect(() => {
    searchProductLineLItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDebounceChange]);

  // fetch api
  const searchProductLineLItem = async () => {
    try {
      if (itemCode.length > 1) {
        const res = await API.get(`/products/byItemcode/${itemCode}`);
        console.log(res.data.data.length);
        if (res.data.data.length > 1 || !res.data.data.length) {
          console.log("remove old value");
          const selectedProductLine = {
            item_description: "",
            price: "",
            tax_class: "",
          };
          // update product Lines
          const _jsonData = { ...purchaseOrderoneLine };
          const index = purchaseOrderoneLine.lineItem.findIndex(
            (e) => e.item_code === itemCode
          );
          const updateLineItemZustand: any =
            purchaseOrderoneLine.lineItem[index];
          const updateObjectData = {
            ...updateLineItemZustand,
            ...selectedProductLine,
          };
          const updateObj = update(purchaseOrderoneLine.lineItem, {
            $splice: [[index, 1, updateObjectData]],
          });
          _jsonData.lineItem = updateObj;
          setFinalJson(_jsonData);
        } else {
          console.log("update  value");
          const selectedProductLine = res.data.data[0];
          // update product Lines
          const _jsonData = { ...purchaseOrderoneLine };
          const index = purchaseOrderoneLine.lineItem.findIndex(
            (e) => e.item_code === itemCode
          );
          const updateLineItemZustand: any =
            purchaseOrderoneLine.lineItem[index];
          const updateObjectData = {
            ...updateLineItemZustand,
            ...selectedProductLine,
          };
          const updateObj = update(purchaseOrderoneLine.lineItem, {
            $splice: [[index, 1, updateObjectData]],
          });
          _jsonData.lineItem = updateObj;
          setFinalJson(_jsonData);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.item_code);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleRemoveClick = (index: any) => {
    let indexofValue = purchaseOrderoneLine.lineItem.findIndex(
      (e: any) => e._id === index
    );
    if (indexofValue > -1) {
      purchaseOrderoneLine.lineItem.splice(indexofValue, 1);
    }
    setFinalJson(purchaseOrderoneLine);
    getData();
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                            rows.slice().sort(getComparator(order, orderBy)) */}
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => {
                    const isItemSelected = isSelected(row.item_code);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.item_code)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                          sx={{
                            "& td": { border: 0 },
                            "& & th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <DeleteIcon
                                onClick={() => handleRemoveClick(row._id)}
                                sx={{ color: "#BDBDBD", cursor: "pointer" }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => {
                                setItemCode(e.target.value);
                                handleChange(e, row._id);
                              }}
                              name="item_code"
                              value={row.item_code}
                              id="standard-basic"
                              variant="standard"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchIcon
                                      onClick={() =>
                                        handleOpen(row._id, row.item_code)
                                      }
                                      sx={{ cursor: "pointer" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="item_description"
                              value={row.item_description}
                              id="standard-basic"
                              variant="standard"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="price"
                              value={row.price}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.price} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="quantity"
                              value={row.quantity}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.quantity} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="amount"
                              value={row.amount}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.unit_measure} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="unit_measure"
                              value={row.unit_measure}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.amount} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="tax_class"
                              value={row.tax_class}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.tax_class} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="tax"
                              value={row.tax}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.tax} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="discount_amount"
                              value={row.discount_amount}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.discount_amount} */}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              onChange={(e: any) => handleChange(e, row._id)}
                              name="total"
                              value={row.total}
                              id="standard-basic"
                              variant="standard"
                            />
                            {/* {row.total} */}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {open && (
        <PurchaseOrderLineItemModal
          handleClose={handleClose}
          open={open}
          itemIndex={itemIndex}
          itemCode={itemCode}
        />
      )}
    </>
  );
}
