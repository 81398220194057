import { useEffect, useState, FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import jwt_decode from 'jwt-decode';

import Box from "@mui/material/Box";

import ClaimAttachments from "../ClaimAttachments";
import ClaimItems from "../ClaimItems";
import { createRows, groupByThree } from "utils/form.utils";
import { PURCHASE_REQUEST_LAYOUT } from "components/forms/form-layouts/purchase-request.layout";
import { ClaimFormResponse, Page, PageTable, PurchaseRequestItemsResponse } from "interfaces/form-preview.interface";
import PreviewLoader from "../PreviewLoader";
import { useUserStore } from "state/user.store";
import { IJwtPayload } from "interfaces/user.interface";

interface PurchaseRequestPreviewProps {
    formId?: number;
    paperView?: boolean;
}

const PurchaseRequestPreview: FC<PurchaseRequestPreviewProps> = ({ formId, paperView }) => {

    const params = useParams();
    const [searchParams] = useSearchParams();
    const [formData, setFormData] = useState<ClaimFormResponse | null>(null);
    const [pages, setPages] = useState<Page[]>([])
    const [groupedAttachments, setGroupedAttachments] = useState<string[][]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const isAuth = useUserStore(state => state.isAuth)

    const country = isAuth ? isAuth?.country : (jwt_decode(searchParams?.get("token") ?? '') as IJwtPayload).country

    const getFormData = async () => {
        setLoading(true)
        const result = await axios
            .create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: 30000,
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${searchParams.get("token") ?? localStorage.getItem('token')}`,
                },
            })
            .get(`/purchase-request-form/${params.documentNumber ?? formId}`);
        console.log(result)
        if (result.data) {
            setLoading(false)
            const array = []
            result.data.purchaseItems.map((claim: PurchaseRequestItemsResponse) => {
                const createdRows = createRows(
                    claim,
                    PURCHASE_REQUEST_LAYOUT,
                    ['inclGST'],
                    [{ label: 'Reasons', type: 'Additional', value: result.data.description }]
                )
                array.push({
                    type: "claim_table",
                    data: createdRows,
                    footerData: [{
                        '$ Amt Incl GST': `$${(Number(claim['inclGST'])).toFixed(2)}`
                    }]
                })
                return null
            })
            const total: number = result.data.purchaseItems.reduce((acc: any, cur: any) => {
                return Number(Number(acc) + Number(cur['inclGST']));
            }, 0)
            array.push({ type: "claim_total", data: { total: `$${total.toFixed(2)}` } })
            if (result.data.comment) array.push({ type: "comment", data: result.data.comment ?? '' })
            setFormData(result.data);
            setGroupedAttachments(groupByThree(result.data.attachments))
            setPages([{ pageNumber: 1, tables: [...array] }])
        }
    };

    useEffect(() => {
        getFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reorder = (overflowedPage: number) => {
        if (overflowedPage === pages.length) {
            // overflowed page is the last page
            const tempPages = [...pages, { pageNumber: pages.length + 1, tables: [] }]
            let lastTable: PageTable;
            let endCount: number;
            const newPages = tempPages.map(page => {
                if (page.pageNumber === overflowedPage) {
                    lastTable = (page.tables[page.tables.length - 1])
                    const newTables = page.tables.slice(0, -1)
                    endCount = newTables.filter((table: PageTable) => table.type !== 'claim_total').length
                    return { ...page, tables: [...newTables] }
                } else if (page.pageNumber === overflowedPage + 1) {
                    return { ...page, tables: [lastTable], startCount: endCount + 1 }
                } else {
                    return { ...page }
                }
            })
            setPages([...newPages])
        } else {
            // already created a new page but still overflowed
            let lastTable: PageTable;
            let endCount: number;
            const newPages = pages.map(page => {
                if (page.pageNumber === overflowedPage) {
                    lastTable = (page.tables[page.tables.length - 1])
                    const newTables = page.tables.slice(0, -1)
                    endCount = newTables.filter((table: PageTable) => table.type !== 'claim_total').length
                    return { ...page, tables: [...newTables] }
                } else if (page.pageNumber === overflowedPage + 1) {
                    return { ...page, tables: [lastTable, ...page.tables], startCount: endCount + 1 }
                } else {
                    return { ...page }
                }
            })
            setPages([...newPages])
        }
    }

    const totalPages = pages.length + groupedAttachments.length

    if (loading) {
        return <PreviewLoader />
    }
    return (
        <Box>
            {formData && pages.map((page: Page, index: number) => {
                return (
                    <ClaimItems
                        key={index}
                        formData={formData}
                        tables={page.tables}
                        page={page.pageNumber}
                        totalPages={totalPages}
                        reorder={reorder}
                        startCount={page.startCount ? page.startCount : 1}
                        formType="Internal Purchase Request Form"
                        paperView={paperView}
                        country={country}
                    />
                )
            })}


            {formData && groupedAttachments.map((atts: string[], index: number) => {
                return (
                    <ClaimAttachments
                        attachments={atts}
                        formData={formData}
                        page={pages.length + index + 1}
                        totalPages={totalPages}
                        formType="Internal Purchase Request Form"
                        paperView={paperView}
                        country={country}
                    />
                )
            })}
        </Box>
    )
}

export default PurchaseRequestPreview