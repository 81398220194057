import { useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useNavigate, useSearchParams, useOutletContext, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline';

import Header from 'components/header/Header';
import BasicSnackbar from 'components/common/BasicSnackbar';
import CustomerSidebar from "components/sidebar/CustomerSidebar";
import CustomerMobileDrawer from "components/sidebar/CustomerMobileDrawer";
import { useInvoiceStore } from 'state/invoice.store';
import { FormStatus, useCustomerStore } from "state/customer.store";
import DeleteFormDialog from "components/forms/customer-forms/DeleteFormDialog";
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";
import API from 'api'
import { CUSTOMER_DOCUMENT_TYPES } from "enums/customer-trade-application-form.enums";
import { useFormStore } from "state/form.store";
import FormSavedDialog from "components/forms/customer-forms/FormSavedDialog";
import FormSubmittedDialog from "components/forms/customer-forms/FormSubmittedDialog";
import { FormStep, getSteps } from "utils/form.utils";


type ContextType = {
    processing: boolean;
    steps: FormStep[];
    handleInitialSave: () => void;
    handleSave: () => void;
    handleSubmit: () => void;
    innerDivRef: any;
    formNumber: number | undefined;
    activeStep: number;
    setActiveStep: (value: number) => void;
};

const Customer = () => {

    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const location = useLocation()
    const innerDivRef = useRef<any>(null);

    const [deleteForm, setDeleteForm] = useState<FormStatus | null>(null)
    const [formNumber, setFormNumber] = useState<number | undefined>(undefined)
    const [processing, setProcessing] = useState<boolean>(false)
    const [activeStep, setActiveStep] = useState<number>(0)
    const [openFormSavedModal, setOpenFormSavedModal] = useState<boolean>(false)
    const [openFormSubmittedModal, setOpenFormSubmittedModal] = useState<boolean>(false)

    const { updateInvoice, showSnackbar, snackbarSeverity, snackbarMessage } = useInvoiceStore();
    const { setCustomerApplicationForms, setPaymentInformationForms, customerApplicationForms, paymentInformationForms, activeForm, setActiveForm } = useCustomerStore()
    const {
        formData,
        completedSteps,
        failedSteps,
        setValidating,
        updateStepsStatus,
        updateForm,
        resetForm
    } = useFormStore()

    const isCustomerTradeApplication = location.pathname.includes('customer-trade-application-form')

    const documentType = isCustomerTradeApplication ?
        CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM : CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM

    const steps = useMemo(() => getSteps(documentType, false, formData), [activeForm, formData?.DIRECT_DEBIT_CHOSEN]);

    const handleValidateSteps = (rawData?: any) => {
        const data = rawData ?? formData
        const completedArray: number[] = []
        const failedArray: number[] = []
        getSteps(documentType, true, data).forEach((step, index) => {
            let notNullCount = 0
            const requiredFields = step.layout?.filter(item => item.required)

            requiredFields?.forEach(field => {
                if (data[field.name]) {
                    notNullCount = notNullCount + 1
                }
            })

            if (notNullCount === 0) {
                return
            } else if (requiredFields?.length === notNullCount) {
                completedArray.push(index)
            } else {
                failedArray.push(index)
            }

            console.info("STEP:", step.label, '|', "REQUIRED COUNT:", requiredFields?.length, '|', "FILLED-UP COUNT:", notNullCount)
        })
        updateStepsStatus({ completedSteps: completedArray, failedSteps: failedArray })
        return completedArray
    }

    useEffect(() => {
        const formId = searchParams.get("form-id") ?? activeForm?.id
        if (formId) {
            try {
                const getAllCustomerForms = async () => {
                    const { data } = await API.get(`/customer-trade-application-form/customer/${formId}`)
                    if (data.error) {
                        setCustomerApplicationForms([null])
                        setPaymentInformationForms([null])
                        setActiveForm(null)
                        navigate('/customer/customer-trade-application-form/applicant_details')
                        const contentOption = snackBarStyle({
                            text: `Form not found`,
                            variant: "error",
                        });
                        enqueueSnackbar(contentOption.message, contentOption.options)
                    } else {
                        const form = data.find((form: any) => form.id === formId)
                        setCustomerApplicationForms(data.filter((form: FormStatus) => form.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM))
                        setPaymentInformationForms(data.filter((form: FormStatus) => form.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM))
                        setActiveForm(form)
                        navigate(form.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                            '/customer/customer-trade-application-form/applicant_details' :
                            '/customer/payment-information-form/applicant_details'
                        )
                    }
                }
                getAllCustomerForms()
            } catch (error) {
                console.error(error)
            }
        }
    }, [])

    useEffect(() => {
        const getFormData = async () => {
            try {
                const { data } = await API.get(`/customer-trade-application-form/${activeForm?.id}`)
                updateForm(data)
                setFormNumber(data.DWDOCID)
                handleValidateSteps(data)
            } catch (error) {
                console.log(error)
            }
        }

        if (activeForm) {
            getFormData()
            navigate(activeForm.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                '/customer/customer-trade-application-form/applicant_details' :
                '/customer/payment-information-form/applicant_details'
            )
        } else {
            resetForm()
            setFormNumber(undefined)
            isCustomerTradeApplication ?
                navigate('/customer/customer-trade-application-form/applicant_details') :
                navigate('/customer/payment-information-form/applicant_details')

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeForm])

    useEffect(() => {
        if (formData) {
            const currentStep = steps.findIndex(step => location.pathname.includes(step.route))
            setActiveStep(currentStep)
            if (failedSteps.includes(currentStep)) {
                setValidating(true)
            } else {
                setValidating(false)
            }
            if (innerDivRef.current) {
                innerDivRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
            handleValidateSteps()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        if (
            formData && (formData?.INFORMATION_TRUE_AND_CORRECT ||
                formData?.ACKNOWLEDGE_ALL_LEGAL ||
                formData?.DIRECT_DEBIT_CHOSEN)
        ) {
            handleValidateSteps()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.INFORMATION_TRUE_AND_CORRECT, formData?.ACKNOWLEDGE_ALL_LEGAL, formData?.DIRECT_DEBIT_CHOSEN])

    useEffect(() => {
        if (formData && isCustomerTradeApplication && formData?.IS_EXISTENT_CUSTOMER === 'No') {
            updateForm({ ...formData, CUSTOMER_NUMBER: null })
        }
        if (formData && isCustomerTradeApplication && formData?.KNOW_OWNER_OR_STAFF === 'No') {
            updateForm({ ...formData, PROVIDE_CUSTOMER_NUMBER: null })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.IS_EXISTENT_CUSTOMER, formData?.KNOW_OWNER_OR_STAFF])

    const handleInitialSave = async () => {
        setValidating(true)
        setProcessing(true)
        try {
            const { data, status } = await API.post(
                '/customer-trade-application-form/',
                {
                    ...formData,
                    clientUrl: window.location.origin,
                    DOCUMENT_TYPE: documentType
                }
            )

            if (status === 201) {
                const newForm = { id: data.id, status: 'partial', type: data.DOCUMENT_TYPE } as FormStatus
                data.DOCUMENT_TYPE === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                    setCustomerApplicationForms([...customerApplicationForms.map(form => form === null ? newForm : form)]) :
                    setPaymentInformationForms([...paymentInformationForms.map(form => form === null ? newForm : form)])
                setActiveForm(newForm)

                setFormNumber(data.DWDOCID)
                updateForm(data)
                const contentOption = snackBarStyle({
                    text: `Successfully saved changes`,
                    variant: "success",
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                setOpenFormSavedModal(true)
                handleValidateSteps(data)
            }
        } catch (error: any) {
            console.log(error)
            const contentOption = snackBarStyle({
                text: 'Failed to save changes',
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
        } finally {
            setProcessing(false)
        }
    }

    const handleSave = async () => {
        setValidating(true)
        setProcessing(true)
        try {
            const { data, status } = await API.patch(`/customer-trade-application-form/save/${activeForm?.id}`, formData)
            if (status === 200) {
                updateForm(data)
                const contentOption = snackBarStyle({
                    text: `Successfully saved changes`,
                    variant: "success",
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                handleValidateSteps(data)
            }
        } catch (error: any) {
            console.log(error)
            const contentOption = snackBarStyle({
                text: Array.isArray(error?.response?.data?.message) ? error?.response?.data?.message[0] : (error?.response?.data?.message ?? 'Failed to save changes.'),
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
        } finally {
            setProcessing(false)
        }
    }

    const handleSubmit = async () => {
        setProcessing(true)
        try {
            const { data, status } = await API.patch(`/customer-trade-application-form/submit/${activeForm?.id}`, formData)

            if (status === 200) {
                const contentOption = snackBarStyle({
                    text: `Successfully submitted form`,
                    variant: "success",
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                if (activeForm?.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM) {
                    setCustomerApplicationForms([...customerApplicationForms.map((form) => {
                        if (form && form?.id === activeForm?.id) {
                            return { ...form, status: 'complete' } as FormStatus
                        } else {
                            return form ?? null
                        }
                    })])
                } else {
                    setPaymentInformationForms([...paymentInformationForms.map((form) => {
                        if (form && form?.id === activeForm?.id) {
                            return { ...form, status: 'complete' } as FormStatus
                        } else {
                            return form ?? null
                        }
                    })])
                }
                updateForm(data)
                setOpenFormSubmittedModal(true)
            }
        } catch (error: any) {
            console.log(error)
            const contentOption = snackBarStyle({
                text: Array.isArray(error?.response?.data?.message) ? error?.response?.data?.message[0] : (error?.response?.data?.message ?? `Failed to submit form.`),
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
        } finally {
            setProcessing(false)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
                padding: 0,
                margin: 0,
            }}
        >
            <CssBaseline />
            <Header />
            <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
                <CustomerSidebar setDeleteForm={setDeleteForm} />
                <CustomerMobileDrawer setDeleteForm={setDeleteForm} />
                <Outlet context={{
                    processing, steps, handleInitialSave,
                    handleSave, handleSubmit, innerDivRef,
                    formNumber, activeStep, setActiveStep
                }}
                />
            </Box>
            <BasicSnackbar
                open={showSnackbar}
                handleClose={() =>
                    updateInvoice({
                        showSnackbar: false,
                        snackbarSeverity: undefined,
                        snackbarMessage: '',
                    })
                }
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <DeleteFormDialog deleteForm={deleteForm} open={!!deleteForm} handleClose={() => setDeleteForm(null)} />
            <FormSavedDialog
                open={openFormSavedModal}
                handleClose={() => setOpenFormSavedModal(false)}
                formNumber={formNumber}
            />
            <FormSubmittedDialog
                open={openFormSubmittedModal}
                handleClose={() => setOpenFormSubmittedModal(false)}
                formNumber={formNumber}
            />
        </Box>
    )
}

export function useCustomerOutletContext() {
    return useOutletContext<ContextType>();
}

export default Customer