import React, { useState } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Badge from '@mui/material/Badge';

import { useSettingStore } from 'state/setting.store';
import HelpMenu from './HelpMenu';
import grey from '@mui/material/colors/grey';

const footerMenus: any = [
  {
    label: 'Help',
    icon: <HelpIcon />,
  },
];

const SidebarFooter = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { extend } = useSettingStore();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List>
        {footerMenus.map((menu: any) => {
          return (
            <ListItemButton
              key={menu.label}
              sx={{
                minHeight: 48,
                color: grey[600],
                justifyContent: extend ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={handleMenuOpen}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: extend ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                <Tooltip title={extend ? '' : menu.label} placement="right">
                  {menu.label === 'Help' ? (
                    menu.icon
                  ) : (
                    <Badge badgeContent={4} color="primary">
                      {menu.icon}
                    </Badge>
                  )}
                </Tooltip>
              </ListItemIcon>

              <ListItemText
                primary={menu.label}
                sx={{ opacity: extend ? 1 : 0 }}
              />
            </ListItemButton>
          );
        })}
      </List>
      <HelpMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
    </>
  );
};

export default SidebarFooter;
