import { FC, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import API from 'api'
import { User } from 'interfaces/user.interface';
import UserProfileCell from './UserProfileCell';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack";
import { snackBarStyle } from "utils/common.utils";

interface DeleteUserDialogProps {
    open: boolean;
    handleClose: () => void;
    users: User[];
    updateUsers: (deletedUsers: User[]) => void;
}

const DeleteUserDialog: FC<DeleteUserDialogProps> = ({ open, handleClose, users, updateUsers }) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setIsProcessing(true)
        try {
            const { data } = await API.patch('/users', [...users?.map((user) => user.id)])
            if (data) {
                const contentOption = snackBarStyle({
                    text: "Successfully deleted users",
                    variant: "success",
                    autoHideDuration: 3000
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                updateUsers(data)
                handleClose()
            }
        } catch (error: any) {
            const contentOption = snackBarStyle({
                text: error?.response?.data?.message ?? "Encountered error",
                variant: "error",
                autoHideDuration: 3000
            });
            enqueueSnackbar(contentOption.message, contentOption.options)

        } finally {
            setIsProcessing(false)
        }
    }


    return (
        <Dialog open={open} onClose={handleClose} sx={{ '& div.MuiPaper-root': { width: '100%', mx: 2, p: 1, pt: 0.5 } }}>
            <IconButton
                sx={{ position: 'absolute', right: 0 }}
                onClick={handleClose}
            >
                <CloseIcon fontSize='medium' />
            </IconButton>
            <DialogTitle sx={{ fontWeight: 700, display: 'flex', justifyContent: 'center' }}>Delete User</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography>Are you sure you want to delete the following users?</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {users?.map((user) => {
                            return <UserProfileCell key={user.id} firstName={user.firstName} lastName={user.lastName} username={user.username} />
                        })}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 2 }}>
                    <Button fullWidth disabled={isProcessing} variant='outlined' onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                        fullWidth
                        loading={isProcessing}
                        variant="contained"
                        type='submit'
                        color='error'
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default DeleteUserDialog