import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete, Box, Divider, InputAdornment, Paper, Tab, Tabs, TextField, Typography
} from "@mui/material";
import API from "api";
import CreatePoOrder from "components/orders/createPoOrder";
import { useEffect, useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreatePurchaseOrder = () => {
  const [TabValue, setTabValue] = useState(0);
  const [vendorListData, setVendorListData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const onTabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = async () => {
    const { data } = await API.get("/vendors/get-vendor-list");
    setVendorListData(data.data);
  };

  const selectSupplier = async (e: any, selectVal: any) => {
    const { data } = await API.get(
      `/vendors/${Number(selectVal.split("-")[0])}`
    );
    if (data.data.length) return setVendorData(data.data);
    else return;
  };
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Create Purchase Order
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            Padding: 2,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "25px",
                }}
              >
                <TextField
                  disabled
                  id="outlined-basic"
                  label="Po Number"
                  size="small"
                  variant="outlined"
                />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={vendorListData}
                  onInputChange={selectSupplier}
                  sx={{
                    width: "30ch",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor ID"
                      size="small"
                      required
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  disableClearable
                />
              </Box>
              <Divider
                light
                sx={{
                  marginTop: 1,
                }}
              />
            </Box>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={TabValue}
                  onChange={onTabHandleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="ORDER" {...a11yProps(0)} />
                  <Tab label="OPTIONAL FIELDS" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={TabValue} index={0}>
                <CreatePoOrder vendordata={vendorData[0]} />
              </TabPanel>
              <TabPanel value={TabValue} index={1}>
                OPTIONAL FIELDS
              </TabPanel>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreatePurchaseOrder;
