import { FC } from "react";

import Typography from "@mui/material/Typography";

import { PurchaseReqForm } from "interfaces/myforms.interface";
import FormControlItem, {
  FormControlItemLayout,
} from "../../form-components/FormControlItem";
import { FORM_CONTROL_TYPE } from "enums/form.enums";

const REASONS_DATA: FormControlItemLayout = {
  name: "description",
  label: "Reasons",
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.MULTI_LINE_TEXT,
  rows: 3,
};

const PurchaseReasonsForm: FC<{
  data: PurchaseReqForm;
  onChange: (value: PurchaseReqForm) => void;
  submitValidating: boolean;
}> = ({ data = {}, onChange = () => { }, submitValidating }) => {
  return (
    <div>

      <FormControlItem data={data} onChange={onChange} layout={REASONS_DATA} submitValidating={submitValidating} />
      <Typography
        variant="caption"
        display="block"
        color="GrayText"
      >
        I would like to request the purchase of the above items(s) for using at
        JFC office.
      </Typography>
      <Typography
        variant="caption"
        display="block"
        color="GrayText"
      >
        The price and quantity have been verified to my satisfaction.
      </Typography>
    </div>
  );
};

export default PurchaseReasonsForm;
