import { Box, CardContent, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';

function InvoiceCard({
    amount = '',
    title = '',
    cardIcon = null,
}: any) {

    return (
        <Card sx={{ width: '100%', minWidth: '150px' }}>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Grid item>
                        <Typography color={'#000000'} fontWeight={500}>
                            {title}
                        </Typography>
                        <Typography variant='h5' fontWeight={600} color={'#536dfe'}>
                            {amount}
                        </Typography>
                    </Grid>
                    <Grid item>{cardIcon}</Grid>
                </Box>
            </CardContent>
        </Card>
    );
}

export default InvoiceCard;
