import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Box from '@mui/material/Box';
import API from 'api';
import LoaderContainer from 'components/common/LoaderContainer';
import React, { useEffect, useState } from 'react';
import { useUserStore } from 'state/user.store';
import { dataUrlToFile } from 'utils/common.utils';

interface ViewInvoiceProps {
  docId: string;
}

const DocumentViewer: React.FC<ViewInvoiceProps> = ({ docId }) => {
  const [data, setData] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const { logout } = useUserStore();

  const getDocument = async () => {
    setLoading(true);
    try {
      const result = await API.get(`/invoices/${docId}/download`);
      setData(result.data);
    } catch (err) {
      console.log(err);
      const error = (err as any).response.data.message;
      if (error === 'Unauthorized') {
        logout();
      }
    }
  };

  const loadData = async () => {
    setLoading(true);
    await getDocument();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  return (
    <LoaderContainer open={loading}>
      <Box>
        {data ? (
          <DocViewer
            documents={[
              {
                uri: URL.createObjectURL(dataUrlToFile(data.base64Data)),
                fileName: data.fileName,
                fileType: 'application/pdf',
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          />
        ) : null}
      </Box>
    </LoaderContainer>
  );
};

export default DocumentViewer;
