import { Box, CircularProgress, Link, TextField, Typography } from "@mui/material"
import { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate, useSearchParams } from "react-router-dom"
import API from 'api'
import { grey } from "@mui/material/colors";

const EmailVerification = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false)
    const [resendingLink, setResendingLink] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean | null>(null)
    const [email, setEmail] = useState<string>('')

    useEffect(() => {
        setLoading(true)
        const token = searchParams.get("token")

        const verifyAccount = async () => {
            try {
                const { data } = await API.post('/auth/verify-token', { token })
                if (data) {
                    setTimeout(() => {
                        setLoading(false)
                        setSuccess(true)
                    }, 4000)
                } else {
                    setTimeout(() => {
                        setLoading(false)
                        setSuccess(false)
                    }, 4000)
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false)
                    setSuccess(false)
                }, 4000)
            } finally {
                sessionStorage.removeItem('public-path')
            }
        }
        verifyAccount()
    }, [])


    useEffect(() => {
        if (success) {
            setTimeout(() => {
                navigate('auth/signin')
            }, 3000)
        }
    }, [success])

    const resendLink = async () => {
        setResendingLink(true)
        try {
            const { data } = await API.post('/auth/resend-link', { username: email, clientUrl: window.location.origin })
        } catch (error) {
            console.log("ERROR", error)
        } finally {
            setTimeout(() => {
                setResendingLink(false)
            }, 3000)

        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', gap: 2
        }}>
            {loading ? <CircularProgress /> :
                <>
                    {success === true ? <CheckCircleIcon color="primary" sx={{ fontSize: 100 }} /> : null}
                    {success === false ? <ErrorIcon color="error" sx={{ fontSize: 100 }} /> : null}
                </>

            }

            {loading ?
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">Account verification ongoing.</Typography>
                </Box> :
                <>
                    {success === true ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: grey[800] }}>Verified!</Typography>
                            <Typography variant="h6" sx={{ color: grey[600] }}>You have successfully verified you account.</Typography>
                            <Box sx={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center', gap: 1, pt: 2 }}>
                                <CircularProgress size={20} />
                                <Typography variant="caption">Redirecting to Login</Typography>
                            </Box>
                        </Box> : null}
                    {success === false ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: grey[800] }}>Error!</Typography>
                            <Typography variant="h6" sx={{ color: grey[600] }}>An error occured or token expired.</Typography>
                            <Box sx={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1, pt: 2 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label='Email'
                                    value={email}
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {resendingLink ?
                                    <CircularProgress size={20} /> :
                                    <Link variant="caption" sx={{ cursor: 'pointer' }} onClick={resendLink}>Resend link</Link>}
                            </Box>
                        </Box> : null}
                </>
            }
        </Box>
    )
}

export default EmailVerification