import { Box, Typography } from "@mui/material"

const WebsiteMaintenace = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', gap: 2
        }}>
            <Typography variant="h5">🛠️ Website Under Maintenance</Typography>

            <Box sx={{ width: { xs: '95%', md: "70%", lg: "50%" } }}>
                <Typography sx={{ textAlign: "justify" }}>We're sorry for the inconvenience, but we're currently performing maintenance on our website to enhance your experience. During this time, the website is temporarily unavailable.</Typography>
            </Box>

            <Box sx={{ width: { xs: '95%', md: "70%", lg: "50%" } }}>
                <Typography sx={{ textAlign: "justify" }}>We expect to resume normal service on August 27, 2023. We appreciate your patience and understanding.</Typography>
            </Box>


            <Typography>— The Coreform Team</Typography>

        </Box>
    )
}

export default WebsiteMaintenace