import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import API from 'api';
import { ZoomTransition } from 'components/common/Transitions';
import { DecisionTypeEnum } from 'enums/invoice.enums';
import { Invoice, InvoiceSummary } from 'interfaces/invoice.interface';
import { useInvoiceStore } from 'state/invoice.store';
import { useUserStore } from 'state/user.store';
import {
  createRequestBody,
  MAX_UPDATE_INVOICE_COUNT,
} from 'utils/invoice.utils';
import ActionsDialogContent from './ActionsDialogContent';
import ProcessingLoader from './ProcessingLoader';
import { ApiCounts } from 'interfaces/api-counts.interfaces';

export interface ActionsDialogProps {
  invoices: InvoiceSummary[];
}

const ActionsDialog: React.FC<ActionsDialogProps> = ({ invoices }) => {
  const { logout } = useUserStore();
  const {
    singleSelected,
    bulkSelected,
    decisionType,
    processing,
    reason,
    personRequestedName,
    personRequestedEmail,
    allInvoices,
    updateInvoice,
    resetApproval,
  } = useInvoiceStore();

  const [counts, setCounts] = useState<ApiCounts>({
    all: 0,
    success: 0,
    fail: 0,
  });

  const handleConfirm = async (e: any) => {
    e.preventDefault();

    if (
      !(decisionType === DecisionTypeEnum.APPROVED) &&
      !(decisionType === DecisionTypeEnum.REJECTED) &&
      !(decisionType === DecisionTypeEnum.ON_HOLD) &&
      (!reason || !personRequestedName || !personRequestedEmail)
    ) {
      updateInvoice({
        showSnackbar: true,
        snackbarSeverity: 'warning',
        snackbarMessage: 'Input all fields correctly',
      });
      return;
    }

    updateInvoice({ processing: true });
    try {
      const invoices = createRequestBody(
        decisionType,
        singleSelected ? singleSelected : bulkSelected,
        reason,
        personRequestedName,
        personRequestedEmail
      );

      let result = true;
      const failedIds: string[] = [];
      const successIds: string[] = [];
      setCounts({ all: invoices.length, success: 0, fail: 0 });
      for (let i = 0; i < invoices.length; i += MAX_UPDATE_INVOICE_COUNT) {
        const subInvoices = invoices.slice(i, i + MAX_UPDATE_INVOICE_COUNT);

        const res = await API.patch('/documents', { invoices: subInvoices });
        result = result && res.status === 200;

        if (res.status === 200) {
          setCounts(
            (s: ApiCounts) =>
            ({
              ...s,
              success: s.success + subInvoices.length,
            } as ApiCounts)
          );

          // save success docuwareId
          subInvoices.forEach((item) => {
            successIds.push(item?.id ?? '');
          });
        } else {
          setCounts(
            (s: ApiCounts) =>
            ({
              ...s,
              fail: s.fail + subInvoices.length,
            } as ApiCounts)
          );

          // save failed docuwareId
          subInvoices.forEach((item) => {
            failedIds.push(item?.id ?? '');
          });
        }
      }

      // remove approved items from list
      updateInvoice({
        bulkSelected: failedIds,
        allInvoices: allInvoices.filter(
          (invoice: Invoice) =>
            !successIds.some((idItem) => Number(idItem) === invoice?.DWDOCID)
        ),
      });

      if (result) {
        resetApproval();
        updateInvoice({
          showSnackbar: true,
          snackbarSeverity: 'success',
          snackbarMessage: 'Successfully updated invoice',
        });
      } else {
        updateInvoice({
          showSnackbar: true,
          snackbarSeverity: 'warning',
          snackbarMessage:
            'Some of the invoices had not been updated correctly',
        });
      }
    } catch (err) {
      resetApproval();
      updateInvoice({
        showSnackbar: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to update invoice',
      });
      const error = (err as any).response.data.message;
      if (error === 'Unauthorized') {
        logout();
      }
    }
  };

  const getTitleContent = (decisionType: string, singleSelected: string) => {
    switch (decisionType) {
      case DecisionTypeEnum.APPROVED:
        return singleSelected ? 'Approve invoice' : 'Approve invoices';
      case DecisionTypeEnum.REJECTED:
        return 'Rejection reason';
      case DecisionTypeEnum.REQUESTED_INFO:
        return 'Request more information';
      default:
        return 'Put invoice on hold';
    }
  };

  return (
    <Dialog
      open={!!decisionType}
      onClose={resetApproval}
      TransitionComponent={ZoomTransition}
      fullWidth
      BackdropProps={{
        style: {
          backgroundColor: '#536DFE',
          opacity: 0.4,
        },
      }}
      sx={{ '& div.MuiPaper-root': { borderRadius: 2 } }}
      PaperProps={{ elevation: 6 }}
      scroll='paper'
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            letterSpacing: '0.15px',
            lineHeight: '150%',
          }}
        >
          {getTitleContent(decisionType, singleSelected)}
        </Typography>
        {!processing && (
          <IconButton
            onClick={resetApproval}
            sx={{
              position: 'absolute',
              p: 0,
              top: 15,
              right: 20,
              color: 'black',
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent sx={{ pt: 0.5 }}>
        {processing ? (
          <ProcessingLoader value={(counts.success / counts.all) * 100} />
        ) : (
          <ActionsDialogContent invoices={invoices} />
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3, justifyContent: 'center' }}>
        <Button
          onClick={handleConfirm}
          variant='contained'
          sx={{
            borderRadius: 10,
            width: '75%',
            textTransform: 'capitalize',
            py: 1.2,
          }}
          disabled={processing}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionsDialog;
