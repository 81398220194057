import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';
import { Role } from 'enums/user.enum';

export const ESPH_USER_FORM_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'role',
    label: 'Select Role',
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: [...Object.values(Role)],
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: 'department',
    label: 'Department',
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: [...Object.values(Role)],
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: 'username',
    label: 'Email address',
    required: true,
    fullWidth: true,
    autoComplete: 'off',
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: 'password',
    label: 'Password',
    type: FORM_CONTROL_TYPE.PASSWORD,
    required: true,
    fullWidth: true,
    autoComplete: 'off',
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: 'firstName',
    label: 'First name',
    required: true,
    fullWidth: true,
    autoComplete: 'off',
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: 'lastName',
    label: 'Last name',
    required: true,
    fullWidth: true,
    autoComplete: 'off',
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
];
