import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { ACCEPTANCE_OF_CONDITIONS_LAYOUT_1, ACCEPTANCE_OF_CONDITIONS_LAYOUT_2 } from 'components/forms/form-layouts/customer-trade-application-layouts/acceptance-of-conditions.layout'
import { useFormStore } from 'state/form.store'


export const AOCStatements = () => {
    return (
        <>
            <Typography>I/We hereby:</Typography>

            <ol style={{ textAlign: 'justify', paddingLeft: 20 }}>
                <li>
                    Apply for credit to be provided to me/us by JFC Australia Co Pty Ltd, and acknowledge that the terms and conditions
                    of sale attached to this application will apply to all orders placed with, or credit granted by, JFC Australia Co Pty Ltd.
                </li>
                <li>
                    Declare that the credit provided to me/us is to be applied wholly for business or investment purposes, and not personal,
                    domestic, or household purposes.
                </li>
                <li>
                    Authorise JFC Australia Co Pty Ltd to request credit reports from credit reporting agencies for the purposes of assessing
                    this application and/or for collection purposes.
                </li>
                <li >
                    Authorise JFC Australia Co Pty Ltd to request and obtain identification documents from Buyer and/or Guarantors, including
                    but not limited to driver license, for the purposes of assessing this application and/or for collection; noting that:
                    <ol type='I' style={{ textAlign: 'justify', paddingLeft: 15 }}>
                        <li>
                            JFC Australia Co Pty Ltd shall retain your identification documents for the duration of your customer account and will comply
                            with their obligations pursuant to Privacy Act 1988 (Cth).
                        </li>
                        <li>
                            Your identification documents shall be destroyed within 14 days of your final payment of any invoices owing to JFC Australia
                            Co Pty Ltd.
                        </li>
                    </ol>
                </li>
                <li>
                    Authorise JFC Australia Co Pty Ltd to request and obtain credit card information from Buyer and/or Guarantors, noting that;
                    <ol type='I' style={{ textAlign: 'justify', paddingLeft: 15 }}>
                        <li>
                            JFC Australia Co Pty Ltd shall retain your credit card information for the duration of your customer account and will comply
                            with their obligations pursuant to Payment Card Industry Data Security (PCI DSS)
                        </li>
                        <li>
                            Your credit card information shall be destroyed within 14 days of your final payment of any invoices owing to JFC Australia Co
                            Pty Ltd.
                        </li>
                    </ol>
                </li>
                <li>
                    Without limiting the above, accept the following conditions:
                    <ol type='I' style={{ textAlign: 'justify', paddingLeft: 15 }}>
                        <li>
                            Any overdue amount will be charged to the nominated credit card even after the expiry date given on this form
                        </li>
                        <li>
                            JFC Australia Co Pty Ltd may alter its terms and conditions of sale by sending written notice of the updated terms to the Applicant's
                            address as noted on this form, in which case the updated terms will govern all sales after the giving of that notice.
                        </li>
                        <li>
                            On the sale or transfer of the Applicant's business listed above, the Applicant must provide written notice to JFC Australia Co Pty Ltd
                            within 7 days of the execution of sale or transfer of business document (and in any event before the sale or transfer takes effect).
                        </li>
                    </ol>
                </li>
            </ol>
        </>
    )
}

const AcceptanceOfConditions = () => {

    const { formData, validating, updateForm } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    return (
        <Box>
            <AOCStatements />
            <Box sx={{ pb: 3 }}>
                <Typography sx={{ fontWeight: "bold" }}>Signed by Proprietors/Directors</Typography>
                <Grid container spacing={1}>
                    {ACCEPTANCE_OF_CONDITIONS_LAYOUT_1(formData?.RESIDENCE_STATUS_AOC_1 ?? '').map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Box sx={{ pb: 3 }}>
                <Grid container spacing={1}>
                    {ACCEPTANCE_OF_CONDITIONS_LAYOUT_2.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                <Box sx={{ pt: 1 }}>
                    <Typography variant='caption'>**All company Directors must sign the attached guarantee**</Typography>
                </Box>

            </Box>



        </Box>
    )
}

export default AcceptanceOfConditions