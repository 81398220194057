import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  registerables,
  Title,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Title, Legend, ...registerables);

export function PieChart(props: any) {
  const title = props.title;

  const data: any = {
    labels: props?.data?.map((d: any) => {
      return d.label;
    }),
    datasets: [
      {
        label: title,
        data: props?.data?.map((d: any) => d?.value),
        backgroundColor: props?.data?.map((d: any) => {
          return d.color;
        }),
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        callbacks: {
          label: props?.tooltip,
        },
      },

      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        color: '#000',
        text: title,
      },
    },
  };

  return <Pie data={data} options={options} height={'450px'} />;
}

export const PieChartTooltip = (tooltipItem: any) => {
  const allData = tooltipItem.dataset.data as Array<any>;
  const itemValue = Number(allData[tooltipItem.dataIndex]);
  const total = allData.reduce((ret: number, cur: any) => ret + Number(cur), 0);

  const itemPercentage = Math.round((itemValue / total) * 100).toFixed(2);

  return tooltipItem.label + ' : ' + itemPercentage + '%';
};
