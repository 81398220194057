import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Grid from "@mui/material/Grid"

import SuccessRegistration from 'components/authentication/SuccessRegistration'
import API from 'api'
import { IS_ESPH, IS_JFC } from 'constants/global'
import { LoadingButton } from "@mui/lab"
import { JFC_SIGNUP_FORM_LAYOUT } from "components/users/layouts/jfc-signup-form.layout"
import FormControlItem, { FormControlItemLayout } from "components/forms/form-components/FormControlItem"
import { ESPH_SIGNUP_FORM_LAYOUT } from "components/users/layouts/esph-signup-form.layout"

const Signup = () => {
  const navigate = useNavigate()
  const [errors, setErrors] = useState<string[] | null>(null)
  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [formData, setFormData] = useState<any>(null)
  const [validating, setValidating] = useState<boolean>(false)

  const onChange = (value: any) => {
    setErrors(null)
    setFormData(value)
  }

  const jfcFormValidator = () => {
    if (
      formData &&
      formData.username &&
      formData.password &&
      formData.confirmPassword
    ) return true
    return false
  }

  const esphFormValidator = () => {
    if (
      formData &&
      formData.firstName &&
      formData.lastName &&
      formData.username &&
      formData.password
    ) return true
    return false
  }

  const handleSignup = async () => {
    setValidating(true)
    if (IS_JFC) {
      if (!jfcFormValidator()) return
      if (formData?.password !== formData?.confirmPassword) {
        setErrors(["Passwords not match!"])
        return
      }
    }
    if (IS_ESPH) {
      if (!esphFormValidator) return
    }

    setProcessing(true)
    try {
      const response = await API.post('/auth/signup', {
        ...formData,
        clientUrl: window.location.origin
      })

      if (response.status === 201) {
        setFormData(null)
        setShowRegisterSuccess(true)
      }

    } catch (err) {
      const errors = (err as any).response.data.message
      if (Array.isArray(errors)) {
        setErrors(errors)
      } else {
        setErrors([errors])
      }
    } finally {
      setProcessing(false)
      setValidating(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      {errors && (
        <Box sx={{ pb: 2 }}>
          {errors.map((error, index) => {
            return (
              <Box key={index}>
                <Typography color='error' variant='caption'>
                  ⚠ {error}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}


      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

        <Grid container spacing={1}>
          {IS_JFC ? JFC_SIGNUP_FORM_LAYOUT.map((item: FormControlItemLayout) => {
            return (
              <Grid key={item.name} item xs={item.gridSizeMobile}>
                <FormControlItem
                  data={formData}
                  onChange={onChange}
                  layout={item}
                  submitValidating={validating}
                />
              </Grid>
            )
          }) :
            ESPH_SIGNUP_FORM_LAYOUT.map((item: FormControlItemLayout) => {
              return (
                <Grid key={item.name} item xs={item.gridSizeMobile}>
                  <FormControlItem
                    data={formData}
                    onChange={onChange}
                    layout={item}
                    submitValidating={validating}
                  />
                </Grid>
              )
            })
          }
        </Grid>


        <LoadingButton
          size="large"
          onClick={handleSignup}
          loading={processing}
          variant="contained"
        >
          Sign up
        </LoadingButton>
      </Box>

      <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
        <Link
          onClick={(event: any) => {
            event.preventDefault()
            navigate('/auth/signin')
          }}
          variant='caption'
          sx={{ cursor: 'pointer', textDecoration: 'none' }}
        >
          Already have an account?
        </Link>
      </Box>
      <SuccessRegistration
        email={formData?.email}
        open={showRegisterSuccess}
        handleClose={() => setShowRegisterSuccess(false)}
      />
    </Box>
  )
}

export default Signup
