import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CustomTextArea from 'components/common/CustomTextArea';
import React from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  p: 2,
};

interface rejectPoModalProps {
  rejectOpen: boolean;
  rejectHandleClose: any;
}

export const RejectPoModal: React.FC<rejectPoModalProps> = ({
  rejectOpen,
  rejectHandleClose,
}) => {
  //   const [select, setSelect] = useState("");

  //   const onSelectHandleChange = (event: SelectChangeEvent) => {
  //     setSelect(event.target.value as string);
  //   };

  return (
    <>
      <Modal
        open={rejectOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            rejectHandleClose();
          }
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        BackdropProps={{
          style: {
            backgroundColor: '#536DFE',
            opacity: 0.4,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start',
              }}
            >
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Rejection Reason
              </Typography>
            </Box>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={rejectHandleClose} />
          </Box>
          <Box mt={2} sx={{ textAlign: 'center' }}>
            <CustomTextArea onChange={(e) => {}} required />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
