import moment from 'moment';

import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { grey } from "@mui/material/colors";

import { useFormStore } from 'state/form.store'
import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { REVIEW_BEFORE_SUBMISSION_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/review-before-submission.layout'
import { BUSINESS_DETAILS_ABN_CERTIFICATE, BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT, BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT, BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL, BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT, BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1, BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2 } from 'components/forms/form-layouts/customer-trade-application-layouts/business-details.layout'
import { APPLICANT_DETAILS_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/applicant-details.layout'

import { ACCEPTANCE_OF_CONDITIONS_LAYOUT_1, ACCEPTANCE_OF_CONDITIONS_LAYOUT_2 } from 'components/forms/form-layouts/customer-trade-application-layouts/acceptance-of-conditions.layout'
import { DRIVERS_LICENSE, GUARANTOR_LAYOUT_1, GUARANTOR_LAYOUT_2, TERMS_AND_CONDITIONS_CONFIRMATION, WITNESS_LAYOUT_1, WITNESS_LAYOUT_2 } from 'components/forms/form-layouts/customer-trade-application-layouts/terms-and-conditions.layout'
import { AOCStatements } from './AcceptanceOfConditions';
import SignatureImage from "components/forms/form-components/SignatureImage";
import { filterPaymentLayout } from "utils/form.utils";
import { FORM_CONTROL_TYPE } from "enums/form.enums";


const UploadedFileInfo = ({ uploadedFiles }: { uploadedFiles: string[] }) => {
    return (
        <Box sx={{ minHeight: 30, border: '1px dashed gray', borderRadius: 1, p: 3, display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-start' }}>
            {uploadedFiles?.length ? uploadedFiles.map((file, index) => {
                return (
                    <Chip key={index} label={file.substring(11)} sx={{ width: 'fit-content', borderRadius: 2 }} />
                )
            }) : <Typography>No file uploaded</Typography>
            }
        </Box>
    )
}

const RowData = ({ data, item }: { data: any, item: FormControlItemLayout }) => {
    if (item.type === FORM_CONTROL_TYPE.DATE) {
        return <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{item.label}: <span style={{ fontWeight: 400 }}>{data?.[item.name] ? moment(data?.[item.name]).format('DD/MM/YYYY') : ''}</span></Typography>
    } else if (item.type === FORM_CONTROL_TYPE.SIGNATURE) {
        return <SignatureImage label={item.label} imageUrl={data?.[item.name]} />
    } else {
        return <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{item.label}: <span style={{ fontWeight: 400 }}>{data?.[item.name] ?? ''}</span></Typography>
    }
}

const ReviewBeforeSubmission = () => {

    const {
        formData,
        validating,
        completedSteps,
        updateForm
    } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    const ADDRESS = ['Shop number', 'Street number', 'Street name', 'Suburb', 'State', 'Postcode']

    return (
        <Box>
            {/* START BUSINESS DETAILS */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Business Details</Typography>

                <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>Delivery address:
                    <span style={{ fontWeight: 400 }}>
                        {BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT.filter(item => ADDRESS.includes(item.label as string)).map((item, index) => {
                            return (
                                formData?.[item.name] ? ` ${formData?.[item.name]}` : ''
                            )
                        })
                            .filter(item => item !== '')
                            .join(', ')}
                    </span>
                </Typography>


                {BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT.filter(item => !ADDRESS.includes(item.label as string)).map((item, index) => {
                    return (
                        <Typography key={item.name} variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{item.label}: <span style={{ fontWeight: 400 }}>{formData?.[item.name] ?? ''}</span></Typography>
                    )
                })}

                {formData?.['BUSINESS_MATCH_DELIVERY_ADDRESS'] === 'No' &&
                    <>
                        <Divider />

                        <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>Business address:
                            <span style={{ fontWeight: 400 }}>
                                {BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT.filter(item => ADDRESS.includes(item.label as string)).map((item, index) => {
                                    return (
                                        formData?.[item.name] ? ` ${formData?.[item.name]}` : ''
                                    )
                                }).join(', ')}
                            </span>
                        </Typography>
                    </>}


                {(formData?.['BUSINESS_MATCH_DELIVERY_ADDRESS'] === 'No' || formData?.['POSTAL_MATCH_DELIVERY_ADDRESS'] === 'No') &&
                    <Divider />}

                {formData?.['POSTAL_MATCH_DELIVERY_ADDRESS'] === 'No' &&
                    <>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>Postal address:
                            <span style={{ fontWeight: 400 }}>
                                {BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT.filter(item => ADDRESS.includes(item.label as string)).map((item, index) => {
                                    return (
                                        formData?.[item.name] ? ` ${formData?.[item.name]}` : ''
                                    )
                                }).join(', ')}
                            </span>
                        </Typography>

                        <Divider />
                    </>}


                {BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL(formData?.TYPE).map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Trade References</Typography>

                {BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

                {BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}


                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700], py: 2 }}>Please attach a valid ABN certificate</Typography>
                <UploadedFileInfo uploadedFiles={formData?.[BUSINESS_DETAILS_ABN_CERTIFICATE?.name]} />
            </Box>

            {/* END BUSINESS DETAILS */}

            <Divider />

            {/* START APPLICANT DETAILS */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Applicant Details</Typography>

                {APPLICANT_DETAILS_LAYOUT.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
            </Box>
            {/* END APPLICANT DETAILS */}

            <Divider />

            {/* START PAYMENT INFORMATION */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Payment Information</Typography>
                {filterPaymentLayout(formData?.PAYMENT_METHOD).map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
            </Box>
            {/* END PAYMENT INFORMATION */}

            <Divider />

            {/* START ACCEPTANCE OF CONDITIONS */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Acceptance of Conditions</Typography>
                <AOCStatements />
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Signed by Proprietors/Directors</Typography>
                {ACCEPTANCE_OF_CONDITIONS_LAYOUT_1().map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
                {ACCEPTANCE_OF_CONDITIONS_LAYOUT_2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
            </Box>
            {/* END ACCEPTANCE OF CONDITIONS */}

            <Divider />

            {/* START TERMS AND CONDITIONS OF SALE */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Terms and Conditions of Sale</Typography>
                <Typography key={TERMS_AND_CONDITIONS_CONFIRMATION.name} variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{TERMS_AND_CONDITIONS_CONFIRMATION.label}: <span style={{ fontWeight: 400 }}>{formData?.[TERMS_AND_CONDITIONS_CONFIRMATION.name] ?? ''}</span></Typography>
                {GUARANTOR_LAYOUT_1.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
                {WITNESS_LAYOUT_1.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Please attach a valid driver's license</Typography>
                <UploadedFileInfo uploadedFiles={formData?.[DRIVERS_LICENSE?.name]} />


                {GUARANTOR_LAYOUT_2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
                {WITNESS_LAYOUT_2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

            </Box>
            {/* END TERMS AND CONDITIONS OF SALE */}

            <Divider />

            <Box sx={{ pt: 5, pb: 3 }}>
                {completedSteps.length < 5 ?
                    <Alert variant='standard' severity='info'>Please complete all steps to proceed with form submission.</Alert> :
                    REVIEW_BEFORE_SUBMISSION_LAYOUT.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={12}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
            </Box>
        </Box>
    )
}

export default ReviewBeforeSubmission