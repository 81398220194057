import { FC, useState } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton'

import { DialogProps } from 'interfaces/common.interfaces';
import API from 'api'
import { FormStatus, useCustomerStore } from "state/customer.store";
import { useSnackbar } from "notistack";
import { snackBarStyle } from "utils/common.utils";
import { CUSTOMER_DOCUMENT_TYPES } from "enums/customer-trade-application-form.enums";

interface DeleteFormDialogProps extends DialogProps {
  deleteForm: FormStatus | null;
}

const DeleteFormDialog: FC<DeleteFormDialogProps> = ({ open, handleClose, deleteForm }) => {

  const { enqueueSnackbar } = useSnackbar()
  const [processing, setProcessing] = useState(false)
  const { customerApplicationForms, setCustomerApplicationForms, paymentInformationForms, setPaymentInformationForms, activeForm, setActiveForm } = useCustomerStore()

  const handleDeleteForm = () => {
    if (deleteForm) {
      setProcessing(true)
      try {

        const removeForm = async () => {
          const { data } = await API.delete(`/customer-trade-application-form/${deleteForm.id}`)
          if (data) {
            const newForms = (deleteForm.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ? customerApplicationForms : paymentInformationForms).filter(form => form?.id !== deleteForm.id)
            if (newForms.length && activeForm?.id === deleteForm.id) {
              deleteForm.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                setCustomerApplicationForms([...newForms]) :
                setPaymentInformationForms([...newForms])
              setActiveForm(newForms[0])
            } else {
              deleteForm.type === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                setCustomerApplicationForms([null]) :
                setPaymentInformationForms([null])
              setActiveForm(null)
            }
            const contentOption = snackBarStyle({
              text: `Form deleted.`,
              variant: "success",
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            setProcessing(false)
            handleClose()
          }
        }
        removeForm()
      } catch (error) {
        console.error(error)
        const contentOption = snackBarStyle({
          text: `Delete form failed.`,
          variant: "error",
        });
        enqueueSnackbar(contentOption.message, contentOption.options)
        setProcessing(false)
        handleClose()
      }
    } else {
      if (activeForm === null) {
        setActiveForm(customerApplicationForms[0])
      }
      setCustomerApplicationForms([...customerApplicationForms.filter(form => form !== null)])
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
        Delete Form
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 2, md: 5 } }}>
        <Typography color='error'>Are you sure you want delete the form?</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <Button disabled={processing} fullWidth sx={{ textTransform: 'none' }} variant='outlined' onClick={handleClose}>Cancel</Button>
        <LoadingButton
          fullWidth
          variant='outlined'
          onClick={handleDeleteForm}
          loading={processing}
          sx={{ textTransform: 'none' }}
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteFormDialog