import { AlertColor } from '@mui/material/Alert';
import { DOC_STATUS } from 'enums/invoice.enums';
import create from 'zustand';

interface InvoiceSummary {
  allInvoices: any;
  docStatus: string;
  bulkSelected: string[];
  singleSelected: string;
  decisionType: string;
  reason: string;
  personRequestedName: string;
  personRequestedEmail: string;
  processing: boolean;
  chosenDepartment: string;
  showSnackbar: boolean;
  snackbarSeverity: AlertColor | undefined;
  snackbarMessage: string;
  updateInvoice: (payload: any) => void;
  resetApproval: () => void;
}

export const useInvoiceStore = create<InvoiceSummary>((set: any) => ({
  allInvoices: [],
  docStatus: DOC_STATUS.WAITING_APPROVAL,
  bulkSelected: [],
  singleSelected: '',
  decisionType: '',
  reason: '',
  personRequestedName: '',
  personRequestedEmail: '',
  processing: false,
  chosenDepartment: '',
  showSnackbar: false,
  snackbarSeverity: undefined,
  snackbarMessage: '',
  updateInvoice: ({ ...payload }) =>
    set((state: any) => ({ ...state, ...payload })),
  resetApproval: () =>
    set((state: any) => ({
      ...state,
      bulkSelected: [],
      singleSelected: '',
      decisionType: '',
      reason: '',
      personRequestedName: '',
      personRequestedEmail: '',
      processing: false,
    })),
}));
