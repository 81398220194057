export enum DW_INVOICE_FIELDS {
  DWDOCID = 'Document ID',
  DWSECTIONCOUNT = 'Section count',
  DWSTOREDATETIME = 'Stored on',
  DWMODDATETIME = 'Modified on',
  DWEXTENSION = 'File type',
  DWMODUSER = 'Modified by',
  DWSTOREUSER = 'Stored by',
  DWDOCSIZE = 'Document size',
  DWPAGECOUNT = 'Section count',
  DWDISK = 'Disk number',
  DWLASTACCESSUSER = 'Last accessed by',
  DWLASTACCESSDATETIME = 'Disk number',
  DWDISKNO = 'File type',
  DOCUMENT_TYPE = 'Document Type',
  SUPPLIER_ABN = 'Supplier ABN',
  SUPPLIER_NAME = 'Supplier Name',
  SUPPLIER_CODE = 'Supplier Code',
  INVOICE_NUMBER = 'Invoice Number',
  PO_NUMBER = 'PO Number',
  SUBTOTAL = 'Subtotal',
  GST = 'GST',
  FREIGHT = 'Freight',
  TOTAL = 'Total',
  STATUS = 'Status',
  GRN_STATUS = 'GRN_STATUS',
  COMPARISON_STATUS = 'Comparison Status',
  WORKFLOW_STATUS = 'Workflow Status',
  WORKFLOW_NAME = 'Workflow Name',
  DUE_DATE = 'Due Date',
  DATE = 'Date',
  SUPPLIER_EMAIL_ADDRESS = 'Supplier Email Address',
  REQUESTOR = 'Requestor',
  COST_CENTER_APPROVER = 'Cost Center Approver',
  COST_CENTER_GROUP = 'Cost Center Group',
  COST_CENTER_AMOUNT = 'Cost Center Amount',
  COST_CENTER_NUMBER = 'Cost Center Number',
  LOOP_STATUS = 'Loop Status',
  LOOPS = 'Loops',
  GL_CODES_ROW_COUNT = 'GL Codes Row Count',
  INVOICE_CREATION_IN_ATTACHE_D = 'Invoice Creation in Attache Date',
  VALIDATION = 'Validation',
  EMAIL = 'Email',
  RECEIPT_NUMBER = 'Receipt Number',
  QUICKMATCH = 'QuickMatch',
  APPROVAL_LIMIT = 'Approval Limit',
  DEPARTMENT = 'Department',
  EXPENSE_APPROVER = 'Expense Approver',
  EXPENSE_GL_CODE = 'Expense GL Code',
  REJECTION_REASON = 'Rejection Reason',
  MANUAL_DESCRIPTION = 'Manual Description',
  PO_TOTAL = 'PO Total',
  PO_DOC_ID = 'PO Doc ID',
}

export enum DOCUMENT_TYPE_QUERY_ENUM {
  INVOICE = 'invoice',
  INVOICE_DOCTOR = 'Invoice - Doctor',
}

export enum YES_OR_NO {
  YES = 'Yes',
  NO = 'No',
}

export enum EMAIL_CATEGORY {
  REQUEST_FEATURE = 'Request a feature',
  REQUEST_UPGRADE = 'Request an upgrade',
  REPORT_ISSUE = 'Report an issue',
}
