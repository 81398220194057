import {
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Documents } from 'interfaces/document.interfaces';
import React, { FC, PropsWithChildren, ReactNode, useState } from 'react';

export interface DocumentsTree {
  name: string | null;
  count: number;
  total: number;
  data: {
    name: string | null;
    count: number;
    total: number;
    data: Array<Documents | { name: string; count: number; total: number }>;
  }[];
}

interface Column {
  key: string;
  label?: string;
  cell?: (row: any, rowIndex: number, self: Array<any>) => ReactNode;
}

const renderCell = (
  row: any,
  rowIndex: number,
  self: Array<any>,
  column: Column
) => {
  if (column.cell) {
    if (typeof column.cell) {
      return column.cell(row, rowIndex, self);
    }
  }
  if (row[column.key]) {
    return row[column.key];
  }
};

const TreeTableRows: FC<
  PropsWithChildren<{
    data: Array<any>;
    childKey: string;
    columns: Array<Column>;
    deep?: number;
  }>
> = ({ data = [], childKey = 'data', columns = [], deep = 0 }) => {
  const [openedIndexes, setOpenedIndexes] = useState<Array<number>>([]);

  const handleToggle = (value: any, valueIndex: number) => {
    const matchIndex = openedIndexes.findIndex((i) => i === valueIndex);
    if (matchIndex < 0) {
      setOpenedIndexes([...openedIndexes, valueIndex]);
    } else {
      setOpenedIndexes([
        ...openedIndexes.slice(0, matchIndex),
        ...openedIndexes.slice(matchIndex + 1),
      ]);
    }
  };

  return (
    <>
      {data.map((row: any, rowIndex: number, self) => {
        const isChildren = Array.isArray(row?.[childKey]);

        const isOpen = openedIndexes.some((i) => i === rowIndex);

        return (
          <React.Fragment key={rowIndex}>
            <TableRow
              onClick={() => handleToggle(row, rowIndex)}
              sx={{ cursor: 'pointer' }}
            >
              {columns.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  sx={columnIndex ? {} : { paddingTop: 0, paddingBottom: 0 }}
                >
                  {!columnIndex ? (
                    <Box
                      sx={{
                        paddingLeft: isChildren ? deep * 2 : (deep + 1) * 2,
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      {isChildren ? (
                        <IconButton size='small'>
                          {isOpen ? (
                            <IndeterminateCheckBoxOutlined />
                          ) : (
                            <AddBoxOutlined />
                          )}
                        </IconButton>
                      ) : null}
                      {renderCell(row, rowIndex, self, column)}
                    </Box>
                  ) : (
                    renderCell(row, rowIndex, self, column)
                  )}
                </TableCell>
              ))}
            </TableRow>
            {isChildren && isOpen ? (
              <TreeTableRows
                data={row?.[childKey]}
                childKey={childKey}
                columns={columns}
                deep={deep + 1}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  );
};

const TreeTable: FC<
  PropsWithChildren<{
    data: Array<any>;
    childKey: string;
    columns: Array<Column>;
  }>
> = ({ data = [], childKey = 'data', columns = [] }) => {
  return (
    <TableContainer component={Paper} sx={{ height: '100%' }}>
      <Table sx={{ width: '100%' }} stickyHeader aria-label='tree table'>
        <TableHead>
          <TableRow>
            {columns.map((column, columnIndex, self) => (
              <TableCell key={columnIndex} component='th' scope='row'>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TreeTableRows data={data} childKey={childKey} columns={columns} />
          {/* <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell
              component='th'
              scope='row'
              style={{ fontWeight: 'bold' }}
            >
              Total
            </TableCell>
            <TableCell align='right'>
              {formatCurrencyNumber(
                props?.data.reduce(
                  (n: any, { d0_d7_total }: any) => n + Number(d0_d7_total),
                  0
                )
              )}
            </TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'>
              {formatCurrencyNumber(
                props?.data.reduce(
                  (n: any, { d20_plus_total }: any) =>
                    n + Number(d20_plus_total),
                  0
                )
              )}
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TreeTable;
