import React from "react";

import { DecisionTypeEnum } from "enums/invoice.enums";
import { InvoiceSummary } from "interfaces/invoice.interface";
import { useInvoiceStore } from "state/invoice.store";
import Approve from "./Approve";
import BulkApprove from "./BulkApprove";
import Reject from "./Reject";
import RequestInfo from "./RequestInfo";

export interface ActionsDialogContentProps {
  invoices: InvoiceSummary[];
}

const ActionsDialogContent: React.FC<ActionsDialogContentProps> = ({
  invoices,
}) => {
  const { singleSelected, bulkSelected, decisionType } = useInvoiceStore();

  if (decisionType === DecisionTypeEnum.APPROVED && singleSelected !== "") {
    return <Approve invoices={invoices} />;
  }
  if (decisionType === DecisionTypeEnum.APPROVED && bulkSelected.length > 0) {
    return <BulkApprove invoices={invoices} />;
  }
  if (decisionType === DecisionTypeEnum.REJECTED && singleSelected !== "") {
    return <Reject />;
  }
  if (
    decisionType === DecisionTypeEnum.REQUESTED_INFO &&
    singleSelected !== ""
  ) {
    return <RequestInfo />;
  }
  return null;
};

export default ActionsDialogContent;
