import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';

import { useFormStore } from 'state/form.store'
import { PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT } from "components/forms/form-layouts/payment-information-layouts/payment-information-applicant-details.layout";
import FormControlItem, { FormControlItemLayout } from "components/forms/form-components/FormControlItem";
import { APPLICANT_EMAIL_LAYOUT } from "components/forms/form-layouts/customer-trade-application-layouts/applicant-details.layout";


const PaymentInformationApplicantDetailsForm = () => {

    const { formData, validating, updateForm } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', pb: 1 }}>
                <Typography variant="h6" sx={{ my: 0 }}>Request and Authority to debit</Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    Please enter your business details.
                </Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    Kindly be informed that this form will be used to update your payment details.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT.map((item: FormControlItemLayout) => {
                    return (
                        <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet}>
                            <FormControlItem
                                data={formData}
                                onChange={onChange}
                                layout={item}
                                submitValidating={validating}
                            />
                        </Grid>
                    )
                })}
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 500, py: 1 }}>
                        To ensure a smoother process and allow you to save your progress,
                        we kindly request that you provide your email address at the beginning
                        of the form.
                    </Typography>
                    <FormControlItem
                        data={formData}
                        onChange={onChange}
                        layout={APPLICANT_EMAIL_LAYOUT}
                        submitValidating={validating}
                    />
                </Grid>
            </Grid>
            <Box sx={{ pb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PrivacyTipOutlinedIcon color="primary" />
                    <Typography color="primary" variant="h6">Privacy Notice</Typography>
                </Box>
                <Typography variant="body2" sx={{ fontWeight: 500, pb: 1 }}>
                    Important: By saving the form, you acknowledge that sharing data incorrectly is your responsibility,
                    and you must obtain proper consent before providing any addresses in this SmartForm.
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Additionally, only forward the SmartForm URL to individuals with explicit consent.
                </Typography>
            </Box>
        </>
    )
}

export default PaymentInformationApplicantDetailsForm