import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';

import { ZoomTransition } from 'components/common/Transitions';
import { useUserStore } from 'state/user.store';
import API from 'api';
import CustomTextArea from 'components/common/CustomTextArea';

const HelpMenuDialog = ({ category, handleClose }: any) => {
  const [description, setDescription] = useState('');
  const [processing, setProcessing] = useState(false);

  const { logout } = useUserStore();

  const handleExitDialog = () => {
    setDescription('');
    handleClose();
  };

  const handleSubmit = async () => {
    setProcessing(true);
    try {
      const res = await API.post('/jet-mail/support', { category, description });

      if (res.status === 201) {
        setProcessing(false);
        handleExitDialog();
      }
    } catch (err) {
      setProcessing(false);
      handleExitDialog();
      const error = (err as any).response.data.message;
      if (error === 'Unauthorized') {
        logout();
      }
    }
  };


  return (
    <Dialog
      open={!!category}
      onClose={handleExitDialog}
      TransitionComponent={ZoomTransition}
      fullWidth
      BackdropProps={{
        style: {
          backgroundColor: '#536DFE',
          opacity: 0.4,
        },
      }}
      sx={{ '& div.MuiPaper-root': { borderRadius: 2 } }}
      PaperProps={{ elevation: 6 }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            letterSpacing: '0.15px',
            lineHeight: '150%',
          }}
        >
          {category}
        </Typography>

        {!processing && (
          <IconButton
            onClick={handleExitDialog}
            sx={{
              position: 'absolute',
              p: 0,
              top: 15,
              right: 20,
              color: 'black',
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        )}
      </DialogTitle>

      {processing ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            py: 3,
          }}
        >
          <CircularProgress />
          <Typography variant='h6'>Processing</Typography>
        </Box>
      ) : (
        <form>
          <DialogContent sx={{ pt: 0.5 }}>
            <CustomTextArea
              onChange={(e) => setDescription(e.target.value)}
              placeholder='Please type your request'
              required
            />
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3, justifyContent: 'center' }}>
            <Button
              variant='contained'
              onClick={handleSubmit}
              sx={{
                borderRadius: 10,
                width: '75%',
                textTransform: 'capitalize',
                py: 1.2,
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default HelpMenuDialog;
