// import NumberFormat from 'react-number-format';
import React from 'react';
import { IMaskInput } from 'react-imask';
import {
  InputAttributes,
  NumberFormatBaseProps,
  NumericFormat,
} from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask?: string;
}
export const CurrencyFormatCustom = React.forwardRef<
  NumberFormatBaseProps<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: any }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      valueIsNumericString
      prefix='$'
    />
  );
});
export const NumberFormatCustom = React.forwardRef<
  NumberFormatBaseProps<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: any }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      valueIsNumericString
    />
  );
});

export const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={props.mask}
        definitions={{ '#': /[0-9]/, '$': /[ampAMP]/ }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
