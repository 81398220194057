import { FORM_CONTROL_TYPE } from "enums/form.enums"
import { FormControlItemLayout } from "../form-components/FormControlItem"
import { TRAVEL_EXPENSE_SUBCATEGORY } from "../employee-forms/entertainment/SelectList"

export const TRAVEL_COMMON_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'destination',
        label: 'Destination',
        required: true,
        fullWidth: true,
        variant: 'outlined',
        item: true
    },
    {
        name: 'duration',
        label: 'Duration (days)',
        required: true,
        fullWidth: true,
        variant: 'outlined',
        type: FORM_CONTROL_TYPE.NUMBER,
        item: true
    },
    {
        name: 'exchangeRate',
        label: 'Exchange rate',
        required: true,
        fullWidth: true,
        variant: 'outlined',
        type: FORM_CONTROL_TYPE.NUMBER,
        item: true
    },
]

export const TRAVEL_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'from',
        label: 'From',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true
    },
    {
        name: 'to',
        label: 'To',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true
    },
    {
        name: 'category',
        label: 'Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: [{ value: 'Travel', label: 'Travel' }],
        item: true
    },
    {
        name: 'subCategory',
        label: 'Sub Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: TRAVEL_EXPENSE_SUBCATEGORY,
        item: true
    },
    {
        name: 'branch',
        label: 'Branch',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        item: true
    },
    {
        name: 'comment',
        label: 'Comments',
        required: true,
        variant: 'standard',
        fullWidth: true,
        item: true
    },
    {
        name: 'amountInclGst',
        label: '$ Amt Incl GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true
    }
]

export const TRAVEL_ENTERTAINMENT_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: 'details',
        label: 'Details',
        required: true,
        fullWidth: true,
        variant: 'standard',
        item: true
    },
    {
        name: 'from',
        label: 'From',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true
    },
    {
        name: 'to',
        label: 'To',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.DATE,
        item: true
    },
    {
        name: 'category',
        label: 'Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: [{ value: 'Travel', label: 'Travel' }],
        item: true
    },
    {
        name: 'subCategory',
        label: 'Sub Category',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: TRAVEL_EXPENSE_SUBCATEGORY,
        item: true
    },
    {
        name: 'branch',
        label: 'Branch',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.SELECT,
        item: true
    },
    {
        name: 'amountInclGst',
        label: '$ Amt Incl GST',
        required: true,
        fullWidth: true,
        variant: 'standard',
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true
    }
]