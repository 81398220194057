import { Toaster } from 'react-hot-toast';
import LogRocket from 'logrocket';
import jwt_decode from 'jwt-decode';
import { toast } from "react-hot-toast";

import { ThemeProvider } from '@mui/material/styles'
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { useTheme } from 'hooks/useTheme'
import AppRoutes from 'routes/AppRoutes'
import { SnackbarProvider } from 'notistack'
import { useEffect } from "react";
import { useUserStore } from "state/user.store";
import { IS_JFC, IS_PORTAL_COREFORM_CO, IS_PORTAL_HEROKU } from "constants/global";
import { useLocation } from "react-router";

const App = () => {
  const theme = useTheme()
  const { isAuth, logout } = useUserStore()
  const location = useLocation()

  const checkTokenExpiry = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: any = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken) {
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          console.log("Token is expired!")
          logout()
          toast.error('Session token expired. Please login again.', { duration: 6000 })
        }
      }
    }
  };

  useEffect(() => {
    checkTokenExpiry()
  }, [location.pathname])

  useEffect(() => {
    if ((IS_PORTAL_COREFORM_CO || IS_PORTAL_HEROKU) && !location.pathname.includes('customer') && isAuth?.username) {
      LogRocket.init('qlhjwl/cowo-portal-prod');
      LogRocket.identify(isAuth.username ?? '', {
        name: `${isAuth?.firstName ?? ''} ${isAuth?.lastName ?? ''}`,
        email: isAuth?.username ?? '',
        organisation: IS_JFC ? "JFC" : "ESPH"
      });
    } else {
      LogRocket.init('qlhjwl/cowo-portal-prod');
    }
  }, [isAuth])

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4}>
          <AppRoutes />
          <Toaster />
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
