import { DecisionTypeEnum } from 'enums/invoice.enums';
import { DOCUMENT_SYNC_STATUS } from 'interfaces/document-status.enum';
import { ICsvData, Invoice, InvoiceSummary } from 'interfaces/invoice.interface';
import { ymd2dmy } from './common.utils';

export const MAX_UPDATE_INVOICE_COUNT = 5;

// create summary for invoices table
export const createInvoiceSummary = (invoice: Invoice): InvoiceSummary => {
  const {
    DWDOCID,
    INVOICE_NUMBER,
    date,
    DUE_DATE,
    SUPPLIER_NAME,
    TOTAL,
    STATUS,
    WORKFLOW_STATUS,
    PO_NUMBER,
    PO_TOTAL,
    PO_DOC_ID,
    COMMENT_TO_CFO_,
    COMPARISON_STATUS,
    COMMENTS
  } = invoice;

  return {
    invoiceNumber: INVOICE_NUMBER,
    invoiceDate: date && ymd2dmy(date),
    dueDate: DUE_DATE && ymd2dmy(DUE_DATE),
    vendorName: SUPPLIER_NAME,
    totalAmount: `$${parseFloat(TOTAL).toLocaleString('en-US')}`,
    status: STATUS,
    docuwareId: DWDOCID,
    workflowStatus: WORKFLOW_STATUS,
    poNumber: !PO_NUMBER ? '-' : PO_NUMBER,
    poTotal: `${
      PO_TOTAL === null || PO_TOTAL === undefined ? '-' : `$${parseFloat(PO_TOTAL).toLocaleString('en-US')}`
    }`,
    poDocId: PO_DOC_ID,
    commentToCFO: COMMENT_TO_CFO_,
    comparisonStatus: COMPARISON_STATUS,
    comments: COMMENTS
  };
};

const timestampToDate = (docuwareDate: string) => {
  const timestampRegex = /\/Date\((\d+)\)\//;
  const matches = docuwareDate?.match(timestampRegex) ?? null;

  let timestamp: number = 0;

  if (matches && matches?.length > 1) {
    timestamp = parseInt(matches[1], 10);
  } else {
    return null;
  }

  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

// create summary for csv
export const createCsvData = (data: ICsvData) => {
  return {
    ...data,
    DATE: timestampToDate(data.DUE_DATE),
    DUE_DATE: timestampToDate(data.DUE_DATE),
  };
};

// approve/reject/requestInfo create request body
export const createRequestBody = (
  action: string,
  ids: string[] | string,
  reason: string,
  personRequestedName?: string,
  personRequestedEmail?: string
) => {
  if (Array.isArray(ids)) {
    return ids.map((id: string) => {
      return {
        action: DecisionTypeEnum.APPROVED,
        id: id.toString(),
        reason: '',
      };
    });
  }
  return [
    {
      action,
      id: ids.toString(),
      reason,
      personRequestedName,
      personRequestedEmail,
      SYNC_STATUS: DOCUMENT_SYNC_STATUS.NOT_SYNCED,
    },
  ];
};

export const filterTableData: any = (rows: any, filter: any) => {
  const filteredData = rows.filter(
    (item: any) =>
      (filter.invoiceNumber === undefined || filter.invoiceNumber.includes(item.invoiceNumber)) &&
      (filter.invoiceDate === undefined || filter.invoiceDate.includes(item.invoiceDate)) &&
      (filter.dueDate === undefined || filter.dueDate.includes(item.dueDate)) &&
      (filter.vendorName === undefined || filter.vendorName.includes(item.vendorName)) &&
      (filter.totalAmount === undefined || filter.totalAmount.includes(item.totalAmount))
  );
  return filteredData;
};
