import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import TableFilter from 'components/common/TableFilter';
import { User } from 'interfaces/user.interface';
import { Column } from 'pages/common/users';
import { FC, useMemo } from 'react'
import { Order } from 'utils/table.utils';

interface UsersTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof User) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    numSelected: number;
    rowCount: number;
    rows: User[];
    columns: readonly Column[];
    onChangeFilter: (category: string, newValue: string[]) => void;
    filterCriteria: any;
}

const UsersTableHead: FC<UsersTableHeadProps> = ({
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    rows,
    columns,
    onChangeFilter,
    filterCriteria
}) => {

    const createSortHandler = (property: keyof User) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const options = useMemo(() => {
        let obj: any = {}
        columns?.map((column: any) => {
            if (column.key === 'firstName') {
                return obj[column.key] = Array.from(new Set(rows?.map((row: any) => `${row[column.key]} ${row['lastName']}`))).filter((val => val !== null))
            }
            return obj[column.key] = Array.from(new Set(rows?.map((row: any) => row[column.key]))).filter((val => val !== null))
        })
        return obj
    }, [rows])

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        size='small'
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all users',
                        }}
                    />
                </TableCell>
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.key}
                        align={headCell.align || 'center'}
                        sortDirection={orderBy === headCell.key ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.key}
                            direction={orderBy === headCell.key ? order : 'asc'}
                            onClick={createSortHandler(headCell.key)}
                        >
                            {headCell.label}
                            {orderBy === headCell.key ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                        {headCell.filter ? <TableFilter category={headCell.key} options={options[headCell.key]} onChangeFilter={onChangeFilter} filterCriteria={filterCriteria} label={headCell.label} /> : null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default UsersTableHead