import { FC } from 'react'
import { useNavigate } from "react-router-dom";

import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import DashboardIcon from '@mui/icons-material/Dashboard'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { grey } from "@mui/material/colors";

import { IS_JFC } from "constants/global";
import { OrganisationCountry } from "enums/organisation.enums";
import { useUserStore } from "state/user.store";

interface MobileFormsMenuProps {
    open: boolean;
    handleClose: () => void;
}

const JFC_AU_ROUTES = [
    { label: 'General expense', icon: <ReceiptIcon />, route: '/general-expense' },
    { label: 'Taxi/Parking', icon: <LocalTaxiIcon />, route: '/taxi-parking' },
    { label: 'Entertainment', icon: <MovieFilterIcon />, route: '/entertainment' },
    // { label: 'Travel', icon: <FlightTakeoffIcon />, route: '/travel' },
]

const JFC_NZ_ROUTES = [
    { label: 'General expense', icon: <ReceiptIcon />, route: '/general-expense' },
    { label: 'Taxi/Parking', icon: <LocalTaxiIcon />, route: '/taxi-parking' },
    { label: 'Entertainment', icon: <MovieFilterIcon />, route: '/entertainment' },
]

const ESPH_ROUTES = [
    { label: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
    { label: 'My Invoices', icon: <ReceiptIcon />, route: '/invoices' },
    { label: 'Purchase Orders', icon: <DescriptionIcon />, route: '/purchase-order' },
    { label: 'Doctors Invoices', icon: <FormatListNumberedIcon />, route: '/doctors-invoices' },
]

const MobileBottomMenu: FC<MobileFormsMenuProps> = ({ open, handleClose }) => {

    const { isAuth } = useUserStore()
    const navigate = useNavigate()

    const handleNavigate = (route: string) => {
        navigate(route)
        handleClose()
    }

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
        >
            {IS_JFC ?
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate('/purchase-request')}>
                                <ListItemIcon>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText primary='Purchase request' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    <Typography variant="caption" sx={{ fontWeight: 800, color: grey[500], pl: 1, pt: 1 }}>Employee Claims</Typography>
                    <List>
                        {(isAuth?.country === OrganisationCountry.Australia ? [...JFC_AU_ROUTES] : [...JFC_NZ_ROUTES]).map((route, index) => (
                            <ListItem key={route.label} disablePadding>
                                <ListItemButton onClick={() => handleNavigate(route.route)}>
                                    <ListItemIcon>
                                        {route.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={route.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </> :
                <>
                    <List>
                        {ESPH_ROUTES.map((route, index) => (
                            <ListItem key={route.label} disablePadding>
                                <ListItemButton onClick={() => handleNavigate(route.route)}>
                                    <ListItemIcon>
                                        {route.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={route.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>}
        </SwipeableDrawer>
    )
}

export default MobileBottomMenu