import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import API from 'api';
import Loader from 'components/common/Loader';
import { DocumentSummary } from 'interfaces/document.summary.interfaces';
import { useEffect, useState } from 'react';
import { useUserStore } from 'state/user.store';

interface Column {
  key: keyof DocumentSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: 'left' | 'right' | 'center';
  minWidth?: number;
  width?: number;
  FieldName?: string;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  {
    key: 'POItem',
    numeric: false,
    disablePadding: false,
    label: 'PO Item',
    align: 'left',
    FieldName: 'COMPA_PO_ITEM',
  },
  {
    key: 'InvItem',
    numeric: false,
    disablePadding: true,
    label: 'Inv Item',
    align: 'center',
    FieldName: 'COMPA_INV_ITEM',
  },
  {
    key: 'POQty',
    numeric: false,
    disablePadding: true,
    label: 'PO Qty',
    align: 'left',
    FieldName: 'COMPA_PO_QTY',
  },
  {
    key: 'InvQty',
    numeric: true,
    disablePadding: true,
    label: 'Inv Qty',
    align: 'right',
    FieldName: 'COMPA_INV_QTY',
  },
  {
    key: 'ReceiptQty',
    numeric: true,
    disablePadding: true,
    label: 'Receipt Qty',
    align: 'right',
    FieldName: 'COMPA_RECEIPT_QTY',
  },
  {
    key: 'POUnitPrice',
    numeric: false,
    disablePadding: false,
    label: 'PO Unit Price',
    align: 'left',
    FieldName: 'COMPA_PO_UNIT_PRICE',
  },
  {
    key: 'InvUnitPrice',
    numeric: false,
    disablePadding: true,
    label: 'Inv Unit Price',
    FieldName: 'COMPA_INV_UNIT_PRICE',
    align: 'center',
  },
  {
    key: 'Status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    align: 'center',
    FieldName: 'COMPA_STATUS',
  },
  {
    key: 'Message',
    numeric: false,
    disablePadding: true,
    label: 'Message',
    align: 'center',
    FieldName: 'COMPA_MESSAGE',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align='center'
            padding={'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const DocumentTable = ({ docId }: any) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [document, setDocument] = useState<any>();

  const { logout } = useUserStore();

  const getDocumentById = async () => {
    setLoading(true);
    try {
      const { data } = await API.get(`/invoices/${docId}`);
      await setDocument(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = (err as any).response.data.message;
      if (error === 'Unauthorized') {
        logout();
      }
    }
  };

  useEffect(() => {
    getDocumentById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  const comparisonTableData = document?.Fields?.find((d: any) => {
    return d.FieldName === 'COMPARISON_TABLE';
  });

  const receiptNumber = document?.Fields?.find((d: any) => {
    return d.FieldName === 'RECEIPT_NUMBER';
  });

  return (
    <Loader open={loading}>
      <Box>
        {comparisonTableData?.Item?.Row?.length > 0 ? (
          <Box>
            {' '}
            <Typography variant='h6' sx={{ fontWeight: 600 }} gutterBottom>
              Receipt Number : {receiptNumber?.Item ?? ''}
            </Typography>
            <Paper>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                  <EnhancedTableHead />
                  <TableBody>
                    {comparisonTableData?.Item?.Row?.map((row: any, i: any) => {
                      return (
                        <TableRow>
                          {columns.map((column: any, index: any) => {
                            const c = row?.ColumnValue?.find((col: any) => {
                              return col.FieldName === column?.FieldName;
                            });
                            if (column?.FieldName === 'COMPA_PO_ITEM') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (column?.FieldName === 'COMPA_INV_ITEM') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (
                              column?.FieldName === 'COMPA_RECEIPT_ITEM'
                            ) {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (column?.FieldName === 'COMPA_PO_QTY') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (column?.FieldName === 'COMPA_INV_QTY') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (
                              column?.FieldName === 'COMPA_RECEIPT_QTY'
                            ) {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (
                              column?.FieldName === 'COMPA_PO_UNIT_PRICE'
                            ) {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (
                              column?.FieldName === 'COMPA_INV_UNIT_PRICE'
                            ) {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (column?.FieldName === 'COMPA_STATUS') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else if (column?.FieldName === 'COMPA_MESSAGE') {
                              return (
                                <TableCell align='center'>
                                  {c?.Item ? c?.Item : '-'}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell align='center'>{'-'}</TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        ) : (
          <Typography align='center'>No Data Available</Typography>
        )}
      </Box>
    </Loader>
  );
};

export default DocumentTable;
