import { PaletteMode } from '@mui/material';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface Setting {
  mode?: PaletteMode;
  extend: boolean;
  toggleMode: () => void;
  toggleExtend: () => void;
}

export const useSettingStore = create(
  persist<Setting>(
    (set, get) => ({
      mode: 'light',
      extend: false,
      toggleMode: () => set((state: any) => (state.mode === 'light' ? { mode: 'dark' } : { mode: 'light' })),
      toggleExtend: () => set((state: any) => ({ ...state, extend: !state.extend })),
    }),
    {
      name: 'setting',
      getStorage: () => localStorage,
    }
  )
);
