import { FC } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface FormHeaderProps {
    documentDocuwareId: number;
    formType: string;
    country: string;
}

const FormHeader: FC<FormHeaderProps> = ({ documentDocuwareId, formType, country }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, pb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <img
                        src={`/static/img/JFC-LOGO-${country}.png`}
                        alt="JFC"
                        style={{ maxWidth: 300 }}
                    />
                </Box>
                <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Claim Number
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {documentDocuwareId ?? ''}
                    </Typography>
                </Box>
            </Box>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>{formType}</Typography>
        </Box>
    )
}

export default FormHeader