import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import coreform from "../../img/coreform.png";
import PrintPoTable from "./printPotable";

export interface printPoOrdersProps {
  name?: string;
}

const PrintPoOrder = React.forwardRef(({ name }: printPoOrdersProps, ref) => {
  return (
    <Box ref={ref} sx={{ p: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 50,
            width: 200,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="coreform logo"
          src={coreform}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "12px",
        }}
      >
        <Box>
          <Typography variant="body2" gutterBottom>
            CoreForm
          </Typography>
          <Typography variant="body2" gutterBottom>
            info@corefrom.com
          </Typography>
          <Typography variant="body2" gutterBottom>
            Oxford Street 282, Sydeny, 2011
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ textTransform: "uppercase", textAlign: "center" }}
          >
            PURCHASE ordER
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            textAlign: "center",
            gap: 5,
          }}
        >
          <Box>
            <Typography variant="body2" gutterBottom>
              PO#
            </Typography>
            <Typography variant="body2" gutterBottom>
              GMS001-7376
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" gutterBottom>
              Date Created
            </Typography>
            <Typography variant="body2" gutterBottom>
              29/07/2021
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "225px" }}>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: 600 }}>
          Baxter Healthcare
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: "15px" }}>
          9-29 Some Street
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: "15px" }}>
          BRUNSWICK
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: "15px" }}>
          vlc,3056
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: "15px" }}>
          P: 1300789646
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ lineHeight: "15px" }}>
          info@baxter.com
        </Typography>
      </Box>

      <Box>
        <PrintPoTable printPo={[]} />
      </Box>
    </Box>
  );
});

export default PrintPoOrder;
