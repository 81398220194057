export enum DEPT_MANAGERS {
  CEO = 'CEO',
  CFO = 'CFO',
  FINANCIAL_CONTROLLER = 'Financial Controller',
  CATERING_MANAGER = 'Catering Manager',
  RECOVERY_MANAGER = 'Recovery Manager',
  STORE_MANAGER = 'Store Manager',
  ADMINISTRATION_MANAGER = 'Administration Manager',
  CSD_MANAGER = 'CSD Manager',
  WARD_MANAGER = 'Ward Manager',
  THEATRE_BOOKING_MANAGER = 'Theatre Booking Manager',
  THEATRE_MANAGER = 'Theatre Manager',
  HEAD_OF_PURCHASE = 'Head of Purchase',
  DCS = 'DCS',
}

export const EXECUTIVES: string[] = [DEPT_MANAGERS.CEO, DEPT_MANAGERS.CFO];

export const ACCOUNTING: string[] = [DEPT_MANAGERS.FINANCIAL_CONTROLLER];

export const MANAGERS: string[] = [
  DEPT_MANAGERS.CATERING_MANAGER,
  DEPT_MANAGERS.RECOVERY_MANAGER,
  DEPT_MANAGERS.STORE_MANAGER,
  DEPT_MANAGERS.ADMINISTRATION_MANAGER,
  DEPT_MANAGERS.CSD_MANAGER,
  DEPT_MANAGERS.WARD_MANAGER,
  DEPT_MANAGERS.THEATRE_BOOKING_MANAGER,
  DEPT_MANAGERS.THEATRE_MANAGER,
  DEPT_MANAGERS.HEAD_OF_PURCHASE,
  DEPT_MANAGERS.DCS,
];
