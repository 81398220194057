import { FC, useEffect, useRef } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import AppLogo from "components/common/AppLogo";
import SignatureImage from "components/forms/form-components/SignatureImage";
import { AOCStatements } from "components/forms/customer-forms/form-sections/AcceptanceOfConditions";
import { FORM_CONTROL_TYPE } from "enums/form.enums";

const UploadedFileInfo = ({
  uploadedFiles,
  label,
  sublabel,
}: {
  uploadedFiles: string[];
  label: string;
  sublabel?: string;
}) => {
  return (
    <Box sx={{ py: 1 }}>
      <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
      {sublabel ? (
        <Typography
          variant="caption"
          sx={{ fontStyle: "italic", fontWeight: 500 }}
        >
          {sublabel}
        </Typography>
      ) : null}
      <Box
        sx={{
          minHeight: 30,
          border: "1px dashed gray",
          borderRadius: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          justifyContent: "flex-start",
        }}
      >
        {uploadedFiles?.length ? (
          uploadedFiles.map((file, index) => {
            return (
              <Chip
                key={index}
                label={file.substring(11)}
                sx={{ width: "fit-content", borderRadius: 2 }}
              />
            );
          })
        ) : (
          <Typography>No file uploaded</Typography>
        )}
      </Box>
    </Box>
  );
};

interface CTAFormPageProps {
  items: any[];
  reorder: (page: number) => void;
  page: number;
  totalPages: number;
  paperView?: boolean;
  formNumber?: number;
  revisionNumber?: number;
  formType: string;
}

const CTAFormPage: FC<CTAFormPageProps> = ({
  items,
  reorder,
  page,
  totalPages,
  formNumber,
  revisionNumber,
  paperView = false,
  formType,
}) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current) {
      const hasOverflow = ref.current.scrollHeight > ref.current.clientHeight;
      if (hasOverflow) {
        reorder(page);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, reorder]);

  return (
    <Paper
      elevation={paperView ? 3 : 0}
      sx={{
        width: "21cm",
        height: "29.7cm",
        marginX: "auto",
        display: "flex",
        flexDirection: "column",
        p: 4,
        position: "relative",
        breakInside: "avoid",
        breakBefore: "always",
        marginY: paperView ? 3 : null,
        boxShadow: paperView ? null : "none",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <AppLogo />
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>Form Number</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{formNumber}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {revisionNumber && revisionNumber > 0 ? "Resubmitted" : ""}
            </Typography>
          </Box>
        </Box>
        <Typography variant="h5" sx={{ fontWeight: "bold", py: 2 }}>
          {formType}
        </Typography>
      </Box>

      <Box
        ref={ref}
        sx={{
          height: "25cm",
          // border: '2px solid black'
        }}
      >
        {items?.map((item: any, index: number) => {
          if (item.type === "divider") {
            return (
              <Box sx={{ py: 2 }} key={index}>
                <Divider />
              </Box>
            );
          }
          if (item.type === "AOCStatements") {
            return (
              <Box key={index}>
                <AOCStatements />
              </Box>
            );
          }
          if (item.type === "text") {
            return (
              <Typography variant="caption" key={index}>
                {item.value}
              </Typography>
            );
          }
          if (item.type === FORM_CONTROL_TYPE.FILE_UPLOAD) {
            return (
              <UploadedFileInfo
                key={index}
                label={item.label}
                sublabel={item.sublabel ?? null}
                uploadedFiles={item.value}
              />
            );
          }
          if (item.type === FORM_CONTROL_TYPE.SIGNATURE) {
            return (
              <SignatureImage
                key={index}
                label={item.label}
                imageUrl={item.value}
              />
            );
          }
          if (item.type === FORM_CONTROL_TYPE.CHECKBOX) {
            return (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked={item.value ?? null} disabled />
                <Typography sx={{ fontWeight: 600 }}>{item.label}</Typography>
              </Box>
            );
          }
          if (item.type === FORM_CONTROL_TYPE.DATE) {
            return (
              <Typography key={index} sx={{ py: 0.5 }}>
                <strong>{item.label}: </strong>
                {item.value ? moment(item.value).format("DD/MM/YYYY") : ""}
              </Typography>
            );
          }
          if (item.type === "link") {
            return (
              <a key={index} href={item.value} target="__blank">
                {item.value}
              </a>
            );
          }
          if (item.type === "header") {
            return (
              <Typography key={index} variant="h6" sx={{ fontWeight: 700 }}>
                {item.value}
              </Typography>
            );
          }
          if (item.type === "Direct Debit Paragraph 1") {
            return (
              <Typography key={index} sx={{ textAlign: "justify" }}>
                "You" request and authorise JFC AUSTRALIA CO PTY LTD to arrange,
                through its own financial institution, a debit to your nominated
                account any amount JFC AUSTRALIA CO PTY LTD has deemed payable
                by you. This debit or charge will be made through the Bulk
                Electronic Clearing System (BECS) from your account held at the
                financial institution you have nominated below and will be
                subject to the terms and conditions of the Direct Debit Request
                Service Agreement.
              </Typography>
            );
          }
          if (item.type === "Direct Debit Paragraph 2") {
            return (
              <Typography key={index} sx={{ textAlign: "justify" }}>
                By signing and/or providing us with a valid instruction in
                respect to your Direct Debit Request, you have understood and
                agreed to the terms and conditions governing the debit
                arrangements between you and JFC AUSTRALIA CO PTY LTD as set out
                in this Request and in your Direct Debit Request Service
                Agreement.
              </Typography>
            );
          }
          return (
            <Typography key={index} sx={{ py: 0.5 }}>
              <strong>{item.label}: </strong>
              {item.value}
            </Typography>
          );
        })}
      </Box>

      <Typography
        sx={{ position: "absolute", right: 30, bottom: 30 }}
      >{`Page ${page} - ${totalPages}`}</Typography>
    </Paper>
  );
};

export default CTAFormPage;
