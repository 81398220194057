import { FC, useState } from 'react'
import moment from "moment";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete'
import { green } from "@mui/material/colors";

import { SubmitSummary } from "interfaces/submitform.interface";
import PreviewModal from "components/pdfPreview/PreviewModal";


interface MyFormsCardProps {
    row: SubmitSummary;
    handleDelete: (id: number, type: string) => void
}

const MyFormsCard: FC<MyFormsCardProps> = ({ row, handleDelete }) => {

    const [expanded, setExpanded] = useState<number[]>([])
    const [previewForm, setPreviewForm] = useState<{ type: string, id: number } | null>(null)


    const handleChange = (id: number) => {
        if (expanded.includes(id)) {
            setExpanded([...expanded.filter(exp => exp !== id)])
        } else {
            setExpanded([...expanded, id])
        }
    }



    return (
        <Accordion disableGutters square expanded={expanded.includes(row?.id)} onChange={() => handleChange(row.id)}>
            <AccordionSummary
                expandIcon={<ExpandCircleDownIcon color="primary" />}
                sx={{
                    display: "flex", flexDirection: "row-reverse",
                }}
            >
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ width: "100%", pl: 1, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {row.type}
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} color={green[700]}>
                                {row.paymentStatus === "Paid" ? row.paymentStatus : ''}
                            </Typography>
                        </Box>

                        {expanded.includes(row?.id) ?
                            null :
                            <>
                                <Typography variant="caption">
                                    <strong>Submitted date:</strong> {moment(row.submittedDate).format('DD/MM/YYYY')}
                                </Typography>
                                <Typography variant="caption">
                                    <strong>Total amount:</strong> {`$${row.totalAmount}`}
                                </Typography>
                            </>}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ pl: 4, display: "flex", flexDirection: "column" }}>
                    <Typography variant="body2">
                        <strong>Form Number:</strong> {row.formNumber}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Submitted date:</strong> {moment(row.submittedDate).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Total amount:</strong> {`$${row.totalAmount}`}
                    </Typography>
                </Box>
            </AccordionDetails>
            <AccordionActions sx={{
                pl: 6, display: "flex", alignItems: "center", justifyContent: "flex-start"
            }}>

                <Typography variant="body2">
                    <strong>Actions:</strong>
                </Typography>
                <IconButton
                    onClick={() => setPreviewForm({ id: row.id, type: row.type })}
                >
                    <PictureAsPdfIcon color="error" />
                </IconButton>
                <IconButton
                    disabled={row.paymentStatus !== 'Paid'}
                    onClick={() => handleDelete(row.id, row.type)}
                >
                    <DeleteIcon color={row.paymentStatus === 'Paid' ? "error" : "disabled"} />
                </IconButton>


            </AccordionActions>
            <PreviewModal
                open={!!previewForm}
                handleClose={() => setPreviewForm(null)}
                formId={previewForm?.id}
                formType={previewForm?.type}
            />
        </Accordion>
    )
}

export default MyFormsCard