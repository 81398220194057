import TextareaAutosize from '@mui/material/TextareaAutosize';
import React from 'react';

export interface CustomTextAreaProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  required?: boolean;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  onChange,
  minRows = 4,
  maxRows = 5,
  placeholder = 'Please type here',
  required = false,
}) => {
  return (
    <TextareaAutosize
      aria-label='empty textarea'
      placeholder={placeholder}
      minRows={minRows}
      maxRows={maxRows}
      style={{
        width: '100%',
        font: 'inherit',
        borderRadius: 5,
        borderColor: 'lightgrey',
        fontSize: 13,
      }}
      onChange={onChange}
      required={required}
      className='custom-textarea'
    />
  );
};

export default CustomTextArea;
