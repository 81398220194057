import { CreatePo, CreatePoSummary } from "interfaces/createpo.interface";
import { Orders, PurchaseOrderSummary } from "interfaces/order.interface";

// create summary for Orders table
export const createPurchaseOrderSummary = (order: Orders): PurchaseOrderSummary => {
  const {
    po_number,
    created_at,
    name,
    items,
    total_amount,
    status
  } = order;

  return {
    po_number: po_number,
    created_at: created_at,
    name: name,
    items: items,
    total_amount: total_amount,
    status: status,
  };
};


export const createPoOrderSummary = (po: CreatePo): CreatePoSummary => {
  const {
    itemCode,
    itemDescription,
    qtyOrder,
    unitMeasure,
    unitCost,
    extendedCost,
    discount,
    discountAmount,
    discountExtended,
    taxClass,
    taxAmount,
    netOfTax,
  } = po;

  return {
    itemCode: itemCode,
    itemDescription: itemDescription,
    qtyOrder: qtyOrder,
    unitMeasure: unitMeasure,
    unitCost: unitCost,
    extendedCost: extendedCost,
    discount: discount,
    discountAmount: discountAmount,
    discountExtended: discountExtended,
    taxClass: taxClass,
    taxAmount: taxAmount,
    netOfTax: netOfTax,
  };
};