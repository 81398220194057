export const PORTAL_COREFORM_CO = 'portal.coreform.co';
export const IS_PORTAL_COREFORM_CO =
  window.location.host.includes(PORTAL_COREFORM_CO);

export const PORTAL_HEROKU = 'approval-portal-fe.herokuapp.com';
export const IS_PORTAL_HEROKU = window.location.host.includes(PORTAL_HEROKU);

export const JFC = 'jfc.portal';
export const IS_JFC = window.location.host.includes(JFC);

export const ESPH = 'esph.portal';
export const IS_ESPH = window.location.host.includes(ESPH);

export const getOrganisation = () => {
  switch (true) {
    case window.location.host.includes(JFC):
      return 'JFC';
    case window.location.host.includes(ESPH):
      return 'ESPH';
    default:
      return 'ESPH';
  }
};
