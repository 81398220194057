import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PrintPoOrder from "components/orders/printPoOrder";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export const ViewPo = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //     ga('send', 'pageview');
  //   }, [location]);
  // console.log("locationid", state.poId);
  const componentRef: any = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const back = () => {
    navigate("/purchase-order");
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Purchase Orders
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            sx={{
              width: "35ch",
              "& .css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "15px",
              },
            }}
            label="Search"
            type="search"
            variant="outlined"
            margin="dense"
            // onChange={searchPurchaseOrder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
            justifyContent: "end",
            gap: 3,
            mt: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<PrintIcon />}
            onClick={handlePrint}
          >
            PRINT
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<DeleteIcon />}
            // onClick={createNewPo}
          >
            DELETE
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<ChevronLeftIcon />}
            onClick={back}
          >
            BACK
          </Button>
        </Box>

        <Box mt={2}>
          <Paper
            variant="elevation"
            elevation={5}
            sx={{ borderRadius: "20px" }}
          >
            <PrintPoOrder name={"test"} ref={componentRef} />
          </Paper>
        </Box>
      </Box>
    </>
  );
};
