import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { InvoiceSummary } from "interfaces/invoice.interface";

interface TableCellStatusProps {
  row: InvoiceSummary;
}

const TableCellStatus: React.FC<TableCellStatusProps> = ({ row }) => {
  const hasRequestedInfo = row.workflowStatus === 'INFO_REQUESTED';
  const isOnHold = row.workflowStatus === 'ON_HOLD_PORTAL';
  let status1 = row.status;
  let status2 = hasRequestedInfo ? 'Info requested' : 'On hold';
  let status4 = row.comparisonStatus === 'MISMATCH' ? 'Mismatch' : '';
  let status3 = row.commentToCFO ?? '';
  let status5 = row.comments;
  if (!!row.dueDate) {
    const dueDateArr = row.dueDate.split('/');
    // new Date("2/1/22") is equals to: Tue Feb 01 2022 00:00:00 GMT+0000 (GMT)
    const dueDate = new Date(
      `${dueDateArr[1]}/${dueDateArr[0]}/${dueDateArr[2]}`
    );
    const today = new Date();
    const isOverDue = dueDate < today;
    status1 = isOverDue ? 'Overdue' : 'On Time';
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: !!isOverDue ? '#FFB400' : '#4CAF50',
          }}
        >
          {isOverDue ? (
            <AccessTimeIcon fontSize="small" />
          ) : (
            <CheckCircleOutlineIcon fontSize="small" />
          )}
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {status1}
          </Typography>
        </Box>

        {(hasRequestedInfo || isOnHold) && (
          <Typography
            color="#64B6F7"
            variant="caption"
            sx={{ fontWeight: '500' }}
          >
            {status2}
          </Typography>
        )}

        {!!status4 && (
          <Typography
            color="error"
            variant="caption"
            sx={{ fontWeight: '500' }}
          >
            {status4}
          </Typography>
        )}
        {!!status3 && (
          <Typography
            color="#64B6F7"
            variant="caption"
            sx={{ fontWeight: '500' }}
          >
            {status3}
          </Typography>
        )}
        {!!status5 && (
          <Typography
            color="#64B6F7"
            variant="caption"
            sx={{ fontWeight: '500' }}
          >
            {status5}
          </Typography>
        )}
      </Box>
    );
  } else {
    return (
      <>
        <Typography color="primary" fontWeight={500}>
          {status1}
        </Typography>
        <Typography color="primary" fontWeight={500}>
          {status2}
        </Typography>
        <Typography color="primary" fontWeight={500}>
          {status3}
        </Typography>
        <Typography color="primary" fontWeight={500}>
          {status5}
        </Typography>
        <Typography color="primary" fontWeight={500}>
          {status4}
        </Typography>
      </>
    );
  }
};

export default TableCellStatus;
