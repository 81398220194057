export enum DecisionTypeEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED_INFO = 'REQUESTED_INFO',
  ON_HOLD = 'ON_HOLD',
}

export enum DOC_STATUS {
  WAITING_APPROVAL = 'WAITING_APPROVAL',
}

export enum WORKFLOW_STATUS {
  APPROVED_DECISION = 'APPROVED_DECISION',
  REJECTED_DECISION = 'REJECTED_DECISION',
}
