import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import API from "api";
import SuccessForgotPassword from "components/authentication/SuccessForgotPassword";
import { JFC } from "constants/global";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [errors, setErrors] = useState<string[] | null>(null);
  const [showforgetPassSuccess, setShowforgetPassSuccess] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isJfc, setIsJfc] = React.useState<boolean>(false);

  const handleOnChangeCredentials = (event: any) => {
    setErrors(null);
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const handleForgotPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    const { data } = await API.post("/forgot-password", {
      email: credentials.email,
      clientUrl: window.location.origin,
    });
    setLoading(false);
    if (data.status) setShowforgetPassSuccess(true);
  };

  React.useEffect(() => {
    window.location.host.includes(JFC) ? setIsJfc(true) : setIsJfc(true);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      {errors && (
        <Box sx={{ pb: 2 }}>
          {errors.map((error, index) => {
            return (
              <Box key={index}>
                <Typography color="error" variant="caption">
                  ⚠ {error}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}

      <form onSubmit={handleForgotPassword}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ py: 1 }}>
            {isJfc && (
              <>
                <Typography variant="body1" gutterBottom>
                  Please enter the email associated with your account and we'll
                  send an email with instructions to reset your password.
                </Typography>
              </>
            )}
            <Typography variant="body2">Email</Typography>

            <TextField
              fullWidth
              name="email"
              type="email"
              placeholder="Please type your email"
              value={credentials.email}
              onChange={handleOnChangeCredentials}
              variant="outlined"
              required
              sx={{ pt: 0.5 }}
              inputProps={{
                sx: {
                  fontSize: 14,
                  "&::placeholder": { opacity: 0.7 },
                },
              }}
            />
          </Box>

          <Link
            onClick={(event: any) => {
              event.preventDefault();
              navigate("/auth/signin");
            }}
            variant="caption"
            sx={{ cursor: "pointer", textDecoration: "none" }}
          >
            Back to log in
          </Link>
          <LoadingButton
            fullWidth
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ mt: 2, textTransform: "none" }}
            size="medium"
          >
            Reset Password
          </LoadingButton>
        </Box>
      </form>
      <SuccessForgotPassword
        open={showforgetPassSuccess}
        handleClose={() => setShowforgetPassSuccess(false)}
      />
    </Box>
  );
};

export default ForgotPassword;
