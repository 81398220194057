import { FC } from 'react';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { SxProps, Theme, useTheme } from "@mui/material";

import { FormStep } from "utils/form.utils";


const stepStyles = (index: number, completedSteps: number[], failedSteps: number[], activeStep: number, theme: Theme): SxProps => {
    if (activeStep === index) {
        return {
            '& .MuiStepLabel-root .MuiSvgIcon-root': {
                color: 'grey.600',
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':
            {
                color: 'grey.800',
            },
            '& .MuiStepLabel-root .MuiStepIcon-text': {
                fill: 'common.white',
            },
        }
    }
    if (completedSteps.includes(index)) {
        return {
            '& .MuiStepLabel-root .MuiSvgIcon-root': {
                color: 'primary.main',
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':
            {
                color: 'grey.600',
            },
            '& .MuiStepLabel-root .MuiStepIcon-text': {
                fill: 'common.white',
            },
        }
    }
    if (failedSteps.includes(index)) {
        return {
            '& .MuiStepLabel-root .MuiSvgIcon-root': {
                color: 'error.light',
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':
            {
                color: 'error.main', // Just text label (COMPLETED)
            },
            '& .MuiStepLabel-root .MuiStepIcon-text': {
                fill: 'common.white', // circle's number (ACTIVE)
            },
        }
    }
    return {
        '& .MuiStepLabel-root .MuiSvgIcon-root': {
            color: 'transparent',
            border: '1px solid #3f50b5',
            borderRadius: '50%',
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':
        {
            color: 'grey.600',
        },
        '& .MuiStepIcon-text': {
            fill: `${theme.palette.primary.main} !important`,
        },
    }

}

interface FormStepsProps {
    steps: FormStep[];
    completedSteps: number[];
    failedSteps: number[];
    activeStep: number;
    stepOneComplete: boolean;
}

const FormSteps: FC<FormStepsProps> =
    ({
        steps,
        completedSteps,
        failedSteps,
        activeStep,
        stepOneComplete
    }) => {

        const navigate = useNavigate()

        const theme = useTheme()

        return (
            <Box sx={{
                width: '100%', pb: 2,
                display: { xs: 'none', sm: 'block' }
            }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => {
                        return (
                            <Step
                                onClick={() => navigate(stepOneComplete ? step.route : steps[0].route)}
                                sx={{
                                    cursor: 'pointer',
                                    ...stepStyles(index, completedSteps, failedSteps, activeStep, theme)
                                }}
                                key={step.label}
                                completed={false}
                                last
                            >
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        );
    }

export default FormSteps