import { useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Header from 'components/header/Header';
import Sidebar from 'components/sidebar/Sidebar';

import { useUserStore } from 'state/user.store';
import BasicSnackbar from 'components/common/BasicSnackbar';
import { useInvoiceStore } from 'state/invoice.store';
import MobileDrawer from "components/sidebar/MobileDrawer";
import MobileBottomNavigation from "components/common/MobileBottomNavigation";

const Layout = () => {
  const { isAuth, logout } = useUserStore();
  const location = useLocation()
  const [searchParams] = useSearchParams();
  const { updateInvoice, showSnackbar, snackbarSeverity, snackbarMessage } =
    useInvoiceStore();


  useEffect(() => {
    if (location.pathname.includes('email-verification') || location.pathname.includes('account-setup')) {
      sessionStorage.setItem('public-path', `${location.pathname}?${searchParams}`)
      logout()
    }
  }, [])

  return (
    <>
      {isAuth ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            padding: 0,
            margin: 0,
          }}
        >
          <CssBaseline />
          <Header />
          <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
            <Sidebar />
            <MobileDrawer />
            <MobileBottomNavigation />
            <Box
              sx={{
                flex: 1,
                padding: { xs: 1, sm: 2 },
                width: '100%',
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Box>
          </Box>
          <BasicSnackbar
            open={showSnackbar}
            handleClose={() =>
              updateInvoice({
                showSnackbar: false,
                snackbarSeverity: undefined,
                snackbarMessage: '',
              })
            }
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </Box>
      ) : (
        <>
          <CssBaseline />
          <Outlet />
        </>

      )}
    </>
  );
};

export default Layout;
