import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { BUSINESS_DETAILS_ABN_CERTIFICATE, BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT, BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT, BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL, BUSINESS_DETAILS_MISC_LAYOUT2_CONDITIONAL, BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT, BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1, BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2 } from '../../form-layouts/customer-trade-application-layouts/business-details.layout'
import FormControlItem, { FormControlItemLayout } from '../../form-components/FormControlItem'
import { useFormStore } from 'state/form.store'

const BusinessDetailsForm = () => {

    const { formData, validating, updateForm } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    return (
        <>
            <Box sx={{ pt: 3, pb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Delivery Address</Typography>
                <Grid container spacing={2}>
                    {BUSINESS_DETAILS_DELIVERY_ADDRESS_LAYOUT.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <Divider />

            {!!formData?.BUSINESS_MATCH_DELIVERY_ADDRESS && formData.BUSINESS_MATCH_DELIVERY_ADDRESS === 'No' ?
                <>
                    <Box sx={{ pt: 3, pb: 2 }}>
                        <Typography sx={{ fontWeight: 500 }}>Business Address</Typography>
                        <Grid container spacing={2}>
                            {BUSINESS_DETAILS_BUSINESS_ADDRESS_LAYOUT.map((item: FormControlItemLayout) => {
                                return (
                                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                        <FormControlItem
                                            data={formData}
                                            onChange={onChange}
                                            layout={item}
                                            submitValidating={validating}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Divider />
                </> : null}

            {!!formData?.POSTAL_MATCH_DELIVERY_ADDRESS && formData.POSTAL_MATCH_DELIVERY_ADDRESS === 'No' ?
                <>
                    <Box sx={{ pt: 3, pb: 2 }}>
                        <Typography sx={{ fontWeight: 500 }}>Postal Address</Typography>
                        <Grid container spacing={2}>
                            {BUSINESS_DETAILS_POSTAL_ADDRESS_LAYOUT.map((item: FormControlItemLayout) => {
                                return (
                                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                        <FormControlItem
                                            data={formData}
                                            onChange={onChange}
                                            layout={item}
                                            submitValidating={validating}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Divider />
                </> : null}

            <Box sx={{ pt: 4, pb: 2 }}>
                <Grid container spacing={2}>
                    {BUSINESS_DETAILS_MISC_LAYOUT1_CONDITIONAL(formData?.TYPE || '').map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ pt: 4, pb: 2 }}>
                <Grid container spacing={2}>
                    {BUSINESS_DETAILS_MISC_LAYOUT2_CONDITIONAL(formData?.IS_EXISTENT_CUSTOMER || '', formData?.KNOW_OWNER_OR_STAFF || '')
                        .map((item: FormControlItemLayout) => {
                            return (
                                <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                    <FormControlItem
                                        data={formData}
                                        onChange={onChange}
                                        layout={item}
                                        submitValidating={validating}
                                    />
                                </Grid>
                            )
                        })}
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ pt: 3, pb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Trade References</Typography>
                <Grid container spacing={2}>
                    {BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT1.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                    {BUSINESS_DETAILS_TRADE_REFERENCES_LAYOUT2.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <Box sx={{ pb: 3 }}>
                <Typography sx={{ fontWeight: 500 }}>Please Attach a valid ABN certificate</Typography>
                <Grid item xs={12}>
                    <FormControlItem
                        data={formData}
                        onChange={onChange}
                        layout={BUSINESS_DETAILS_ABN_CERTIFICATE}
                        submitValidating={validating}
                    />
                </Grid>
            </Box>
        </>
    )
}

export default BusinessDetailsForm