import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-hot-toast';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken: any = jwt_decode(token);
    let currentDate = new Date();
    if (decodedToken) {
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log('Token is expired!');
        localStorage.removeItem('token');
        toast.error('Session token expired. Redirecting to login.', { duration: 6000 });
        setTimeout(() => window.location.reload(), 3000);
        return Promise.reject('Token expired');
      }
    }
  }
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default axiosInstance;
