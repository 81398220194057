import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import API from "api";
import CreateVendorName from "components/vendors/CreateVendorName";
import CreateVendorAddress from "components/vendors/vendorAddress";
import CreateVendorCurrency from "components/vendors/vendorCurrenency";
import { useEffect, useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateNewVendor = () => {
  const [TabValue, setTabValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [vendorListData, setVendorListData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const onTabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = async () => {
    const { data } = await API.get("/vendors/get-vendor-list");
    setVendorListData(data.data);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectSupplier = async (e: any, selectVal: any) => {
    const { data } = await API.get(
      `/vendors/${Number(selectVal.split("-")[0])}`
    );
    if (data.data.length) return setVendorData(data.data);
    else return;
  };
  console.log("selected Vendor Data::", vendorData);
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Create Vendor
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            Padding: 2,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box>
              <Box sx={{ mt: 5 }}>
                <Tabs
                  value={TabValue}
                  onChange={onTabHandleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="NAME" {...a11yProps(0)} />
                  <Tab label="ADDRESS" {...a11yProps(1)} />
                  <Tab label="CURRENCY" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={TabValue} index={0}>
                <CreateVendorName />
              </TabPanel>
              <TabPanel value={TabValue} index={1}>
                <CreateVendorAddress />
              </TabPanel>
              <TabPanel value={TabValue} index={2}>
                <CreateVendorCurrency />
              </TabPanel>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
                gap: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
              // onClick={createNewPo}
              >
                add more
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="small"
              // onClick={() => EditVendor(bulkSelected)}
              >
                save
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewVendor;
