import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { EntertainmentClaim, ExpenseForm, PartyItems } from 'interfaces/myforms.interface'
import { useSnackbar, withSnackbar } from 'notistack'
import UploadFormControl from '../../form-components/UploadFormControl'
import { formattedDate, snackBarStyle } from 'utils/common.utils'
import { YES_OR_NO } from 'interfaces/enums'
import Loader from 'components/common/Loader'
import { entertainmentClaimItemValidator, formDataValidator } from 'utils/form.utils'
import FormHeader from '../FormHeader'
import FormButtons from '../FormButtons'
import EmployeeInformation from '../EmployeeInformation'
import CommentsForm from '../CommentsForm'
import FormControlItem, { FormControlItemLayout } from "../../form-components/FormControlItem"
import EntertainmentFormDesktop from "./EntertainmentFormDesktop"
import EntertainmentFormMobile from "./EntertainmentFormMobile";
import { ENTERTAINMENT_EXPENSE_LAYOUT, ENTERTAINMENT_EXPENSE_PARTY_LAYOUT } from "../../form-layouts/entertainment-expense.layout";
import { OrganisationCountry } from "enums/organisation.enums";
import { useUserStore } from "state/user.store";
import { JFC_AU_BRANCHES, JFC_NZ_BRANCHES } from "../general-expense-form/SelectList";
import API from 'api'
import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material';
import EntertainmentPartyDetails from './EntertainmentPartyDetails';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

const EntertainmentExpense = () => {


  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    // Continue action
    setOpen(false);
    validateSubmit();
  };
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [data, setData] = useState<ExpenseForm>({
    entertainmentExpenseItems: [] as EntertainmentClaim[],
    entertainmentPartyOptionalItems: {} as PartyItems,
    claimDate: new Date(),
    attachments: [],
    comment: '',
  });
  const [formData, setFormData] = useState<EntertainmentClaim | undefined>(
    undefined
  );
  const [editData, setEditData] = useState<EntertainmentClaim | undefined>(
    undefined
  );
const [amountIncludingGST, setAmountIncludingGST] = useState<number>(0);
const [itemsValidating, setItemsValidating] = useState<boolean>(false);
const [partyDataValidating, setPartyDataValidating] = useState<boolean>(false);
const [processing, setProcessing] = useState<boolean>(false);
const [submitValidating, setSubmitValidating] = useState<boolean>(false);
const [formNumberLayout] = useState<FormControlItemLayout>({
  name: 'lastSaleOver30dReason',
  label:
    'Please provide the reason if the last sales was over 30 days and the account is zero when you apply for the employee claim',
  required: true,
  fullWidth: true,
  item: true,
});
  const [formOverdue] = useState<FormControlItemLayout>({
    name: 'accountOverdueReason',
    label:
      'Please provide the reason if account is overdue when you apply for this claim or N/A is any problems',
      required: true,
      fullWidth: true,
      item: true,
    });
  const [lastSaleOverLayout] = useState<FormControlItemLayout>({
    name: 'lastSaleOver30d',
    label: 'Last sales over 30 days',
    fullWidth: true,
    item: true,
    type: FORM_CONTROL_TYPE.SELECT,
    selectList: [
      { label: YES_OR_NO.YES, value: YES_OR_NO.YES },
      { label: YES_OR_NO.NO, value: YES_OR_NO.NO },
    ],
  });
  const [lastSalPaymentLayout] = useState<FormControlItemLayout>({
    name: 'isLastPaymentOverdue',
    label: 'Is last payment overdue ?',
    fullWidth: true,
    item: true,
    type: FORM_CONTROL_TYPE.SELECT,
    selectList: [
      { label: YES_OR_NO.YES, value: YES_OR_NO.YES },
      { label: YES_OR_NO.NO, value: YES_OR_NO.NO },
    ],
  });

 
  //Party Data
  const [entertaimentType, setFormType] = useState("");
  const [allBudgetInputsPopulated, setAllInputsPopulated] = useState(false);
  const [budgetInclGST, setBudgetTotal] = useState(0);
  const [partyData, setPartyData] = useState<PartyItems>({
      amountPerPerson: undefined,
      ytd: undefined,
      numberOfPeople: undefined,
      budgetInclGST:undefined
    });
  
  const isAuth = useUserStore((state) => state.isAuth);
  
  const layouts = ENTERTAINMENT_EXPENSE_LAYOUT;
  
  const newFormLayouts = layouts.map((layout) => {
    if (layout.name === 'branch') {
      return {
        ...layout,
        selectList:
        isAuth.country === OrganisationCountry.Australia
        ? JFC_AU_BRANCHES
        : JFC_NZ_BRANCHES,
      };
    }
    return { ...layout };
  });

  const handleChange = (value: any, keyName: string) => {
    setData((s) => ({ ...(s ?? {}), [keyName]: value }));
  };
  
  const handleDeleteFile = (value: string) => {
    setData((s) => ({
      ...(s ?? {}),
      attachments: s.attachments?.filter((att) => att !== value),
    }));
  };
  
  const handleChangeFormData = (value: any = {}) => {
    setFormData(value);
  };
  
  const handleSave = async () => {
    await API.post('/entertainment-expense-claim/save', data);
    return true;
  };

  const handleAddItem = () => {
    if (!entertainmentClaimItemValidator(formData as EntertainmentClaim)) {
      setItemsValidating(true);
      const contentOption = snackBarStyle({
        text: 'Please fillout the all columns.',
        variant: 'warning',
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
      return false;
    }
    setItemsValidating(false);
    setData({
      ...data,
      entertainmentExpenseItems: [
        ...(data.entertainmentExpenseItems as Array<any>),
        { ...formData, id: nanoid() },
      ],
    });
    setFormData(undefined);
  };

  const handleSubmit = async () => {
    //check if entertainment type option slected
    if (!entertaimentType) {
      const contentOption = snackBarStyle({
        text: 'Please select Entertainment Form Type',
        variant: 'warning',
        autoHideDuration: 5000,
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
      return;
    }
    //check if is editing and trying to submit
    if (editData) {
      const contentOption = snackBarStyle({
        text: 'Please save the item you are editing.',
        variant: 'warning',
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
      return;
    }

    
    //check if submitting a party form without populating all the party details
    if (entertaimentType === 'party' && !allBudgetInputsPopulated) {
      const contentOption = snackBarStyle({
        text: `Please complete Budget Review details`,
        variant: 'warning',
        autoHideDuration: 5000,
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
      setPartyDataValidating(true);
      return;
    }

    // check if items are OK 
    const validatorResult = formDataValidator(
      'Claim',
      data.entertainmentExpenseItems?.length ?? 0,
      data
    );

    if (validatorResult !== 'Valid') {
      enqueueSnackbar(validatorResult.message, validatorResult.options);
    } else {
      
      //At this point form it's OK to submit but a waring message needs to be sent 
      //if Total amount incl GST is higher than budget
      if (entertaimentType === 'party') {
        setPartyDataValidating(true);
        console.log(`Budget: $${budgetInclGST}`);
        //Calculate Amount Inc. GST
        const amountInclGST = Number(
          data.entertainmentExpenseItems?.reduce(
            (total, e) => total + Number(e.amountInclGst),
            0
          )
        );
        console.log(`Total: $${amountInclGST}`);
        //Check if budget is higher than Amount Inc. GST
        if (amountInclGST > budgetInclGST) {
          setAmountIncludingGST(amountInclGST);
          setOpen(true);
          return;
        }
      }
      validateSubmit();
    }
  };

  const validateSubmit = async () => {
    setProcessing(true);
    const contentOption = snackBarStyle({
      text: 'Processing Entertainment Expense Form',
      variant: 'info',
      autoHideDuration: 30000,
    });
    const key = enqueueSnackbar(contentOption.message, contentOption.options);
    let payload = {
      ...data,
      entertainmentExpenseItems: data.entertainmentExpenseItems?.map(
        (item: any) => {
          if (item.id) delete item.id;
          return {
            ...item,
            branch: item.branch.value,
            isLastPaymentOverdue:
              item.isLastPaymentOverdue?.value === YES_OR_NO.YES ?? false,
            lastSaleOver30d:
              item.lastSaleOver30d?.value === YES_OR_NO.YES ?? false,
          };
        }
      ),
      claimDate: formattedDate(data.claimDate as Date),
    };

    if (entertaimentType === 'party') {
      payload = {
        ...payload,
        entertainmentPartyOptionalItems: { ...partyData, budgetInclGST },
      };
    }
    try {
      const res = await API.post('/entertainment-expense-form/', payload);
      if (res.data.status) {
        setProcessing(false);
        closeSnackbar(key);
        const contentOption = snackBarStyle({
          text: `${res.data.msg}, Redirecting to submitted forms page`,
          variant: 'success',
          autoHideDuration: 3000,
        });
        enqueueSnackbar(contentOption.message, contentOption.options);
        setTimeout(() => navigate('my-form'), 5000);
      }
    } catch (err) {
      setProcessing(false);
      closeSnackbar(key);
      const contentOption = snackBarStyle({
        text: 'Submit Failed',
        variant: 'error',
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
    }
  };


  // Function to handle form type "General" - "Party" change
  const handleFormTypeChange = (event: any) => {
    const selectedFormType = event.target.value;
    setFormType(selectedFormType);
  };
  useEffect(() => {
    // Check if all inputs are populated
    if (Boolean(partyData?.amountPerPerson) && Boolean(partyData?.numberOfPeople) && Boolean(partyData?.ytd)) {
      setAllInputsPopulated(true);
      const budget = (Number(partyData?.amountPerPerson?.value) * Number(partyData?.numberOfPeople) * Number(partyData?.ytd)) / 100;
      const budgetInclGST = Number((budget * 1.1).toFixed(2));
      setBudgetTotal(budgetInclGST);
      console.log(partyData)
    } else {
      setAllInputsPopulated(false);
    }
  }, [partyData]);


  const formatCurrency = (amount: number | bigint) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  }

  return (
    <div>
      <Loader open={processing} />
      <FormHeader />
      <Typography
        sx={{ fontWeight: 500, fontSize: { xs: 16, sm: 18, md: 21 } }}
      >
        Entertainment Expense Claim
      </Typography>

      <Paper
        elevation={5}
        sx={{
          p: { xs: 1, sm: 2 },
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <EmployeeInformation
          data={data}
          onChange={(v) => setData(v)}
          submitValidating={submitValidating}
        />
        {/* Radio buttons to select form type */}
         <Box>
         <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Entertainment type (*)
          </Typography>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <RadioGroup
                row
                value={entertaimentType}
                onChange={handleFormTypeChange}
              >
                <FormControlLabel
                  value="general"
                  control={<Radio />}
                  label={
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      General
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="party"
                  control={<Radio />}
                  label={
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      Party
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Box> 

{/* Warinig Dialog for Budget exceeded */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="warning-dialog-title" style={{ textAlign: 'center' }}>{"Budget Exceeded"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          The total amount including GST has exceeded the calculated budget. 
          <br/> <br/>
          Total Amount Inc. GST: <Typography component="span" style={{ color: 'red' }}> {formatCurrency(amountIncludingGST)} </Typography>
          <br/>
          Budget Inc. GST: <Typography component="span" style={{ color: 'red' }}> {formatCurrency(budgetInclGST)} </Typography> 
          <br/>
          <br/>
          Are you sure you want to continue with the current entertainment claim?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>



        {/* Conditional rendering of dice section */}
        {entertaimentType === 'party' && (
          <EntertainmentPartyDetails
            setData={setData}
            submitValidating={submitValidating}
            partyDataValidating={partyDataValidating}
            allBudgetInputsPopulated={allBudgetInputsPopulated}
            budgetInclGST={budgetInclGST}
            setPartyData={setPartyData}
            partyData={partyData}
          />
        )}
 {!!entertaimentType && (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Items
          </Typography>

          <EntertainmentFormDesktop
            items={data?.entertainmentExpenseItems ?? []}
            onChange={(v) => handleChange(v, 'entertainmentExpenseItems')}
            submitValidating={submitValidating}
            itemsValidating={itemsValidating}
            setItemsValidating={setItemsValidating}
            formData={formData}
            editData={editData}
            setEditData={setEditData}
            handleChangeFormData={handleChangeFormData}
            handleAddItem={handleAddItem}
            formNumberLayout={formNumberLayout}
            formOverdue={formOverdue}
            lastSaleOverLayout={lastSaleOverLayout}
            lastSalPaymentLayout={lastSalPaymentLayout}
            formLayouts={newFormLayouts}
          />

          <EntertainmentFormMobile
            items={data?.entertainmentExpenseItems ?? []}
            onChange={(v) => handleChange(v, 'entertainmentExpenseItems')}
            submitValidating={submitValidating}
            itemsValidating={itemsValidating}
            setItemsValidating={setItemsValidating}
            formData={formData}
            editData={editData}
            setEditData={setEditData}
            handleChangeFormData={handleChangeFormData}
            handleAddItem={handleAddItem}
            formNumberLayout={formNumberLayout}
            formOverdue={formOverdue}
            lastSaleOverLayout={lastSaleOverLayout}
            lastSalPaymentLayout={lastSalPaymentLayout}
            formLayouts={newFormLayouts}
          />
        </Box>
 )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, pb: 2, visibility: 'hidden' }}
            >
              Comment
            </Typography>
            <CommentsForm data={data} onChange={(v) => setData(v)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ fontWeight: 600, pb: 2 }}>
              Please attach a file*
            </Typography>
            <UploadFormControl
              onChange={(v) => handleChange(v, 'attachments')}
              deleteFile={handleDeleteFile}
            />
          </Grid>

        </Grid>
        <FormButtons
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          processing={processing}
        />
      </Paper>
    </div>
  );
}

export default withSnackbar(EntertainmentExpense)
