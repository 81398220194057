export enum DOCUMENT_STATUS_ENUM {
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  WAITING_TO_BE_PROCESSED_IN_ATTACHE = 'WAITING_TO_BE_PROCESSED_IN_ATTACHE',
  PROCESSED = 'PROCESSED',
  SUCCESSFULLY_PROCESSED_IN_ATTACHE = 'SUCCESSFULLY_PROCESSED_IN_ATTACHE',

  DUPLICATION_CHECK = 'DUPLICATION_CHECK',
  NOT_PROCESSED = 'NOT_PROCESSED',
  PROCESSING = 'PROCESSING',
  WAITING_INTEGRATION = 'WAITING_INTEGRATION',
  ON_HOLD = 'ON_HOLD',
}

export enum GRN_STATUS {
  NOT_RECEIVED = 'NOT_RECEIVED',
  RECEIVED = 'RECEIVED',
}

export enum DOCUMENT_SYNC_STATUS {
  NOT_SYNCED = 'NOT_SYNCED',
}
