import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

type Props = {
  open?: boolean | Boolean;
  variant?: 'linear' | 'circular';
  children?: JSX.Element | null;
};

const LoaderContainer: React.FC<Props> = ({ children, open = false }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {open ? (
        <Box
          sx={{
            zIndex: (theme) => theme?.zIndex?.drawer,
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(5px)',
          }}
        >
          <CircularProgress color='primary' />
          <Typography variant='h6' color='primary' sx={{ marginLeft: 5 }}>
            Loading ...{' '}
          </Typography>
        </Box>
      ) : null}
      <Box sx={{ position: 'relative' }}>{children}</Box>
    </Box>
  );
};

export default LoaderContainer;
