import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem'

import { useUserStore } from "state/user.store";

const FormHeader = () => {

    const isAuth = useUserStore(state => state.isAuth)

    return (
        <Box >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <ImageListItem sx={{ width: { xs: "60%", sm: "45%", md: "30%" } }}>
                    <img
                        src={`/static/img/JFC-LOGO-${isAuth?.country}.png`}
                        alt="JFC Logo"
                        loading="lazy"
                    />
                </ImageListItem>
            </Box>
        </Box>
    )
}

export default FormHeader