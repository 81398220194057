import { Button, Grid, Tooltip } from '@mui/material'
import { FC } from 'react'
import { LoadingButton } from '@mui/lab'
import InfoIcon from '@mui/icons-material/Info'

interface FormButtonsProps {
    handleSave: () => void;
    handleSubmit: () => void;
    processing: boolean;
}

const FormButtons: FC<FormButtonsProps> = ({ handleSave, handleSubmit, processing }) => {
    return (
        <Grid sx={{ pb: 10 }} container alignItems={'flex-end'} justifyContent={'flex-end'}>
            <Button
                variant='contained'
                sx={{ position: 'relative', width: '100px', textTransform: 'none' }}
                size='large'
                onClick={handleSave}
                disabled={true}
            >
                Save
                <Tooltip
                    title='You can save and continue later'
                    placement='top'
                    arrow
                >
                    <InfoIcon
                        sx={{
                            fontSize: '15px',
                            position: 'absolute',
                            top: '3px',
                            right: '3px',
                        }}
                    />
                </Tooltip>
            </Button>
            <LoadingButton
                variant='contained'
                onClick={handleSubmit}
                loading={processing}
                size='large'
                sx={{
                    position: 'relative',
                    width: '100px',
                    textTransform: 'none',
                    marginLeft: '20px',

                }}
            >
                Submit
            </LoadingButton>
        </Grid>
    )
}

export default FormButtons