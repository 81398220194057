import { Box, CardContent, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';

function DashboardCard({
  amount = '',
  title = '',
  cardIcon = null,
  caption = '',
  subCaption = '',
  onClick = (e: React.MouseEvent<HTMLDivElement>) => { },
  data: cardData = {},
}: any) {
  const handleClickCard = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick(e);

    window.document
      .getElementById(cardData?.detailTableId)
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box>
      <Card sx={{ minHeight: '140px' }} onClick={handleClickCard}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Grid item>
              <Typography color={'#000000'} fontWeight={500}>
                {title}
              </Typography>
              <Typography variant='h5' fontWeight={600} color={'#536dfe'}>
                {amount}
              </Typography>
              <Typography color={'GrayText'} fontWeight={400}>
                {caption}
              </Typography>
              <Typography color={'GrayText'} variant='body2' fontWeight={400}>
                {subCaption || <br />}
              </Typography>
            </Grid>
            <Grid item>{cardIcon}</Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default DashboardCard;
