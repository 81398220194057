import { YES_OR_NO } from "interfaces/enums";
import { FormControlItemLayout } from "../form-components/FormControlItem";
import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { ENTERTAINMENT_PARTY_AMOUNT_PER_PERSON } from '../employee-forms/entertainment/SelectList';

export const ENTERTAINMENT_EXPENSE_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: "itemDate",
        label: "Date",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "branch",
        label: "Branch",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.SELECT,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "customerNumber",
        label: "Customer number",
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "customerName",
        label: "Customer Name/Descriptions",
        required: true,
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "lastSale",
        label: "Last sales",
        fullWidth: true,
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "lastSaleAmount",
        label: "Last sales amount $",
        fullWidth: true,
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "lastPayment",
        label: "Last payment ",
        fullWidth: true,
        type: FORM_CONTROL_TYPE.DATE,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "lastPaymentAmount",
        label: "Last payment amount $",
        fullWidth: true,
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "invitedGuestName",
        label: "Invited guest name",
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "invitedBy",
        label: "Invited by (JFC Employee's name)",
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "place",
        label: "Place",
        required: true,
        fullWidth: true,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "amountInclGst",
        label: "Amount Incl GST $",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "gst",
        label: "GST $",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.CURRENCY,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
];


export const ENTERTAINMENT_EXPENSE_FOOTER_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: "lastSaleOver30d",
        label: "Last sales over 30 days",
        fullWidth: true,
        item: true,
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: [
            { label: YES_OR_NO.YES, value: YES_OR_NO.YES },
            { label: YES_OR_NO.NO, value: YES_OR_NO.NO },
        ],
    },
    {
        name: "isLastPaymentOverdue",
        label: "Is last payment overdue ?",
        fullWidth: true,
        item: true,
        type: FORM_CONTROL_TYPE.SELECT,
        selectList: [
            { label: YES_OR_NO.YES, value: YES_OR_NO.YES },
            { label: YES_OR_NO.NO, value: YES_OR_NO.NO },
        ],
    }
]
export const ENTERTAINMENT_EXPENSE_PARTY_LAYOUT: Array<FormControlItemLayout> = [
    {
        name: "amountPerPerson",
        label: "Amount per person",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.SELECT,
        item: true,
        selectList: ENTERTAINMENT_PARTY_AMOUNT_PER_PERSON
    },
    {
        name: "numberOfPeople",
        label: "Number of people",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.NUMBER,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
    {
        name: "ytd",
        label: "YTD (%)",
        required: true,
        fullWidth: true,
        type: FORM_CONTROL_TYPE.NUMBER,
        item: true,
        gridSizeMobile: 12,
        gridSizeTablet: 6,
        gridSizeDesktop: 3
    },
]
