import { Box, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { FC } from "react";

interface HomepageCardProps {
  amount: string;
  title: string;
  cardIcon: React.ReactNode;
  caption: string;
}

const HomepageCard: FC<HomepageCardProps> = ({
  amount = "",
  title = "",
  cardIcon = null,
  caption = "",
}) => {
  return (
    <Card sx={{ minHeight: "140px" }} elevation={4}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Grid item>
            <Typography variant="h5" fontWeight={600} color={"#536dfe"}>
              {amount}
            </Typography>
            <Typography color={"#000000"} fontWeight={500} sx={{ pb: 1 }}>
              {title}
            </Typography>
            <Typography color={"GrayText"} fontWeight={400}>
              {caption}
            </Typography>
            {/* <Typography color={"GrayText"} variant="body2" fontWeight={400}>
                {subCaption || <br />}
              </Typography> */}
          </Grid>
          <Grid item>{cardIcon}</Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HomepageCard;
