import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Box } from "@mui/system";
import { PurchaseOrderSummary } from "interfaces/order.interface";
import React, { useState } from "react";
import { useInvoiceStore } from "state/invoice.store";
import { DeletePoModal } from "../modal/deletePoModal";
import { RejectPoModal } from "../modal/rejectPoModal";

interface ActionButtonsProps {
  row: PurchaseOrderSummary;
}

const PurchaseOrderActionIcon: React.FC<ActionButtonsProps> = ({ row }) => {
  // console.log("Row::", row)
  // modal type
  const [type, setType] = useState<string>("");
  const { bulkSelected } = useInvoiceStore();

  // modal state
  const [open, setOpen] = useState<boolean>(false);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);

  // functions for modal
  const handleOpen = (type: string) => {
    setType(type);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const rejectHandleOpen = () => setRejectOpen(true);
  const rejectHandleClose = () => setRejectOpen(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          alignItems: "center",
          alignContent: "flex-start",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#dddddd",
            p: "5px 15px",
            lineHeight: "10px",
            borderRadius: "5px",
          }}
        >
          <ThumbUpAltIcon
            sx={{ fontSize: 15, cursor: "pointer" }}
            onClick={() => handleOpen("approve")}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#dddddd",
            p: "5px 15px",
            lineHeight: "10px",
            borderRadius: "5px",
          }}
        >
          <ThumbDownAltIcon
            sx={{ fontSize: 15, cursor: "pointer" }}
            onClick={rejectHandleOpen}
          />
        </Box>
      </Box>

      {open && (
        <DeletePoModal
          open={open}
          handleClose={handleClose}
          type={type}
          selectedPurchaseorder={bulkSelected}
        />
      )}
      {rejectOpen && (
        <RejectPoModal
          rejectOpen={rejectOpen}
          rejectHandleClose={rejectHandleClose}
        />
      )}
    </>
  );
};

export default PurchaseOrderActionIcon;
