import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import API from "api";
import OrderStatusTabs from "components/orders/orderStatusTabs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateNewProduct = () => {
  const navigate = useNavigate();
  const [createProduct, setCreateProduct] = useState({
    supplier_item_code: "",
    product_name: "",
    product_unit_cost: "",
    tax_rate: "",
  });

  function handleChange(evt: any) {
    const value = evt.target.value;
    setCreateProduct({
      ...createProduct,
      [evt.target.name]: value,
    });
  }

  const createProducts = async () => {
    const createProducts = await API.post("/products", { ...createProduct });
    if (createProducts.data.status) navigate("/products");
  };
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Create Products
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            Padding: 2,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <OrderStatusTabs statusName="ITEMS" />
            <Box sx={{ mt: "10px" }}>
              <TextField
                label="Item Code"
                fullWidth
                disabled
                type="text"
                variant="outlined"
                margin="dense"
              />

              <TextField
                name="supplier_item_code"
                label="Supplier Item Code"
                value={createProduct.supplier_item_code}
                onChange={handleChange}
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
              />

              <TextField
                name="product_name"
                label="Item Description"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
                value={createProduct.product_name}
                onChange={handleChange}
              />

              <TextField
                name="product_unit_cost"
                label="Unit Price"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
                value={createProduct.product_unit_cost}
                onChange={handleChange}
              />

              <TextField
                name="tax_rate"
                label="Tax Rate"
                fullWidth
                type="text"
                variant="outlined"
                margin="dense"
                value={createProduct.tax_rate}
                onChange={handleChange}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
                gap: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={createProducts}
              >
                save
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewProduct;
