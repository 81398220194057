import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { CTA_FORM_FIELDS } from 'enums/customer-trade-application-form.enums';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const APPLICANT_DETAILS_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: 'APPLICANT_TYPE',
    label: 'Applicant type',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Sole Trader', 'Partnership', 'Company', 'Trust'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.APPLICANT_LEGAL_NAME,
    label: 'Legal name (ex. Pty Limited)',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.BRANCH,
    label: 'Branch',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Adelaide', 'Brisbane', 'Canberra', 'Gold Coast', 'Melbourne', 'Perth', 'Sunshine Coast', 'Sydney'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.ABN,
    label: 'ABN',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.APPLICANT_TRADING_NAME,
    label: 'Trading name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: 'APPLICANT_ID',
    label: "ID # (driver's license or passport)",
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
];

export const APPLICANT_EMAIL_LAYOUT: FormControlItemLayout = {
  name: 'APPLICANT_EMAIL_ADDRESS',
  label: 'Email address',
  required: true,
  fullWidth: true,
  type: FORM_CONTROL_TYPE.EMAIL,
  gridSizeMobile: 12,
  gridSizeTablet: 6,
};
