import { FC } from 'react'
import FormControlItem, { FormControlItemLayout } from '../form-components/FormControlItem';
import { Box } from '@mui/material';
import { ExpenseForm, PurchaseReqForm } from 'interfaces/myforms.interface';
import { FORM_CONTROL_TYPE } from "enums/form.enums";

const commentLayout: FormControlItemLayout = {
    name: "comment",
    label: "Comment",
    fullWidth: true,
    type: FORM_CONTROL_TYPE.MULTI_LINE_TEXT,
    rows: 3,
};

interface CommentsFormProps {
    data: ExpenseForm | PurchaseReqForm;
    onChange: (value: any) => void;

}

const CommentsForm: FC<CommentsFormProps> = ({ data, onChange }) => {
    return (
        <Box>
            <FormControlItem data={data} onChange={onChange} layout={commentLayout} />
        </Box>
    )
}

export default CommentsForm