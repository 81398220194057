import React from "react";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";

export const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom timeout={5000} ref={ref} {...props} />;
});
