import { FC, useEffect, useState } from 'react'
import { toast } from "react-hot-toast";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { SubmitSummary } from "interfaces/submitform.interface";
import MyFormsCard from "./MyFormsCard";
import Loader from 'components/common/Loader'
import API from 'api';
import { setSubmitFormApidata } from "state/form.store";



interface MyFormsCardListProps {
    submitforms?: SubmitSummary[];
    search?: string;
}

const MyFormsCardList: FC<MyFormsCardListProps> = ({
    submitforms = [],
    search
}) => {

    const [rows, setRows] = useState<SubmitSummary[]>([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [processing, setProcessing] = useState<boolean>(false)

    const { setSubmitFormApiData }: any = setSubmitFormApidata();

    useEffect(() => {
        if (search) {
            setRows(submitforms.filter(form => String(form.formNumber).includes(search)))
        } else {
            setRows(submitforms)
        }
    }, [submitforms, search])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteAnyway = async (id: number, type: string, deleteOnDocuware: boolean) => {
        setProcessing(true)
        const deleteToast = toast.loading('Proceed on deleting claim...')
        try {
            const { data } = await API.patch('/my-form', { id, type, deleteOnDocuware })
            if (data.success) {
                toast.success('Claim deleted successfully')
                const newRows = rows.filter(row => row.id !== id)
                setSubmitFormApiData(newRows)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            toast.error('Failed to delete claim. Please try again')
            console.log("ERROR", error)
        } finally {
            toast.dismiss(deleteToast)
            setProcessing(false)
        }
    }

    const handleDeletePaidClaim = async (id: number, type: string) => {
        setProcessing(true)
        const deleteToast = toast.loading('Deleting claim...')
        try {
            const { data } = await API.patch('/my-form', { id, type, deleteOnDocuware: true })
            if (data.success) {
                toast.success('Claim deleted successfully')
                const newRows = rows.filter(row => row.id !== id)
                setSubmitFormApiData(newRows)
            } else {
                if (data.message?.includes('Document not found in Docuware')) {
                    toast.loading((t) => (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography>
                                Document not found in Docuware. It might have been deleted manually.
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button variant="outlined" onClick={() => {
                                    toast.dismiss(t.id)
                                }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    handleDeleteAnyway(id, type, false)
                                    toast.dismiss(t.id)
                                }}>
                                    Delete anyway
                                </Button>
                            </Box>
                        </Box>
                    ));
                } else {
                    toast.error(data.message)
                }
            }
        } catch (error) {
            toast.error('Failed to delete claim. Please try again')
            console.log(error)
        } finally {
            toast.dismiss(deleteToast)
            setProcessing(false)
        }
    }

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Loader open={processing} showProgress={false} />
            <Box>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: SubmitSummary) => {
                    return <MyFormsCard key={row.id} row={row} handleDelete={handleDeletePaidClaim} />
                })
                }
            </Box>

            <TablePagination
                rowsPerPageOptions={[15, 30, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>

    )
}

export default MyFormsCardList