import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { ACKNOWLEDGEMENT_AND_SIGNATURES_1, ACKNOWLEDGEMENT_AND_SIGNATURES_2, DIRECT_DEBIT_1, DIRECT_DEBIT_2, DIRECT_DEBIT_CHOSEN_LAYOUT, DIRECT_DEBIT_SERVICE_AGREEMENT } from 'components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout'
import { useFormStore } from 'state/form.store'

const PaymentInformationDirectDebit = () => {

    const { formData, validating, updateForm } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Grid item xs={12}>
                    <FormControlItem
                        data={formData}
                        onChange={onChange}
                        layout={DIRECT_DEBIT_CHOSEN_LAYOUT}
                        submitValidating={validating}
                    />
                </Grid>
            </Box>

            {formData?.DIRECT_DEBIT_CHOSEN === 'No' ?
                null
                :
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center', py: 3 }}>
                        <Typography variant='h6'>Request and Authority to debit the account named below to pay</Typography>
                        <Typography sx={{ textAlign: 'justify' }}>
                            "You" request and authorise JFC AUSTRALIA CO PTY LTD to arrange,
                            through its own financial institution, a debit to your nominated
                            account any amount JFC AUSTRALIA CO PTY LTD has deemed payable by you.
                            This debit or charge will be made through the Bulk Electronic Clearing
                            System (BECS) from your account held at the financial institution you
                            have nominated below and will be subject to the terms and conditions of
                            the Direct Debit Request Service Agreement.
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 3 }}>
                        <Typography variant='h6'>Nominated Account Details</Typography>
                        <Typography variant='subtitle2' sx={{ fontStyle: 'italic' }}>Insert the name and address of financial institution at which account is held</Typography>
                        <Grid container spacing={2}>
                            {DIRECT_DEBIT_1.map((item: FormControlItemLayout) => {
                                return (
                                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                        <FormControlItem
                                            data={formData}
                                            onChange={onChange}
                                            layout={item}
                                            submitValidating={validating}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 3 }}>
                        <Typography variant='h6'>Insert details of account to be debited</Typography>
                        <Grid container spacing={2}>
                            {DIRECT_DEBIT_2.map((item: FormControlItemLayout) => {
                                return (
                                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                        <FormControlItem
                                            data={formData}
                                            onChange={onChange}
                                            layout={item}
                                            submitValidating={validating}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>

                    <Divider />

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 3 }}>
                        <Typography variant='h6'>Acknowledgment & Signatures</Typography>
                        <Typography variant='subtitle2' sx={{ fontStyle: 'italic', textAlign: 'justify' }}>
                            By signing and/or providing us with a valid instruction in respect to your Direct Debit Request,
                            you have understood and agreed to the terms and conditions governing the debit arrangements between
                            you and JFC AUSTRALIA CO PTY LTD as set out in this Request and in your Direct Debit Request Service Agreement.
                        </Typography>
                        <Box>
                            <Grid container spacing={1}>
                                {ACKNOWLEDGEMENT_AND_SIGNATURES_1.map((item: FormControlItemLayout) => {
                                    return (
                                        <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                            <FormControlItem
                                                data={formData}
                                                onChange={onChange}
                                                layout={item}
                                                submitValidating={validating}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>

                        </Box>

                        <Box>
                            <Grid container spacing={1}>
                                {ACKNOWLEDGEMENT_AND_SIGNATURES_2.map((item: FormControlItemLayout) => {
                                    return (
                                        <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                            <FormControlItem
                                                data={formData}
                                                onChange={onChange}
                                                layout={item}
                                                submitValidating={validating}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 3 }}>
                        <Typography variant='h6'>Acknowledgment & Signatures</Typography>
                        <a href='https://www.jfcaustralia.com.au/direct-debit-request-service-agreement/' target='__blank'>
                            https://www.jfcaustralia.com.au/direct-debit-request-service-agreement/
                        </a>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <FormControlItem
                                data={formData}
                                onChange={onChange}
                                layout={DIRECT_DEBIT_SERVICE_AGREEMENT}
                                submitValidating={validating}
                            />
                        </Box>

                    </Box>
                </>}


        </Box>



    )
}

export default PaymentInformationDirectDebit