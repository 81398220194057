import { PurchaseItem } from "interfaces/myforms.interface";
import create from "zustand";
import { produce } from "immer";

export const setPurchaseorderdata = create((set) => ({
  purchaseOrderApiData: [],
  setPurchaseOrderApiData: (purchaseOrder: any) =>
    set(() => ({ purchaseOrderApiData: purchaseOrder })),
}));

const purchaseRequestOrderData = {
  id: 0,
  quantity: 0,
  item: "",
  description: "",
  inclGST: 0,
};

export const usePurchaseRequestFormStore = create((set) => ({
  claims: purchaseRequestOrderData,
  claimsData: [{ ...purchaseRequestOrderData }] as PurchaseItem[],
  addPurchseItem: (payload: any) => {
    set(
      produce((draft: any) => {
        draft.claimsData.push({
          ...payload,
        });
      })
    );
  },
  patchPurchaseItem: (payload: any, index: number) => {
    set(
      produce((draft: any) => {
        draft.claimsData[index] = payload;
      })
    );
  },
  replacePurchaseItem: (payload: []) => {
    set(
      produce((draft: any) => {
        draft.claimsData = payload;
      })
    );
  },
}));
