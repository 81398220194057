import { FormControlItemLayout } from 'components/forms/form-components/FormControlItem';
import { CTA_FORM_FIELDS } from 'enums/customer-trade-application-form.enums';
import { FORM_CONTROL_TYPE } from 'enums/form.enums';

export const PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT: Array<FormControlItemLayout> = [
  {
    name: CTA_FORM_FIELDS.APPLICANT_LEGAL_NAME,
    label: 'Legal name (ex. Pty Limited)',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 12,
  },
  {
    name: CTA_FORM_FIELDS.APPLICANT_TRADING_NAME,
    label: 'Trading name',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.CUSTOMER_NUMBER,
    label: 'JFC Customer number',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.BRANCH,
    label: 'Branch',
    required: true,
    fullWidth: true,
    type: FORM_CONTROL_TYPE.NEW_SELECT,
    newSelectList: ['Adelaide', 'Brisbane', 'Canberra', 'Gold Coast', 'Melbourne', 'Perth', 'Sunshine Coast', 'Sydney'],
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
  {
    name: CTA_FORM_FIELDS.ABN,
    label: 'ABN',
    required: true,
    fullWidth: true,
    gridSizeMobile: 12,
    gridSizeTablet: 6,
  },
];
