import { useEffect, useState } from 'react'

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import API from "api";
import { User } from 'interfaces/user.interface';
import UsersTableHead from 'components/users/UsersTableHead';
import UsersTableBody from 'components/users/UsersTableBody';
import { Order } from 'utils/table.utils';
import CreateUserDialog from 'components/users/CreateUserDialog';
import EditUserDialog from 'components/users/EditUserDialog';
import DeleteUserDialog from 'components/users/DeleteUserDialog';
import { filterUsersTable } from 'utils/user.utils';
import { useUserStore } from "state/user.store";
import { IS_JFC } from "constants/global";

export interface Column {
    key: keyof User;
    label: string;
    disablePadding?: boolean;
    numeric?: boolean;
    align?: 'left' | 'right' | 'center';
    filter?: boolean;
    minWidth?: number;
    width?: number;
}


const JFC_columns: readonly Column[] = [
    { key: 'firstName', label: 'Name', minWidth: 170, filter: true },
    { key: 'employeeNumber', label: 'Employee Number', minWidth: 170, filter: true },
    { key: 'department', label: 'Department', minWidth: 170, filter: true },
    { key: 'branch', label: 'Branch', minWidth: 170, filter: true },
    { key: 'vendorId', label: 'Vendor ID', minWidth: 170, filter: true },
    { key: 'role', label: 'Role', minWidth: 170, filter: true },
    { key: 'isActivated', label: 'Active', minWidth: 170 },
];

const ESPH_columns: readonly Column[] = [
    { key: 'firstName', label: 'Name', minWidth: 170, filter: true },
    { key: 'department', label: 'Department', minWidth: 170, filter: true },
    { key: 'role', label: 'Role', minWidth: 170, filter: true },
    { key: 'isActivated', label: 'Active', minWidth: 170 },
];

const Users = () => {
    const [search, setSearch] = useState<string>('');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [showEditScreen, setShowEditScreen] = useState<boolean>(false);
    const [showDeleteScreen, setShowDeleteScreen] = useState<boolean>(false);
    const [showAddNewScreen, setShowAddNewScreen] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof User>('firstName');
    const [rows, setRows] = useState<User[]>([]);
    const [filterCriteria, setFilterCriteria] = useState<any>({})
    const [filteredRows, setFilteredRows] = useState<any>([])

    const isAuth = useUserStore(state => state.isAuth)


    // START: Users Query //
    const getUsers = async () => {
        const { data } = await API.get("/users");
        console.log("USERS DATA", data)
        setRows(data);
    }

    useEffect(() => {
        getUsers()
    }, [])
    // END: Users Query //


    // START: Search Users Feature //
    const handleSearchUsers = (e: any) => {
        const { value } = e.target
        const searchTerm = value.toLowerCase();
        const filteredUsers = rows.filter((row) => {
            const userFirstName = row.firstName.toLowerCase();
            const userLastName = row.lastName.toLowerCase();
            const userName = row.username.toLowerCase();
            return userFirstName.includes(searchTerm) || userLastName.includes(searchTerm) || userName.includes(searchTerm);
        });
        setSearch(value)
        setFilteredRows(filteredUsers)
    }
    // END: Search Users Feature //


    // START: Filter Users Feature //
    const handleChangeFilter = (category: any, newValue: any) => {
        setFilterCriteria({ ...filterCriteria, [category]: newValue.length ? [...newValue] : undefined })
    }

    useEffect(() => {
        const filteredRows = filterUsersTable(rows, filterCriteria)
        setFilteredRows([...filteredRows])
    }, [filterCriteria, rows]);
    // END: Filter Users Feature //


    // START: Users single-multiselect checkbox //
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((row) => row.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickSelect = (userId: string) => {
        const selectedIndex = selected.indexOf(userId);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, userId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const numSelected = selected.length;
    // END: Users single-multiselect checkbox //


    // START: Pagination Feature //
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const rowCount = filteredRows?.length;
    // END: Pagination Feature //


    // START: Sorting Feature //
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof User
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    // END: Sorting Feature //


    // START: Create, Edit, Delete Users UI update //
    const handleEditUsers = (editedRow: User) => {
        const newRows = rows.map((row) => {
            if (row.id === editedRow.id) {
                return { ...editedRow };
            } else {
                return { ...row };
            }
        });
        setRows(newRows);
    };

    const handleCreateUser = (newRow: User) => {
        setRows([...rows, newRow])
    };

    const handleDeleteUsers = (deletedRows: User[]) => {
        setRows([...rows.filter((row) => !deletedRows.some(deletedRow => deletedRow.id === row.id))])
    }
    // END: Create, Edit, Delete Users UI update //

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowCount) : 0;

    return (
        <Box>
            <Box sx={{ py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', position: "relative" }}>
                <Box>
                    <Box >
                        {IS_JFC ?
                            <img
                                src={`/static/img/JFC-LOGO-${isAuth?.country}.png`}
                                alt='JFC'
                                style={{ maxWidth: 300 }}

                            /> :
                            <img
                                src={`/static/img/esph.png`}
                                alt='JFC'
                                style={{ maxWidth: 300 }}

                            />}
                    </Box>
                    <Stack
                        direction='row'
                        alignItems='center'
                    >
                        <Typography sx={{ fontWeight: 500 }} variant='h5'>
                            Users
                        </Typography>
                        <IconButton onClick={() => getUsers()} aria-label='refresh'>
                            <RefreshIcon />
                        </IconButton>
                    </Stack>
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <TextField
                        id='search'
                        placeholder='Search'
                        fullWidth
                        size='small'
                        variant='outlined'
                        value={search}
                        sx={{ marginBottom: '20px' }}
                        onChange={handleSearchUsers}
                        InputProps={{
                            endAdornment: <SearchIcon style={{ color: 'gray' }} />,
                        }}
                    />
                    <Stack direction='row' spacing={2}>
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            onClick={() => {
                                setShowAddNewScreen(true);
                            }}
                        >
                            Create User
                        </Button>
                        <Button
                            startIcon={<EditIcon />}
                            disabled={numSelected <= 0 || numSelected > 1}
                            variant={numSelected === 1 ? 'contained' : 'outlined'}
                            onClick={() => {
                                setShowEditScreen(true);
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            startIcon={<DeleteIcon />}
                            disabled={numSelected <= 0}
                            variant={numSelected > 0 ? 'contained' : 'outlined'}
                            onClick={() => setShowDeleteScreen(true)}
                        >
                            Delete
                        </Button>
                    </Stack>
                </Box>
            </Box>

            <Paper elevation={5} sx={{ width: '100%', overflow: 'hidden' }} >
                <TableContainer>
                    <Table size='small' stickyHeader aria-label='sticky table'>
                        <UsersTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            numSelected={selected.length}
                            rowCount={rowCount}
                            columns={IS_JFC ? JFC_columns : ESPH_columns}
                            rows={rows}
                            filterCriteria={filterCriteria}
                            onChangeFilter={handleChangeFilter}
                        />
                        <UsersTableBody
                            rows={filteredRows}
                            handleClickSelect={handleClickSelect}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            columns={IS_JFC ? JFC_columns : ESPH_columns}
                            emptyRows={emptyRows}
                            order={order}
                            orderBy={orderBy}
                        />
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component='div'
                    count={rowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <CreateUserDialog
                open={showAddNewScreen}
                handleClose={() => setShowAddNewScreen(false)}
                updateUsers={handleCreateUser}
            />
            {showEditScreen ?
                <EditUserDialog
                    open={showEditScreen}
                    handleClose={() => setShowEditScreen(false)}
                    user={rows?.find((user) => user.id === selected[0])}
                    updateUsers={(editedUser) => handleEditUsers(editedUser)}
                /> : null}
            <DeleteUserDialog
                open={showDeleteScreen}
                handleClose={() => setShowDeleteScreen(false)}
                users={rows?.filter((row) => selected.includes(row.id))}
                updateUsers={handleDeleteUsers}
            />
        </Box>
    )
}

export default Users