import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { nanoid } from 'nanoid'

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { withSnackbar, useSnackbar } from "notistack";
import FormTabs from "components/common/FormTabs";
import { PurchaseItem, PurchaseReqForm } from "interfaces/myforms.interface";
import UploadFormControl from "../../form-components/UploadFormControl";
import PurchaseReasonsForm from "./PurchaseReasonsForm";
import { formattedDate, snackBarStyle } from "utils/common.utils";
import Loader from "components/common/Loader";
import FormHeader from "../FormHeader";
import EmployeeInformation from "../EmployeeInformation";
import CommentsForm from "../CommentsForm";
import FormButtons from "../FormButtons";
import PurchaseRequestFormDesktop from "./PurchaseRequestFormDesktop";
import PurchaseRequestFormMobile from "./PurchaseRequestFormMobile";
import { formDataValidator, purchaseItemValidator } from "utils/form.utils";
import API from "api";

const KEY_NAMES = [
  "user",
  "purchaseItems",
  "description",
  "filePath",
];

const PurchaseRequestForm = () => {

  const [data, setData] = useState<PurchaseReqForm>({
    purchaseItems: [] as PurchaseItem[],
    claimDate: new Date(),
    description: "",
    attachments: [],
    comment: '',
  })
  const [formData, setFormData] = useState<PurchaseItem | undefined>(undefined)
  const [editData, setEditData] = useState<PurchaseItem | undefined>(undefined)
  const [processing, setProcessing] = useState<boolean>(false)
  const [submitValidating, setSubmitValidating] = useState<boolean>(false)
  const [itemsValidating, setItemsValidating] = useState<boolean>(false)
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()


  const handleChange = (value: any, keyName: string) => {
    setData((s) => ({ ...(s ?? {}), [keyName]: value }));
  };

  const handleChangeFormData = (value: any = {}) => {
    setFormData(value)
  }

  const handleDeleteFile = (value: string) => {
    setData((s) => ({ ...(s ?? {}), attachments: s.attachments?.filter(att => att !== value) }))
  }

  const handleAddItem = () => {
    if (!purchaseItemValidator(formData as PurchaseItem)) {
      setItemsValidating(true)
      const contentOption = snackBarStyle({
        text: "Please fillout all required fields.",
        variant: "warning",
      });
      enqueueSnackbar(contentOption.message, contentOption.options);
      return false;
    }
    setItemsValidating(false)
    setData({ ...data, purchaseItems: [...data.purchaseItems as Array<any>, { ...formData, id: nanoid() }] })
    setFormData(undefined);
  };

  const handleSubmit = async () => {
    setSubmitValidating(true)
    if (editData) {
      const contentOption = snackBarStyle({
        text: 'Please save the item you are editing.',
        variant: 'warning',
      })
      enqueueSnackbar(contentOption.message, contentOption.options)
      return
    }
    const validatorResult = formDataValidator('PurchaseRequest', data.purchaseItems?.length ?? 0, data)
    if (validatorResult === 'Valid') {
      setProcessing(true)
      const contentOption = snackBarStyle({
        text: 'Processing Purchase Request Form',
        variant: "info",
        autoHideDuration: 30000,
      });
      const key = enqueueSnackbar(contentOption.message, contentOption.options);
      const payload = {
        ...data,
        purchaseItems: data.purchaseItems?.map((item: any) => {
          if (item.id) delete item.id
          return { ...item }
        }),
        claimDate: formattedDate(data.claimDate as Date),
      }
      try {
        const res = await API.post("/purchase-request-form/", payload)
        if (res.data.status) {
          setProcessing(false)
          closeSnackbar(key)
          const contentOption = snackBarStyle({
            text: `${res.data.msg}, Redirecting to submitted forms page`,
            variant: "success",
            autoHideDuration: 3000
          });
          enqueueSnackbar(contentOption.message, contentOption.options);
          setTimeout(() => navigate("my-form"), 5000)
        }
      } catch (err) {
        setProcessing(false)
        closeSnackbar(key)
        const contentOption = snackBarStyle({
          text: "Submit Failed",
          variant: "error",
        });
        enqueueSnackbar(contentOption.message, contentOption.options);
      }
    } else {
      enqueueSnackbar(validatorResult.message, validatorResult.options)
    }
  };

  const handleSave = async () => {
    await API.post("/purchase-request-form/save", data);
    return true;
  };

  return (
    <div>
      <Loader open={processing} />
      <FormHeader />
      <Typography sx={{ fontWeight: 500, fontSize: { xs: 16, sm: 18, md: 21 } }}>
        Internal Purchase Request Form
      </Typography>
      <Paper elevation={5} sx={{ p: 2 }}>
        <FormTabs options={["EMPLOYEE DETAILS"]} />
        <EmployeeInformation
          data={data}
          onChange={(v) => setData(v)}
          submitValidating={submitValidating}
        />
        <br />
        <FormTabs options={["ITEMS"]} />
        <PurchaseRequestFormDesktop
          items={data?.purchaseItems ?? []}
          onChange={(v) => handleChange(v, KEY_NAMES[1])}
          submitValidating={submitValidating}
          itemsValidating={itemsValidating}
          formData={formData}
          handleChangeFormData={handleChangeFormData}
          handleAddItem={handleAddItem}
        />
        <PurchaseRequestFormMobile
          items={data?.purchaseItems ?? []}
          onChange={(v) => handleChange(v, KEY_NAMES[1])}
          submitValidating={submitValidating}
          itemsValidating={itemsValidating}
          setItemsValidating={setItemsValidating}
          formData={formData}
          editData={editData}
          setEditData={setEditData}
          handleChangeFormData={handleChangeFormData}
          handleAddItem={handleAddItem}
        />
        <Grid container paddingTop={4} spacing={3}>
          <Grid item xs={12} lg={6}>
            <PurchaseReasonsForm
              data={data}
              onChange={(v) => setData(v)}
              submitValidating={submitValidating}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CommentsForm
              data={data}
              onChange={(v) => setData(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, paddingBottom: 1 }}
            >
              Please attach quotation (s)
            </Typography>
            <UploadFormControl
              onChange={(v) => handleChange(v, 'attachments')}
              deleteFile={handleDeleteFile}
            />
            <Typography
              variant="caption"
              display="block"
              color="GrayText"
              marginTop={3}
            >
              Please attach 2 quotations if total exceeds $2.000
            </Typography>
          </Grid>
        </Grid>

        <FormButtons handleSave={handleSave} handleSubmit={handleSubmit} processing={processing} />
      </Paper>
    </div>
  );
};

export default withSnackbar(PurchaseRequestForm);
