import { FC } from 'react'

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import { useSettingStore } from "state/setting.store";

interface MobileSettingMenuProps {
    open: boolean;
    handleClose: () => void;
}

const MobileSettingMenu: FC<MobileSettingMenuProps> = ({ open, handleClose }) => {

    const { mode, toggleMode } = useSettingStore()

    const handleToggleMode = () => {
        toggleMode()
        handleClose()
    }

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleToggleMode}>
                        <ListItemIcon>
                            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
                        </ListItemIcon>
                        <ListItemText primary={mode === 'light' ? "Dark Mode" : "Light Mode"} />
                    </ListItemButton>
                </ListItem>

            </List>
        </SwipeableDrawer>
    )
}

export default MobileSettingMenu