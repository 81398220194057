import { FC } from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PublishIcon from '@mui/icons-material/Publish';
import InfoIcon from '@mui/icons-material/Info'

import { FormStep } from "utils/form.utils";
import { useFormStore } from "state/form.store";
import { useLocation } from "react-router-dom";

interface FormButtonsProps {
    steps: FormStep[];
    handleSave: () => void;
    handleNext: () => void;
    handleBack: () => void;
    handleSubmit: () => void;
    stepOneComplete: boolean;
    completedSteps: Array<number>
    activeStep: number;
    processing: boolean;
}

const FormButtons: FC<FormButtonsProps> =
    ({
        handleSave,
        handleNext,
        handleBack,
        stepOneComplete,
        completedSteps,
        handleSubmit,
        steps,
        activeStep,
        processing,
    }) => {

        const { formData } = useFormStore()
        const location = useLocation()

        const isCustomerTradeApplication = location.pathname.includes('customer-trade-application-form')


        const allStepsCompleted = () => {
            if (isCustomerTradeApplication) {
                return completedSteps.length === steps.length
            } else {
                if (formData?.DIRECT_DEBIT_CHOSEN === 'Yes') {
                    return completedSteps.includes(0) && completedSteps.includes(1) && completedSteps.includes(4)
                } else {
                    return completedSteps.includes(0) && completedSteps.includes(1) && completedSteps.includes(2) && completedSteps.includes(4)
                }
            }
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                {activeStep === steps.length - 1 ?
                    <Button
                        onClick={handleBack}
                        variant='contained'
                        startIcon={<NavigateBeforeIcon />}
                    >
                        Back
                    </Button> :

                    <LoadingButton
                        onClick={handleSave}
                        loading={processing}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        sx={{ width: 110 }}
                        disabled={allStepsCompleted() || !!formData?.documentStatus}
                    >
                        Save
                        <Tooltip
                            title='You can save and continue later'
                            placement='top'
                            arrow
                        >
                            <InfoIcon
                                sx={{
                                    fontSize: '15px',
                                    position: 'absolute',
                                    top: '3px',
                                    right: '3px',
                                }}
                            />
                        </Tooltip>
                    </LoadingButton>

                }

                {activeStep === steps.length - 1 ?
                    <LoadingButton
                        onClick={handleSubmit}
                        disabled={!allStepsCompleted() || !!formData?.documentStatus}
                        loading={processing}
                        loadingPosition="start"
                        startIcon={<PublishIcon />}
                        variant="contained"
                        sx={{ width: 110 }}
                    >
                        Submit
                    </LoadingButton> :
                    (
                        stepOneComplete ?
                            <>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant='contained'
                                    startIcon={<NavigateBeforeIcon />}
                                >
                                    Prev
                                </Button>
                                <Button
                                    disabled={activeStep === steps.length - 1}
                                    onClick={handleNext}
                                    variant='contained'
                                    endIcon={<NavigateNextIcon />}
                                >
                                    Next
                                </Button>
                            </> : null
                    )

                }

            </Box>
        )
    }

export default FormButtons