import { FC } from 'react'

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { tableCellClasses } from '@mui/material'

import { PageTable } from 'interfaces/form-preview.interface';

interface ClaimItemProps {
    table: PageTable;
}

const ClaimItem: FC<ClaimItemProps> = ({ table }) => {

    return (
        <Box>
            <TableContainer
                sx={{
                    borderRadius: "6px",
                    border: '3px solid rgba(128, 128, 128, 0.6)',
                }}
            >
                <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                        border: '1px solid rgba(128, 128, 128, 0.5)',
                        width: '100%',
                        py: 0.5
                    },
                }}>
                    <TableBody>
                        {table?.data?.map((row: any, index: number) => {
                            return (
                                <TableRow key={index} sx={{ display: 'flex' }}>
                                    <TableCell>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{Object.keys(row)[0]}: <span style={{ fontWeight: 400 }}>{`${Object.values(row)[0]}`}</span></Typography>
                                    </TableCell>
                                    <TableCell>
                                        {Object.values(row)[1] ? <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{Object.keys(row)[1]}: <span style={{ fontWeight: 400 }}>{`${Object.values(row)[1]}`}</span></Typography> : ''}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {table?.footerData?.map((row: any, index: number) => {
                            return (
                                <TableRow key={index} sx={{ display: 'flex' }}>
                                    <TableCell>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{Object.keys(row)[0]}: <span style={{ fontWeight: 400 }}>{`${Object.values(row)[0]}`}</span></Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default ClaimItem