import create from 'zustand';

export const setPendingFormApidata = create((set) => ({
  pendingFormApiData: [],
  setPendingFormApiData: (pendingForm: any) => set(() => ({ pendingFormApiData: pendingForm })),
}));

export const setSubmitFormApidata = create((set) => ({
  submitFormApiData: [],
  setSubmitFormApiData: (submitForm: any) => set(() => ({ submitFormApiData: submitForm })),
}));

// MANAGE SELECT FOR SUBMIT FORMS
export const setSubmittedForm = create((set) => ({
  submittedForms: [],
  setSubmittedForms: (submitForm: any) => set(() => ({ submittedForms: submitForm })),
}));

// MANAGE SELECT FOR PENDING FORMS
export const setPendingForm = create((set) => ({
  pendingForms: [],
  setPendingForms: (pendingForm: any) => set(() => ({ pendingForms: pendingForm })),
}));

interface CustomerForm {
  formData: any;
  validating: boolean;
  completedSteps: number[];
  failedSteps: number[];
  focus: string | undefined;
  updateStepsStatus: (payload: any) => void;
  updateForm: (payload: any) => void;
  updateFocus: (payload: string) => void;
  setValidating: (payload: boolean) => void;
  resetForm: () => void;
}

const initialValues = {
  formData: null,
  validating: false,
  completedSteps: [],
  failedSteps: [],
};

export const useFormStore = create<CustomerForm>((set: any) => ({
  ...initialValues,
  focus: undefined,
  updateStepsStatus: (payload) => set({ ...payload }),
  updateForm: (payload) => set({ formData: { ...payload } }),
  updateFocus: (payload) => set({ focus: payload }),
  setValidating: (payload) => set({ validating: payload }),
  resetForm: () => set({ ...initialValues }),
}));
