import { progressSummary, Progress } from "interfaces/inprogressform.interface";
// create summary for Orders table
export const createprogressSummary = (progress: Progress): progressSummary => {
  const { type, formNumber, claimDate, totalAmount, purchaseRequestForm } =
    progress;

  return {
    type: type,
    formNumber: formNumber,
    claimDate: claimDate,
    totalAmount: totalAmount,
    purchaseRequestForm: purchaseRequestForm,
  };
};
