import { product, productSummery } from 'interfaces/product.interfaces';
// create summary for Orders table
export const createProductSummary = (product: product): productSummery => {
    const {
        product_id,
        item_code,
        item_description,
        unit_price,
        tax_rate
    } = product;

    return {
        product_id: product_id,
        item_code: item_code,
        item_description: item_description,
        unit_price: unit_price,
        tax_rate: tax_rate
    };
};