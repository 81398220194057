import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CustomTextArea from 'components/common/CustomTextArea';
import { useInvoiceStore } from 'state/invoice.store';

const RequestInfo = () => {
  const { updateInvoice, personRequestedEmail, personRequestedName } =
    useInvoiceStore();

  return (
    <>
      <CustomTextArea
        onChange={(e) => updateInvoice({ reason: e.target.value })}
        required
      />
      <Box sx={{ py: 1 }}>
        <Typography variant='caption' sx={{ color: 'black' }}>
          Recipient name
        </Typography>
        <TextField
          id='person-requested-name'
          placeholder='Please type recipient’s name'
          fullWidth
          value={personRequestedName}
          onChange={(e) =>
            updateInvoice({ personRequestedName: e.target.value })
          }
          variant='outlined'
          size='small'
          required
          autoComplete='off'
          inputProps={{
            sx: {
              fontSize: 13,
              '&::placeholder': { opacity: 0.6 },
            },
          }}
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <Typography variant='caption' sx={{ color: 'black' }}>
          Recipient email
        </Typography>
        <TextField
          id='person-requested-email'
          placeholder='Please type recipient’s email'
          fullWidth
          type='email'
          value={personRequestedEmail}
          onChange={(e) =>
            updateInvoice({ personRequestedEmail: e.target.value })
          }
          variant='outlined'
          size='small'
          required
          autoComplete='off'
          inputProps={{
            sx: {
              fontSize: 13,
              '&::placeholder': { opacity: 0.6 },
            },
          }}
        />
      </Box>
    </>
  );
};

export default RequestInfo;
