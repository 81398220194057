import { FC, useEffect, useRef } from 'react'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import FormHeader from './FormHeader'
import EmployeeInformation from './EmployeeInformation'
import ClaimItem from './ClaimItem';
import { ClaimFormResponse, PageTable } from 'interfaces/form-preview.interface';
import PartyDetails from './PartyDetails';
import PartyBudget from './PartyDetails';
import { dataURLFileLoader } from '@cyntler/react-doc-viewer';

interface ClaimItemsProps {
    formData: ClaimFormResponse;
    tables: PageTable[];
    reorder: (page: number) => void;
    page: number;
    totalPages: number;
    startCount: number;
    formType: string;
    paperView?: boolean;
    country: string;
    totalAmountInclGst?: number
}

const ClaimItems: FC<ClaimItemsProps> = ({
  formData,
  tables,
  reorder,
  page,
  totalPages,
  startCount,
  formType,
  paperView,
  country,
  totalAmountInclGst
}) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const hasOverflow = ref.current.scrollHeight > ref.current.clientHeight;
    if (hasOverflow) {
      reorder(page);
    }
  }, [ref, tables, reorder]);



  return (
    <Paper
      elevation={paperView ? 3 : 0}
      sx={{
        width: '21cm',
        height: '29.7cm',
        marginX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        px: 4,
        py: 5,
        position: 'relative',
        breakInside: 'avoid',
        breakBefore: 'always',
        marginY: paperView ? 3 : null,
        boxShadow: paperView ? null : 'none',
      }}
    >
      <FormHeader
        documentDocuwareId={formData.documentDocuwareId}
        formType={formType}
        country={country}
      />
      <EmployeeInformation
        user={formData.user}
        date={formData.claimDate ?? formData.createdAt}
      />
      {isEntertainmmentType(formType) && formData?.budgetInclGST != 0 && (
        <>
          <Box sx={{ py: 2 }}>
            <Typography variant="h6" color="gray" sx={{ pb: 1 }}>
              Party Budget
            </Typography>
            <PartyBudget
              partyItems={formData}
              totalAmountInclGst={totalAmountInclGst || 0}
            ></PartyBudget>
          </Box>
        </>
      )}
      <Box ref={ref} sx={{ height: paperView ? '19cm' : '21.2cm' }}>
        {tables?.map((table: PageTable, index: number) => {
          if (table.type === 'claim_total') {
            const total = parseFloat(
              table.data.total.replace('$', '').replace(/,/g, '')
            );
            const budgetInclGST = Number(formData.budgetInclGST);
            const budgetWarning =
              isEntertainmmentType(formType) &&
              Boolean(formData?.budgetInclGST && formData?.budgetInclGST > 0) &&
              budgetInclGST < total;

            return (
              <Box
                key={index}
                sx={{
                  p: 1,
                  backgroundColor: 'rgba(128, 128, 128, 0.3)',
                  border: '3px solid rgba(128, 128, 128, 0.6)', // Default border style
                  borderRadius: '6px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ pb: 1, color: budgetWarning ? 'red' : 'inherit' }}
                >{`Total: ${table.data.total}`}</Typography>
              </Box>
            );
          }
          if (table.type === 'comment') {
            return (
              <Box
                key={index}
                sx={{
                  py: 5,
                }}
              >
                <TextField
                  disabled
                  fullWidth
                  id="outlined-disabled"
                  label="Comment"
                  defaultValue={table.data}
                  multiline
                  minRows={3}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                      color: '#000',
                    },
                  }}
                />
              </Box>
            );
          }
          return (
            <Box key={index} sx={{ py: 3 }}>
              <Typography variant="h6" color="gray" sx={{ pb: 1 }}>
                {`Item ${startCount + index} ${
                  table.subcategory ? '- ' + table.subcategory : ''
                }`}
              </Typography>
              <ClaimItem table={table} />
            </Box>
          );
        })}
      </Box>

      <Typography
        sx={{ position: 'absolute', right: 30, bottom: 30 }}
      >{`Page ${page} - ${totalPages}`}</Typography>
    </Paper>
  );
};

function isEntertainmmentType( type: string): boolean{
  return type === "Employee Entertainment Expense Claim"
}

export default ClaimItems;