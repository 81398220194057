import { FC } from 'react'
import moment from 'moment';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { User } from 'interfaces/user.interface';

interface EmployeeInformationProps {
    user: User;
    date: string;
}

const EmployeeInformation: FC<EmployeeInformationProps> = ({ user, date }) => {

    return (
        <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", pr: 6 }}>
            <Box>
                <Stack
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Name:
                    </Typography>
                    <Typography variant="subtitle1">{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Employee Number:
                    </Typography>
                    <Typography variant="subtitle1">{user.employeeNumber}</Typography>
                </Stack>

                <Stack
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Date:
                    </Typography>
                    <Typography variant="subtitle1">{moment(date).format('DD/MM/YYYY')}</Typography>
                </Stack>
            </Box>
            <Box>
                <Stack
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Branch:
                    </Typography>
                    <Typography variant="subtitle1">{user.branch ?? ''}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Department:
                    </Typography>
                    <Typography variant="subtitle1">{user.department ?? ''}</Typography>
                </Stack>
            </Box>

        </Box>
    )
}

export default EmployeeInformation