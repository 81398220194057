import { FC } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'
import grey from "@mui/material/colors/grey";

import { FormStatus, useCustomerStore } from "state/customer.store";
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";
import { CUSTOMER_DOCUMENT_TYPES } from "enums/customer-trade-application-form.enums";
import { useLocation, useNavigate } from "react-router-dom";

const DRAWER_WIDTH = 210;

interface CustomerSidebarProps {
    setDeleteForm: (form: FormStatus) => void
}

const CustomerSidebar: FC<CustomerSidebarProps> = ({ setDeleteForm }) => {
    const { customerApplicationForms, paymentInformationForms, activeForm, setCustomerApplicationForms, setPaymentInformationForms, setActiveForm } = useCustomerStore()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const location = useLocation()

    const isCustomerTradeApplication = location.pathname.includes('customer-trade-application-form')
    const isPaymentInformation = location.pathname.includes('payment-information-form')

    const handleCreateNewForm = (formType: CUSTOMER_DOCUMENT_TYPES) => {
        if ((formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ? [...customerApplicationForms] : [...paymentInformationForms]).includes(null)) {
            const contentOption = snackBarStyle({
                text: 'Please use the blank form before creating a new one.',
                variant: "warning",
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        setActiveForm(null)
        formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
            setCustomerApplicationForms([...customerApplicationForms, null]) :
            setPaymentInformationForms([...paymentInformationForms, null])
    }



    const handleDeleteForm = (form: FormStatus | null, formType: CUSTOMER_DOCUMENT_TYPES) => {
        if (form) {
            setDeleteForm(form)
        } else {
            if (activeForm === null) {
                if (formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM) {
                    setActiveForm(customerApplicationForms[0])
                } else {
                    setActiveForm(paymentInformationForms[0])
                }

            }

            formType === CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM ?
                setCustomerApplicationForms([...customerApplicationForms.filter(form => form !== null)]) :
                setPaymentInformationForms([...paymentInformationForms.filter(form => form !== null)])
        }
    }

    return (
        <Paper
            elevation={5}
            sx={{
                borderRadius: 0,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                display: { xs: 'none', lg: 'flex' },
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflow: 'auto',
                height: '100%',
                zIndex: 11,
                overflowX: 'hidden',
                width: DRAWER_WIDTH,
                p: 1
            }}
        >

            {isCustomerTradeApplication ?
                <>
                    <Typography variant="caption" sx={{ fontWeight: 800, color: grey[500] }}>Customer Trade Applications</Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 1 }}>
                        {customerApplicationForms.map((form, index) => {
                            return (
                                <Box key={form?.id ?? null} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        sx={{
                                            textDecoration: (isCustomerTradeApplication && activeForm?.id === form?.id) ? null : 'none',
                                            fontWeight: (isCustomerTradeApplication && activeForm?.id === form?.id) ? 600 : 400
                                        }}
                                        onClick={() => {
                                            setActiveForm(form)
                                            navigate('/customer/customer-trade-application-form/applicant_details')
                                        }}
                                    >
                                        {`Form ${index + 1} ${!form ? '(blank)' : ''}`}
                                    </Link>
                                    {(form?.status === 'partial' || (customerApplicationForms.length > 1 && form === null)) ?
                                        <IconButton onClick={() => handleDeleteForm(form ?? null, CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM)}>
                                            <DeleteIcon fontSize="small" color="primary" />
                                        </IconButton> : null}

                                </Box>
                            )
                        })}
                        <Button onClick={() => handleCreateNewForm(CUSTOMER_DOCUMENT_TYPES.CUSTOMER_TRADE_APPLICATION_FORM)} size="large" sx={{ textTransform: 'none', p: 0 }}>+ New Form</Button>
                    </Box>
                </> : null}

            {isPaymentInformation ?
                <>
                    <Typography variant="caption" sx={{ fontWeight: 800, color: grey[500] }}>Payment Information</Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 1 }}>

                        {paymentInformationForms.map((form, index) => {
                            return (
                                <Box key={form?.id ?? null} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        sx={{
                                            textDecoration: (isPaymentInformation && activeForm?.id === form?.id) ? null : 'none',
                                            fontWeight: (isPaymentInformation && activeForm?.id === form?.id) ? 600 : 400
                                        }}
                                        onClick={() => {
                                            setActiveForm(form)
                                            navigate('/customer/payment-information-form/applicant_details')
                                        }}
                                    >
                                        {`Form ${index + 1} ${!form ? '(blank)' : ''}`}
                                    </Link>
                                    {(form?.status === 'partial' || (paymentInformationForms.length > 1 && form === null)) ?
                                        <IconButton onClick={() => handleDeleteForm(form ?? null, CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM)}>
                                            <DeleteIcon fontSize="small" color="primary" />
                                        </IconButton> : null}

                                </Box>
                            )
                        })}

                        <Button onClick={() => handleCreateNewForm(CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM)} size="large" sx={{ textTransform: 'none', p: 0 }}>+ New Form</Button>
                    </Box>
                </> : null}
        </Paper>
    )
}

export default CustomerSidebar