import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";

const CreateVendorAddress = () => {
  return (
    <>
      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label="Street"
              fullWidth
              type="search"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Street"
              fullWidth
              type="search"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PostCode"
              fullWidth
              type="search"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              fullWidth
              type="search"
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateVendorAddress;
