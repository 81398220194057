import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { CREDIT_CARD_DETAILS_LAYOUT, CREDIT_CARD_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout'
import { useFormStore } from 'state/form.store'
import { Box, Link } from "@mui/material";
import DeleteCreditCardDialog from "./DeleteCreditCardDialog";

import Cards, { Focused } from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useLocation } from "react-router-dom";



const CreditCard = () => {

    const location = useLocation()

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    const { formData, validating, focus, updateFocus, updateForm, } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    useEffect(() => {
        updateFocus('number')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.CARD_HOLDER_NAME_])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", pb: 2 }}>

            {(location.pathname.includes('payment-information-form') && formData?.DIRECT_DEBIT_CHOSEN === 'No') &&
                <Box sx={{ pb: 2 }}>
                    <Typography sx={{ textAlign: 'justify', fontWeight: 600 }}>
                        Please be aware that your current payment method will not change after you complete the form.
                        For security reasons, we need to get another type of payment. Additionally, please note that in
                        the event of non-payment of overdue invoices as per our terms and conditions, we may utilize
                        the credit card information provided.
                    </Typography>
                </Box>}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: "center", md: 'flex-start' },
                    justifyContent: 'center',
                    gap: 1, pb: 2
                }}
            >
                <Box>
                    {!formData?.credit_card_submitted ?
                        <Grid container spacing={1}>
                            {CREDIT_CARD_DETAILS_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN).map((item: FormControlItemLayout) => {
                                return (
                                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                                        <FormControlItem
                                            data={formData}
                                            onChange={onChange}
                                            layout={item}
                                            submitValidating={validating}
                                        />
                                    </Grid>
                                )
                            })}

                        </Grid> : null
                    }
                </Box>

                <Box>

                    {formData?.credit_card_submitted ?
                        <Box sx={{ py: 1 }}>
                            <Link variant="caption" sx={{ cursor: 'pointer' }} onClick={() => setShowDeleteDialog(true)}>Change credit card.</Link>
                        </Box> :
                        null
                    }
                    <Box
                        sx={{
                            width: 'fit-content',
                            cursor: 'cell',
                            display: { xs: formData?.credit_card_submitted ? "block" : "none", md: "block" }
                        }}
                        onClick={() => updateFocus(focus === 'cvc' ? 'number' : 'cvc')}
                    >
                        <Cards
                            number={formData?.CARD_NUMBER ?? ''}
                            expiry={formData?.EXPIRY_DATE_ ?? ''}
                            cvc={formData?.CSV__SECURITY_CODE_ ?? ''}
                            name={formData?.CARD_HOLDER_NAME_ ?? ''}
                            focused={focus as Focused}
                            preview={true}
                        />
                    </Box >


                </Box>
            </Box>

            {CREDIT_CARD_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN).map((item: FormControlItemLayout) => {
                return (
                    <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                        <FormControlItem
                            data={formData}
                            onChange={onChange}
                            layout={item}
                            submitValidating={validating}
                        />

                    </Grid>
                )
            })}

            <DeleteCreditCardDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
            />
        </Box>
    )
}

export default CreditCard