import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

export interface OrderStatusTabsProps {
  // Order: Invoice[];
  statusName: string;
  // Order: [];
}

const OrderStatusTabs: React.FC<OrderStatusTabsProps> = ({ statusName }) => {
  return (
    <Box>
      <Box>
        <Tabs value={statusName} aria-label='basic tabs example'>
          <Tab
            sx={{ textTransform: 'none' }}
            label={statusName} // (${Order.length})
            value={statusName}
          />
          {/* <Tab label="Approved(0)" value="Approved" />
          <Tab label="Rejected(0)" value="Rejected" /> */}
        </Tabs>
      </Box>
    </Box>
  );
};

export default OrderStatusTabs;
