import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AppLogo from 'components/common/AppLogo';
import Logo from 'components/common/Logo';

const Authentication = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
      }}
    >
      <Box display='flex' height='100vh'>
        <Box sx={{ width: '100%', maxWidth: '544px', position: 'relative' }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: { xs: 0, md: 3 },
            }}
          >

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <AppLogo style={{ width: '70%' }} />
            </Box>

            <Outlet />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                py: 1,
              }}
            >
              <Typography variant='body2' color='primary'>
                Powered by
              </Typography>
              <Logo color='#536DFE' width='150' height='40' />
            </Box>

            <Box
              sx={{
                position: 'absolute',
                bottom: 20,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: { xs: 0, md: 5 },
              }}
            >
              {location.pathname === '/auth/signin' && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: 0.5,
                    justifyContent: 'flex-end',
                    pr: 2
                  }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    No account?{' '}
                  </Typography>
                  <Link
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigate('/auth/signup');
                    }}
                    underline='always'
                    variant='body2'
                    sx={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    Sign up
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box flex='1' position='relative'>
          <Box
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            style={{
              backgroundImage: `url('/static/img/auth-bg.jpg')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Authentication;
