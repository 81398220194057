import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import API from 'api';
import { useUserStore } from 'state/user.store';

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const { updateIsAuth } = useUserStore();
  const [loading, setLoading] = React.useState(false);

  const handleOnChangeCredentials = (event: any) => {
    setErrors(null);
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const savedPublicPath = sessionStorage.getItem('public-path')
    if (savedPublicPath) {
      navigate(savedPublicPath)
    }
  }, [])

  const signInRequest = async () => {
    const form = {
      username: credentials.email,
      password: credentials.password,
      clientUrl: window.location.origin
    };
    try {
      const { data } = await API.post('/auth/signin', form);
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        updateIsAuth();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const errors = (err as any).response.data.message;
      if (Array.isArray(errors)) {
        setErrors(errors);
      } else {
        setErrors([errors]);
      }
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    signInRequest();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      {errors && (
        <Box sx={{ pb: 2 }}>
          {errors.map((error, index) => {
            return (
              <Box key={index}>
                <Typography color='error' variant='caption'>
                  ⚠ {error}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}

      <form onSubmit={handleLogin}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ py: 1 }}>
            <Typography variant='body2'>Email</Typography>
            <TextField
              fullWidth
              name='email'
              type='email'
              placeholder='Please type your email'
              value={credentials.email}
              onChange={handleOnChangeCredentials}
              variant='outlined'
              required
              sx={{ pt: 0.5 }}
              inputProps={{
                sx: {
                  fontSize: 14,
                  '&::placeholder': { opacity: 0.7 },
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant='body2'>Password</Typography>
            <TextField
              fullWidth
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder='Please type your password'
              value={credentials.password}
              onChange={handleOnChangeCredentials}
              variant='outlined'
              required
              autoComplete='off'
              sx={{ pt: 0.5 }}
              inputProps={{
                sx: {
                  fontSize: 14,
                  '&::placeholder': { opacity: 0.7 },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      size='large'
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Link
            onClick={(event: any) => {
              event.preventDefault();
              navigate('/auth/forgotPassword');
            }}
            variant='caption'
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            Forgot password?
          </Link>
          <LoadingButton
            fullWidth
            type='submit'
            loading={loading}
            variant='contained'
            sx={{ mt: 2, textTransform: 'none' }}
            size='medium'
          >
            Login
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
