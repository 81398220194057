// PartySection.tsx

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { PartyItems } from 'interfaces/myforms.interface';
import { ENTERTAINMENT_EXPENSE_PARTY_LAYOUT } from 'components/forms/form-layouts/entertainment-expense.layout';
import FormControlItem from 'components/forms/form-components/FormControlItem';
import { AddBox } from '@mui/icons-material';

interface PartySectionProps {
  setData: React.Dispatch<React.SetStateAction<any>>;
  submitValidating: boolean;
  partyDataValidating: boolean;
  allBudgetInputsPopulated: boolean;
  budgetInclGST: number;
  partyData: PartyItems;
  setPartyData:React.Dispatch<React.SetStateAction<any>>;
}

const EntertainmentPartyDetails: React.FC<PartySectionProps> = (
  { submitValidating, partyDataValidating, allBudgetInputsPopulated,budgetInclGST, partyData,setPartyData },
) => {
  const partyLayouts = ENTERTAINMENT_EXPENSE_PARTY_LAYOUT;
  const newFormPartyLayouts = partyLayouts.map((layout) => {
    return { ...layout };
  });

  const handleChangeFormPartyData = (value: any) => {
    setPartyData(value);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 500 }}>
        Party Details
      </Typography>
      <Paper elevation={5} sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 500, paddingBottom: 4, textAlign: 'center' }}
        >
          Budget Review
        </Typography>
        <Grid container spacing={4}>
          {newFormPartyLayouts.map((item, itemIndex) => (
            <Grid
              key={`${item.name}${itemIndex}`}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <FormControlItem
                data={partyData}
                onChange={handleChangeFormPartyData}
                layout={item}
                submitValidating={submitValidating}
                itemsValidating={partyDataValidating}
              />
                            {item.name.includes('ytd') && (
                  <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    <strong>Note:</strong> The budget shall be adjusted accordingly depending on the company's overall YTD. Please confirm before submitting it.
                  </Typography>
                )}
            </Grid>
          ))}

          {allBudgetInputsPopulated && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  paddingBottom: 4,
                  marginTop: '10px',
                  textAlign: 'left',
                }}
              >
                Budget Incl. GST:{' '}
                {` ${budgetInclGST.toLocaleString('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                })}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default EntertainmentPartyDetails;
