import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { DOC_STATUS, WORKFLOW_STATUS } from 'enums/invoice.enums';

export interface InvoiceStatusTabsProps {
  status: string;
  currentSearchParams: URLSearchParams;
}

const InvoiceStatusTabs: React.FC<InvoiceStatusTabsProps> = ({ status, currentSearchParams }) => {

  const [_, setSearchParams] = useSearchParams();

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    const key = newValue === DOC_STATUS.WAITING_APPROVAL ? "status" : "workflow_status"
    newValue === DOC_STATUS.WAITING_APPROVAL ? currentSearchParams.delete("workflow_status") : currentSearchParams.delete("status")
    currentSearchParams.set(key, newValue)
    currentSearchParams.set('page', '0')
    setSearchParams(currentSearchParams)
  };

  return (
    <Box>
      <Tabs
        value={status}
        onChange={handleChange}
      >
        <Tab
          sx={{ textTransform: 'none' }}
          label='Pending'
          value={DOC_STATUS.WAITING_APPROVAL}
        />
        <Tab
          sx={{ textTransform: 'none' }}
          label='Approved'
          value={WORKFLOW_STATUS.APPROVED_DECISION}
        />
        <Tab
          sx={{ textTransform: 'none' }}
          label='Rejected'
          value={WORKFLOW_STATUS.REJECTED_DECISION}
        />
      </Tabs>
    </Box>
  );
};

export default InvoiceStatusTabs;
