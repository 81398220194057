import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import API from "api";
import RequestUpgradeDialog from "components/common/RequestUpgradeDialog";
import OrderStatusTabs from "components/orders/orderStatusTabs";
import { DeleteProductModal } from "components/products/modal/deleteProductModal";
import ProductsTable from "components/products/productsTable";
import HelpMenuDialog from "components/sidebar/HelpMenuDialog";
import { EMAIL_CATEGORY } from "interfaces/enums";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInvoiceStore } from "state/invoice.store";
import { setProductdata } from "state/product.store";

const Products = () => {
  const { bulkSelected } = useInvoiceStore();
  const { productApiData, setProductApiData }: any = setProductdata();
  console.log("productApiDataproductApiData", productApiData);
  //   const [deleteProduct, setDeleteProduct] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState<Boolean>(false)
  const [category, setCategory] = useState<EMAIL_CATEGORY | ''>('');

  //////////////////////////////////////////
  ///****DON'T DELETE COMMENTED CODE****////
  /////////////////////////////////////////

  // const [open, setOpen] = useState<boolean>(false);
  // const handleOpen = () => {
  //   console.log("handle open>>>>>>>>");
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  //   getProductRequest();
  // };

  // const navigate = useNavigate();

  // const getProductRequest = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const res = await API.get("/products");
  //     setProductApiData(res.data.data);
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [setProductApiData]);

  // useEffect(() => {
  //   getProductRequest();
  // }, [getProductRequest]);

  // const createNewProduct = () => {
  //   navigate("/create-new-product");
  // };

  // const EditProduct = (vendorID: any) => {
  //   navigate(`/edit-product`, { state: { editproductId: vendorID } });
  // };

  // const DeleteProduct = () => {
  //   handleOpen();
  // };

  // const searchProduct = async (e: any) => {
  //   const searchParam = e.target.value;
  //   if (searchParam.length) {
  //     const res = await API.get(`/products/search/${searchParam}`);
  //     setProductApiData(res.data.data);
  //   } else {
  //     getProductRequest();
  //   }
  // };

  const handleUpgradeRequest = () => {
    setOpenUpgradeDialog(true)
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Products
            </Typography>
            {loading ? (
              <CircularProgress sx={{ ml: 1.5 }} size={20} />
            ) : (
              <Tooltip title="Refresh" placement="right">
                {/* <IconButton onClick={() => getProductRequest()}>
                  <RefreshIcon />
                </IconButton> */}
                <IconButton onClick={handleUpgradeRequest}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            sx={{
              width: "38ch",
              "& .css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "15px",
              },
            }}
            type="search"
            variant="outlined"
            margin="dense"
            // onChange={searchProduct}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Search <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            gap: 2.2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            // onClick={createNewProduct}
            onClick={handleUpgradeRequest}
            disabled={bulkSelected.length > 0}
          >
            create new
          </Button>

          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => EditProduct(bulkSelected[0])}
            onClick={handleUpgradeRequest}
            disabled={bulkSelected.length > 1 || !bulkSelected.length}
          >
            Edit
          </Button>

          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<DeleteIcon />}
            disabled={!bulkSelected.length}
            // onClick={() => DeleteProduct()}
            onClick={handleUpgradeRequest}
          >
            Delete
          </Button>
        </Box>

        <Box
          sx={{
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <OrderStatusTabs statusName="ITEMS" />
        </Box>

        <Paper elevation={5} sx={{ height: "100%", p: 2 }}>
          <ProductsTable products={productApiData} />
        </Paper>
      </Box>
      {/* {open && (
        <DeleteProductModal
          open={open}
          handleClose={handleClose}
          deleteProduct={bulkSelected}
        />
      )} */}
      <RequestUpgradeDialog open={openUpgradeDialog} handleClose={() => setOpenUpgradeDialog(false)} setCategory={setCategory} />
      <HelpMenuDialog category={category} handleClose={() => setCategory('')} />
    </>
  );
};

export default Products;
