import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import { DialogProps } from "interfaces/common.interfaces";
import React, { useState } from "react";
import DocumentViewer from "../document-viewer/DocumentViewer";
import DocumentTable from "./DocumentTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DocumentModalProps extends DialogProps {
  docId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DocumentModalTable: React.FC<DocumentModalProps> = ({
  open,
  handleClose,
  docId,
}: any) => {
  const [tab, setTab] = useState<number>(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      sx={{
        "& div.MuiPaper-root": {
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="end">
          <IconButton
            onClick={handleClose}
            sx={{
              color: "black",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Document Files" />
            <Tab label="Index Entries" />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <DocumentViewer
            docId={docId}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DocumentTable docId={docId} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentModalTable;
