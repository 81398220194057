import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { formatCurrencyNumber } from "utils/common.utils";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export function MultiColorBarChart({
  data: propsData = [],
  title = "",
}: {
  data: Array<any>;
  title?: string;
}) {
  const options: any = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    cornerRadius: 4,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        formatter: (value: number) => {
          return formatCurrencyNumber(value, 0);
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        color: "black",
        text: title,
        font: {
          size: 24,
          weight: 600,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#828B9B",
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      // y: {
      //     position: 'right' as const,
      //     ticks: {
      //         stepSize: 1000,
      //         color: '#828B9B',
      //         beginAtZero: true,
      //     },
      //     grid: {
      //         drawBorder: false,
      //         color: '#f2f2f2',
      //     },

      // },
      y1: {
        position: "left" as const,
        ticks: {
          stepSize: 10000,
          color: "#828B9B",
          beginAtZero: true,
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return formatCurrencyNumber(value, 0);
          },
        },
        grid: {
          drawBorder: false,
          color: "#f2f2f2",
        },
      },
    },
  };

  const data: any = {
    labels: propsData.map((d: any) => {
      return d.label;
    }),
    datasets: [
      // {
      //     type: 'line' as const,
      //     label: 'Total Invoices',
      //     data: propsData.map((d: any) => {
      //         return d.count;
      //     }),
      //     backgroundColor: '#536DFE',
      //     borderColor: '#536DFE',
      //     yAxisID: 'y',
      // },

      {
        type: "bar" as const,
        label: "Total Amount",
        borderSkipped: false,
        categoryPercentage: 0.6,
        barPercentage: 0.7,
        data: propsData.map((d: any) => {
          return d.total;
        }),
        backgroundColor: ["#92D050", "#4472C4", "#FF0000", "#FFC000"],
        fillCOlor: ["#92D050", "#4472C4", "#FF0000", "#FFC000"],
        yAxisID: "y1",
        datalabels: {
          anchor: "end",
          align: "top",
          color: "black",
        },
      },
    ],
  };

  return (
    <Chart
      type="bar"
      plugins={[ChartDataLabels]}
      options={options}
      data={data}
    />
  );
}
