import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import grey from '@mui/material/colors/grey';

import { FormStep } from "utils/form.utils";

interface FormSelectorProps {
    steps: FormStep[];
    completedSteps: number[];
    failedSteps: number[];
    activeStep: number;
    stepOneComplete: boolean;
}

const FormSelector: FC<FormSelectorProps> = ({ steps, completedSteps, failedSteps, activeStep, stepOneComplete }) => {

    const navigate = useNavigate()

    const renderStep = (step: string, index: number) => {
        switch (true) {
            case activeStep === index:
                return (
                    <>
                        <ListItemIcon><HorizontalRuleIcon fontSize="medium" sx={{ rotate: '90deg' }} /></ListItemIcon>
                        <Typography color='GrayText'>{step}</Typography>
                    </>
                )
            case failedSteps.includes(index):
                return (
                    <>
                        <ListItemIcon>
                            <WarningAmberIcon fontSize="small" color='error' />
                        </ListItemIcon>
                        <Typography color='error'>{step}</Typography>
                    </>
                )
            case completedSteps.includes(index):
                return (
                    <>
                        <ListItemIcon>
                            <CheckIcon fontSize="small" color='primary' />
                        </ListItemIcon>
                        <Typography color='primary'>{step}</Typography>
                    </>
                )
            default:
                return (
                    <>
                        <ListItemIcon></ListItemIcon>
                        <Typography color='GrayText'>{step}</Typography>
                    </>
                )
        }
    }

    return (
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <MenuList>
                {steps.map((step, index) => {
                    return (
                        <MenuItem
                            key={step.route}
                            disabled={!stepOneComplete}
                            onClick={() => navigate(step.route)}
                            sx={{ backgroundColor: activeStep === index ? grey[100] : '' }}
                        >
                            {renderStep(step.label, index)}
                        </MenuItem>

                    )
                })}
            </MenuList>
        </Box>
    )
}

export default FormSelector