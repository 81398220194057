import { FC } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ClaimFormResponse } from 'interfaces/form-preview.interface';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';

interface PartyBudgetProps {
  partyItems: ClaimFormResponse;
  totalAmountInclGst: number
}

const PartyBudget: FC<PartyBudgetProps> = ({ partyItems, totalAmountInclGst }) => {
  return (
    <Box>
      <TableContainer
        sx={{
          borderRadius: '6px',
          border: '3px solid rgba(128, 128, 128, 0.6)',
        }}
      ></TableContainer>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: '1px solid rgba(128, 128, 128, 0.5)',
            width: '100%',
            py: 0.5,
          },
        }}
      >
        <TableBody>
          <TableRow sx={{ display: 'flex' }}>
            <TableCell>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Amount per person:{' '}
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  {partyItems.amountPerPerson?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD', // Change currency code as needed
                  })}
                </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Number of people:{' '}
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  {partyItems.numberOfPeople}
                </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                YTD:{' '}
                <span style={{ fontWeight: 400 }}> {`${partyItems.ytd}%`}</span>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ display: 'flex' }}>
            <TableCell sx= {{color: partyItems.budgetInclGST && partyItems.budgetInclGST < totalAmountInclGst ? 'red' : 'inherit',}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Budget Inc. GST:{' '}
                <span style={{   color: partyItems.budgetInclGST && partyItems.budgetInclGST < totalAmountInclGst ? 'red' : 'inherit',}}>
                  {' '}
                  {partyItems.budgetInclGST?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD', // Change currency code as needed
                  })}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default PartyBudget;
