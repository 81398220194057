import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CircularProgressProps } from '@mui/material/CircularProgress';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant='determinate' {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='caption'
            component='div'
            color='text.secondary'
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const ProcessingLoader = ({ value, ...props }: CircularProgressProps) => {
  return typeof value === 'number' ? (
    <CircularProgressWithLabel value={value} {...props} />
  ) : (
    <Box
      sx={{
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
      <Typography variant='h6'>Processing Invoice</Typography>
    </Box>
  );
};

export default ProcessingLoader;
