import moment from 'moment';

export const convertArrayToObject = (array: any, key: string) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item.Item,
    };
  }, initialValue);
};

export const formatCurrencyNumber = (value: any, fixedDigits = 2) => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fixedDigits,
  }).format(value);
};

export const getRandomColor = () => {
  return '#' + (Math.random().toString(16) + '000000').substring(2, 8);
};

export const getPercentage = (
  partialValue: any,
  totalValue: any,
  fixedDigits = 2
) => {
  return Number(totalValue)
    ? ((100 * Number(partialValue)) / Number(totalValue)).toFixed(fixedDigits)
    : 0;
};

// password validator
// export function checkRegex(string: any) {
//   if (string.length < 8) return false;
//   let checkSpecial = /[*@!#%&()^~{}]+/.test(string),
//     checkUpper = /[A-Z]+/.test(string),
//     checkLower = /[a-z]+/.test(string),
//     r = false;
//   if (checkUpper && checkLower && checkSpecial) {
//     r = true;
//   }
//   return r;
// }

// password validator
export function checkRegex(string: any) {
  const matches = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    string
  );
  if (string.length >= 8 && matches) {
    return true;
  }
  return false;
}

export const getMomentIntervalArray = (
  amount: number = 12,
  unit: moment.unitOfTime.DurationConstructor = 'month'
) => {
  const ret = [];

  for (let i = 0; i < amount; i++) {
    const m = moment().subtract(i, unit);
    ret.push(m);
  }

  return ret.reverse();
};

export function formatArray<T = any>(param: any): Array<T> {
  return Array.isArray(param) ? param : [];
}

export function ymd2dmy(str: string) {
  const m = moment(str, 'Y-M-D', true);
  if (m.isValid()) {
    return m.format('D/M/Y');
  }
  return '';
}

export function mdy2dmy(str: string) {
  const m = moment(str, 'M/D/Y', true);
  if (m.isValid()) {
    return m.format('D/M/Y');
  }
  return '';
}

export const dataUrlToFile = (dataUrl: any, docExtension?: string) => {
  let trimmedString: string;
  if (docExtension === 'pdf') {
    trimmedString = dataUrl.replace('data:application/pdf;base64,', '');
  } else {
    trimmedString = dataUrl.replace(
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      ''
    );
  }
  const bs = window.atob(trimmedString);
  const buffer = new ArrayBuffer(bs.length);
  const ba = new Uint8Array(buffer);
  for (var i = 0; i < bs.length; i++) {
    ba[i] = bs.charCodeAt(i);
  }
  return new Blob([ba]);
  // const newFile = new File([blob], fileName, {
  //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // });
};

export const toNumber = (p: any) => {
  const n = Number(p);
  return isNaN(n) ? 0 : n;
};

export const compareStrings = (a: any = '', b: any = '', ignoreCase = true) => {
  return (
    String(a ?? '')
      .trim()
      .toLowerCase() ===
    String(b ?? '')
      .trim()
      .toLowerCase()
  );
};
type SnackBarVariant = {
  text: string;
  variant?: 'default' | 'error' | 'info' | 'success' | 'warning';
  vertical?: 'top' | 'bottom';
  horizontal?: 'right' | 'left' | 'center';
  autoHideDuration?: number;
};
export const snackBarStyle = (context: SnackBarVariant) => {
  const contentOption = {
    message: context.text,
    options: {
      anchorOrigin: {
        vertical: context?.vertical ?? 'top',
        horizontal: context?.horizontal ?? 'right',
      },
      variant: context?.variant ?? 'default',
      autoHideDuration: context?.autoHideDuration ?? 1500,
    },
  };
  return contentOption;
};

function padTo2Digits(num: any) {
  return num.toString().padStart(2, '0');
}

export const formattedDate = (date: Date | null) => {
  if (date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }
};
