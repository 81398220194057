import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockResetIcon from "@mui/icons-material/LockReset";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { green, grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import SuccessForgotPassword from "components/authentication/SuccessForgotPassword";
import Logo from "components/common/Logo";
import { useSettingStore } from "state/setting.store";
import { useUserStore } from "state/user.store";
import ChangePasswordDialog from "components/authentication/ChangePasswordDialog";

const Header = () => {
  const location = useLocation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openChangePasswordForm, setOpenChangePasswordForm] = useState<boolean>(false)
  const [showforgetPassSuccess, setShowforgetPassSuccess] =
    useState<boolean>(false);

  const { logout, isAuth } = useUserStore();

  const { toggleExtend } = useSettingStore();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isCustomerForm = location.pathname.includes('customer-trade-application-form') || location.pathname.includes('payment-information-form')

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ display: "flex", gap: 1.5, p: 2, alignItems: "center" }}>
        <Avatar sx={{ bgcolor: "#F6C470", width: 50, height: 50 }}>
          {isAuth?.firstName.charAt(0).toUpperCase() +
            isAuth?.lastName.charAt(0).toUpperCase()}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 500 }}
          >{`${isAuth?.firstName} ${isAuth?.lastName}`}</Typography>
          <Typography variant="caption" sx={{ color: grey[800] }}>
            {isAuth?.department}
          </Typography>
          <Typography variant="caption" sx={{ color: grey[600] }}>
            {isAuth?.username}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 1 }} />

      <MenuItem
        onClick={() => {
          setOpenChangePasswordForm(true)
          handleMenuClose();
        }}
      >
        <ListItemIcon>
          <LockResetIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText>Change Password</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          logout();
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText>Log out</ListItemText>
      </MenuItem>
    </Menu>
  );



  return (
    <>
      <SuccessForgotPassword
        open={showforgetPassSuccess}
        handleClose={() => setShowforgetPassSuccess(false)}
      />
      <AppBar style={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar>
            <IconButton
              edge="start"
              sx={{ mr: 2, display: { xs: 'block', lg: isCustomerForm ? 'none' : '' } }}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleExtend}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Logo color="#FFF" width="84" height="20" />
            <Box sx={{ flexGrow: 1 }} />

            {isCustomerForm ? null :
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  size="medium"
                >
                  <Badge
                    sx={{
                      marginRight: "1px",
                      "& span": {
                        bgcolor: green[500],
                        border:
                          // eslint-disable-next-line no-template-curly-in-string
                          "1.5px solid ${(props) => props.theme.palette.common.white}",
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                      },
                    }}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar sx={{ bgcolor: "#F6C470" }}>
                      {isAuth?.firstName.charAt(0).toUpperCase() +
                        isAuth?.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                  </Badge>
                </IconButton>
              </Box>}
          </Toolbar>
        </Box>
      </AppBar>

      {/* to offset content below appbar */}
      <Box sx={{ ...theme.mixins.toolbar }} />

      {isCustomerForm ? null : renderMenu}

      <ChangePasswordDialog open={openChangePasswordForm} handleClose={() => setOpenChangePasswordForm(false)} />
    </>
  );
};

export default Header;
