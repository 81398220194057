import moment from 'moment';

import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { grey } from "@mui/material/colors";

import { useFormStore } from 'state/form.store'
import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { REVIEW_BEFORE_SUBMISSION_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/review-before-submission.layout'
import { DRIVERS_LICENSE, GUARANTOR_LAYOUT_1, GUARANTOR_LAYOUT_2, TERMS_AND_CONDITIONS_CONFIRMATION, WITNESS_LAYOUT_1, WITNESS_LAYOUT_2 } from 'components/forms/form-layouts/customer-trade-application-layouts/terms-and-conditions.layout'
import SignatureImage from "components/forms/form-components/SignatureImage";
import { CREDIT_CARD_DETAILS_LAYOUT, CREDIT_CARD_LAYOUT, DIRECT_DEBIT_CHOSEN_LAYOUT } from "components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout";
import { DIRECT_DEBIT_LAYOUTS } from "utils/form.utils";
import { PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT } from "components/forms/form-layouts/payment-information-layouts/payment-information-applicant-details.layout";
import { FORM_CONTROL_TYPE } from "enums/form.enums";




const UploadedFileInfo = ({ uploadedFiles }: { uploadedFiles: string[] }) => {
    return (
        <Box sx={{ minHeight: 30, border: '1px dashed gray', borderRadius: 1, p: 3, display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-start' }}>
            {uploadedFiles?.length ? uploadedFiles.map((file, index) => {
                return (
                    <Chip key={index} label={file.substring(11)} sx={{ width: 'fit-content', borderRadius: 2 }} />
                )
            }) : <Typography>No file uploaded</Typography>
            }
        </Box>
    )
}

const RowData = ({ data, item }: { data: any, item: FormControlItemLayout }) => {
    if (item.type === FORM_CONTROL_TYPE.DATE) {
        return <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{item.label}: <span style={{ fontWeight: 400 }}>{data?.[item.name] ? moment(data?.[item.name]).format('DD/MM/YYYY') : ''}</span></Typography>
    } else if (item.type === FORM_CONTROL_TYPE.SIGNATURE) {
        return <SignatureImage label={item.label} imageUrl={data?.[item.name]} />
    } else {
        return <Typography variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{item.label}: <span style={{ fontWeight: 400 }}>{data?.[item.name] ?? ''}</span></Typography>
    }
}

const PaymentInformationReviewBeforeSubmission = () => {

    const {
        formData,
        validating,
        completedSteps,
        updateForm
    } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    const isPreviousStepsCompleted = () => {
        if (formData?.DIRECT_DEBIT_CHOSEN === 'Yes') {
            return completedSteps.includes(0) && completedSteps.includes(1) && completedSteps.includes(3)
        } else {
            return completedSteps.length === 4
        }
    }

    return (
        <Box sx={{ width: '100%', }}>

            {/* START APPLICANT DETAILS */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Applicant Details</Typography>

                {PAYMENT_INFO_APPLICANT_DETAILS_LAYOUT.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
            </Box>
            {/* END APPLICANT DETAILS */}

            <Divider />

            {/* START PAYMENT INFORMATION */}

            {formData?.DIRECT_DEBIT_CHOSEN === 'Yes' ?
                <Box>
                    <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Payment Information</Typography>
                    <RowData data={formData} item={DIRECT_DEBIT_CHOSEN_LAYOUT} />
                    {DIRECT_DEBIT_LAYOUTS.map((item, index) => {
                        return <RowData key={item.name} data={formData} item={item} />
                    })}
                    {[...CREDIT_CARD_DETAILS_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN), ...CREDIT_CARD_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN)].map((item, index) => {
                        return <RowData key={item.name} data={formData} item={item} />
                    })}
                </Box> :
                <Box>
                    <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Payment Information</Typography>
                    <RowData data={formData} item={DIRECT_DEBIT_CHOSEN_LAYOUT} />
                    {[...CREDIT_CARD_DETAILS_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN), ...CREDIT_CARD_LAYOUT(formData?.DIRECT_DEBIT_CHOSEN)].map((item, index) => {
                        return <RowData key={item.name} data={formData} item={item} />
                    })}
                </Box>}
            {/* END PAYMENT INFORMATION */}

            <Divider />

            {/* START TERMS AND CONDITIONS OF SALE */}
            <Box>
                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Terms and Conditions of Sale</Typography>
                <Typography key={TERMS_AND_CONDITIONS_CONFIRMATION.name} variant="body2" sx={{ fontWeight: 600, color: grey[700], py: 1 }}>{TERMS_AND_CONDITIONS_CONFIRMATION.label}: <span style={{ fontWeight: 400 }}>{formData?.[TERMS_AND_CONDITIONS_CONFIRMATION.name] ?? ''}</span></Typography>
                {GUARANTOR_LAYOUT_1.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
                {WITNESS_LAYOUT_1.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

                <Typography variant="body1" sx={{ fontWeight: 600, color: grey[800], py: 2 }}>Please attach a valid driver's license</Typography>
                <UploadedFileInfo uploadedFiles={formData?.[DRIVERS_LICENSE?.name]} />


                {GUARANTOR_LAYOUT_2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}
                {WITNESS_LAYOUT_2.map((item, index) => {
                    return <RowData key={item.name} data={formData} item={item} />
                })}

            </Box>
            {/* END TERMS AND CONDITIONS OF SALE */}

            <Divider />

            <Box sx={{ pt: 5, pb: 3 }}>
                {isPreviousStepsCompleted() ?
                    REVIEW_BEFORE_SUBMISSION_LAYOUT.map((item: FormControlItemLayout) => {
                        return (
                            <Grid key={item.name} item xs={12}>
                                <FormControlItem
                                    data={formData}
                                    onChange={onChange}
                                    layout={item}
                                    submitValidating={validating}
                                />
                            </Grid>
                        )
                    }) :
                    <Alert variant='standard' severity='info'>Please complete all steps to proceed with form submission.</Alert>
                }
            </Box>
        </Box>
    )
}

export default PaymentInformationReviewBeforeSubmission