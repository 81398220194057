import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import { FinalJson } from "../../state/purchaseorderLine.store";
import PurchaseOrderItemTable from "./purchaseOrderItemTable";

interface CreatePoOrderProps {
  children?: React.ReactNode;
  vendordata?: any;
}

const CreatePoOrder = (props: CreatePoOrderProps) => {
  const { vendordata } = props;
  const [poDate, setPoDate] = useState<Date | null>(null);
  const { purchaseOrderoneLine, setFinalJson } = FinalJson();

  //   useEffect(() => {
  //     console.log(
  //       "setFinalJsonsetFinalJsonsetFinalJsonsetFinalJson",
  //       purchaseOrderoneLine
  //     );
  //   }, []);

  // handle click event of the Add button
  const handleAddClick = () => {
    const _purchaseOrderoneLine = purchaseOrderoneLine;
    const addContent: any = [
      ...purchaseOrderoneLine.lineItem,
      {
        item_code: "",
        item_description: "",
        price: "",
        quantity: "",
        amount: "",
        unit_measure: "",
        tax_class: "",
        tax: "",
        discount_amount: "",
        total: "",
        _id: purchaseOrderoneLine.lineItem.length++,
      },
    ];
    _purchaseOrderoneLine.lineItem = addContent;
    setFinalJson(_purchaseOrderoneLine);
  };

  return (
    <>
      <Box>
        <Box>
          <Grid
            sx={{ justifyContent: "space-evenly" }}
            container
            spacing={2}
            columns={12}
            rowSpacing={1}
          >
            <Grid item xl={6} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Ship To Location
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Due date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={poDate}
                    onChange={(newValue) => {
                      setPoDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{
                          width: "300px",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Currency*
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={vendordata?.currency ? vendordata?.currency : ""}
                  required
                  variant="outlined"
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Terms Code*
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  variant="outlined"
                  value={vendordata?.terms ? vendordata?.terms : ""}
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={4} xl-offset={2} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  PO Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={poDate}
                    onChange={(newValue) => {
                      setPoDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{
                          width: "300px",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Bill To Location
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Carrier
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    mx: 1,
                    width: "125px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  Description
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "300px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Tabs value={"Item"}>
            <Tab
              sx={{ textTransform: "uppercase" }}
              label={"Item"}
              value={"Item"}
            />
          </Tabs>
        </Box>
        {/* {


                    purchaseOrderoneLine.test.map((value, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CreatePoLineItem value={value} inputdata={purchaseOrderoneLine} handleRemoveClick={handleRemoveClick} />
                            </React.Fragment>)
                    })
                } */}
        <Box sx={{ mt: "20px", borderRadius: "5px" }}>
          <PurchaseOrderItemTable
            rowsItem={purchaseOrderoneLine.lineItem.sort((a: any, b: any) => {
              return b - a;
            })}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            Add Item
          </Button>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Card
            sx={{
              backgroundColor: "#EFF0F4",
              borderRadius: 3,
              padding: "20px",
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                justifyItems: "right",
              }}
            >
              <Grid container justifyContent="flex-end">
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                  <Typography sx={{ width: "full" }}>Sub Total</Typography>
                  <Typography sx={{ width: "full" }}>Discount%:</Typography>
                  <Typography sx={{ width: "full" }}>
                    Discount Amount%:
                  </Typography>
                  <Typography sx={{ width: "full", fontWeight: "bold" }}>
                    Total:
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "right" }}>
                  <Typography sx={{ width: "full" }}>$198.80</Typography>
                  <Typography sx={{ width: "full" }}>00.0</Typography>
                  <Typography sx={{ width: "full" }}>00.0</Typography>
                  <Typography sx={{ width: "full", fontWeight: "bold" }}>
                    $198.80
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            justifyContent: "right",
            justifyItems: "right",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            sx={{
              paddingX: 3,
            }}
          >
            submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreatePoOrder;
