import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { InvoiceSummary } from 'interfaces/invoice.interface';

interface TableCellSupplierNameProps {
  row: InvoiceSummary;
  maxLength?: number;
}

const TableCellSupplierName: React.FC<TableCellSupplierNameProps> = ({
  row,
  maxLength = 15,
}) => {
  if (row?.vendorName?.length > maxLength) {
    return (
      <Tooltip title={row.vendorName} placement='bottom'>
        <Typography variant='inherit'>
          {row.vendorName.substring(0, maxLength)}...
        </Typography>
      </Tooltip>
    );
  }

  return <Typography variant='inherit'>{row.vendorName}</Typography>;
};

export default TableCellSupplierName;
