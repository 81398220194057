import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import API from "api";
import RequestUpgradeDialog from "components/common/RequestUpgradeDialog";
import OrderStatusTabs from "components/orders/orderStatusTabs";
import HelpMenuDialog from "components/sidebar/HelpMenuDialog";
import VendorTable from "components/vendors/vendorTable";
import { EMAIL_CATEGORY } from "interfaces/enums";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInvoiceStore } from "state/invoice.store";

const Vendors = () => {
  const [vendor, setVendor] = useState([]);
  const { bulkSelected } = useInvoiceStore();
  console.log("bulkSelected in parenrt", bulkSelected);

  const [loading, setLoading] = useState<boolean>(false);

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState<Boolean>(false)
  const [category, setCategory] = useState<EMAIL_CATEGORY | ''>('');

  //////////////////////////////////////////
  ///****DON'T DELETE COMMENTED CODE****////
  /////////////////////////////////////////

  // const navigate = useNavigate();

  // const getVendorRequest = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const res = await API.get("/vendors");
  //     setVendor(res.data.data);
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   getVendorRequest();
  // }, [getVendorRequest]);

  // const createNewPo = () => {
  //   navigate("/create-new-vendor");
  // };

  // const EditVendor = (vendorID: any) => {
  //   console.log("Edit vendor Id", vendorID);
  // };
  // const DeleteVendor = (vendorID: any) => {
  //   console.log("Delete vendor Id", vendorID);
  // };

  // const searchVendor = async (e: any) => {
  //   const searchParam = e.target.value;
  //   if (searchParam.length) {
  //     const res = await API.get(`/vendors/search/${searchParam}`);
  //     setVendor(res.data.data);
  //   } else {
  //     getVendorRequest();
  //   }
  // };

  const handleUpgradeRequest = () => {
    setOpenUpgradeDialog(true)
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Vendors
            </Typography>
            {loading ? (
              <CircularProgress sx={{ ml: 1.5 }} size={20} />
            ) : (
              <Tooltip title="Refresh" placement="right">
                {/* <IconButton>
                  onClick={handleRefresh}
                  <RefreshIcon />
                </IconButton> */}
                <IconButton onClick={handleUpgradeRequest}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            sx={{
              width: "38ch",
              "& .css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "15px",
              },
            }}
            type="search"
            variant="outlined"
            margin="dense"
            // onChange={searchVendor}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Search <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            gap: 2.2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            // onClick={createNewPo}
            onClick={handleUpgradeRequest}
          >
            create new
          </Button>

          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<EditIcon />}
            disabled={bulkSelected.length > 1 || !bulkSelected.length}
            // onClick={() => EditVendor(bulkSelected)}
            onClick={handleUpgradeRequest}
          >
            Edit
          </Button>

          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<DeleteIcon />}
            disabled={!bulkSelected.length}
            // onClick={() => DeleteVendor(bulkSelected)}
            onClick={handleUpgradeRequest}
          >
            Delete
          </Button>
        </Box>

        <Box
          sx={{
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <OrderStatusTabs statusName="Department" />
        </Box>

        <Paper elevation={5} sx={{ height: "100%", p: 2 }}>
          {/* orders props */}
          <VendorTable orders={vendor} />
        </Paper>
      </Box>
      <RequestUpgradeDialog open={openUpgradeDialog} handleClose={() => setOpenUpgradeDialog(false)} setCategory={setCategory} />
      <HelpMenuDialog category={category} handleClose={() => setCategory('')} />
    </>
  );
};

export default Vendors;
