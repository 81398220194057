import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import API from 'api';
import { createRef, useEffect, useState } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { CSVLink } from 'react-csv';
import { LoadingButton } from "@mui/lab";
import { DOCUMENT_TYPE_QUERY_ENUM } from "interfaces/enums";
import moment from "moment";
import DatePicker from '@mui/lab/DatePicker';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createCsvData } from "utils/invoice.utils";
import { ICsvData } from "interfaces/invoice.interface";
import CloseIcon from '@mui/icons-material/Close';

const CsvHeaders = [
    {
        key: 'DOCUMENT_TYPE',
        label: 'Documment Type',
    },
    {
        key: 'SUPPLIER_NAME',
        label: 'Supplier Name',
    },
    {
        key: 'INVOICE_NUMBER',
        label: 'Invoice Number',
    },
    {
        key: 'SUPPLIER_CODE',
        label: 'Supplier Code',
    },
    {
        key: 'SUBTOTAL',
        label: 'Subtotal',
    },
    {
        key: 'TOTAL',
        label: 'Total',
    },
    {
        key: 'DUE_DATE',
        label: 'Due Date',
    },
    {
        key: 'DATE',
        label: 'Date',
    },
    {
        key: 'EXPENSE_GL_CODE',
        label: 'Expense GL Code',

    },
    {
        key: 'PO_BALANCE',
        label: 'PO Balance',
    },
    {
        key: 'GST',
        label: 'GST',
    },

    // Expense Line Items
    {
        key: 'EXPEN_GL_CODE',
        label: 'GL Code',
    },
    {
        key: 'EXPEN_AMOUNT',
        label: 'Amount Excl GST',
    },
    {
        key: 'EXPEN_GST__',
        label: 'GST %',
    },
    {
        key: 'EXPEN_MANUAL_DESC',
        label: 'Manual Desc',
    },
    {
        key: 'EXPEN_MRN',
        label: 'MRN',
    },
    {
        key: 'EXPEN_TAX_CODE',
        label: 'Tax Code',
    },
    {
        key: 'DWDOCID',
        label: 'DW DocID',
    },
]


const DoctorsInvoices = () => {

    const [docs, setDocs] = useState<ICsvData[] | null>(null)
    const [active, setActive] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [glCodes, setGlCodes] = useState<string>('All')


    const [fromDate, setFromDate] = useState<Date | null>(
        moment().add(-1, 'days').toDate()
    );
    const [toDate, setToDate] = useState<Date | null>(moment().toDate());

    const csvLink = createRef<any>();

    const getDocuments = async () => {
        setLoading(true)
        setError(false)
        try {
            const res = await API.get('/documents/csv', {
                params: {
                    DOCUMENT_TYPE: DOCUMENT_TYPE_QUERY_ENUM.INVOICE_DOCTOR,
                    department: 'All',
                    to: moment(toDate).format('YYYY-MM-DD'),
                    from: moment(fromDate).format('YYYY-MM-DD'),
                }
            });
            if (res.status === 200) {
                setActive(true)
                setDocs(res.data)
            } else {
                setError(true)
            }
        } catch (err) {
            console.log(err)
            setError(true)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (active) {
            setActive(false);
            csvLink.current.link.click()
        }
    }, [docs]);

    // disable past date
    function disablePrevDates(startDate: any) {
        const startSeconds = Date.parse(startDate);
        return (date: any) => {
            return Date.parse(date) < startSeconds;
        };
    }

    const filterDocs = () => {
        if (glCodes.toLowerCase() === 'all' || glCodes === '') return docs || []
        const glCodesArray = glCodes.replaceAll(" ", "").split(",");
        return docs?.filter((doc: ICsvData) => glCodesArray.includes(doc.EXPEN_GL_CODE)) || []
    }

    return (
        <Box sx={{ height: '90%' }}>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    Doctors Invoices
                </Typography>


                <CSVLink
                    filename={`Doctor-Invoices-${(new Date()).toLocaleDateString()}`}
                    data={docs && [...filterDocs().map((invoice: ICsvData) => createCsvData(invoice))] || []}
                    headers={CsvHeaders.map((col) => {
                        return { key: col.key, label: col.label };
                    })}
                    ref={csvLink}
                    target="_blank"
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', md: 'row' },
                    mt: 3,
                    gap: 10
                }}
            >

                <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' } }}>
                    <TextField
                        fullWidth
                        size="small"
                        label="GL Codes"
                        value={glCodes}
                        onChange={(e) => setGlCodes(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setGlCodes('')}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="caption">For multiple GL codes, separate with comma.</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DatePicker
                        label='Start Date'
                        value={fromDate || null}
                        inputFormat='dd/MM/yyyy'
                        shouldDisableDate={disablePrevDates(moment().subtract(1, 'year').toDate())}
                        onChange={(newValue: any) => {
                            setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField size='small' {...params} />}
                    />

                    <Typography>To</Typography>

                    <DatePicker
                        label='End Date'
                        value={toDate || null}
                        inputFormat='dd/MM/yyyy'
                        shouldDisableDate={disablePrevDates(fromDate)}
                        onChange={(newValue: any) => {
                            setToDate(newValue);
                        }}
                        renderInput={(params) => <TextField size='small' {...params} />}
                    />
                </Box>

            </Box>
            {
                loading ?
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                        <CircularProgress />
                        <Typography variant="body2">Downloading Data. Please wait.</Typography>
                    </Box> :
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                        <LoadingButton
                            variant='contained'
                            onClick={getDocuments}
                            loading={loading}
                            size='large'
                            startIcon={<FileDownloadIcon fontSize='small' />}
                        >
                            Download CSV
                        </LoadingButton>
                        {(!loading && docs && !error) ?
                            <>
                                <FileDownloadDoneIcon fontSize="large" color="success" />
                                <Typography variant="body2">Download Complete.</Typography>
                                <Typography variant="body2">Please check csv file on your Download folder.</Typography>
                            </> : null
                        }
                        {(!loading && error) ?
                            <>
                                <ErrorOutlineIcon fontSize="large" color="error" />
                                <Typography color="error" variant="body2">Download Error.</Typography>
                                <Typography color="error" variant="body2">Please try using filter with lower date range.</Typography>
                            </> : null
                        }
                    </Box>
            }

        </Box >
    );
};

export default DoctorsInvoices;
