import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

import MobileSettingMenu from "./MobileSettingMenu";
import MobileBottomMenu from "./MobileBottomMenu";
import MobileBottomProfileMenu from "./MobileBottomProfileMenu";
import ChangePasswordDialog from "components/authentication/ChangePasswordDialog";
import { IS_JFC } from "constants/global";


const MobileBottomNavigation = () => {
    const navigate = useNavigate();
    const [navValue, setNavValue] = useState('');
    const [openSetting, setOpenSetting] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const [openChangePasswordForm, setOpenChangePasswordForm] = useState<boolean>(false)

    const handleChangeNavigation = (newValue: any) => {
        setNavValue(newValue);
    };


    return (
        <Paper
            sx={{
                display: { xs: "block", sm: "none" },
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 100
            }}
            elevation={3}
        >
            <BottomNavigation
                sx={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    backgroundColor: "#536dfe",
                    '& .Mui-selected, svg': {
                        color: 'white',
                        opacity: 1
                    },
                }}
                showLabels
                value={navValue}
                onChange={(event, newValue) => {
                    console.log(newValue)
                    handleChangeNavigation(newValue);
                }}
            >
                <BottomNavigationAction
                    onClick={() => navigate('/my-form')}
                    sx={{ color: 'white', opacity: 0.5 }}
                    value={'/my-form'}
                    label='Home'
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    onClick={() => setOpenMenu(true)}
                    sx={{ color: 'white', opacity: 0.5 }}
                    value={'/menus'}
                    label={IS_JFC ? 'Forms' : 'Menu'}
                    icon={<FormatListNumberedIcon />}
                />
                <BottomNavigationAction
                    onClick={() => setOpenSetting(true)}
                    sx={{ color: 'white', opacity: 0.5 }}
                    value={'/setting'}
                    label='Settings'
                    icon={<SettingsIcon />}
                />
                <BottomNavigationAction
                    onClick={() => setOpenProfile(true)}
                    sx={{ color: 'white', opacity: 0.5 }}
                    value={'/account'}
                    label='Account'
                    icon={<AccountCircleIcon />}
                />
            </BottomNavigation>
            <MobileSettingMenu open={openSetting} handleClose={() => setOpenSetting(false)} />
            <MobileBottomMenu open={openMenu} handleClose={() => setOpenMenu(false)} />
            <MobileBottomProfileMenu open={openProfile} handleClose={() => setOpenProfile(false)} setOpenChangePasswordForm={setOpenChangePasswordForm} />
            <ChangePasswordDialog open={openChangePasswordForm} handleClose={() => setOpenChangePasswordForm(false)} />
        </Paper>
    );
};

export default MobileBottomNavigation;
