import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import { DOC_STATUS } from 'enums/invoice.enums';
import { DW_INVOICE_FIELDS } from 'interfaces/enums';
import { Invoice, InvoiceSummary } from 'interfaces/invoice.interface';
import { useInvoiceStore } from 'state/invoice.store';
import { createInvoiceSummary, filterTableData } from 'utils/invoice.utils';
import DocumentModalTable from './document-modal/DocumentModalTable';
import ActionsDialog from './modals/ActionsDialog';
import TableCellActions from './table-cells/TableCellActions';
import TableCellStatus from './table-cells/TableCellStatus';
import TableCellSupplierName from './table-cells/TableCellSupplierName';
import TableFilter from 'components/common/TableFilter';
// import DocumentViewer from './document-viewer/DocumentViewer';
import shallow from 'zustand/shallow'


type Order = 'asc' | 'desc';

function descendingComparator<T>(a: InvoiceSummary, b: InvoiceSummary, orderBy: keyof InvoiceSummary, order: Order) {
  if (orderBy === "dueDate" || orderBy === "invoiceDate") {
    // Handle null or undefined values by setting them to a string that represents a very old date.
    const dateA = a[orderBy] ? a[orderBy].split("/").reverse().join("/") : "0000/00/00";
    const dateB = b[orderBy] ? b[orderBy].split("/").reverse().join("/") : "0000/00/00";
    
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  }
  // Additionally handle cases where either comparison value could be undefined or null.
  if (b[orderBy] === undefined || b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === undefined || a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof InvoiceSummary>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy, order)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy, order);
}

interface Column {
  key: keyof InvoiceSummary;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  align?: 'left' | 'right' | 'center';
  filter?: boolean;
  minWidth?: number;
  width?: number;
  format?: (value: number) => string;
}

export const invoiceTableColumns: readonly Column[] = [
  {
    key: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Invoice Number',
    align: 'left',
    filter: true,
  },
  {
    key: 'invoiceDate',
    numeric: false,
    disablePadding: true,
    label: 'Invoice Date',
    align: 'center',
    filter: true,
  },
  {
    key: 'dueDate',
    numeric: false,
    disablePadding: true,
    label: DW_INVOICE_FIELDS.DUE_DATE,
    align: 'center',
    filter: true,
  },
  {
    key: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor Name',
    align: 'left',
    filter: true,
  },

  {
    key: 'totalAmount',
    numeric: true,
    disablePadding: true,
    label: 'Total Amount',
    align: 'right',
    filter: true,
  },
  {
    key: 'poTotal',
    numeric: true,
    disablePadding: true,
    label: 'PO Amount',
    align: 'right',
  },
  {
    key: 'poNumber',
    numeric: false,
    disablePadding: false,
    label: 'PO Number',
    align: 'left',
  },
  {
    key: 'status',
    numeric: false,
    disablePadding: true,
    label: DW_INVOICE_FIELDS.STATUS,
    align: 'center',
  },
  {
    key: 'docuwareId',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
    align: 'center',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvoiceSummary
  ) => void;
  order: Order;
  orderBy: string;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
  rows: any;
  onChangeFilter: (category: string, newValue: string[]) => void;
  filterCriteria: any;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
  onSelectAllClick,
  numSelected,
  rowCount,
  rows,
  onChangeFilter,
  filterCriteria
}) => {

  const [searchParams] = useSearchParams();


  const docStatus = searchParams.get("status")

  const createSortHandler =
    (property: keyof InvoiceSummary) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const isWaiting = docStatus === DOC_STATUS.WAITING_APPROVAL;

  const columns = isWaiting
    ? invoiceTableColumns
    : invoiceTableColumns.filter(
      (column) =>
        !(column.label === DW_INVOICE_FIELDS.STATUS) &&
        !(column.label === 'Actions')
    );

  const options = useMemo(() => {
    let obj: any = {}
    invoiceTableColumns?.map((column: any) => {
      return obj[column.key] = Array.from(new Set(rows?.map((row: any) => row[column.key]))).filter((val => val !== null))
    })
    return obj
  }, [rows])

  return (
    <TableHead>
      <TableRow>
        {isWaiting ? (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        ) : null}
        {columns.map((headCell) => (
          <TableCell
            sx={{
              fontWeight: 600,
              width: headCell.width,
            }}
            key={headCell.key}
            align='center'
            padding={'normal'}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <Box component='span' sx={visuallyHidden} >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}

                </Box>
              ) : null}
            </TableSortLabel>
            {headCell.filter ? <TableFilter category={headCell.key} options={options[headCell.key]} onChangeFilter={onChangeFilter} filterCriteria={filterCriteria} label={headCell.label} /> : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface InvoicesTableProps {
  invoices: Invoice[];
  count: number;
  status: string;
  page: string | number;
  limit: string | number;
  currentSearchParams: URLSearchParams;
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({ invoices, count, status, page, limit, currentSearchParams }) => {

  const bulkSelected = useInvoiceStore((state) => state.bulkSelected, shallow);
  const updateInvoice = useInvoiceStore((state) => state.updateInvoice, shallow);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof InvoiceSummary>('dueDate');
  const [rows, setRows] = useState<any>([]);
  const [showDocId, setShowDocId] = useState<string | null>(null);
  const [filterCriteria, setFilterCriteria] = useState<any>({})
  const [filteredRows, setFilteredRows] = useState<any>([])

  const [_, setSearchParams] = useSearchParams();

  const handleChangeFilter = (category: any, newValue: any) => {
    setFilterCriteria({ ...filterCriteria, [category]: newValue.length ? [...newValue] : undefined })
  }

  const isWaiting = status === DOC_STATUS.WAITING_APPROVAL;

  const columns = isWaiting
    ? invoiceTableColumns
    : invoiceTableColumns.filter(
      (column) =>
        !(column.label === DW_INVOICE_FIELDS.STATUS) &&
        !(column.label === 'Actions')
    );
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof InvoiceSummary
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    currentSearchParams.set('page', `${newPage}`)
    setSearchParams(currentSearchParams)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    currentSearchParams.set('limit', `${event.target.value}`)
    setSearchParams(currentSearchParams)
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((row: InvoiceSummary) => row.docuwareId);
      updateInvoice({ bulkSelected: newSelecteds });
      return;
    }
    updateInvoice({ bulkSelected: [] });
  };

  const isSelected = (name: string) => bulkSelected.indexOf(name) !== -1;

  const handleSelectRow = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = bulkSelected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(bulkSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(bulkSelected.slice(1));
    } else if (selectedIndex === bulkSelected.length - 1) {
      newSelected = newSelected.concat(bulkSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        bulkSelected.slice(0, selectedIndex),
        bulkSelected.slice(selectedIndex + 1)
      );
    }
    updateInvoice({ bulkSelected: newSelected });
  }

  useEffect(() => {
    const filteredRows = filterTableData(rows, filterCriteria)
    setFilteredRows([...filteredRows])
  }, [filterCriteria, rows]);


  useEffect(() => {
    const data = invoices.map((invoice: Invoice) => {
      return { ...createInvoiceSummary(invoice) };
    });
    setRows(data);
  }, [invoices])

  useEffect(() => {
    setFilterCriteria({})
  }, [status])

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer
        sx={{ maxHeight: Math.max(window.innerHeight - 380, 400) }}
      >
        <Table size="small" stickyHeader>
          <EnhancedTableHead
            numSelected={bulkSelected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            rows={rows}
            onChangeFilter={handleChangeFilter}
            filterCriteria={filterCriteria}
          />
          <TableBody>
            {filteredRows
              .sort(getComparator(order, orderBy))
              .map((row: InvoiceSummary, index: number) => {
                // console.log("Docuware id:::>>?::", row.docuwareId);
                const isItemSelected = isSelected(row.docuwareId);
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.docuwareId}
                    selected={isItemSelected}

                  >
                    {isWaiting ? (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleSelectRow(event, row.docuwareId)
                          }
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      const value = row[column.key];

                      if (column.key === 'invoiceNumber') {
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            <Link
                              sx={{ cursor: 'pointer', textDecoration: 'none' }}
                              onClick={() => setShowDocId(row.docuwareId)}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      }

                      if (column.key === 'poNumber') {
                        const isValueValidForLink = value !== '-';
                        return (
                          <TableCell
                            key={column.key}
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            {isValueValidForLink ? (
                              <Link
                                sx={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                }}
                                onClick={() => setShowDocId(row.poDocId)}
                              >
                                {value}
                              </Link>
                            ) : (
                              <>{value}</>
                            )}
                          </TableCell>
                        );
                      }

                      if (column.key === 'docuwareId') {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <TableCellActions row={row} />
                          </TableCell>
                        );
                      }

                      if (column.key === 'status') {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <TableCellStatus row={row} />
                          </TableCell>
                        );
                      }

                      if (column.key === 'vendorName') {
                        return (
                          <TableCell key={column.key} align={column.align}>
                            <TableCellSupplierName
                              row={row}
                              maxLength={isWaiting ? 15 : 30}
                            />
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          sx={{ pr: 4, width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

            <TableRow
              style={{
                height: 33,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 100, 500, 1000, 2000]}
        component='div'
        count={count}
        rowsPerPage={Number(limit)}
        page={Number(page)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ActionsDialog invoices={filteredRows} />
      {/* {showDocId && (
        <DocumentViewer
          open={!!showDocId}
          handleClose={() => setShowDocId('')}
          docId={showDocId}
        />
      )} */}
      {showDocId && (
        <DocumentModalTable
          open={!!showDocId}
          handleClose={() => setShowDocId('')}
          docId={showDocId}
        />
      )}
    </Box>
  );
};

export default InvoicesTable;
