import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import FormControlItem, { FormControlItemLayout } from 'components/forms/form-components/FormControlItem'
import { EFT_ON_DELIVERY_DATE_LAYOUT } from 'components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout'
import { useFormStore } from 'state/form.store'

const EFTOnTheDeliveryDate = () => {

    const { formData, validating, updateForm } = useFormStore()

    const onChange = (value: any) => {
        updateForm({ ...value })
    }

    return (
        <Box sx={{ pb: 3 }}>
            <Grid container spacing={1}>
                {EFT_ON_DELIVERY_DATE_LAYOUT.map((item: FormControlItemLayout) => {
                    return (
                        <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                            <FormControlItem
                                data={formData}
                                onChange={onChange}
                                layout={item}
                                submitValidating={validating}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{ py: 2 }}>
                <a href='https://www.jfcaustralia.com.au/direct-debit-request-service-agreement/' target='__blank'>
                    https://www.jfcaustralia.com.au/direct-debit-request-service-agreement/
                </a>
            </Box>

        </Box>
    )
}

export default EFTOnTheDeliveryDate