import { ChangeEvent, ElementType, FC, useState } from "react";

import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { CurrencyFormatCustom, NumberFormatCustom, TextMaskCustom } from "components/common/CurrencyInput";
import UploadFormControl from "./UploadFormControl";
import AddressAutoComplete from "./AddressAutoComplete";
import SignaturePad from "./SignaturePad";
import { useFormStore } from "state/form.store";
import { FORM_CONTROL_TYPE } from "enums/form.enums";
import { setFocusName, validateCreditCardExpiry } from "utils/form.utils";

export type FormControlItemLayout = {
  name: string;
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  type?: FORM_CONTROL_TYPE;
  rows?: number;
  helperText?: string;
  selectList?: { label: string; value: string | number }[];
  newSelectList?: string[] | number[];
  variant?: "outlined" | "standard" | "filled";
  disabled?: boolean;
  item?: boolean;
  autoComplete?: string;
  charLength?: number;
  mask?: string;
  size?: "small" | "medium"
  gridSizeMobile?: number;
  gridSizeTablet?: number;
  gridSizeDesktop?: number;
};

const FormControlItem: FC<{
  data: any;
  onChange: (value: any) => void;
  layout: FormControlItemLayout;
  submitValidating?: boolean;
  itemsValidating?: boolean;
}> = ({ data = {}, layout, onChange = () => { }, submitValidating = false, itemsValidating = false }) => {

  const updateFocus = useFormStore(state => state.updateFocus)

  const [showPassword, setShowPassword] = useState<string>('')

  const name = layout.name;
  const propsValue = data?.[name];

  const handleChangeValue = (newValue: any) => {
    onChange({ ...(data ?? {}), [name]: newValue === '' ? null : newValue });
  };

  const handleChangeAddress = (value: any) => {
    if (typeof (value) === 'string') {
      handleChangeValue(value)
    } else {
      onChange({ ...(data ?? {}), ...value });
    }
  };

  const handleChangeDateValue = (newValue: any) => {
    onChange({ ...(data ?? {}), [name]: newValue });
  };

  const handleChangeNumberValue = (event: any) => {
    onChange({ ...(data ?? {}), [name]: Number(event.target.value) });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: any
  ) => {
    const value = typeof checked === "boolean" ? checked : e?.target?.value;

    handleChangeValue(value);
  };

  const handleSelectChange = (e: SelectChangeEvent<any>) => {
    const value = e?.target?.value ?? "";
    handleChangeValue({
      value: value,
      label: layout.selectList?.find((item) => item.value === value)?.label,
    });
  };

  const handleOnFocus = () => {
    const fieldName = setFocusName(name)
    updateFocus(fieldName)
  }

  const handleAddAttachment = (values: string[]) => {
    const currentValues = data?.[name] ?? []
    const newValues = Array.from(new Set([...currentValues, ...values]))
    onChange({ ...(data ?? {}), [name]: [...newValues] });
  }

  const handleDeleteFile = (value: string) => {
    const currentValues: string[] = data[name] ?? []
    const newValues = currentValues.filter(file => file !== value)
    onChange({ ...(data ?? {}), [name]: newValues.length ? [...newValues] : null });
  }



  const errorHandler = () => {
    const mailformat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/;

    if (propsValue) {
      if (layout.type === FORM_CONTROL_TYPE.EMAIL && !propsValue.match(mailformat)) {
        return { error: true, helperText: 'You have entered an invalid email address' };
      }
      if (layout.charLength && String(propsValue).length !== layout.charLength) {
        return {
          error: true,
          helperText: layout?.label ? layout.label.substring(layout.label.indexOf('(') + 1, layout.label.indexOf(')')) : 'Invalid value'
        };
      }
      if (layout.name === 'EXPIRY_DATE_' && propsValue && propsValue.length === 5 && !validateCreditCardExpiry(propsValue)) {
        return { error: true, helperText: 'Invalid expiry date' };
      }
    }

    if (layout.type === FORM_CONTROL_TYPE.CHECKBOX && propsValue === false && submitValidating) {
      return { error: true, helperText: 'Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy.' };
    }

    if (layout.type === FORM_CONTROL_TYPE.TIME && propsValue?.length >= 5) {
      const militaryTimeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (militaryTimeRegex.test(propsValue.substring(0, 5)) === false) {
        return { error: true, helperText: 'Invalid Time' };
      }
    }

    if (!propsValue && layout.required) {
      if ((submitValidating && !itemsValidating && !layout.item) || (submitValidating && itemsValidating) || (!submitValidating && itemsValidating && layout.item)) {
        return { error: true, helperText: 'This field is required' };
      }
    }
    return { error: false, helperText: ' ' };
  };

  switch (layout.type) {
    case FORM_CONTROL_TYPE.DATE:
      return (
        <DatePicker
          label={layout?.label ?? ""}
          value={propsValue ?? null}
          inputFormat="dd/MM/yyyy"
          onChange={handleChangeDateValue}
          disabled={layout?.disabled ?? false}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              required={layout?.required ?? false}
              variant={layout?.variant ?? "outlined"}
              error={errorHandler().error}
              helperText={errorHandler().helperText}
              InputLabelProps={{
                sx: { fontWeight: 600, fontSize: 18 }
              }}
            />
          )}

        />
      );
    case FORM_CONTROL_TYPE.TIME:
      //changed timepicker to textfield with validation
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          disabled={layout?.disabled ?? false}
          size={layout?.size ?? 'medium'}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
              mask: '##:## $$',
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }
          }}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
        />
      );
    case FORM_CONTROL_TYPE.CURRENCY:
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          disabled={layout?.disabled ?? false}
          InputProps={{
            inputComponent:
              CurrencyFormatCustom as unknown as ElementType<InputBaseComponentProps>,
          }}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
        />
      );

    case FORM_CONTROL_TYPE.NEW_NUMBER:
      //USE CASE: input that needs the output as number data type for calculation purposes
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChangeNumberValue}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          disabled={layout?.disabled ?? false}
          InputProps={{
            inputComponent:
              NumberFormatCustom as unknown as ElementType<InputBaseComponentProps>,
          }}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}

        />
      );

    case FORM_CONTROL_TYPE.NUMBER_AS_STRING:
      //USE CASE: input that accepts only number but includes leading 0 (e.g. BSB number is 001234, Account No. is 00123456789)
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          onFocus={handleOnFocus}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          disabled={layout?.disabled ?? false}
          size={layout?.size ?? 'medium'}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
              mask: layout?.mask,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }
          }}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
        />
      );

    case FORM_CONTROL_TYPE.NUMBER:
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          disabled={layout?.disabled ?? false}
          InputProps={{
            inputComponent:
              NumberFormatCustom as unknown as ElementType<InputBaseComponentProps>,
          }}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
        />
      );

    case FORM_CONTROL_TYPE.PASSWORD:
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          variant={layout?.variant ?? "outlined"}
          fullWidth={layout?.fullWidth ?? false}
          disabled={layout?.disabled ?? false}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          type={showPassword === name ? 'text' : 'password'}
          size={layout?.size ?? 'medium'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(showPassword ? '' : name)}
                  size='large'
                >
                  {showPassword === name ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )

    case FORM_CONTROL_TYPE.MULTI_LINE_TEXT:
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          fullWidth={layout?.fullWidth ?? false}
          variant={layout?.variant ?? "outlined"}
          InputProps={{ multiline: true, rows: layout?.rows ?? 0 }}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          disabled={layout?.disabled ?? false}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
        />
      );
    case FORM_CONTROL_TYPE.SELECT:
      return (
        <FormControl variant={layout?.variant ?? "outlined"} disabled={layout?.disabled ?? false} error={errorHandler().error} fullWidth={layout?.fullWidth ?? false} required={layout?.required ?? false} >
          <InputLabel id="demo-simple-select-label" sx={{ fontWeight: 600, fontSize: 18 }}>
            {layout?.label ?? ""}
          </InputLabel>
          <Select
            name={name ?? ""}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={propsValue?.value ?? ""}
            label={layout?.label ?? ""}
            variant={layout?.variant ?? "outlined"}
            onChange={handleSelectChange}

          >
            {layout?.selectList &&
              layout?.selectList.map((selectItem) => (
                <MenuItem
                  key={selectItem.label}
                  value={selectItem?.value || selectItem.label}
                >
                  {selectItem.label}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{errorHandler().helperText}</FormHelperText>
        </FormControl>
      );

    case FORM_CONTROL_TYPE.NEW_SELECT:
      return (
        <FormControl variant={layout?.variant ?? "outlined"} disabled={layout?.disabled ?? false} error={errorHandler().error} fullWidth={layout?.fullWidth ?? false} required={layout?.required ?? false} >
          <InputLabel id="demo-simple-select-label" sx={{ fontWeight: 600, fontSize: 18 }}>
            {layout?.label ?? ""}
          </InputLabel>
          <Select
            name={name ?? ""}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={propsValue ?? ""}
            label={layout?.label ?? ""}
            variant={layout?.variant ?? "outlined"}
            onChange={(e) => handleChangeValue(e.target.value)}

          >
            {layout?.newSelectList &&
              layout?.newSelectList.map((selectItem) => (
                <MenuItem
                  key={selectItem}
                  value={selectItem}
                >
                  {selectItem}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{errorHandler().helperText}</FormHelperText>
        </FormControl>
      );

    case FORM_CONTROL_TYPE.SIGNATURE:
      return (
        <SignaturePad
          data={data}
          name={name}
          onChange={onChange}
          value={propsValue}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          label={layout.label}
        />
      );


    case FORM_CONTROL_TYPE.ADDRESS_AUTOFILL:
      return (
        <AddressAutoComplete
          label={layout?.label ?? ""}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          required={layout?.required ?? false}
          value={propsValue ?? ''}
          name={layout?.name}
          onChange={handleChangeAddress}
        />
      );

    case FORM_CONTROL_TYPE.FILE_UPLOAD:
      return (
        <Box sx={{ border: errorHandler().error ? '1px solid red' : '', borderRadius: 1 }}>
          {propsValue?.length ?
            <Box sx={{ pb: 1 }}>
              <Typography sx={{ width: '100%' }} variant="body2">Uploaded Files:</Typography>
              {propsValue.map((item: string, index: number) => {
                return (

                  <Chip sx={{ mx: '2px' }} size="small" color="success" label={item.substring(11)} variant="outlined" onDelete={() => handleDeleteFile(item)} />
                )
              })}
            </Box> : null}

          <UploadFormControl onChange={handleAddAttachment} deleteFile={handleDeleteFile} />
        </Box>
      );

    case FORM_CONTROL_TYPE.CHECKBOX:
      return (
        <FormControl required
          error={errorHandler().error}
          variant="standard"
        >
          <FormControlLabel
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 900,
                fontSize: 18
              }
            }}
            control={
              <Checkbox checked={propsValue ?? null} onChange={handleChange} name={name} />
            }
            label={layout?.label ?? ""}
          />
          {errorHandler().error ? <FormHelperText >{errorHandler().helperText}</FormHelperText> : null}
        </FormControl>
      );

    default:
      return (
        <TextField
          name={name}
          value={propsValue ?? ""}
          onChange={handleChange}
          label={layout?.label ?? ""}
          required={layout?.required ?? false}
          variant={layout?.variant ?? "outlined"}
          fullWidth={layout?.fullWidth ?? false}
          disabled={layout?.disabled ?? false}
          error={errorHandler().error}
          helperText={errorHandler().helperText}
          type={layout.type ?? ''}
          size={layout?.size ?? 'medium'}
          InputLabelProps={{
            sx: { fontWeight: 600, fontSize: 18 }
          }}
        />
      );
  }
};

export default FormControlItem;
