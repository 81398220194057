import { ChangeEvent, FC, useState } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { Button, DialogActions, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { DialogProps } from "interfaces/common.interfaces";
import { ZoomTransition } from 'components/common/Transitions';
import API from 'api'
import { snackBarStyle } from "utils/common.utils";
import { useSnackbar } from "notistack";

interface ChangePasswordDialogProps extends DialogProps { }

const initialPasswords = { currentPassword: '', newPassword: '', confirmPassword: '' }

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({ open, handleClose }) => {

    const [passwords, setPasswords] = useState(initialPasswords)
    const [loading, setLoading] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()


    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPasswords({ ...passwords, [event.target.name]: event.target.value })
    }

    const confirmChangePassword = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            const contentOption = snackBarStyle({
                text: `New Password and Confirm Password don't match.`,
                variant: "error",
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
            return
        }
        setLoading(true)
        try {
            const { data } = await API.patch('/users/change-password', { currentPassword: passwords.currentPassword, newPassword: passwords.newPassword })
            if (data) {
                const contentOption = snackBarStyle({
                    text: `Successfully change password.`,
                    variant: "success",
                });
                enqueueSnackbar(contentOption.message, contentOption.options)
                setPasswords(initialPasswords)
                handleClose()
            }
        } catch (error) {

            console.log("ERROR", error)
            const contentOption = snackBarStyle({
                text: `Failed to change password.`,
                variant: "error",
            });
            enqueueSnackbar(contentOption.message, contentOption.options)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={ZoomTransition}
        >
            <DialogTitle
                sx={{ fontWeight: 700, display: 'flex', justifyContent: 'center' }}
            >
                Change Password
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
                    <TextField
                        type="password"
                        fullWidth
                        size="small"
                        name="currentPassword"
                        value={passwords.currentPassword}
                        onChange={handleChange}
                        label='Enter Current Password'
                    />
                    <TextField
                        type="password"
                        fullWidth
                        size="small"
                        name="newPassword"
                        value={passwords.newPassword}
                        onChange={handleChange}
                        label='Enter New Password'
                    />
                    <TextField
                        type="password"
                        fullWidth
                        size="small"
                        name="confirmPassword"
                        value={passwords.confirmPassword}
                        onChange={handleChange}
                        label='Confirm New Password'
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant="outlined" onClick={handleClose}>Cancel</Button>
                <LoadingButton
                    fullWidth
                    variant='contained'
                    onClick={confirmChangePassword}
                    loading={loading}
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePasswordDialog