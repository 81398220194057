import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import API from "api";
import update from "immutability-helper";
import React, { useCallback, useEffect } from "react";
import { FinalJson } from "state/purchaseorderLine.store";

interface Data {
  item_code: any;
  item_description: any;
  price: number;
  tax_class: number;
}

function createItemData(lineItem: any): Data {
  const { item_code, item_description, price, tax_class } = lineItem;
  return {
    item_code,
    item_description,
    price,
    tax_class,
  };
}

// const rows: any = [
//   createData('Cupcake', 305, 3.7, 67),
//   createData('Donut', 452, 25.0, 51),
//   createData('Eclair', 262, 16.0, 24)
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  align?: "center" | "left" | "right";
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "item_code",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Item Code",
  },
  {
    id: "item_description",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Item Description",
  },
  {
    id: "price",
    align: "left",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "tax_class",
    align: "left",
    numeric: true,
    disablePadding: false,
    label: "Tax Class",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  productLineItem: any[];
  closeModal: any;
}

export const EnhancedTable = React.forwardRef(
  ({ productLineItem, closeModal }: EnhancedTableToolbarProps, ref) => {
    const { purchaseOrderoneLine, setFinalJson } = FinalJson();
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("item_code");
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dense, setDense] = React.useState(false);
    const [rows, setRows] = React.useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // call function on Child button Click
    React.useImperativeHandle(ref, () => ({
      updateProductLine: (id: any) => {
        if (selected.length) {
          // get selecteddata from array
          const selectedProductLine = rows.find(
            (el: any) => el.item_code === selected[0]
          );
          // update product Lines
          const _jsonData = { ...purchaseOrderoneLine };
          const index = purchaseOrderoneLine.lineItem.findIndex(
            (e) => e._id === id
          );
          const updateLineItemZustand: any =
            purchaseOrderoneLine.lineItem[index];
          const updateObjectData = {
            ...updateLineItemZustand,
            ...selectedProductLine,
          };
          const updateObj = update(purchaseOrderoneLine.lineItem, {
            $splice: [[index, 1, updateObjectData]],
          });
          _jsonData.lineItem = updateObj;
          setFinalJson(_jsonData);
          closeModal();
        } else return;
      },
    }));

    // convert data to object Line for table
    const getProductLineData = () => {
      const data = productLineItem.map((productLine: any) => {
        return { ...createItemData(productLine) };
      });
      setRows(data);
    };

    useEffect(() => {
      getProductLineData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLineItem]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (event.target.checked) {
        const newSelected = rows.map((n: any) => n.item_code);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selected.length && selectedIndex === -1) {
        let selectdValue = selected[0];
        if (selectdValue !== name) {
          // remove old value and new add
          newSelected = newSelected.concat(name);
          return setSelected(newSelected);
        }
        if (selectdValue === name) {
          newSelected = [];
          return setSelected(newSelected);
        }
      }

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = (name: any) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
      <Box sx={{ width: "100%" }} ref={ref}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{ overflow: "hidden" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.length === 0 && (
                  <>
                    <TableRow>
                      <TableCell colSpan={5} sx={{ borderBottom: 0 }}>
                        <Box sx={{ width: "100%", textAlign: "center", mt: 1 }}>
                          <Typography>
                            There are no records to display.
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.item_code);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.item_code)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.item_code}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          align="center"
                          scope="row"
                          padding="none"
                        >
                          {row.item_code}
                        </TableCell>
                        <TableCell align="center">
                          {row.item_description}
                        </TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">{row.tax_class}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface purchaseOrderLineItemModalProps {
  open: boolean;
  handleClose: any;
  itemIndex: any;
  itemCode: any;
}

export const PurchaseOrderLineItemModal: React.FC<
  purchaseOrderLineItemModalProps
> = ({ open, handleClose, itemIndex, itemCode }) => {
  const childRef: any = React.useRef(null);
  const { purchaseOrderoneLine, setFinalJson } = FinalJson();
  const [rows, setRows] = React.useState<any>([]);

  const getProductRequest = useCallback(async () => {
    try {
      const res = await API.get(`/products/byItemcode/${itemCode}`);
      setRows(res.data.data);
    } catch (err) {
      console.log("err", err);
    }
  }, [itemCode]);

  useEffect(() => {
    getProductRequest();
  }, [getProductRequest]);

  const searchProduct = async (e: any) => {
    const serchTerm = e.target.value;
    const _jsonData = { ...purchaseOrderoneLine };
    const index = purchaseOrderoneLine.lineItem.findIndex(
      (e) => e._id === itemIndex
    );
    const updateLineItemZustand: any = purchaseOrderoneLine.lineItem[index];
    updateLineItemZustand[e.target.name] = serchTerm;
    const updateObj = update(purchaseOrderoneLine.lineItem, {
      $splice: [[index, 1, updateLineItemZustand]],
    });
    _jsonData.lineItem = updateObj;
    setFinalJson(_jsonData);
    try {
      if (serchTerm.length > 0) {
        const res = await API.get(`/products/byItemcode/${serchTerm}`);
        setRows(res.data.data);
      } else return;
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "#536DFE",
            opacity: 0.4,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Products
              </Typography>
              {/* {itemCode} */}
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Box>
            <TextField
              type="search"
              label="search"
              defaultValue={itemCode}
              name="item_code"
              onChange={(e: any) => searchProduct(e)}
              size="small"
              sx={{
                mt: "15px",
                width: "100%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: "50px" }}>
            {/* table data for product item line */}
            <EnhancedTable
              productLineItem={rows.sort()}
              ref={childRef}
              closeModal={handleClose}
            />
          </Box>
          <Box mt={3} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "250px", boxShadow: "none", borderRadius: "12px" }}
              // onClick={()=>updateProductLineItem()}
              onClick={() => {
                childRef.current.updateProductLine(itemIndex);
              }}
            >
              SELECT
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
