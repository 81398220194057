import { FC, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { Autocomplete, Badge, TextField } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

interface TableFilterProps {
    category: string;
    onChangeFilter: (category: string, newValue: string[]) => void;
    options: string[];
    filterCriteria: any;
    label: string;
}

const TableFilter: FC<TableFilterProps> = ({ options, category, onChangeFilter, filterCriteria, label }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                onClick={handleClick}
            >
                <Badge
                    badgeContent={filterCriteria[category]?.length}
                    invisible={!filterCriteria[category] || filterCriteria[category]?.length === 0}
                    color="primary"
                >
                    <FilterListIcon fontSize='small' />
                </Badge>
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Autocomplete
                    sx={{ width: '400px', px: 1 }}
                    multiple
                    id="tags-outlined"
                    options={options}
                    onChange={(_event, newValue) => {
                        onChangeFilter(category, newValue)
                    }}
                    value={filterCriteria[category] ?? []}

                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={label}
                        />
                    )}
                />
            </Menu>

        </>
    );
}

export default TableFilter


