import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import API from "api";
import React from "react";
// import { useNavigate } from "react-router-dom";
import { useInvoiceStore } from "state/invoice.store";
import { setProductdata } from "state/product.store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 2,
};

interface deleteProductModalProps {
  open: boolean;
  handleClose: any;
  deleteProduct: any;
}
export const DeleteProductModal: React.FC<deleteProductModalProps> = ({
  open,
  handleClose,
  deleteProduct,
}) => {
  //   const navigate = useNavigate();
  const { productApiData }: any = setProductdata();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bulkSelected, updateInvoice } = useInvoiceStore();

  const deleteProducts = async () => {
    const deleteProducts = await API.post("/products/delete-product", {
      deletedata: deleteProduct,
    });
    if (deleteProducts.data.status) {
      updateInvoice({ bulkSelected: [] });
      handleClose();
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "#536DFE",
            opacity: 0.4,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Delete Products
              </Typography>
              <Typography
                variant="caption"
                component="h5"
                sx={{ color: "#999" }}
              >
                Do you want to do delete this Products?
              </Typography>
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>

          <Box mt={2}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& td": { padding: "5px" },
                    "& th": { padding: "5px", borderColor: "#444" },
                  }}
                >
                  <TableCell>Item Code</TableCell>
                  <TableCell align="center">Item Description</TableCell>
                  <TableCell align="center">Unit Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productApiData
                  .filter((product: any) =>
                    deleteProduct.includes(product.product_id)
                  )
                  .map((row: any) => (
                    <TableRow
                      key={row.product_id}
                      sx={{
                        "& td": { border: 0, padding: "5px" },
                        "& th": { border: 0, padding: "10px 5px" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.item_code}
                      </TableCell>
                      <TableCell align="center">
                        {row.item_description}
                      </TableCell>
                      <TableCell align="center">{row.unit_price}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Box mt={3} sx={{ textAlign: "center" }}>
            <Button
              onClick={() => deleteProducts()}
              type="submit"
              variant="contained"
              sx={{ width: "250px", boxShadow: "none", borderRadius: "12px" }}
            >
              CONFIRM
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
