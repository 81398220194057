import { IS_JFC } from 'constants/global';
import React, { FC, PropsWithChildren } from 'react';

const AppLogo: FC<
  PropsWithChildren<{ style?: React.CSSProperties | undefined }>
> = ({ style }) => {
  return IS_JFC ? (
    <img
      src='/static/img/JFC-LOGO-AU.png'
      alt='JFC'
      style={{ ...(style ?? {}), maxWidth: 300 }}
    />
  ) : (
    <img
      src='/static/img/esph.png'
      alt='ESPH'
      style={{ ...(style ?? {}), maxWidth: 300 }}
    />
  );
};

export default AppLogo;
