import { FC } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';


interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
    terms: any;
    address_components: any;
    place_id: string;
}

interface AddressAutoCompleteProps {
    label: string;
    error?: boolean;
    helperText?: string;
    value: PlaceType;
    name: string;
    required?: boolean;
    onChange: (value: any) => void;
}

const parseGoogleAddressComponents = (addressComponents: any, name: string) => {
    let components: any = {};
    addressComponents.forEach((addressComponent: any) => {
        addressComponent.types.forEach((type: any) => {
            if (type === "administrative_area_level_1") {
                components[type] = addressComponent.short_name;
                return
            }
            components[type] = addressComponent.long_name;
        });
    });
    if (name.includes('DELIVERY_ADDRES')) {
        return {
            STREET_NUMBER_DELIVERY_ADDRES: components.street_number,
            STREET_NAME_DELIVERY_ADDRESS: components.route,
            SUBURB_DELIVERY_ADDRESS: components.locality,
            STATE_DELIVERY_ADDRESS: components.administrative_area_level_1,
            POSTCODE_DELIVERY_ADDRESS: components.postal_code
        }
    }
    if (name.includes('BUSINESS_ADDRESS')) {
        return {
            STREET_NUMBER_BUSINESS_ADDRESS: components.street_number,
            SUBURB_BUSINESS_ADDRESS: components.locality,
            STATE_BUSINESS_ADDRESS: components.administrative_area_level_1,
            POSTCODE_BUSINESS_ADDRESS: components.postal_code
        }
    }
    if (name.includes('POSTAL_ADDRESS')) {
        return {
            STREET_NUMBER_POSTAL_ADDRESS: components.street_number,
            SUBURB_POSTAL_ADDRESS: components.locality,
            STATE_POSTAL_ADDRESS: components.administrative_area_level_1,
            POSTCODE_POSTAL_ADDRESS: components.postal_code
        }
    }
};

function removeNullOrUndefinedProperties(obj: any) {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    }
    return obj;
}

const AddressAutoComplete: FC<AddressAutoCompleteProps> = ({ label, error, helperText, value, name, required = false, onChange, }) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });


    const handleOnchange = (newValue: PlaceType | null, name: string) => {
        if (!newValue?.place_id) return
        if (!name.includes('MATCH') &&
            (name.includes('DELIVERY_ADDRES') ||
                name.includes('BUSINESS_ADDRESS') ||
                name.includes('POSTAL_ADDRESS'))
        ) {
            placesService?.getDetails({ placeId: newValue?.place_id },
                (placeDetails: any) => {
                    const components = parseGoogleAddressComponents(placeDetails.address_components, name)
                    onChange(removeNullOrUndefinedProperties(components))
                }
            );
            return
        }
        onChange(newValue?.description)
    }

    return (
        <Autocomplete
            fullWidth
            options={placePredictions}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            // filterOptions={(x) => x}
            freeSolo
            value={value}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event: any, newValue: PlaceType | null) => {
                handleOnchange(newValue, name)
            }}
            onInputChange={(event, newInputValue) => {
                onChange(newInputValue)
                getPlacePredictions({ input: newInputValue });
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth
                    error={error}
                    helperText={helperText}
                    required={required}
                    InputLabelProps={{
                        style: { fontWeight: 600, fontSize: 18 }
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Typography variant="body2" color="text.secondary">
                                    {option.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    )
};

export default AddressAutoComplete